@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.promo3Card {
  display: flex;
  flex-direction: column;
  padding: h.rem(30px) 0;
  position: relative;

  &:nth-child(1),
  &:nth-child(2) {
    border-bottom: 1px var(--grey-40) solid;
  }

  @include h.breakpoint-l {
    flex: 1 0;

    &:nth-child(1) {
      border-bottom: 0;
      padding-right: var(--space-40);
    }

    &:nth-child(2) {
      border-bottom: 0;
      padding-left: var(--space-40);
      padding-right: var(--space-40);
    }

    &:nth-child(3) {
      padding-left: var(--space-40);
    }

    &:nth-child(1)::after,
    &:nth-child(2)::after {
      border-right: 1px var(--grey-40) solid;
      top: var(--promo-card--separator-border__top);
      bottom: var(--promo-card--separator-border__bottom);
      content: '';
      display: block;
      position: absolute;
      right: 0;
      width: 0;
    }
  }

  .promo3PanelButton {
    margin-top: h.rem(20px);
    width: h.rem(120px);

    // this solves an overlap issue because of the absolutely positioned image
    @media (min-width: 1000px) and (max-width: 1085px) {
      width: h.rem(100px);
    }
  }

  .promo3PanelButtonMultiLine {
    align-self: flex-start;
    width: auto;
    height: auto;
    padding: h.rem(10px);
    margin-top: h.rem(20px);
    max-width: calc(100% - 135px);

    @include h.breakpoint-x {
      max-width: calc(100% - 170px);
    }
  }
}

.promo3CardHeadline {
  color: var(--default-color);
  font-family: var(--default-headline-font-family);
  font-size: h.rem(24px);
  font-style: var(--normal);
  font-weight: var(--normal);
  margin-bottom: 0;
  margin-top: 0;

  :global(.msnbc) &,
  :global(.news) & {
    color: var(--black);
  }

  :global(.noticias) &,
  :global(.deportes) & {
    color: var(--grey-70);
  }

  :global(.think) & {
    color: var(--grey-70);
    font-family: var(--founders-cond);
  }

  :global(.today) & {
    color: var(--promo-headline-color);
    font-family: var(--promo-headline-font);
    font-size: h.rem(20px);
    font-style: var(--promo-headline-font-style);
    font-weight: var(--promo-headline-font-weight);
    margin-bottom: 3px;
    margin-top: 5px;
  }

  :global(.shows) &,
  :global(.entretenimiento) &,
  :global(.telemundo) & {
    color: var(--blue-60);
  }
}

.promo3CardDek {
  color: var(--grey-70);
  font-size: h.rem(14px);
  line-height: 1.5;
  margin: h.rem(10px) 0 auto;

  :global(.msnbc) &,
  :global(.today) &,
  :global(.news) & {
    color: var(--promo-card-dek-color);
    font-family: var(--promo-card-dek-font-family);
  }

  :global(.shows) &,
  :global(.entretenimiento) &,
  :global(.telemundo) &,
  :global(.deportes) &,
  :global(.noticias) & {
    color: var(--grey-60);
  }

  @include h.breakpoint-m {
    margin: 1rem 0 auto;
  }
}

.promo3CardImage {
  bottom: 0;
  position: absolute;
  right: 0;
  width: h.rem(100px);
  z-index: -1;

  @include h.breakpoint-m {
    width: h.rem(120px);
  }

  @include h.breakpoint-x {
    width: h.rem(155px);
  }
}

.promo3CardHasImage {
  .promo3CardDek {
    @include h.breakpoint-m {
      margin-right: 7rem;
    }

    @include h.breakpoint-l {
      margin-right: 6rem;
    }

    @include h.breakpoint-x {
      margin-right: 7.75rem;
    }
  }

  &:nth-child(3) {
    .promo3CardDek {
      @include h.breakpoint-l {
        margin-right: 8.5rem;
      }

      @include h.breakpoint-x {
        margin-right: 10rem;
      }
    }
  }
}

:global(.today) {
  @include h.breakpoint-m-max {
    .promo3Card {
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: var(--promo-card-border-bottom);

        &::after {
          @include h.pattern-dots-border(var(--grey-60), bottom);
          content: '\00a0';
          bottom: 0;
          display: block;
          left: 0;
          position: absolute;
          right: auto;
          top: auto;
          width: 100%;
        }
      }
    }
  }
}

:global(body.today) {
  .promo3Card {
    &:nth-child(1),
    &:nth-child(2) {
      &::after {
        background-image: none;
      }
    }
  }
}
