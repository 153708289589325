@use 'assets/styles/utils/helper' as h;

.timerContainer {
  font-family: var(--secondary-font);
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .timer {
    margin-top: 1.25rem;
    padding: 0 1.875rem;
    font-size: h.rem(20px);
    line-height: 1.5;
    height: 2.875rem;
    border-radius: h.rem(35px);
    text-transform: uppercase;
    background: var(--grey-20);
    transition: border-color 0.25s, background-color 0.25s, transform 0.25s;
    width: h.rem(330px);
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include h.breakpoint-m {
      width: 100%;
    }

    .newTime {
      color: var(--primary-color);
      font-weight: 700;
      padding-right: h.rem(6px);
    }
  }

  .previousRecord {
    font-size: h.rem(18px);
    display: flex;
    justify-content: center;
    padding-bottom: h.rem(16px);
    padding-top: h.rem(8px);
    color: var(--grey-70);
  }

  .newRecordTime {
    text-transform: capitalize;
    font-weight: 700;
  }
}
