@use 'assets/styles/utils/helper' as h;
@import '../../sassVariables.module.scss';

/* stylelint-disable property-no-unknown  */
/* stylelint-disable unit-no-unknown  */
/* stylelint-disable scss/at-rule-no-unknown */
/* stylelint-disable at-rule-no-unknown */
.#{$packageOuterClass} {
  .story-item {
    container-type: inline-size;
    container-name: story-item;

    --multistory-related-spacing: var(--multistory-related-spacing-override, 16px);
    // Spacer Heights
    --multistory-badge-spacer-height: 8px;
    --multistory-headline-spacer-height: 3px;
    --multistory-headline-under-media-height: 8px;
    --multistory-dek-spacer-height: 6px;
    --multistory-author-spacer-height: 6px;
    --multistory-media-spacer-height: 16px;

    &.show-bottom-border {
      padding-bottom: var(--msp-spacing);
      border-bottom: solid 1px var(--grey-50, rgba(153, 153, 153, 1));
    }

    @include h.breakpoint-s-only {
      margin-bottom: var(--msp-spacing);
      width: auto;

      // Probably not needed now(?)
      &:last-child {
        margin-bottom: 0;
      }
    }

    .media {
      text-align: center;
    }

    .mobile-multistory-media {
      display: block;

      @include h.breakpoint-m {
        display: none;
      }
    }

    .non-mobile-multistory-media {
      &.media-multiStandard,
      &.liveblog {
        order: 3;

        @include h.breakpoint-m {
          order: 0;
        }
      }

      &.media-multiSmall {
        display: block;
      }
    }

    .media.media-multiSmall {
      min-width: var(--multistory-small-media-width, 147px);
      max-width: var(--multistory-small-media-width, 147px);
    }

    .media.media-multiSmall.one-to-one-aspect {
      min-width: var(--multistory-small-media-width, 98px);
      max-width: var(--multistory-small-media-height, 98px);
    }

    .media.live-video-container {
      text-align: left;
    }

    .multi-item-container {
      display: flex;
      flex-direction: column;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      /* Spacers
      */
      // space above badge (below image except in mobile and w/small image
      .badge-top-spacer {
        height: var(--multistory-badge-spacer-height, 8px);
      }

      // space above headline and below badge
      .headline-top-spacer {
        height: var(--multistory-headline-spacer-height, 3px);
      }

      .headline-under-media-spacer {
        height: var(--multistory-headline-under-media-spacer, 8px);
      }

      .dek-author-timestamp {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
      }

      // space above dek and below headline
      .dek-top-spacer {
        height: var(--multistory-dek-spacer-height, 6px);
      }

      // space above byline/author and below dek
      .author-top-spacer {
        height: var(--multistory-author-spacer-height, 6px);
      }
      // space in mobile when image is at bottom of content.
      .media-top-spacer {
        height: 8px;

        @include h.breakpoint-m {
          height: var(--multistory-media-spacer-height, 16px);
        }
      }

      /* end of spacers
      */

      &.multi-small-container,
      &.contained-media-none {
        .badge-top-spacer {
          height: 0;
        }
      }

      @include h.breakpoint-s-only {
        &.contained-media-multiStandard {
          .badge-top-spacer {
            height: 0;
          }

          .headline-under-media-spacer {
            height: 0;
          }
        }
      }

      li.lbp-card:last-child {
        padding-bottom: 0;
      }

      .multistory-liveblog-content {
        order: 10;
        font-family: var(--publico-txt);

        &.multistory-no-liveblog-top-margin {
          .live-blog-pkg__list .lbp-card:first-child {
            padding-top: 0;
          }
        }
      }
    }

    .multi-small-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include h.breakpoint-m {
        .badge-top-spacer {
          height: 0;
        }
      }

      .media {
        display: var(--multistory-media-display, flex);
        text-align: left;
      }

      .related {
        display: flex;
        flex-direction: column;
      }
      // Small Media Hides
      @container (width < 225px) {
        --multistory-media-display: none;
      }
    }

    .headline-container-small {
      margin-left: 8px;

      &.one-to-one-media {
        width: calc(100cqw - 106px);
      }

      &.three-to-two-media {
        width: calc(100cqw - 155px);
      }
      // Small Media Hides
      @container (width < 225px) {
        // no left margin needed
        margin-left: 0;
        // headline width should be full or just auto
        &.one-to-one-media,
        &.three-to-two-media {
          width: auto;
        }
      }
    }

    .centered-content {
      text-align: center;
    }

    // mobile headline size when standard media or live blog
    .contained-media-multiStandard,
    &.item-type-liveBlog {
      @include h.breakpoint-s-only {
        .headline-small,
        .headline-standard,
        .headline-large,
        .headline-xl,
        .headline-xxl {
          .multistoryline__headline {
            font-size: 28px;
          }
        }

        .headline-mobile-no-media {
          .multistoryline__headline {
            font-size: 32px;
          }
        }
      }
    }

    .headline-small {
      .multistoryline__headline {
        font-size: 20px;
      }
    }

    .headline-small.no-media {
      .multistoryline__headline {
        @include h.breakpoint-s-only {
          font-size: 28px;
        }
      }
    }

    .headline-standard {
      .multistoryline__headline {
        font-size: 24px;
      }
    }

    .headline-standard.no-media {
      .multistoryline__headline {
        @include h.breakpoint-s-only {
          font-size: 28px;
        }
      }
    }

    .headline-large {
      .multistoryline__headline {
        font-size: 32px;
      }
    }

    .headline-xl {
      .multistoryline__headline {
        font-size: 40px;

        @include h.breakpoint-s-only {
          font-size: 32px;
        }
      }
    }

    .headline-xxl {
      .multistoryline__headline {
        font-size: 60px;

        @include h.breakpoint-s-only {
          font-size: 40px;
        }
      }
    }

    .headline-mobile-no-media {
      .multistoryline__headline {
        @include h.breakpoint-s-only {
          font-size: 32px;
        }
      }
    }

    .multistory-related-spacer {
      @include h.breakpoint-s-only {
        height: var(--multistory-related-spacing-override, 20px);
      }
      height: var(--multistory-related-spacing, 16px);
    }

    .multistory-liveblog-related-spacer {
      order: 10;
      height: var(--multistory-liveblog-related-spacing, 8px);
    }

    .related-content-tease.last-related {
      margin-bottom: 0;
    }

    .multi-story-main-content {
      text-align: left;
      color: var(--grey-70);
      font-size: 11px;

      &.centered-content {
        text-align: center;
      }

      time {
        color: var(--grey-60);
      }
    }

    .multi-related-border {
      border-top: 1px solid var(--grey-40);

      @include h.breakpoint-m {
        border-top: 1px solid var(--grey-40);
      }
    }

    @include h.breakpoint-m {
      .side-by-side {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .multi-related-container {
          width: calc(50% - 10px);
        }
      }
    }

    .multi-related-container {
      text-align: left;
    }
  }

  .story-item.image-lead {
    justify-content: space-around;

    @include h.breakpoint-s-only {
      width: 100%;
    }

    // @@NOTE: these are obsolete (?)
    .storyline-media {
      @include h.breakpoint-s-only {
        width: 100cqw;
      }

      @include h.breakpoint-m {
        width: 100%;
      }

      @include h.breakpoint-l {
        width: 100%;
      }

      @include h.breakpoint-x {
        width: 100%;
      }
    }
  }

  .story-item.not-image-lead {
    @include h.breakpoint-s-only {
      width: 100%;
    }

    .storyline-media {
      @include h.breakpoint-s-only {
        width: 100cqw;
      }

      @include h.breakpoint-l {
        width: 100%;
      }

      @include h.breakpoint-x {
        width: 100%;
      }
    }
  }

  .story-item.is-flex-item {
    flex: 1 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .story-item.is-2024-scoreboard-embed {
    // 3 rows of scoreboard results
    flex-basis: 503px;
  }

  @include h.breakpoint-m {
    .story-item.is-2024-scoreboard-embed {
      // 2 rows of scoreboard results
      flex-basis: 366px;
    }
  }

  .story-item.is-flex-item .multi-item-container,
  .story-item.is-flex-item .media {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
