@use 'assets/styles/utils/helper' as h;

.container {
  :global(.lead-type--wordSearchGame) & {
    padding-top: h.rem(40px);
  }
  display: flex;
  justify-content: center;

  @include h.breakpoint-s-only {
    padding-left: h.rem(10px);
    padding-right: h.rem(10px);
  }
}

.info {
  border-width: 0;
  padding: 0 h.rem(10px);
  min-width: h.rem(330px);

  :global(.share-button-word-search),
  :global(.word-search-timer) {
    display: none;
  }

  @include h.breakpoint-m {
    padding: 0;
    margin-right: h.rem(35px);
    flex-grow: 1;
    min-width: 244px;

    :global(.share-button-word-search),
    :global(.word-search-timer) {
      display: block;
    }
  }

  @include h.breakpoint-l {
    flex-grow: 0;
    width: 330px;
  }
}

.game {
  max-width: h.rem(960px);
  flex-wrap: wrap;

  @include h.breakpoint-m {
    display: flex;
  }
}

.title {
  font-family: var(--primary-font);
  font-size: h.rem(24px);
  color: var(--grey-70);
  font-weight: normal;
  margin: h.rem(0 0 10px);
  padding: 0 h.rem(10px);

  @include h.breakpoint-m {
    font-size: h.rem(48px);
  }
}

.description {
  font-family: var(--tertiary-font);
  font-size: h.rem(20px);
  line-height: 1.5;
  color: var(--grey-60);
  margin-top: 0;
  padding: 0 h.rem(10px);

  @include h.breakpoint-m {
    font-size: h.rem(24px);
    margin-bottom: h.rem(30px);
  }
}

.fillerWords {
  font-family: var(--tertiary-font);
  font-size: h.rem(20px);
  line-height: 1.5;
  color: var(--grey-60);

  @include h.breakpoint-m {
    font-size: h.rem(24px);
  }
}

.words,
.wordsLoading {
  background-color: var(--grey-20);
  padding: h.rem(25px 15px 35px);
  margin: h.rem(0 -10px 20px);

  @include h.breakpoint-m {
    max-width: h.rem(340px);
    margin: h.rem(0 -5px 16px);
  }
}

.wordsLoading,
.gridLoading {
  animation: loading 1.5s infinite alternate;
}

.wordsLoading {
  min-height: 150px;
}

.gridLoading {
  fill: var(--grey-20);
}

@keyframes loading {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.word {
  display: inline-block;
  position: relative;
  text-align: center;
  margin-right: h.rem(6px);
  margin-bottom: h.rem(10px);
  font-family: var(--secondary-font);
  font-size: h.rem(20px);
  font-weight: bold;
  height: h.rem(25px);
  vertical-align: middle;
  background: linear-gradient(to top, var(--grey-60) 1.5px, transparent 0);
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  :global(.share-button-word-search),
  :global(.word-search-timer) {
    display: block;
  }

  @include h.breakpoint-m {
    display: block;
    min-width: 352px;
    width: 352px;
    flex-grow: 0;
    flex-shrink: 0;

    :global(.share-button-word-search),
    :global(.word-search-timer) {
      display: none;
    }
  }

  @include h.breakpoint-l {
    min-width: 392px;
    width: 392px;
  }

  &.allWordsFound {
    pointer-events: none;

    :global(.share-button-word-search) {
      pointer-events: auto;
    }

    :global(.color-0) {
      background-color: var(--accent-orange);
    }

    :global(.color-1) {
      background-color: var(--accent-green);
    }

    :global(.color-2) {
      background-color: var(--accent-yellow);
    }

    :global(.color-3) {
      background-color: var(--accent-purple);
    }

    :global(.color-4) {
      background-color: var(--accent-blue);
    }

    :global(.color-5) {
      background-color: var(--grey-40);
    }

    :global(.horizontal),
    :global(.vertical) {
      width: 100%;
    }

    :global(.horizontal.first) {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
    }

    :global(.horizontal.last) {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    :global(.vertical.first) {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }

    :global(.vertical.last) {
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
}

.row {
  display: flex;
  width: 100%;
  justify-content: center;

  @include h.breakpoint-m {
    justify-content: unset;
  }
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--secondary-font);
  text-transform: uppercase;
  width: h.rem(44px);
  height: h.rem(44px);
  line-height: h.rem(44px);
  font-weight: var(--bold);
  font-size: h.rem(26px);
  cursor: pointer;
  border: none;
  background-color: var(--white);
  color: var(--grey-70);
  padding: 0;

  @media (hover: hover) {
    &:hover {
      background-color: var(--accent-blue-light);
      border-radius: 30px;
    }
  }
}

.selectedCell {
  background-color: transparent;
  border: 4px solid var(--accent-blue);
  border-radius: 30px;
}

@keyframes blink {
  0% { border-color: transparent; }
  50% { border-color: var(--primary-color); }
  100% { border-color: transparent; }
}

.incorrectCell {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: h.rem(36px);
    width: h.rem(36px);
    border-radius: 50%;
    border: 4px solid var(--primary-color);
    animation: blink 2s forwards;
  }
}

.matchedCell {
  background-color: var(--accent-blue);
  transition: background-color 500ms linear;

  @media (hover: hover) {
    &:hover {
      background-color: var(--accent-blue-light);
      border-radius: 0;
    }
  }
}

.matchedCell.selectedCell {
  filter: brightness(.85);
  border-radius: 0;
}

.winMessage {
  color: var(--primary-color);
  font-family: var(--primary-font);
  background-color: var(--accent-orange-light);
  text-align: center;
  padding: h.rem(20px 30px);
  position: relative;
  margin-top: h.rem(40px);

  &::before,
  &::after {
    content: '';
    display: block;
    width: h.rem(53px);
    height: h.rem(53px);
    background-image: url('data:image/svg+xml,<svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.1654 0.709766L35.0235 20.1959L52.276 30.1566L32.4637 32.239L28.3218 51.7251L20.219 33.5259L0.406689 35.6083L15.2112 22.2783L7.10843 4.07909L24.3609 14.0398L39.1654 0.709766Z" fill="%23FF503C"/></svg>');
    background-repeat: no-repeat;
    position: absolute;
  }

  &::before {
    left: h.rem(10px);
    top: h.rem(-20px);
  }

  &::after {
    transform: scale(.6);
    left: h.rem(-15px);
    top: h.rem(20px);
  }
}

.winTitle {
  font-size: h.rem(48px);
}

.winSubtitle {
  font-family: var(--secondary-font);
  font-size: h.rem(16px);
}

.horizontalFirst,
.horizontalFirst:hover,
.horizontalFirst.matchedCell.selectedCell {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.horizontalLast,
.horizontalLast:hover,
.horizontalLast.matchedCell.selectedCell {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.verticalFirst,
.verticalFirst:hover,
.verticalFirst.matchedCell.selectedCell {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.verticalLast,
.verticalLast:hover,
.verticalLast.matchedCell.selectedCell {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.overlappingCell {
  filter: brightness(.85);
}

.overlappingCell.verticalFirst,
.overlappingCell.verticalLast,
.overlappingCell.horizontalFirst,
.overlappingCell.horizontalLast {
  border-radius: 0;
}

.overlappingCell.horizontalFirst.verticalFirst {
  border-top-left-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.overlappingCell.horizontalFirst.verticalLast {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}

.overlappingCell.horizontalLast.verticalLast {
  border-bottom-right-radius: 30px;
}

.overlappingCell.horizontalLast.verticalFirst {
  border-top-right-radius: 30px;
}

.teaseCard {
  width: h.rem(330px);
  margin: 0 auto h.rem(30px);

  @include h.breakpoint-m {
    width: auto;
  }
}

.dek {
  font-family: var(--secondary-font);
  font-size: h.rem(20px);
  color: var(--grey-60);
}
