@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.link {
  display: inline-block;

  &:hover {
    opacity: var(--opacity-70);
  }
}

.header {
  margin: 0 0 var(--space-8);
}

.headline {
  color: var(--headline--color);
  display: inline-block;
  font-family: var(--headline--font-family);
  font-size: var(--text-24);
  font-style: var(--headline--font-style);
  font-weight: normal;
  line-height: var(--headline--line-height);

  @include h.breakpoint-x {
    font-size: var(--text-32);
  }

  &.dekless {
    margin: 0 0 var(--space-20);
  }
}

.label {
  color: var(--label--color);
  font-family: var(--label--font-family);
  font-size: var(--label--font-size);
  font-weight: var(--label--font-weight);
  line-height: var(--label--line-height);

  @include h.breakpoint-x {
    font-size: var(--label--font-size-xl);
  }

  &.labelBreaking {
    color: var(--label--breaking--color);
  }
}

.icon {
  color: currentColor;
  display: inline-block;
  font-size: var(--text-16);
  margin: 0 0 0 var(--space-8);
  vertical-align: 4px;

  @include h.breakpoint-x {
    font-size: var(--text-20);
  }
}

.dek {
  color: var(--grey-60);
  font-family: var(--publico-txt);
  font-size: var(--text-14);
  line-height: var(--leading-150);
  margin: 0 0 var(--space-20);
  max-width: 760px;
}
