@use 'assets/styles/utils/helper' as h;

.promo3Panel {
  border-color: var(--grey-40);
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  flex-direction: column;
  margin-left: var(--space-20);
  margin-right: var(--space-20);
  position: relative;

  @include h.breakpoint-m {
    margin-left: 0;
    margin-right: 0;
  }

  @include h.breakpoint-l {
    flex-direction: row;
  }

  :global(.today) {
    @include h.pattern-dots-border(var(--grey-60), 0 top);
    background-clip: padding-box;
    border: none;

    &::after {
      content: '\00a0';
      display: block;

      @include h.pattern-dots-border(var(--grey-60), 0 bottom);
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}
