@use 'assets/styles/utils/helper' as h;

.multi-storyline-container__lead-item-container {
  --multistory-lead-dek-spacer-height: 8px;
  --multistory-lead-author-spacer-height: 8px;
  text-align: center;
  font-family: var(--primary-font);
  margin-bottom: 20px;

  @include h.breakpoint-s-only {
    padding: 0 20px;
    margin-bottom: 8px;
  }

  .lead-headline {
    > a:hover {
      opacity: 70%;
    }

    @include h.breakpoint-s-only {
      margin: 0 20px;
    }
  }

  .small {
    font-size: h.rem(40px);
    line-height: 40px;
  }

  .medium {
    font-size: h.rem(60px);
    line-height: 60px;

    @include h.breakpoint-s-only {
      font-size: h.rem(40px);
      line-height: 40px;
    }
  }

  .large {
    font-size: h.rem(96px);
    line-height: 96px;

    @include h.breakpoint-s-only {
      font-size: h.rem(48px);
      line-height: 48px;
    }
  }

  .lead-item-dek {
    font-size: 20px;
    font-family: var(--publico-txt);

    @include h.breakpoint-s-only {
      font-size: h.rem(18px);
    }
  }

  // space above dek and below headline
  .dek-top-spacer {
    height: var(--multistory-dek-spacer-height, 8px);
  }

  // space above byline/author and below dek
  .author-top-spacer {
    height: var(--multistory-author-spacer-height, 8px);
  }

  .lead-item-byline {
    font-family: var(--founders-mono);
    font-size: h.rem(12px);
    font-weight: 400;
    color: var(--grey-70, #2a2a2a);
  }
}
