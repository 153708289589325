@use './theme.tokens' as t;
@use 'assets/styles/utils/helper' as h;

.tve__signin {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  color: var(--grey-70);
  height: 100%;

  &__action {
    border: none;
    outline: none;
    appearance: none;
    background-color: var(--tve__action-color-base);
    display: inline-block;
    height: 100%;
    font-family: var(--founders-cond);
    font-size: h.rem(16px);
    line-height: 1;
    text-transform: uppercase;
    padding: h.rem(10px 8px);

    .liveBlog & {
      font-size: var(--text-16);
      line-height: h.rem(19px);

      @include h.breakpoint-l {
        width: h.rem(88px);
      }
    }

    &:hover {
      background-color: var(--tve__action-color-hover);
    }

    &:active {
      background-color: var(--tve__action-color-active);
    }

    &--authed {
      font-size: h.rem(14px);
      color: var(--tve__action-color-base);
      background-color: transparent;

      &:hover {
        color: var(--tve__action-color-hover);
        background-color: transparent;
      }

      &:active {
        color: var(--tve__action-color-active);
        background-color: transparent;
      }

      .liveBlog & {
        background-color: var(--tve__action-color-base);
        color: var(--black);
      }
    }

    &--withLock {
      padding: 0 h.rem(32px);

      @include h.breakpoint-l {
        padding: 0;
        width: h.rem(60px);
      }

      @include h.breakpoint-x {
        padding: 0;
        width: h.rem(80px);
      }
    }
  }

  &__lock {
    font-size: h.rem(18px);

    @include h.breakpoint-l {
      font-size: h.rem(24px);
    }
  }

  &__label {
    display: inline-block;

    .tve__signin__action--withLock & {
      margin-left: h.rem(6px);

      @include h.breakpoint-l {
        display: none;
      }
    }
  }
}
