@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.play {
  background-color: var(--video-slate-flag-default-background-color);
  height: var(--video-slate-flag-height);
  opacity: var(--video-slate-flag-default-opacity);
  pointer-events: none;
  transition: opacity 200ms linear;
  width: var(--video-slate-flag-width);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  position: relative;
  border-radius: var(--video-slate-flag-border-radius);
  border: var(--video-slate-flag-border);

  &--live {
    background-color: var(--video-slate-flag-live-background-color);
  }

  @include h.breakpoint-m {
    // Constrain if used on small thumbnail:
    max-height: var(--video-slate-flag-max-height);
    max-width: var(--video-slate-flag-max-width);
  }

  &--icon {
    display: var(--video-slate-flag-icon-display);
    fill: var(--video-slate-flag-icon-fill);
    height: var(--video-slate-flag-icon-height);
    width: var(--video-slate-flag-icon-width);
    margin-left: var(--video-slate-flag-icon-margin-left);
  }

  &::after {
    display: var(--video-slate-icon-after-display);
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    content: '';
    width: 20%;
    height: 23.2%;
    background-color: var(--video-slate-icon-after-bkg-color);
    top: 38%;
    left: 43%;
    position: absolute;
  }
}

.videoSlate {
  line-height: 0;
  cursor: pointer;
  // stylelint-disable-next-line property-no-unknown
  container: videoSlate / inline-size;

  &:hover .play {
    opacity: 1;
  }

  &.rePlayOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1020;
  }
}

.playButtonOuter {
  display: var(--video-player--play-button-outer--display);
  pointer-events: none;
  padding-top: 56.25%;
}

.replayIconContainer {
  pointer-events: none;
  z-index: 2;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.replayIcon {
  font-size: 10vw;

  @include h.breakpoint-m {
    font-size: 60px;
  }
}

.expiredOverlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.expiredText {
  line-height: 1;
}

/* stylelint-disable-next-line scss/at-rule-no-unknown, at-rule-no-unknown */
@container videoSlate (min-width: 0) {
  .expiredText {
    // stylelint-disable-next-line unit-no-unknown
    font-size: clamp(1rem, 6cqi, 2rem);
  }
}
