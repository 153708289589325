@use 'assets/styles/utils/helper' as h;

.tagline {
  color: var(--grey-60);
  display: block;
  font-family: var(--lato);
  font-size: h.rem(20px);
  font-weight: var(--bold);
  line-height: var(--leading-100);
  margin: 0;
  padding: h.rem(24px 10px);
  text-align: center;

  @include h.breakpoint-l {
    font-size: h.rem(24px);
    padding: h.rem(40px 10px);
  }
}
