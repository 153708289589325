@use 'assets/styles/utils/helper' as h;

.single-storyline__section-title {
  color: var(--blue-40);
  font-family: var(--publico-txt);
  font-size: h.rem(16px);
  margin-bottom: 16px;
  font-weight: 700;

  @include h.breakpoint-s-only {
    margin-left: 20px;
    margin-right: 20px;
  }
}
