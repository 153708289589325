:global(body.select) {
  --waffle--promo-code-icon-color: var(--green-70);
  --waffle--promo-code-font: var(--lato);
  --waffle--promo-code-font-weight: 900;
  --waffle--promo-code-hover-background-color: #a3cd3933;
}

:global(body.today) {
  --waffle--promo-code-icon-color: var(--orange-30);
  --waffle--promo-code-font: var(--secondary-font);
  --waffle--promo-code-font-weight: 700;
  --waffle--promo-code-hover-background-color: var(--accent-orange-light);
}
