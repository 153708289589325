@use 'assets/styles/utils/helper' as h;

.pageIndicators {
  display: flex;
  gap: 5px;
  margin-top: var(--space-12);
  margin-right: var(--space-48);
}

@media (hover: none) {
  .pageIndicators {
    display: none;
  }
}

.pageIndicator {
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;
  transition: background 0.25s ease-in-out;
  border: 1px solid var(--grey-50);
  background: transparent;
}

.activePageIndicator {
  background: var(--blue-40);
  border-color: var(--blue-40);
}
