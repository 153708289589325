@use 'assets/styles/utils/helper' as h;

.magazineLead {
  // Outer container
  --mlp--background-image: none;
  --mlp--color: var(--default-text-color);

  // Inner wrapper
  --mlp--inner--padding: 112px 0 20px;

  // Border used by title and see-all button wrappers
  --mlp--wrapper--border: 1px solid var(--default-color);

  // Package title
  --mlp--title--color: var(--default-text-color);
  --mlp--title--font-family: var(--default-headline-font-family);
  --mlp--title--font-size: var(--text-24);
  --mlp--title--font-weight: var(--semibold);
  --mlp--title--font-style: normal;
  --mlp--title--transform: uppercase;
  --mlp--title--line-height: var(--leading-150);

  // See all button
  --mlp--see-all-btn--bg-color: var(--default-color);
  --mlp--see-all-btn--border-color: var(--default-color);
  --mlp--see-all-btn--color: var(--default-color);
  --mlp--see-all-btn--hover-color: var(--white);

  // Tease styling
  --mlp--tease--background-image: none;
  --mlp--image--border-radius: none;
  --mlp--headline--color: var(--default-headline-color);
  --mlp--headline--font-family: var(--default-headline-font-family);
  --mlp--headline--font-size: var(--text-18);
  --mlp--headline--font-style: normal;
  --mlp--headline--font-weight: var(--normal);
  --mlp--headline--line-height: var(--leading-100);
  --mlp--unibrow--color: var(--default-color);
  --mlp--unibrow--font-family: var(--founders-mono);
  --mlp--unibrow--font-size: var(--text-12);
  --mlp--unibrow--font-weight: var(--light);
  --mlp--unibrow--letter-spacing: var(--tracking-tight);
  --mlp--unibrow--line-height: var(--leading-100);
  --mlp--unibrow--text-transform: uppercase;

  @include h.breakpoint-m {
    --mlp--inner--padding: 188px 0 20px;
    --mlp--title--font-size: var(--text-32);
    --mlp--title--line-height: var(--leading-100);
    --mlp--headline--font-size: var(--text-16);
  }

  @include h.breakpoint-l {
    --mlp--inner--padding: 198px 0 20px;
  }

  @include h.breakpoint-x {
    --mlp--inner--padding: 190px 0 20px;
    --mlp--headline--font-size: var(--text-18);
  }

  :global(body.today) & {
    --mlp--background-image: linear-gradient(104deg, #ff503c, #a1127e 82%, #8d048d), linear-gradient(106deg, #ff503c, #8d048d);
    --mlp--color: var(--white);
    --mlp--wrapper--border: 1px solid var(--white);
    --mlp--see-all-btn--bg-color: var(--white);
    --mlp--see-all-btn--border-color: var(--white);
    --mlp--see-all-btn--color: var(--white);
    --mlp--see-all-btn--hover-color: var(--default-color);
    --mlp--tease--background-image: linear-gradient(to right, var(--white) 10%, transparent 20%);
    --mlp--title--color: var(--white);
    --mlp--title--line-height: var(--leading-100);
    --mlp--title--font-weight: var(--light);
    --mlp--title--font-style: italic;
    --mlp--title--transform: normal;
    --mlp--headline--color: var(--white);
    --mlp--headline--font-size: var(--text-16);
    --mlp--headline--line-height: var(--leading-125);
    --mlp--unibrow--color: var(--white);

    @include h.breakpoint-m {
      --mlp--headline--font-size: var(--text-14);
    }

    @include h.breakpoint-x {
      --mlp--headline--font-size: var(--text-16);
    }
  }

  :global(body.think) & {
    --mlp--title--line-height: var(--leading-125);
    --mlp--title--font-weight: var(--light);
    --mlp--title--font-style: italic;
    --mlp--title--transform: normal;
    --mlp--image--border-radius: 50%;
  }

  :global(body.today) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --mlp--image--border-radius: 50%;
  }

  :global(body.entretenimiento) & {
    --mlp--color: var(--blue-60);
    --mlp--title--color: var(--blue-60);
    --mlp--headline--color: var(--blue-60);
  }

  :global(body.noticias) &,
  :global(body.deportes) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --mlp--inner--padding: 0 0 20px 0;
  }
}
