@use 'assets/styles/utils/helper' as h;

.video-live {
  --video-live-background-color: var(--grey-70);
  --video-live--margin-left: -40px;
  --video-live-info---font-size: #{h.rem(16px)};

  @include h.breakpoint-l {
    --video-live--margin-left: calc((880px - 100vw) / 2);
    --video-live--margin-right: calc((880px - 100vw) / 2);
  }

  @include h.breakpoint-x {
    --video-live--margin-left: calc((1120px - 100vw) / 2);
    --video-live--margin-right: calc((1120px - 100vw) / 2);
  }

  body.today & {
    --video-live-background-color: var(--purple-70);
    --video-live--margin-left: auto;
    --video-live--margin-right: auto;
  }
}
