@use 'assets/styles/utils/helper' as h;

.featuredArticle {
  // container styling
  display: flex;
  flex-flow: column nowrap;

  @include h.breakpoint-m {
    display: flex;
    flex-flow: row nowrap;
  }

  .picture {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    margin: 0 0 h.rem(10px) 0;
    position: relative;

    @include h.breakpoint-m {
      margin: 0 h.rem(12px) auto 0;
    }

    img {
      height: auto;
      width: 100%;

      @include h.breakpoint-m {
        width: h.rem(303px);
      }

      @include h.breakpoint-l {
        width: h.rem(200px);
      }
    }
  }

  .unibrow {
    font-family: var(--founders-mono);
    color: var(--blue-40);
    font-size: h.rem(12px);
    font-weight: normal;
    line-height: 1;
    margin: 0 0 h.rem(6px) 0;
    text-transform: uppercase;

    &.withDate {
      color: var(--blue-40);
    }
  }

  .headline {
    font-family: var(--founders-cond);
    font-size: h.rem(28px);
    font-weight: 600;
    line-height: 1;
    margin: 0;

    @include h.breakpoint-m {
      font-size: h.rem(24px);
    }

    @media only screen and (min-width: h.$grid-x-width) {
      font-size: h.rem(28px);
    }
  }

  .date {
    color: var(--blue-40);
  }

  .author {
    font-family: var(--founders-mono);
    font-weight: normal;
    font-size: h.rem(12px);
    line-height: 1;
    margin: h.rem(10px) 0 0;
    padding: 0;

    > * {
      &::before {
        content: ', ';
      }

      &:last-child::before {
        content: ' & ';
      }

      &:first-child::before {
        content: '';
      }
    }

    a {
      color: var(--blue-40);
    }
  }
}
