@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

// Override package spacing of ups in sticky ad track
.stickyAdTrack .contentItems > section {
  :global(.tease-card) {
    @include h.package-spacing;
  }

  &:last-child :global(.tease-card):last-child {
    margin-bottom: 0;
  }
}

.adColumn {
  padding: var(--sat-ad-col--padding);
  order: 1;

  @include h.breakpoint-l {
    .mirror & {
      order: 2;
    }
  }
}

.contentColumn {
  order: 2;
  position: relative;

  @include h.breakpoint-l {
    .mirror & {
      order: 1;
    }
  }

  .hasTitle & {
    &::before {
      background-color: var(--sat--content--before--background-color);
      border: var(--sat--content--before--border);
      border-width: var(--sat--content--before--border-width);
      content: var(--sat--content--before--content);
      height: var(--sat--content--before--height);
      left: var(--sat--content--before--left);
      position: absolute;
      top: var(--sat--content--before--top);
      width: var(--sat--content--before--width);
      z-index: 0;
    }
  }
}

.ad {
  display: none;

  @include h.breakpoint-l {
    display: block;
  }
}

.head {
  border: var(--sat--head--border);
  border-width: var(--sat--head--border-width);
  background-color: var(--sat--head--background-color);
  display: var(--sat--head--display);
  margin: var(--sat--head--margin);
  max-width: var(--sat--head--max-width);
  padding: var(--sat--head--padding);
  position: relative;

  &::after {
    border: var(--sat--head--after--border);
    border-width: var(--sat--head--after--border-width);
    content: var(--sat--head--after--content);
    height: var(--sat--head--after--height);
    position: absolute;
    right: var(--sat--head--after--right);
    top: var(--sat--head--after--top);
    width: var(--sat--head--after--width);
  }
}

.title {
  color: var(--sat--title--color);
  display: var(--sat--title--display);
  font-family: var(--sat--title--font-family);
  font-size: var(--sat--title--font-size);
  font-style: var(--sat--title--font-style);
  font-weight: var(--sat--title--font-weight);
  line-height: var(--sat--title--line-height);
  margin: var(--sat--title--margin);
  position: relative;
  text-transform: var(--sat--title--text-transform);
}

.link {
  border-bottom: var(--sat--link--border-bottom);
  color: var(--sat--link--color);
  display: inline-block;
  font-family: var(--sat--link--font-family);
  font-size: var(--sat--link--font-size);
  line-height: var(--sat--link--line-height);
  padding: var(--sat--link--padding);
  position: relative;
  text-transform: var(--sat--link--text-transform);
  white-space: nowrap;
  z-index: 2;
}
