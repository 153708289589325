@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;
@use 'sass:math';

.three-up__articles {
  @include h.clearfix;
  background-color: var(--three-up-articles-background-color);
  position: relative;

  // TODO: remove when package spacing is standardized for all teases
  & > article {
    // include spacing on the bottom of all teases on mobile
    @include h.package-spacing;

    // last child on mobile should not have spacing due to layout-applied spacing
    &:last-child {
      margin-bottom: 0;
    }

    // all other breakpoints handle spacing at layout-level
    @include h.breakpoint-x {
      margin-bottom: 0;
    }
  }

  @include h.breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .msnbc & {
    &.three-up__accent-line {
      border-top: 5px solid #ffbe03;
    }
  }
}

.slideshow__pictures {
  background-color: var(--default-color);
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{t.$slideshow-outer-gutter});

  @include h.breakpoint-m {
    position: relative;
    width: auto;
    padding-left: 0;
  }

  &::before {
    font-family: var(--founders-cond);
    font-weight: normal;
    font-size: h.rem(22px);
    line-height: h.rem(22px);
    background-color: var(--default-color);
    content: attr(data-count);
    color: var(--white);
    flex-basis: 50%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include h.breakpoint-m {
      order: 3;
    }

    @include h.breakpoint-x {
      order: 2;
    }
  }
}

.slideshow__picture {
  flex-basis: 50%;

  &:first-child {
    order: 1;

    @include h.breakpoint-m {
      position: absolute;
      left: 0;
      width: 50%;
    }

    @include h.breakpoint-x {
      position: static;
    }
  }

  &:nth-child(2) {
    order: 3;

    @include h.breakpoint-m {
      order: 2;
    }

    @include h.breakpoint-x {
      order: 3;
    }
  }

  &:nth-child(3) {
    order: 4;

    @include h.breakpoint-m {
      flex-basis: 100%;
    }

    @include h.breakpoint-x {
      flex-basis: 50%;
    }
  }
}

.tease-card__eyebrow {
  letter-spacing: var(--tease-card-eyebrow-letter-spacing);
}

.tease-card--slideshow {
  article.tease-card__content {
    .tease-card__title {
      .sponsoredcontent & {
        font-family: var(--founders-cond);
      }

      .tease-card__headline--deepLink-episode {
        font-size: h.rem(20px);

        @include h.breakpoint-m {
          font-size: h.rem(20px);
          line-height: var(--leading-125);
        }
      }
    }

    .tease-card__featured-author {
      @include h.responsive-variations(font-size, (s: h.rem(20px),m: h.rem(26px),l: h.rem(20px)));
      @include h.responsive-variations(line-height, t.$tease-card-slideshow-featured-author-line-heights);

      &--today,
      &--think {
        @include h.responsive-variations(font-size, (s: h.rem(18px),m: h.rem(23.4px),l: h.rem(18px)));
      }
    }

    .tease-card__dek {
      font-size: h.rem(15px);
      line-height: h.rem(22px);
    }

    &:first-of-type {
      @include h.responsive-variations(flex-basis, (s: 100%, m: calc(50% - 1px), x: calc(33.3% - 1.33px)));

      .tease-card__title {
        @include h.responsive-variations(font-size, (s: h.rem(24px)));
        line-height: var(--tease-card-slideshow-line-height);

        &--select {
          @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(18px), l: h.rem(24px), x: h.rem(18px)));
        }

        &--think,
        &--today {
          @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px), l: h.rem(20px), x: h.rem(18px)));
        }
      }
    }

    &--select:first-of-type {
      @include h.responsive-variations(flex-basis, (s: 100%, m: calc(33.3% - 1.33px), l: 100%, x: calc(33.3% - 1.33px)));
    }

    &:nth-of-type(2) {
      @include h.responsive-variations(flex-basis, (s: 100%, m: calc(50% - 1px), x: calc(33.3% - 1.33px)));

      .tease-card__title {
        @include h.responsive-variations(font-size, (s: h.rem(24px)));
        line-height: var(--tease-card-slideshow-line-height);

        &--select {
          @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(18px), l: h.rem(22px), x: h.rem(18px)));
        }

        &--today,
        &--think {
          @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px), l: h.rem(20px), x: h.rem(18px)));
        }
      }
    }

    &--select:nth-of-type(2) {
      @include h.responsive-variations(flex-basis, (s: 100%, m: calc(33.3% - 1.33px), l: calc(50% - 1px), x: calc(33.3% - 1.33px)));
    }

    &:nth-of-type(3) {
      @include h.responsive-variations(flex-basis, (s: 100%, x: calc(33.3% - 1.33px)));

      .tease-card__title {
        @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(32px), x: h.rem(24px)));
        line-height: var(--tease-card-slideshow-line-height);

        &--select {
          @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(18px), l: h.rem(22px), x: h.rem(18px)));
        }

        &--today,
        &--think {
          @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(32px), l: h.rem(24px), x: h.rem(18px)));
        }
      }
    }

    &--select:nth-of-type(3) {
      @include h.responsive-variations(flex-basis, (s: 100%, m: calc(33.3% - 1.33px), l: calc(50% - 1px), x: calc(33.3% - 1.33px)));
    }

    @include h.breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
    }

    &:last-child {
      .tease-card__title {
        .tease-card--no-main-image {
          font-size: h.rem(22px);
          line-height: h.rem(30px);
        }

        .tease-card__headline--deepLink-episode {
          font-size: h.rem(20px);

          @include h.breakpoint-m {
            font-size: h.rem(28px);
            line-height: var(--leading-125);
          }

          @include h.breakpoint-x {
            font-size: h.rem(20px);
            line-height: var(--leading-100);
          }
        }
      }

      .tease-card__featured-author {
        @include h.responsive-variations(font-size, (l: h.rem(26px), x: h.rem(20px)));
        @include h.responsive-variations(line-height, t.$tease-card-slideshow-last-child-featured-author-line-heights);

        &--today,
        &--think {
          @include h.responsive-variations(font-size, (l: h.rem(23.4px), x: h.rem(18px)));
        }
      }

      .tease-card__dek {
        @include h.breakpoint-x {
          font-size: h.rem(14px);
          line-height: h.rem(22px);
        }
      }
    }

    &:nth-child(n+2) {
      .tease-card__dek {
        @include h.breakpoint-m {
          font-size: h.rem(14px);
          line-height: h.rem(22px);
        }
      }
    }

    &.tease-card--no-main-image {
      .tease-card__title {
        @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), l: h.rem(24px), x: h.rem(32px)));
        line-height: var(--tease-card-slideshow-no-main-image-title-line-height);

        &--today {
          @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(24px), x: h.rem(20px)));
        }

        &--think {
          @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(35px), l: h.rem(24px), x: h.rem(20px)));
        }
      }
    }
  }
}

// stylelint-disable no-descending-specificity

.tease-card--with-paid-content-slideshow {
  .tease-card__content {
    .tease-card__featured-author {
      @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(26px),l: h.rem(20px)));
      @include h.responsive-variations(line-height, t.$tease-card--with-paid-content-slideshow-featured-author-line-heights);

      &--today,
      &--think {
        @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(23.4px),l: h.rem(18px)));
      }
    }

    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(30px), l: h.rem(24px)));
      @include h.responsive-variations(line-height, t.$tease-card-with-paid-content-slideshow-title-line-heights);

      &--today,
      &--think {
        @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(22.5px), l: h.rem(18px)));
      }
    }

    .tease-card__dek {
      font-size: h.rem(15px);
      line-height: h.rem(22px);
    }

    @include h.breakpoint-m {
      @include h.flex-tease-space(50%, 2);

      &:nth-of-type(2) {
        .tease-card__title {
          .tease-card--no-main-image {
            font-size: h.rem(22px);
            line-height: h.rem(30px);
          }
        }

        .tease-card__featured-author {
          @include h.responsive-variations(font-size, (l: h.rem(26px),x: h.rem(20px)));
          @include h.responsive-variations(line-height, t.$tease-card-slideshow-last-child-featured-author-line-heights);

          &--today,
          &--think {
            @include h.responsive-variations(font-size, (l: h.rem(23.4px),x: h.rem(18px)));
          }
        }

        .tease-card__dek {
          @include h.breakpoint-x {
            font-size: h.rem(14px);
            line-height: h.rem(22px);
          }
        }
        order: 3;
        flex-basis: 100%;
        margin-bottom: 0;

        .imageCredit {
          display: block;
        }
      }

      &:nth-child(n+2) {
        .dek {
          @include h.breakpoint-m {
            font-size: h.rem(14px);
            line-height: h.rem(22px);
          }
        }
      }
    }

    @include h.breakpoint-x {
      @include h.flex-tease-space(math.div(1, 3) * 100%, 3);

      &:nth-of-type(2) {
        flex-basis: calc(33.33% - 1.33px);

        .imageCredit {
          display: none;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;

      @include h.breakpoint-m {
        order: 2;
        flex-basis: 50%;
        margin-bottom: h.rem(48px) !important;
      }

      @include h.breakpoint-x {
        @include h.flex-tease-space(math.div(1, 3) * 100%, 3);
        order: 3;
        flex-basis: calc(33.33% - 1.33px);
        margin-bottom: 0 !important;
      }
    }

    .tease-card--no-main-image {
      .tease-card__title {
        font-size: h.rem(22px);
        line-height: h.rem(30px);
      }
    }
  }
}

.stickyAdTrack--7up .three-up--7up .tease-card__content .tease-card__dek {
  font-size: h.rem(14px) !important;
  line-height: h.rem(21px) !important;
}
