@use 'assets/styles/utils/helper' as h;

.msnbcDaily {
  // container styling
  background-color: #f2f2f2;
  color: var(--grey-70);

  .count {
    background-color: #f2f2f2;
  }

  .featured {
    margin-bottom: h.rem(30px);
    margin-top: h.rem(-30px);
    padding: 0 h.$outer-gutter;

    &.numbered {
      margin-top: h.rem(20px);
    }

    @include h.breakpoint-m {
      margin-top: h.rem(30px);
      padding: 0;
    }

    @media only screen and (min-width: h.$grid-m-width) and (max-width: h.$grid-l-width - 1) {
      margin-left: h.$grid-col-width-tablet;
      margin-right: h.$grid-col-width-tablet;
    }

    @include h.breakpoint-l {
      margin-bottom: h.rem(20px);
      margin-top: h.rem(20px);
    }

    @include h.breakpoint-x {
      margin-bottom: h.rem(30px);
      margin-top: h.rem(30px);
    }
  }

  .featuredItem {
    @include h.breakpoint-m {
      display: flex;
      flex-flow: row no-wrap;
    }
  }

  .grid {
    margin-top: h.rem(30px);
    margin-bottom: h.rem(30px);

    @media only screen and (min-width: h.$grid-m-width) and (max-width: h.$grid-l-width - 1) {
      margin-left: h.$grid-col-width-tablet;
      margin-right: h.$grid-col-width-tablet;
      justify-content: space-between;
    }

    @include h.breakpoint-l {
      margin-top: h.rem(20px);
    }

    @include h.breakpoint-x {
      margin-top: h.rem(30px);
    }
  }

  .gridItem {
    margin-bottom: h.rem(30px);

    @media only screen and (max-width: h.$grid-m-width - 1) {
      padding-left: h.$outer-gutter;
      padding-right: h.$outer-gutter;
    }

    @media only screen and (min-width: h.$grid-m-width) and (max-width: h.$grid-l-width - 1) {
      width: calc(50% - 10px);
    }

    @include h.breakpoint-m {
      display: flex;
      flex-flow: row no-wrap;
    }
  }

  // Manually control column sorting
  @include h.breakpoint-m {
    .grid {
      /* stylelint-disable no-descending-specificity */
      &[data-count='4'] .gridItem {
        &:nth-child(1) { order: 1; }
        &:nth-child(2) { order: 3; }
        &:nth-child(3) { order: 2; }
        &:nth-child(4) { order: 4; }
      }

      &[data-count='6'] .gridItem {
        &:nth-child(1) { order: 1; }
        &:nth-child(2) { order: 3; }
        &:nth-child(3) { order: 5; }
        &:nth-child(4) { order: 2; }
        &:nth-child(5) { order: 4; }
        &:nth-child(6) { order: 6; }
      }

      &[data-count='8'] .gridItem {
        &:nth-child(1) { order: 1; }
        &:nth-child(2) { order: 3; }
        &:nth-child(3) { order: 5; }
        &:nth-child(4) { order: 7; }
        &:nth-child(5) { order: 2; }
        &:nth-child(6) { order: 4; }
        &:nth-child(7) { order: 6; }
        &:nth-child(8) { order: 8; }
      }

      &[data-count='10'] .gridItem {
        &:nth-child(1) { order: 1; }
        &:nth-child(2) { order: 3; }
        &:nth-child(3) { order: 5; }
        &:nth-child(4) { order: 7; }
        &:nth-child(5) { order: 9; }
        &:nth-child(6) { order: 2; }
        &:nth-child(7) { order: 4; }
        &:nth-child(8) { order: 6; }
        &:nth-child(9) { order: 8; }
        &:nth-child(10) { order: 10; }
      }

      &[data-count='12'] .gridItem {
        &:nth-child(1) { order: 1; }
        &:nth-child(2) { order: 3; }
        &:nth-child(3) { order: 5; }
        &:nth-child(4) { order: 7; }
        &:nth-child(5) { order: 9; }
        &:nth-child(6) { order: 11; }
        &:nth-child(7) { order: 2; }
        &:nth-child(8) { order: 4; }
        &:nth-child(9) { order: 6; }
        &:nth-child(10) { order: 8; }
        &:nth-child(11) { order: 10; }
        &:nth-child(12) { order: 12; }
      }
      /* stylelint-enable no-descending-specificity */
    }
  }
}
