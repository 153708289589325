@use 'assets/styles/utils/helper' as h;

.badgeLabel {
  font-family: var(--founders-mono);
  font-size: 12px;
  line-height: 100%;
  font-weight: 400;
  background-color: #ebebeb;
  color: #3061ff;
  border-radius: 3px;
  letter-spacing: -0.04em;
  display: inline;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  margin-right: 6px;
}

.icon {
  flex-shrink: 0;
  margin-right: 3.5px;
}

.playmakerBadgeLabel {
  font-family: var(--founders-mono);
  font-size: 12px;
  line-height: 100%;
  font-weight: 400;
  background-color: var(--grey-20);
  color: #3061ff;
  border-radius: 3px;
  letter-spacing: -0.04em;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  margin-right: 6px;
  position: relative;
  bottom: 3px;
}

.blackBadge {
  color: #000;
}

.redBadge {
  background-color: h.$breaking-red;
  color: #fff;
}

.yellowBadge {
  background-color: h.$nbc-peacock-yellow;
  color: #000;
}

.sportsBadge {
  display: inline-block;
  height: 19.7px;

  .icon {
    vertical-align: middle;
  }
}

.watchBadge {
  color: #3061ff;
  position: relative;
  top: 2px;

  img {
    margin-bottom: -2px;
  }
}

.inlineBlinkingDot {
  height: 4px;
  width: 4px;
  background-color: var(--live-dot--background-color);
  border-radius: 50%;
  display: inline-block;
  animation: live-dot-blink 1.5s ease-in-out infinite;
  vertical-align: middle;
  margin-right: 3px;
}

.iconPosition {
  margin-bottom: -5px;
  bottom: 4px;
}
