@use 'assets/styles/utils/helper' as h;
@import '../sassVariables.module.scss';

/* stylelint-disable
    property-no-unknown,
    at-rule-no-unknown,
    scss/at-rule-no-unknown
    --
    Reason: To use containers.
*/
/* stylelint-disable scss/operator-no-newline-before
    --
    Reason: Putting the minus or plus in the first column makes
    it easier to understand the formula (and then simplify).
*/

.#{$packageOuterClass} {
  .multistory-container {
    --msp-column-gap: var(--msp-spacing);
    --msp-vertical-divider-width: 1px;
    --msp-3col-lead-width: .58; // 58%;

    /* eslint-disable-next-line property-no-unknown */
    container-type: inline-size;
    container-name: multistory-container;

    --multistory-lead-dek-spacer-height: 8px;
    --multistory-lead-author-spacer-height: 8px;

    .multi-storyline-container__package-title {
      color: var(--blue-40, #3061ff);
      margin-bottom: 16px;
      font-family: var(--publico-txt);
      font-size: h.rem(16px);
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 48px */

      @include h.breakpoint-s-only {
        margin-left: var(--msp-spacing);
        margin-right: var(--msp-spacing);
      }
    }

    &.package-go-big {
      .multi-storyline-container__package-title {
        color: var(--grey-70, #2a2a2a);
        text-align: center;
        font-family: var(--founders-cond);
        font-size: h.rem(90px);

        @include h.breakpoint-s-only {
          font-size: h.rem(60px);
        }
      }
    }
  }
}
