@use 'assets/styles/utils/helper' as h;

.textSummary {
  --text-summary--color: var(--default-text-color);
  --text-summary--font-family: var(--default-text-font-family);
  --text-summary--font-size: #{h.rem(14px)};

  :global(body.noticias) &,
  :global(body.deportes) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --text-summary--color: var(--grey-60);
  }

  :global(body.today) & {
    --text-summary--font-family: var(--secondary-font);
    --text-summary--font-size: #{h.rem(16px)};
  }
}
