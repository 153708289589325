@use 'assets/styles/utils/helper' as h;

$collection-primary-color-map: (
  better: h.$better-teal-30,
  news: h.$news-blue-70,
  think: h.$think-red-40,
  mach: h.$mach-purple-50,
  globalcitizen: h.$global-teal-40,
  msnbc: h.$news-blue-40,
  noticias: h.$noticias-blue-60,
) !default;

$collection-modulealternate-color-map: (
  better: h.$better-teal-30,
  news: h.$news-blue-40,
  think: h.$think-red-40,
  mach: h.$mach-purple-50,
  globalcitizen: h.$global-red-40,
  msnbc: h.$news-blue-40,
  noticias: h.$noticias-red-50,
) !default;

.collection__container {
  --collection__header--background-color: var(--default-color);
  --collection__header--border-color: var(--default-color);
  --collection__heading--font-family: var(--founders-cond);
  --collection__heading--font-size: #{h.rem(32px)};
  --collection__heading--font-style: normal;
  --collection__heading--font-weight: normal;
  --collection__heading--line-height: 1;
  --collection__heading--margin: #{h.rem(-7px 0 5px)};
  --collection__heading--text-transform: uppercase;
  --collection__subheading--font-sizes: #{h.rem(12px)};
  --collection__subheading--line-heights: #{h.rem(15px)};
  --collection-featured-author-title-font-family: var(--founders-cond);
  --collection-featured-author-title-font-size: 18px;
  --collection-featured-author-quote-color: var(--default-color);
  --collection-article-featured-author-title-font-family: var(--founders-cond);
  --collection-article-featured-author-title-font-sizes: #{h.rem(24px)};
  --collection-articles-background: var(--neutral-10);
  --collection-author-color: var(--default-color);
  --collection-eyebrow-color: var(--default-color);
  --collection-first-article-title-font-family: var(--founders-cond);
  --collection-first-article-title-font-size: 30px;
  --collection-first-article-title-font-weight: normal;
  --collection-more-color: var(--default-color);
  --collection-more-hover-border-color: var(--default-color);
  --collection-more-text-transform: uppercase;
  --collection-icon-background-color: var(--default-color);
  --collection__title--color: var(--grey-70);
  --collection__title--font-family: var(--founders-cond);
  --collection__title--font-size: 18px;
  --collection__title--font-weight: normal;

  @include h.breakpoint-m {
    --collection__heading--font-size: #{h.rem(48px)};
    --collection__heading--margin: #{h.rem(-10px 0 8px)};
    --collection__subheading--font-sizes: #{h.rem(14px)};
    --collection__subheading--line-heights: #{h.rem(21px)};
    --collection-article-featured-author-title-font-sizes: #{h.rem(30px)};
  }

  body.globalcitizen & {
    --collection-author-color: var(--red-40);
    --collection-icon-background-color: var(--red-40);
    --collection-eyebrow-color: var(--red-40);
    --collection-featured-author-quote-color: var(--red-40);
  }

  body.news & {
    --collection__header--background-color: var(--blue-70);
    --collection-author-color: var(--blue-40);
    --collection-icon-background-color: var(--blue-40);
    --collection-eyebrow-color: var(--blue-40);
    --collection-more-color: var(--blue-40);
    --collection-featured-author-quote-color: var(--blue-40);
  }

  body.noticias & {
    --collection-author-color: var(--red-50);
    --collection-icon-background-color: var(--red-50);
    --collection-eyebrow-color: var(--red-50);
    --collection-more-color: var(--red-50);
    --collection-featured-author-quote-color: var(--red-50);
  }

  body.deportes & {
    --collection-author-color: var(--red-50);
    --collection-icon-background-color: var(--red-50);
    --collection-eyebrow-color: var(--red-50);
    --collection-more-color: var(--red-50);
    --collection-featured-author-quote-color: var(--red-50);
  }

  body.today & {
    --collection__header--background-color: var(--purple-70);
    --collection__heading--font-family: var(--publico-hed);
    --collection__heading--font-size: #{h.rem(32px)};
    --collection__heading--font-style: italic;
    --collection__heading--font-weight: 300;
    --collection__heading--margin: 0 0 4px;
    --collection__heading--text-transform: none;
    --collection-featured-author-title-font-family: var(--publico-hed);
    --collection-featured-author-title-font-size: 13.5px;
    --collection-article-featured-author-title-font-family: var(--publico-hed);
    --collection-article-featured-author-title-font-sizes: #{h.rem(18px)};
    --collection-articles-background: var(--white);
    --collection-first-article-title-font-family: var(--publico-hed);
    --collection-first-article-title-font-size: 22.5px;
    --collection__title--color: var(--purple-70);
    --collection__title--font-family: var(--publico-hed);
    --collection__title--font-size: 13.5px;

    @include h.breakpoint-m {
      --collection__heading--font-size: #{h.rem(60px)};
      --collection-article-featured-author-title-font-sizes: #{h.rem(22.5px)};
    }

    @include h.breakpoint-l {
      --collection__heading--font-size: #{h.rem(32px)};
    }

    @include h.breakpoint-x {
      --collection__heading--font-size: #{h.rem(60px)};
    }
  }

  body.think & {
    --collection-featured-author-title-font-family: var(--publico-hed);
    --collection-featured-author-title-font-size: 13.5px;
    --collection-article-featured-author-title-font-family: var(--publico-hed);
    --collection-article-featured-author-title-font-sizes: #{h.rem(18px)};
    --collection-first-article-title-font-family: var(--publico-hed);
    --collection-first-article-title-font-size: 22.5px;
    --collection__title--font-family: var(--publico-hed);
    --collection__title--font-size: 13.5px;

    @include h.breakpoint-m {
      --collection-article-featured-author-title-font-sizes: #{h.rem(22.5px)};
    }
  }

  body.telemundo & {
    --collection__title--color: var(--blue-70);
    --collection__title--font-size: #{h.rem(20px)};
  }

  body.entretenimiento & {
    --collection__title--color: var(--blue-70);
    --collection__title--font-size: #{h.rem(20px)};
  }

  body.shows & {
    --collection__title--color: var(--blue-70);
    --collection__title--font-size: #{h.rem(20px)};
  }

  body.msnbc & {
    --collection__header--border-color: var(--yellow-40);
  }
}
