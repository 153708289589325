@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.live-video-embed {
  display: none;

  @include h.breakpoint-l {
    display: block;
  }

  .tve-provider {
    display: flex;
    align-items: center;
    height: h.rem(16px);

    img {
      width: auto;
      margin-right: h.rem(8px);
    }
  }
}

.live-video-embed__inline-blinking-live-dot {
  color: var(--live-video-embed-eyebrow-color);
  margin-right: 6px;
  margin-bottom: 6px;
  height: 6px;
  width: 6px;
}

.live-video-embed--is-rail .live-video-embed__inline-blinking-live-dot {
  margin-bottom: 3px;
}

.live-video-embed__block + .live-video-embed__block:not(.live-video-embed__flag-and-headline) {
  margin-top: h.rem(20px);
}

.live-video-embed__msnbc_metadata {
  display: flex;
  align-items: baseline;
  margin-top: h.rem(20px);
  margin-bottom: h.rem(12px);

  &.without-provider-auth {
    justify-content: space-between;
  }
}

.live-video-embed--is-rail .live-video-embed__msnbc_metadata {
  margin-bottom: h.rem(8px);
  margin-top: h.rem(10px);
}

.live-video-embed--is-rail .live-video-embed__block + .live-video-embed__block,
.live-video-embed__flag-and-headline {
  margin-top: h.rem(10px);
}

.live-video-embed__logo-container {
  width: auto;
  height: h.rem(16px);
  display: flex;
  align-items: stretch;
  opacity: inherit;
}

section.msnbc-new-logo {
  .live-video-embed__logo-container {
    height: 26px;

    > svg {
      margin-right: 10px;
      left: -4px;
      position: relative;
    }
  }
}

section.live-video-embed--noticias-ahora {
  .live-video-embed__logo-container {
    height: 22px;

    > svg {
      margin-right: 10px;
      position: relative;
      left: -4px;
    }
  }
}

.live-video-embed__link:hover {
  opacity: var(--live-video-embed-link-opacity-hover);
}

.live-video-embed__link:active {
  opacity: var(--live-video-embed-link-opacity-active);
}

.live-video-embed__logo-svg,
.live-video-embed__logo-container .live-video-embed__link {
  height: 100%;
  width: auto;
}

.live-video-embed--smorgasbord {
  display: none;

  @include h.breakpoint-m {
    display: block;
    margin-top: h.rem(32px);
    margin-left: 0;
    margin-right: 0;
  }

  .live-video-embed__msnbc_metadata.without-provider-auth {
    flex-wrap: wrap;
  }
}

.live-video-embed--is-rail,
.live-video-embed--smorgasbord {
  .live-video-embed__logo-line {
    margin: 0;
    border: none;
    flex-grow: 1;
    background-image:
      // create a 1px horizontal line
      linear-gradient(
        180deg,
        transparent 0% calc(50% - 1px),
        #{var(--live-video-embed-logo-line-color)} calc(50% - 1px) 50%,
        transparent 50%
      );
  }

  .live-video-embed__logo-svg {
    margin-right: h.rem(12px);
  }
}

.live-video-embed__tagline {
  font-family: var(--live-video-embed-tagline-font-family);
  font-size: var(--live-video-embed-tagline-font-size);
  font-weight: var(--live-video-embed-tagline-font-weight);
  line-height: var(--live-video-embed-tagline-line-height);
  color: var(--live-video-embed-tagline-color);
  opacity: inherit;
}

.live-video-embed--is-rail .live-video-embed__tagline {
  font-family: var(--live-video-embed-is-rail-tagline-font-family);
  font-weight: var(--live-video-embed-is-rail-tagline-font-weight);
  line-height: var(--live-video-embed-is-rail-tagline-line-height);
}

.live-video-embed--enabled-on-news-cover {
  display: block;

  .live-video-embed__logo-svg-now-updated {
    height: h.rem(14px);
  }

  .live-video-embed__logo-svg {
    height: h.rem(20px);
  }

  .live-video-embed__logo-svg-now-updated,
  .live-video-embed__logo-svg,
  .live-video-embed__tagline,
  .live-video-embed__flag-and-headline {
    margin-left: h.rem(16px);
    margin-right: h.rem(16px);
  }

  @include h.breakpoint-m-only {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}

.live-video-embed--enabled-on-noticias-cover {
  display: block;

  .live-video-embed__logo-svg-now-updated,
  .live-video-embed__logo-svg,
  .live-video-embed__tagline,
  .live-video-embed__flag-and-headline {
    margin-left: h.rem(16px);
    margin-right: h.rem(16px);
  }

  @include h.breakpoint-m-max {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}

.live-video-embed__button {
  @include h.css-reset-btn;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: h.rem(60px);
  width: h.rem(60px);
  background-color: var(--live-video-embed-unmute-button-background);
  color: var(--live-video-embed-unmute-button-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.live-video-embed__button.lock {
  cursor: default;
}

.live-video-embed__eyebrow {
  font-family: var(--live-video-embed-eyebrow-font-family);
  font-size: var(--live-video-embed-eyebrow-font-size);
  line-height: var(--live-video-embed-eyebrow-line-height);
  color: var(--live-video-embed-eyebrow-color);
}

.live-video-embed--is-rail .live-video-embed__eyebrow {
  font-size: var(--live-video-embed-is-rail-eyebrow-font-size);
}

// see note in tokens file for why we're using separate classes instead of
// styling by vertical
.live-video-embed__eyebrow--today-all-day {
  font-family: var(--live-video-embed-today-all-day-eyebrow-font-family);
  font-size: var(--live-video-embed-today-all-day-eyebrow-font-size);
  line-height: var(--live-video-embed-today-all-day-eyebrow-line-height);
  color: var(--live-video-embed-today-all-day-eyebrow-color);
}

.live-video-embed--is-rail .live-video-embed__eyebrow--today-all-day {
  font-size: var(--live-video-embed-is-rail-today-all-day-eyebrow-font-size);
}

.live-video-embed__headline {
  display: inline;
  font-family: var(--live-video-embed-headline-font-family);
  font-size: var(--live-video-embed-headline-font-size);
  font-weight: var(--live-video-embed-headline-font-weight);
  line-height: var(--live-video-embed-headline-line-height);
}

.live-video-embed--is-rail .live-video-embed__headline {
  font-size: var(--live-video-embed-is-rail-headline-font-size);
}

.live-video-embed--smorgasbord .live-video-embed__flag-and-headline {
  .live-video-embed__eyebrow,
  .live-video-embed__headline {
    font-size: var(--text-20);
    line-height: var(--text-20);
  }
}

.live-video-embed__headline--today-all-day {
  display: inline;
  font-family: var(--live-video-embed-today-all-day-headline-font-family);
  font-size: var(--live-video-embed-today-all-day-headline-font-size);
  font-weight: var(--live-video-embed-today-all-day-headline-font-weight);
  line-height: var(--live-video-embed-today-all-day-headline-line-height);
  color: var(--live-video-embed-today-all-day-headline-color);
}

.live-video-embed--is-rail .live-video-embed__headline--today-all-day {
  font-size: var(--live-video-embed-is-rail-today-all-day-headline-font-size);
}

.live-video-embed__aspect-ratio-container {
  position: relative;
  padding-bottom: calc(9 / 16 * 100%);
  z-index: h.get-z-index(sticky-video-parent);

  &.msnbc-container {
    width: h.rem(267px);

    @include h.breakpoint-x {
      width: h.rem(347px);
    }
  }
}

.live-video-embed__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.live-video-embed__ndp-play-button {
  background-color: var(--orange-30);
}

.live-video-embed__countdown {
  @include h.css-reset-btn;
  font-family: var(--founders-mono);
  font-weight: 400;
  font-size: h.rem(12px);

  &:active {
    opacity: var(--opacity-50);
  }

  &:hover {
    opacity: var(--opacity-70);
  }

  .live-video-embed__tvecountdown {
    font-family: var(--founders-cond);
    font-size: h.rem(16px);
    font-weight: 600;
    line-height: h.rem(16px);
    text-align: left;
    min-width: h.rem(28px);
  }
}

.live-video-embed__sign-in {
  font-size: h.rem(12px);
  font-family: var(--founders-mono);

  .lock-icon {
    background-color: transparent;
    border: none;
    color: var(--black);
    font-family: var(--founders-cond);
    font-size: var(--text-16);
    font-style: normal;
    font-weight: 600;
    padding: 0;
    text-align: left;
    line-height: unset;

    &::before {
      margin-right: var(--space-4);
    }

    &:active {
      opacity: var(--opacity-50);
    }

    &:hover {
      opacity: var(--opacity-70);
    }
  }
}

.live-video-embed__sign-in--pretext {
  @include h.css-reset-btn;
  margin-right: h.rem(8px);

  &:active {
    opacity: var(--opacity-50);
  }

  &:hover {
    opacity: var(--opacity-70);
  }
}

@include h.breakpoint-x {
  .live-video-embed__eyebrow--today-all-day {
    font-size: var(--live-video-embed-xl-today-all-day-eyebrow-font-size);
  }

  .live-video-embed--is-rail .live-video-embed__eyebrow--today-all-day {
    font-size: var(--live-video-embed-xl-is-rail-today-all-day-eyebrow-font-size);
  }

  .live-video-embed__headline--today-all-day {
    font-size: var(--live-video-embed-xl-today-all-day-headline-font-size);
  }

  .live-video-embed--is-rail .live-video-embed__headline--today-all-day {
    font-size: var(--live-video-embed-xl-is-rail-today-all-day-headline-font-size);
  }
}
