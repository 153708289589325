@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.tve__info {
  line-height: 1;

  &__live {
    font-family: var(--founders-cond);
    font-size: h.rem(16px);
    text-transform: uppercase;

    &::before {
      content: '';
      height: var(--space-12);
      width: var(--space-12);
      background-color: var(--news-red-30);
      border-radius: 50%;
      display: inline-block;
      animation: blink 1.5s ease-in-out infinite;
      margin-right: var(--space-4);
      margin-bottom: -1px;
    }
  }

  &__title {
    font-family: var(--founders-cond);
    font-size: h.rem(24px);
    margin: h.rem(20px 0 12px);

    @include h.breakpoint-m {
      font-size: h.rem(32px);
    }

    @include h.breakpoint-l {
      font-size: h.rem(24px);
    }

    @include h.breakpoint-l {
      font-size: h.rem(24px);
    }

    @include h.breakpoint-x {
      margin-bottom: h.rem(4px);
    }

    @media only screen and (min-width: 1440px) {
      font-size: h.rem(32px);
    }
  }

  &__description {
    color: var(--grey-40);
    font-size: h.rem(14px);
    line-height: 1.5;
    margin: h.rem(0 0 12px);
  }

  &__share {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-top: h.rem(12px);

    &__label {
      font-family: var(--founders-mono);
      font-size: h.rem(10px);
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: var(--grey-40);
      margin-top: var(--space-4);

      &::after {
        content: '';
        display: inline-block;
        width: h.rem(6px);
        margin: 0 h.rem(20px) 0 h.rem(6px);
        border-top: 1px solid var(--grey-50);
        height: var(--space-4);
      }
    }

    > a {
      margin-right: var(--space-24);
      font-size: h.rem(20px);
      outline: none;

      &:hover {
        opacity: var(--default-hover-opacity);
      }

      &:active {
        opacity: var(--default-active-opacity);
      }
    }
  }

  @keyframes blink {
    0%,
    100% {
      opacity: 0;
    }

    40%,
    70% {
      opacity: 1;
    }
  }
}
