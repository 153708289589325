@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.container {
  background-color: var(--background-color);
  border-bottom: var(--border-bottom);

  &.isBreaking {
    border-color: var(--red-30);
  }

  @include h.breakpoint-m {
    &:global(.pkg:not(.lead)) {
      margin-bottom: calc(var(--package-spacing) + var(--big-headline-tease-top-offset));
    }
  }
}

.flagWrapper {
  display: inline-block;
  margin: 0 auto 0 0;

  :global(.striped-eyebrow) {
    position: relative;
    z-index: 0;
  }

  :global(.striped-eyebrow__text) {
    align-items: center;
    background-color: var(--red-30);
    color: var(--white);
    display: flex;
    font-family: var(--founders-cond);
    font-size: var(--text-16);
    font-weight: var(--bold);
    height: var(--breaking-tag--height);
    justify-content: center;
    line-height: var(--leading-100);
    padding: 0 h.rem(20px);
  }

  :global(.stripe-pattern) {
    z-index: -1;
  }

  @include h.breakpoint-m {
    margin: 0 auto;
  }
}

.content {
  text-align: center;

  .hasTease & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
}

.tease {
  @include h.breakpoint-m {
    padding: 0 0 0 h.rem(20px);
    transform: translate(0, var(--big-headline-tease-top-offset));
  }

  @include h.breakpoint-l {
    padding: 0;
  }
}

.textContent {
  margin: h.rem(32px 20px);

  @include h.breakpoint-m {
    margin: h.rem(32px) 0;
  }

  a:hover {
    opacity: var(--opacity-70);
  }
}

.unibrow {
  color: var(--unibrow--color);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  font-weight: var(--light);
  line-height: var(--leading-100);
  margin: 0 0 var(--space-12);
  text-transform: uppercase;

  .isBreaking & {
    border-color: var(--red-30);
    color: var(--red-30);
  }
}

.headline {
  color: var(--headline--color);
  font-family: var(--default-headline-font-family);
  font-size: var(--headline--font-size);
  line-height: var(--headline--line-height);
  // Account for space above font characters
  margin: calc((var(--headline--font-size) / 4) * -1) 0 0;
}

.dek {
  color: var(--dek--color);
  font-family: var(--default-text-font-family);
  font-size: var(--text-16);
  line-height: var(--leading-150);
  margin: 10px 0;
}

.updated {
  color: var(--updated--color);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  line-height: none;
  margin: 10px 0;
}
