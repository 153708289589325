@use 'assets/styles/utils/helper' as h;

.button {
  width: 100%;
  margin-top: 0;
}

.shareContainer {
  width: h.rem(330px);
  position: relative;
  margin: h.rem(20px) 0;
  flex-grow: 1;

  @include h.breakpoint-m {
    width: auto;
    min-width: 250px;
  }
}

.copyTooltip {
  align-items: center;
  color: var(--white);
  background-color: var(--secondary-color);
  padding: h.rem(9px 20px);
  display: flex;
  justify-content: center;
  line-height: 1.5;
  top: calc(100% + var(--space-8));
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  transition: visibility 200ms, opacity 200ms linear;
  white-space: nowrap;
  z-index: 2;

  .copiedText {
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: h.rem(16px);
    line-height: 1.5;
    padding: 0;
  }

  span:global(.icon) {
    color: var(--white);
    font-size: h.rem(20px);
    margin: h.rem(1px 7px 0 0);
    opacity: 1;
  }

  &.success {
    opacity: 1;
    visibility: visible;
  }
}
