@use 'assets/styles/utils/helper' as h;

.keyUpdates {
  // Wrapper-scoped variables

  // Title
  --key-up-title--color: var(--default-color);
  --key-up-title--font-size: var(--pkg-title--font-size);
  --key-up-title--margin: var(--pkg-title--margin);
  --key-up-title--padding: var(--pkg-title--padding);
  // List
  --key-up-list--color: var(--grey-70);

  :global(body.news) &,
  :global(body.msnbc) & {
    --key-up-title--color: #fa3a3a;
  }

  :global(body.telemundo) &,
  :global(body.shows) &,
  :global(body.entretenimiento) & {
    --key-up-list--color: var(--grey-60);
  }

  // Adjust title font-size and spacing
  :global(body.today) &,
  :global(body.telemundo) &,
  :global(body.shows) &,
  :global(body.entretenimiento) & {
    --key-up-title--font-size: var(--text-20);
    --key-up-title--margin: 0 0 var(--space-24) 0;
    --key-up-title--padding: 0;

    @include h.breakpoint-m {
      --key-up-title--font-size: var(--text-24);
    }
  }

  // Wrapper rules
  margin: 0 0 var(--spacing-8);

  @include h.breakpoint-m {
    margin: 0;
  }
}

.title {
  border-bottom: var(--pkg-title--border-bottom);
  color: var(--key-up-title--color);
  font-family: var(--pkg-title--font-family);
  font-size: var(--key-up-title--font-size);
  font-style: var(--pkg-title--font-style);
  font-weight: var(--pkg-title--font-weight);
  margin: var(--key-up-title--margin);
  padding: var(--key-up-title--padding);
  position: relative;
  text-transform: var(--pkg-title--text-transform);
}

.list {
  color: var(--key-up-list--color);
  list-style: disc;
  margin-left: 1rem;
  position: relative;
  z-index: 1;
}

.item {
  align-items: baseline;
  display: list-item;
  font-family: var(--publico-txt);
  font-size: var(--text-14);
  line-height: var(--leading-150);
  margin-bottom: h.rem(9px);
  position: relative;
  z-index: 1;
}

.button {
  flex: 0 0 auto;
}
