@use 'assets/styles/utils/helper' as h;

.productCard {
  --product-card--background-color: var(--white);
  --product-card--color: var(--default-text-color);
  --product-card--font-family: var(--default-text-font-family);
  --product-card--info--padding-top: var(--space-20);
  --product-card--list-price--color: var(--grey-50);
  --product-card--sale-price--font-family: var(--founders-mono);
  --product-card--sale-price--font-size: var(--text-16);
  --product-card--sale-price--color: var(--grey-50);
  --product-card--price--display: block;
  --product-card--vendor--border-radius: null;
  --product-card--vendor--height: 42px;
  --product-card--vendor-name--color: var(--default-text-color);
  --product-card--vendor-name--opacity: var(--default-active-opacity);
  --product-card--vendor-name--font-size: #{h.rem(12px)};
  --product-card--vendor-name--text-decoration: unset;
  --product-card--vendor-name--text-decoration-color: unset;
  --product-card--vendor-name--text-decoration-thickness: unset;
  --product-card--vendor-name--font-weight: 400;
  --product-card--vendor-name--line-height: normal;
  --product-card--cost--border-radius: null;
  --product-card--cost--display: none;
  --plus-icon--fill-color: var(--default-text-color);
  --product-card--more-info-button--text-transform: none;
  --product-card-headline--text-underline-offset: 4px;

  @include h.breakpoint-m {
    --product-card--info--padding-top: var(--space-32);
    --product-card--price--display: none;
    --product-card--vendor--height: inherit;
    --product-card--vendor-name--font-size: inherit;
    --product-card--cost--display: flex;
  }

  :global(body.news) & {
    --product-card--product-price-reduced--color: var(--red-50);
  }

  :global(body.today) & {
    --product-card--vendor-name--color: var(--grey-70);
    --product-card--vendor-name--color--hover: var(--orange-30);
    --plus-icon--fill-color: var(--orange-30);
    --product-card--vendor-name--text-decoration: underline;
    --product-card--vendor-name--text-decoration-color: var(--accent-orange);
    --product-card--vendor-name--text-decoration-thickness: 2px;
    --product-card--vendor-name--margin: 4px 0 16px 0;
    --product-card--more-info-button--text-transform: uppercase;
    --product-card--more-info-button--color--hover: var(--orange-30);
    --product-card--name-font-family: var(--secondary-font);
    --product-card--name-font-size: #{h.rem(18px)};
    --product-card--name-color: var(--grey-70);
    --product-card--name-font-weight: 400;
    --product-card--dek-margin: 0 0 16px 0;
    --product-card--list-price--margin: 0 0 var(--space-4) 0;
    --product-card--color: var(--secondary-70);
    --product-card--product-price-reduced--color: var(--primary-color);
    --shop-button-vendor-name-font: var(--secondary-font);
    --product-card--vendor--height: #{h.rem(42px)};
    --product-card--price--display: none;
    --product-card--sale-price--font-weight: 700;
  }

  :global(body.select) & {
    --product-card--vendor-name--color: var(--blue-60);
    --product-card--vendor-name--color--hover: var(--blue-60);
    --product-card--vendor-name--opacity: var(--default-hover-opacity);
    --product-card--vendor-name--text-decoration: unset;
    --product-card--vendor-name--text-decoration-color: unset;
    --product-card--vendor-name--text-decoration-thickness: unset;
    --product-card--vendor-name--font-weight: 700;
    --product-card--more-info-button--text-transform: none;
    --product-card--more-info-button--color--hover: var(--blue-60);
    --product-card--vendor--border-radius: 100px;
    --product-card--vendor--height: 52px;
    --product-card--vendor-name--font-size: #{h.rem(17px)};
    --product-card--vendor-name--margin: 0 0 16px 0;
    --product-card--vendor-name--opacity--hover: .7;
    --product-card--cost--border-radius: 100px 0 0 100px;
    --product-card--cost--display: flex;
    --product-card--price--display: none;
    --product-card--background-color: var(--white);
    --product-card--font-family: var(--lato);
    --product-card--list-price--color: var(--grey-50);
    --product-card--list-price--font-family: var(--lato);
    --product-card--list-price--font-size: #{h.rem(12px)};
    --product-card--list-price--font-weight: 400;
    --product-card--list-price--margin: 2px 0;
    --product-card--sale-price--color: var(--grey-50);
    --product-card--sale-price--font-family: var(--lato);
    --product-card--sale-price--font-size: #{h.rem(14px)};
    --product-card--sale-price--font-weight: 700;
    --product-card--info--padding-top: 36px;
    --plus-icon--fill-color: var(--blue-60);
    --waffle-card--box-shadow-after: none;
    --waffle-card--box-shadow: none;
    --product-card--name-font-family: var(--lato);
    --product-card--name-font-size: #{h.rem(17px)};
    --product-card--name-color: var(--grey-70);
    --product-card--name-font-weight: var(--normal);
    --product-card--name-hover-color: var(--grey-70);
    --product-card--dek-margin: 12px 0 0 0;

    @include h.breakpoint-m {
      --product-card--vendor--border-radius: 100px;
      --product-card--cost--display: none;
      --product-card--price--display: initial;
      --product-card--info--padding-top: 22px;
    }

    @include h.breakpoint-x {
      --product-card--vendor--border-radius: 100px;
      --product-card--cost--display: flex;
      --product-card-price--display: none;
      --product-card--info--padding-top: 32px;
    }
  }
}
