@use 'assets/styles/utils/helper' as h;

.dropdown-container {
  width: 100%;
  position: static;
}

.dropdown-trigger {
  cursor: pointer;
}

.dropdown-title {
  display: inline;
  text-underline-offset: 5px;
  position: relative;
  z-index: 30;

  &:hover,
  &.dropdown-open {
    text-decoration: underline;
  }
}

.chevron {
  padding-left: 6px;
  display: inline;
  font-size: 10px;
  top: 5px;
  position: relative;
  transition: 100ms linear;
}

.dropdown-content {
  width: 100%;
  background-color: var(--white);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 30;
  margin-top: 5px;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;

  &.dropdown-open {
    opacity: 1;
    max-height: fit-content;
    display: block;
  }
}

.dropdown-inner {
  max-width: 1240px;
  margin: 0 auto;
  padding: 20px;

  @include h.breakpoint-s-only {
    max-height: 330px;
    padding: 20px 0 20px 50px;
    overflow: scroll;
  }

  @include h.breakpoint-l-only {
    overflow: scroll;
  }
}

.grid-layout {
  display: grid;
  gap: 13px 20px;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(26, auto);

  @include h.breakpoint-m {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(13, auto);
  }

  @include h.breakpoint-l {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(9, auto);
  }

  @include h.breakpoint-x {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(8, auto);
  }
}

.grid-item {
  padding: 4px 0;
  text-align: left;

  &:hover {
    opacity: var(--page-front--hover--opacity);
  }
}
