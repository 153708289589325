@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.paginationContainer {
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: center;
  max-width: 100%;

  @include h.breakpoint-m {
    gap: 10px;
  }
}

.innerContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  max-width: 375px;
}

.paginationButton {
  background-color: transparent;
  border: none;
  font-weight: 700;
  line-height: 24px;
  font-size: h.rem(16px);
  font-family: var(--secondary-font);
  color: #555;
  align-items: center;
  display: flex;
  padding: 0;

  path {
    stroke: #555;
  }

  &.active {
    color: var(--waffle--pagination-link-active-color);
  }

  &:hover {
    color: var(--waffle--pagination-link-active-color-hover);

    path {
      stroke: var(--waffle--pagination-link-active-color-hover);
    }
  }

  &.disabled {
    cursor: default;

    &:hover {
      path {
        stroke: #555;
      }
    }
  }
}

.mobileHide {
  display: none;

  @include h.breakpoint-m {
    display: flex;
  }
}

.paginationNumber {
  padding: 0 h.rem(15px);
}

.showMore {
  background-color: transparent;
  border: none;
  font-weight: 700;
  line-height: 24px;
  font-size: h.rem(16px);
  font-family: var(--secondary-font);
  color: #555;
  align-items: center;
  display: flex;
}
