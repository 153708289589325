@use 'assets/styles/utils/helper' as h;

.liveblog-layout {
  margin: 0 20px;

  @include h.breakpoint-m {
    margin: 0;
  }
}

.liveblog-layout__author-timestamp-container {
  color: #2a2a2a;
  font-size: h.rem(12px);

  &.bottom-margin-with-media {
    margin-bottom: 8px;
  }

  time {
    color: #555;
  }
}

.liveblog-layout__content-container-right {
  @include h.breakpoint-m {
    margin-left: 20px;
  }

  @include h.breakpoint-x {
    margin-left: 20px;
  }
}

.liveblog-layout__container-side {
  @include h.breakpoint-m {
    $width: 412px;
    width: $width;
    min-width: $width;
  }

  @include h.breakpoint-l {
    $width: 321px;
    width: $width;
    min-width: $width;
  }

  @include h.breakpoint-x {
    &.media-medium {
      width: calc(100% - 412px);
    }

    &.media-large {
      width: calc(100% - 500px);
    }
  }
}

.liveblog-layout__main-content-container {
  display: flex;
  flex-direction: column;

  @include h.breakpoint-m {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.liveblog-layout.standard-story {
  .container-side__content {
    display: none;

    @include h.breakpoint-m {
      display: block;
    }

    .pb0-xl {
      @include h.breakpoint-m {
        padding-bottom: 0;
      }
    }
  }

  .blog-container-left {
    display: none;

    @include h.breakpoint-x {
      display: block;
      margin-top: 12px;
    }
  }

  .media-container-left {
    display: block;

    @include h.breakpoint-x {
      display: none;
    }
  }

  .media-container-right {
    display: none;

    @include h.breakpoint-x {
      display: block;
    }
  }

  .blog-container-right {
    display: block;
    margin-top: 2px;

    @include h.breakpoint-x {
      display: none;
    }
  }

  .liveblog-layout__container-top {
    @include h.breakpoint-m {
      display: none;
    }
  }

  .container-side__content.no-dek.no-author-timestamp {
    @include h.breakpoint-m {
      margin-right: 0;
    }

    @include h.breakpoint-l {
      margin-right: h.rem(20px);
    }
  }
}

.liveblog-layout.important-story {
  .container-side__content {
    display: none;

    @include h.breakpoint-m {
      display: block;
    }
  }

  .headline-center {
    text-align: center;
  }

  .hidePositionStandard {
    display: none;

    @include h.breakpoint-s-only {
      display: block;
    }
  }

  .articleInfoContainerMoved {
    @include h.breakpoint-s-only {
      display: none;
    }
  }

  .hidePositionMoved {
    display: none;
  }

  .important-headline-only {
    .storyline__headline {
      margin-bottom: 16px;
    }
  }

  .blog-container-left,
  .media-container-right {
    display: none;
  }

  .liveblog-layout__container-side {
    @include h.breakpoint-m {
      $width: 412px;
      width: $width;
      min-width: $width;
    }

    @include h.breakpoint-l {
      $width: 321px;
      width: $width;
      min-width: $width;
    }

    @include h.breakpoint-x {
      &.media-medium {
        $width: 412px;
        width: $width;
        min-width: $width;
      }

      &.media-large {
        $width: 460px;
        width: $width;
        min-width: $width;
      }
    }
  }
}

.liveblog-layout__badge.isInline {
  display: inline;
  vertical-align: top;
  line-height: 28px;
}
