@use 'assets/styles/utils/helper' as h;

.container {
  background-color: var(--default-color);
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  :global(body.news) & {
    background-color: var(--blue-70);
  }

  :global(body.noticias) & {
    background-color: var(--blue-60);
  }

  &::before {
    font-family: var(--founders-cond);
    font-weight: normal;
    font-size: h.rem(22px);
    line-height: 1;
    background-color: var(--default-color);
    content: attr(data-count);
    color: var(--white);
    flex-basis: 50%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    :global(body.news) & {
      background-color: var(--blue-70);
    }

    :global(body.noticias) & {
      background-color: var(--blue-60);
    }
  }
}

.item {
  flex-basis: 50%;

  &:first-child {
    order: 1;
  }

  &:nth-child(2) {
    order: 3;
  }

  &:nth-child(3) {
    order: 4;
  }
}

.rectangle {
  @include h.breakpoint-m {
    position: relative;
    padding-left: 50%;
  }

  &::before {
    order: 2;

    @include h.breakpoint-m {
      order: 3;
    }
  }

  .item {
    &:first-child {
      @include h.breakpoint-m {
        position: absolute;
        left: 0;
        width: 50%;
      }
    }

    &:nth-child(2) {
      order: 3;

      @include h.breakpoint-m {
        order: 2;
      }
    }

    &:nth-child(3) {
      @include h.breakpoint-m {
        flex-basis: 100%;
      }
    }
  }
}
