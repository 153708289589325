@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.waffleCard {
  background: var(--waffle-card--background-color);
  overflow: visible;
  position: relative;
  width: 100%;

  @include h.breakpoint-m {
    overflow: hidden;
  }

  @include h.breakpoint-l {
    transition: box-shadow 0.2s;
  }

  &:hover {
    box-shadow: none;

    &::after {
      box-shadow: var(--waffle-card--box-shadow-after);
      content: '';
      display: block;
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @include h.breakpoint-l {
      box-shadow: var(--waffle-card--box-shadow);

      &::after {
        content: '';
        display: none;
      }
    }
  }

  &.nativeAd {
    background-color: var(--grey-70);
  }

  .showMoreBtn {
    @include h.breakpoint-m {
      display: none;
    }
  }

  :global(body.today) &:has(.link:hover) {
    :global(.waffle__headline) {
      text-decoration-color: var(--waffle-headline--text-decoration-color);
      text-decoration-line: var(--waffle-headline--text-decoration-line);
      text-decoration-thickness: var(--waffle-headline--text-decoration-thickness);
      text-underline-offset: var(--waffle-headline--text-underline-offset);
    }
  }
}

.scrollableMoreInfo {
  overflow-y: auto;
  padding: var(--spacing-5) var(--spacing-5) var(--spacing-2);
  height: calc(100% - 50px);

  @include h.breakpoint-x {
    padding: var(--spacing-7) var(--spacing-7) var(--spacing-9);
  }

  .showEyebrow & {
    padding-top: 0;

    @include h.breakpoint-x {
      padding-top: 0;
    }
  }

  &::after {
    background: var(--waffle-card--scrollable-more-info-background);
    bottom: 45px;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.disabled:hover {
  box-shadow: none;
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.link {
  display: block;
  position: relative;
}

.badge {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.badgeLeftPosition {
  left: 0;
}

.badgeRightPosition {
  right: 0;
}

.section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--waffle-card--section--padding);

  .hasMoreInfo & {
    padding: var(--waffle-card--section-more-info--padding);
  }

  .showEyebrow & {
    padding-top: 0;
  }
}

.ad {
  margin-bottom: 48px;
  padding: 0;
  width: unset;

  @include h.breakpoint-m {
    margin-bottom: 0;
    padding: 27px;
  }

  @include h.breakpoint-l {
    padding: 0;
  }

  @include h.breakpoint-x {
    padding: 27px;
  }

  // @include style-only-for('today') {
  :global(body.today) & {
    div[data-mps-slot^='boxrail'],
    div[data-mps-slot^='boxflex'] {
      // ads returned on today already has margin set from Ad component
      padding-top: var(--waffle-card--boxrail-boxflex-padding);

      iframe {
        @include h.breakpoint-l {
          margin: var(--waffle-card--boxrail-boxflex-iframe-margin) !important;
        }
      }
    }
  }
}

.stickyAd {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: h.rem(90px);

  &:hover {
    box-shadow: none;
    transition: none;
  }
}

.unibrow {
  color: var(--grey-60);
  display: none;
  font-family: var(--waffle-card--unibrow--font-family);
  font-size: var(--waffle-card--unibrow--font-size);
  font-weight: var(--waffle-card--unibrow--font-weight);
  line-height: var(--leading-100);
  margin: 0;
  text-transform: uppercase;

  .showEyebrow & {
    display: block;
  }

  &.linkable {
    color: var(--waffle-card--unibrow-linkable-color);
  }

  .nativeAd & {
    color: var(--white);
  }
}

.unibrowSpacing {
  padding: var(--waffle-card--unibrow-padding);
}

.textSummary {
  .nativeAd & {
    color: var(--grey-50);
  }
}

.toggleBtn {
  bottom: 0;
  display: flex;
  left: 0;
  padding-left: var(--waffle-card--toggle--padding);
  padding-right: var(--waffle-card--toggle--padding);
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 3;
}

.toggleBtnOverlay {
  bottom: 0;
  display: flex;
  left: 0;
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 1;

  @include h.breakpoint-m {
    display: none;
  }
}
