@use 'assets/styles/utils/helper' as h;

$z-index-max: 100000;

.tve__player {
  padding: 0;
  z-index: 1;

  &__error {
    pointer-events: none;
    z-index: $z-index-max;
  }

  &__loader {
    background: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: $z-index-max - 1;
  }

  &__player {
    z-index: $z-index-max - 2;
  }

  &__slate {
    pointer-events: none;
    z-index: $z-index-max - 3;

    &__background {
      background: rgba(var(--default-color-rgb), 0.7);
      z-index: 1;
    }

    &__video {
      width: 100% !important;
      height: auto !important;
    }
  }
}
