@use 'assets/styles/utils/helper' as h;

.smorgasbord-no-art-tease {
  margin-top: var(--space-16);
  border-top: 1px solid var(--grey-40);
  padding-top: var(--space-16);
  display: flex;

  @include h.breakpoint-s-only {
    margin-right: var(--space-20);
    margin-left: var(--space-20);
  }

  &--image {
    & + .smorgasbord-no-art-tease {
      @include h.breakpoint-s-only {
        margin-top: var(--space-32);
      }
    }
  }
}

// right rail overrides for first .smorgasbord-no-art-tease item
.smorgasbord__column3 {
  // when video is at the top of rail
  .live-video-embed--smorgasbord + .smorgasbord-no-art-tease {
    border-top: none;
  }

  // when video is at the bottom of rail (or there is no video)
  .smorgasbord-no-art-tease:first-child {
    border-top: none;
    padding-top: 0;
  }
}
