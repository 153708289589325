@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.stripePattern {
  position: absolute;
  left: 0;
  right: h.rem(-8px);
  top: 0;
  bottom: h.rem(-8px);
  z-index: 0;
  opacity: 0.8;

  line {
    stroke: var(--stripe-pattern-color);
  }
}
