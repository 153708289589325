@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.drawerContainer {
  font-family: var(--lato);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 100%;
  overflow: auto;
  height: 100%;
  max-height: 1vh;
  word-wrap: break-word;
  padding-bottom: 36px;

  &.isMobile {
    padding-bottom: 85px;
  }
}

.closeBtnContainer {
  display: flex;
  background: linear-gradient(transparent, #fff, #fff, #fff);
  position: absolute;
  bottom: 0;
  width: 100%;
  gap: 4px;
  align-items: center;

  &.isMobile {
    bottom: 5%;
    padding-bottom: 24px;
  }
}

.closeBtn {
  display: flex;
  background-color: transparent;
  border: none;
  font-family: var(--secondary-font);
  align-items: center;
  gap: 4px;
  color: var(--product-card--close-button--color);
  margin-top: 7px;
  padding: 8px 0;
  text-transform: var(--product-card--close-button--text-transform);
  vertical-align: middle;
  font-size: h.rem(12px);
}

.closeBtn:hover {
  color: var(--product-card--close-button--color--hover);
}

.drawerTitle {
  font-family: var(--product-card--title--font-family);
  margin-bottom: 20px;
  font-weight: 400;
  font-size: h.rem(20px);
  line-height: 25px;
  color: var(--grey-70);
}

.drawerContent {
  margin-bottom: 0;
  font-family: var(--product-card--drawer-content--font-family);

  [class*='textSummary'] {
    padding: 0 !important;
    font-family: var(--product-card--drawer-content--font-family) !important;
  }
}

.related {
  margin-top: var(--product-card--drawer-content--related--margin-top) !important;
  text-decoration: var(--product-card--drawer-content--related--text-decoration);
  text-decoration-color: var(--accent-orange);
  text-decoration-thickness: 2px;
  font-family: var(--product-card--drawer-content--related--font-family);
  line-height: 24px;
  font-size: var(--product-card--drawer-content--related--font-size);
  color: var(--product-card--drawer-content--related--color);
  margin-bottom: 20px;
  text-underline-offset: var(--product-card--drawer-content--related--underline-offset);

  &:hover {
    color: var(--product-card--drawer-content--related--hover--color);
    opacity: var(--product-card--drawer-content--related--hover--opacity);
  }
}

.sellers {
  margin-top: 20px;
}

.sellersList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    border: none !important;
  }
}

.moreInfo {
  color: var(--grey-70);
  line-height: 21px;
  margin: 0 0 12px;
  padding: 0;
  font-size: h.rem(15px);
}

.minusSign {
  fill: var(--waffle-drawer-close-icon-fill);
}
