@use 'assets/styles/utils/helper' as h;

:root {
  --one-up-tease-card-title-line-height: 100%;
  --one-up-accent-line-border-top: none;
  --one-up-slideshow-pictures-background: transparent;
}

.news {
  --one-up-slideshow-pictures-background: var(--blue-70);
}

.noticias {
  --one-up-slideshow-pictures-background: var(--blue-60);
}

.today,
.think {
  --one-up-tease-card-title-line-height: 125%;
}

.msnbc {
  --one-up-accent-line-border-top: 5px solid var(--yellow-40);
}

.globalcitizen,
.better,
.mach,
.think,
.msnbc,
.today {
  --one-up-slideshow-pictures-background: var(--default-color);
}
