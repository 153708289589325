@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.mobileDrawerBackground {
  display: block;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  max-width: 100vw;
  max-height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.mobileDrawer {
  display: block;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 48px;
  width: 100vw;
  height: 100%;
  max-width: 100vw;
  max-height: 100%;
  background-color: var(--white);
  padding: h.rem(20px);
  border-radius: 10px;
}

.section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;

  .showEyebrow & {
    padding-top: 0;
  }
}

.minusIcon {
  fill: var(--plus-icon--fill-color);
}

.plusIcon {
  fill: var(--plus-icon--fill-color);
}

.productCard {
  background-color: var(--product-card--background-color);
  color: var(--product-card--color);
  font-family: var(--product-card--font-family);
  min-height: 387px;
  min-width: 200px;
  padding: 0 10px 0 11px;
  overflow: hidden;

  :global(body.today) &:has(.link:hover) {
    :global(.waffle__headline) {
      text-decoration-color: var(--waffle-headline--text-decoration-color);
      text-decoration-line: var(--waffle-headline--text-decoration-line);
      text-decoration-thickness: var(--waffle-headline--text-decoration-thickness);
      text-underline-offset: var(--waffle-headline--text-underline-offset);
    }
  }

  @include h.breakpoint-m {
    padding: 0 15px;
  }

  @include h.breakpoint-l {
    padding: 0 20px;
  }

  .buttonCost {
    display: var(--product-card--cost--display);
    border-radius: var(--product-card--cost--border-radius);
    padding: 0 var(--space-12);
  }

  // Shop button vendor - nested classes to add specificity to overide default styling
  .buttonVendor {
    border-radius: var(--product-card--vendor--border-radius);
    height: var(--product-card--vendor--height);
  }

  @media only screen and (max-width: 850px) {
    min-height: 370.5px;
    min-width: 157.5px;
  }
}

.productInfo {
  padding: 16px 0 8px;
  align-items: start;
  display: flex;

  // Shop button vendor name - nested classes to add specificity to override default styling
  .vendorName {
    font-family: var(--secondary-font);
    font-size: var(--product-card--sale-price--font-size);
    text-decoration: var(--product-card--vendor-name--text-decoration);
    text-decoration-color: var(--product-card--vendor-name--text-decoration-color);
    text-decoration-thickness: var(--product-card--vendor-name--text-decoration-thickness);
    font-weight: var(--product-card--vendor-name--font-weight);
    line-height: var(--product-card--vendor-name--line-height);
    color: var(--product-card--vendor-name--color);
    margin: var(--product-card--vendor-name--margin);
    text-underline-offset: var(--product-card-headline--text-underline-offset);

    &.showHover {
      color: var(--product-card--vendor-name--color--hover);
      opacity: var(--product-card--vendor-name--opacity--hover);
    }
  }
}

.productPrice {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-family: var(--secondary-font);
  font-size: var(--product-card--sale-price--font-size);
  margin-top: 12px;
}

.productName {
  font-weight: var(--product-card--name-font-weight) !important;
  line-height: 22.5px;
  font-size: var(--product-card--name-font-size);
  font-family: var(--product-card--name-font-family);
  color: var(--product-card--name-color) !important;
  margin: 0;
  height: 45px;
  text-underline-offset: 0.25rem !important;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  word-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &:hover {
    color: var(--product-card--name-hover-color);
  }

  @include h.breakpoint-s-only {
    height: 68px;
    -webkit-line-clamp: 3;
  }
}

.listPrice {
  color: var(--grey-60);
  font-size: var(--product-card--sale-price--font-size);
  font-weight: bold;
  margin: var(--product-card--list-price--margin);
}

.strikeThrough .listPrice {
  font-size: var(--product-card--list-price--font-size);
  font-weight: normal;
  text-decoration: line-through;
}

.salePrice {
  color: var(--grey-60);
  font-size: var(--product-card--sale-price--font-size);
  font-weight: var(--product-card--sale-price--font-weight);
}

.moreInfoBtn {
  display: flex;
  margin-top: auto;
  background-color: transparent;
  border: none;
  font-family: var(--secondary-font);
  font-size: h.rem(12px);
  align-items: center;
  gap: 4px;
  color: #555;
  padding: 0;
  text-transform: var(--product-card--more-info-button--text-transform);
  text-align: left;
}

.moreInfoBtn:hover {
  color: var(--product-card--more-info-button--color--hover);
}

.promotion {
  align-items: center;
  column-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--space-8);
}

.promotionImageLabel {
  display: flex;
  align-items: center;

  path:first-child {
    fill: var(--default-color);
  }
}

.promotionImage {
  margin-left: -6px;
}

.promotionLabel {
  color: var(--grey-70);
  font-family: var(--default-headline-font-family);
  font-size: h.rem(12px);
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: var(--space-16);

  @include h.breakpoint-x {
    font-size: h.rem(14px);
  }
}

.promotionCode {
  background-color: transparent;
  border: 1px solid var(--grey-30);
  color: var(--default-color);
  font-family: var(--founders-mono);
  font-size: h.rem(12px);
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: var(--space-16);
  padding: 3px 4px;
  margin-left: var(--space-8);
  text-align: left;
  text-decoration-line: underline;
  text-transform: uppercase;

  @include h.breakpoint-m {
    margin-left: 0;
  }

  @include h.breakpoint-l {
    margin-left: var(--space-8);
  }
}

.save {
  position: relative;
}

.textSummary {
  color: var(--grey-60);
  font-family: var(--lato);
  font-size: h.rem(16px);
  line-height: 125%;
  margin: var(--product-card--dek-margin);
  padding-top: 0;
}

.shopButtonLink {
  display: block;
}
