@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.shop-button-info__shop {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  @include h.breakpoint-x {
    width: 100%;
  }

  body.today.articlePage & {
    align-items: center;
    background-color: var(--default-color);
    justify-content: center;
    max-width: none;
    width: auto;

    @include h.breakpoint-m {
      max-width: 320px;
    }
  }
}

.shop-button-info__icon {
  color: var(--white);
  font-size: 0.5rem;
  line-height: 1;
  margin-right: h.rem(8px);

  @include h.breakpoint-m {
    font-size: 0.75rem;
  }

  body.today.articlePage & {
    display: none;
  }
}

.shop-button-info__vendor {
  width: 100%;
  height: h.rem(52px);
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--shop-button-vendor-bg-color);
  border: 1px solid var(--shop-button-vendor-border-color);
  outline: none;

  body.today.articlePage & {
    border: none;
    height: auto;
    padding: 0;
  }
}

.shop-button-info__cost {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--shop-button-cost-border-color);
  display: flex;
  flex-direction: var(--shop-button-cost-flex-direction);
  flex-shrink: 0;
  font-family: var(--shop-button-cost-font);
  font-weight: var(--shop-button-cost-font-weight);
  height: h.rem(52px);
  justify-content: center;
  letter-spacing: var(--shop-button-cost-letter-spacing);

  body.today.articlePage & {
    background-color: transparent;
    border: none;
    color: var(--white);
    height: auto;
    min-width: unset;
  }
}

.shop-button-info__out-of-stock {
  max-width: 100px;
  padding: 0 5px;
  text-align: center;
  border-color: var(--grey-50);
  color: var(--grey-50);
  font-weight: 700;
}

.shop-button__out-of-stock {
  background: #999;
  border-color: #999;

  body.today.articlePage & {
    background-color: var(--grey-30);
    border: none;
  }
}

.shop-button-info__sale-cost {
  font-weight: var(--shop-button-sale-cost-font-weight);
  color: var(--shop-button-sale-cost-color);

  body.today.articlePage & {
    font-weight: bold;
    margin-right: 3px;
  }
}

.shop-button-info__list-cost {
  font-weight: bold;
  color: var(--shop-button-list-cost-color);

  body.today & {
    font-family: var(--secondary-font);
  }

  body.today.articlePage & {
    &.strike {
      font-weight: 400;
    }
  }

  body.today.articlePage .shop-button-info__shop:hover & {
    &.strike {
      font-weight: bold;
    }
  }
}

.shop-button-info__vendor-name {
  font-family: var(--shop-button-vendor-name-font);
  text-transform: uppercase;
  color: var(--white);
  text-align: left;

  .shop-button-info__shop:hover & {
    font-weight: var(--shop-button-info-shop-hover-font-weight);
    transform: var(--shop-button-info-shop-hover-transform-y);
  }

  .shop-button-info__vendor.variation & {
    text-align: center;
  }

  body.today.articlePage & {
    background-color: var(--shop-button-vendor-bg-color);
    font-size: h.rem(16px);
    text-align: center;

    &::before {
      content: '\00a0 AT\00a0';
    }
  }

  body.today.articlePage .shop-button__out-of-stock & {
    color: var(--grey-60);
  }
}

.shop-button-info__bacon-button {
  height: h.rem(42px);
  width: h.rem(190px);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
}

.shop-button-info__not-bacon-button {
  padding-left: 1.25rem;
}

.shop-button-info__shop-fit-text {
  @include h.breakpoint-m {
    width: 100% !important;
  }
}

.shop-button-info__vendor-fit-text {
  width: 100%;
}

.shop-button-info__cost-fit-text {
  @include h.breakpoint-m {
    max-width: h.rem(85px);
  }

  @include h.breakpoint-l {
    max-width: h.rem(98px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(114px);
  }
}

body.today.articlePage {
  .listicle-product__card-info > .shop-button-info__shop {
    min-height: 48px;
    padding: 15px 5px 11px;
    flex-wrap: wrap;
  }

  .shop-button-info__shop:hover {
    background-color: var(--accent-orange);

    .shop-button-info__vendor,
    .shop-button-info__vendor-name,
    .shop-button-info__list-cost,
    .shop-button-info__sale-cost {
      background-color: var(--accent-orange);
      color: var(--black);
    }

    &:active {
      opacity: 0.7;
    }

    .shop-button-info__shop:active {
      opacity: 1;
    }
  }

  .shop-button-info__out-of-stock {
    background-color: var(--grey-30);
    border: none;
    color: var(--grey-60);
    font-family: var(--secondary-font);
    font-weight: 700;
    max-width: none;
    padding: 0;

    &:hover,
    &:hover .shop-button-info__out-of-stock,
    &:hover .shop-button__out-of-stock,
    &:hover .shop-button-info__vendor-name {
      background-color: var(--grey-40);
      color: var(--grey-70);
    }

    @include h.breakpoint-m {
      max-width: 320px;
    }
  }
}

// actual button
.shop-button-info__vendor.variation {
  padding: 0 var(--spacing-5);
  text-align: center;
  justify-content: center;
  flex: 1;
  font-size: 16px;
  max-width: unset;
  font-weight: 700;
  transition: background-color 0.25s, font-weight 0.25s, color 0.25s;
  color: var(--white);

  &.shop-button-variation-2 {
    background-color: var(--shop-button-variation-2--background-color);
    border: 0;
    border-radius: var(--shop-button-variation-2--border-radius, 0);
    font-family: var(--shop-button-variation-2--font-family);
    height: unset;
    line-height: 24px;
    max-width: 100%;
    padding: 12px 20px;
    transition: background-color 0.25s, font-weight 0.25s, color 0.25s;
    width: 100%;

    @include h.breakpoint-m {
      padding: 12px;
    }

    .salePrice {
      color: var(--shop-button-variation-2--sale-price-color);
      font-family: var(--shop-button-variation-2--font-family);
      font-weight: 700;
    }

    .listPrice {
      color: var(--shop-button-variation-2--list-price-color);
      font-family: var(--shop-button-variation-2--font-family);
      font-weight: 700;

      &.onSale {
        color: var(--shop-button-variation-2--sale-price-color);
        font-family: var(--shop-button-variation-2--font-family);
        font-weight: 400;
        text-decoration: line-through;
      }
    }

    .sellerText {
      color: var(--shop-button-variation-2--seller-text-color);
      font-family: var(--shop-button-variation-2--font-family);
      font-weight: 400;
    }
  }
}

.shop-button-info__vendor.variation:hover {
  background-color: var(--accent-orange);
  color: var(--grey-60);
  border: 0;

  .listPrice {
    font-weight: 700;
    color: var(--grey-60);
  }

  .salePrice {
    font-weight: 700;
    color: var(--grey-60);
  }

  .sellerText {
    font-weight: 700;
    color: var(--grey-60);
  }
}

.shop-button-info__vendor.shop-button-variation-2:hover {
  background-color: var(--shop-button-variation-2--background-color--hover);
  border: 0;
  color: var(--white);
  opacity: var(--shop-button-variation-2--hover-opacity, 1);
  transition: background-color 0.25s, font-weight 0.25s, color 0.25s;

  .salePrice {
    color: var(--shop-button-variation-2-hover--sale-price-color);
    font-weight: 700;
  }

  .listPrice {
    color: var(--shop-button-variation-2-hover--list-price-color);
    font-weight: 700;

    &.onSale {
      color: var(--shop-button-variation-2--on-sale-price-color--hover);
      font-weight: 500;
      text-decoration: line-through;
    }
  }

  .sellerText {
    color: var(--white);
    font-weight: 700;
  }
}

.shop-button-info__vendor.shop-button-variation-2:active {
  opacity: 0.5;
}

.shop-button-info__vendor.shop-button__out-of-stock.shop-button-variation-2 {
  border: 0;
  background-color: var(--grey-20);
  color: var(--grey-60);

  .listPrice.outOfStock {
    color: var(--grey-60);
  }

  .sellerText {
    color: var(--grey-60);
  }
}

.shop-button-info__vendor.shop-button__out-of-stock.shop-button-variation-2:hover {
  background-color: var(--grey-40);
}

.shop-button-info__vendor.shop-button__out-of-stock.shop-button-variation-2:hover .sellerText {
  font-weight: 700;
}

.shop-button-info__vendor.variation.shop-button-variation-2:hover .listPrice.onSale {
  font-weight: 700;
}
