@use 'assets/styles/utils/helper' as h;

.item {
  // Tease Images
  --image-wrapper--height: var(--space-80);
  --image-wrapper--width: var(--space-80);

  // Headlines
  --tease-headline--color: var(--default-headline-color);
  --tease-headline--font-family: var(--default-headline-font-family);
  --tease-headline--font-size: var(--text-20);
  --tease-headline--line-height: var(--leading-100);
  --tease-headline--first-child--font-size: var(--tease-headline--font-size);

  // Unibrows
  --unibrow--color: var(--default-color);
  --unibrow--margin: 0 0 var(--space-8);
  --unibrow--opinion--color: #ff5042;

  @include h.breakpoint-m {
    --image-wrapper--height: var(--space-80);
    --image-wrapper--width: var(--space-80);
  }

  @include h.breakpoint-l {
    --tease-headline--font-size: var(--text-18);
  }

  &.isFeatured.inRail {
    --tease-headline--font-size: var(--text-20);

    @include h.breakpoint-m {
      --tease-headline--font-size: var(--text-24);
    }

    @include h.breakpoint-l {
      --tease-headline--font-size: var(--text-20);
    }
  }

  :global(body.think) & {
    --tease-headline--font-size: var(--text-16);
    --tease-headline--line-height: var(--leading-125);

    @include h.breakpoint-m {
      --tease-headline--font-size: var(--text-18);
    }

    @include h.breakpoint-l {
      --tease-headline--font-size: var(--text-16);
    }
  }

  :global(body.today) & {
    // Tease Images
    --image-wrapper--height: var(--space-60);
    --image-wrapper--width: var(--space-60);

    // Headlines
    --tease-headline--font-size: var(--text-16);
    --tease-headline--line-height: var(--leading-125);

    // Unibrows
    --unibrow--margin: 0 0 var(--space-4);

    @include h.breakpoint-m {
      --tease-headline--font-size: var(--text-18);
    }

    @include h.breakpoint-l {
      --tease-headline--font-size: var(--text-16);
    }

    &.inRail {
      @include h.breakpoint-m {
        --tease-headline--font-size: var(--text-16);
      }
    }

    &.isFeatured.inRail {
      --tease-headline--font-size: var(--text-18);

      @include h.breakpoint-m {
        --tease-headline--font-size: var(--text-20);
      }

      @include h.breakpoint-l {
        --tease-headline--font-size: var(--text-18);
      }
    }
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    &.inRail {
      --tease-headline--font-size: var(--text-20);

      @include h.breakpoint-l {
        --tease-headline--font-size: var(--text-18);
      }
    }
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --tease-headline--color: var(--blue-60);

    &.inRail {
      --tease-headline--font-size: var(--text-18);

      @include h.breakpoint-m-only {
        --tease-headline--first-child--font-size: var(--text-24);
      }
    }
  }
}
