@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.headline {
  color: var(--waffle-headline--color);
  font-family: var(--waffle-headline--font-family);
  font-size: var(--waffle-headline--font-size);
  font-weight: var(--waffle-headline--font-weight);
  line-height: var(--waffle-headline--line-height);
  margin: 0;
  transition: opacity 100ms linear;

  @include h.breakpoint-s-only {
    .waffle-list--mobile-columns & {
      font-size: var(--waffle-headline--font-size--mobile-2up);
    }
  }

  &.gray {
    color: #2a2a2a;
  }

  &.white {
    color: var(--white);
  }

  &.product {
    color: var(--waffle-headline--product--color);
    margin-bottom: 0;
  }

  &:hover {
    opacity: var(--waffle-headline--hover-opacity);
    color: var(--waffle-headline--hover-color);
    text-decoration-line: var(--waffle-headline--text-decoration-line);
    text-decoration-color: var(--waffle-headline--text-decoration-color);
    text-decoration-thickness: var(--waffle-headline--text-decoration-thickness);
    text-underline-offset: var(--waffle-headline--text-underline-offset);
  }

  &.disabled:hover {
    opacity: initial;
  }
}

.sponsor {
  color: var(--grey-50);
  font-family: var(--founders-mono);
  font-size: h.rem(14px);
  letter-spacing: var(--tracking-tight);
  line-height: var(--leading-100);
  margin-top: h.rem(12px);
}
