@use 'assets/styles/utils/helper' as h;

.drawerTitle {
  :global(body.today) & {
    --product-card--title--font-family: var(--secondary-font);
  }

  :global(body.select) & {
    --product-card--title--font-family: var(--lato);
  }
}

.drawerContent {
  :global(body.today) & {
    --product-card--drawer-content--font-family: var(--tertiary-font);
  }

  :global(body.select) & {
    --product-card--drawer-content--font-family: var(--lato);
    --product-card--dek-margin: 12px 0;
  }
}

.related {
  :global(body.today) & {
    --product-card--drawer-content--related--color: var(--grey-70);
    --product-card--drawer-content--related--font-family: var(--tertiary-font);
    --product-card--drawer-content--related--font-size: #{h.rem(16px)};
    --product-card--drawer-content--related--text-decoration: underline;
    --product-card--drawer-content--related--margin-top: 12px;
    --product-card--drawer-content--related--hover--color: var(--orange-30);
    --product-card--drawer-content--related--hover--opacity: 1;
    --product-card--drawer-content--related--underline-offset: 4px;
  }

  :global(body.select) & {
    --product-card--drawer-content--related--color: var(--blue-60);
    --product-card--drawer-content--related--font-family: var(--lato);
    --product-card--drawer-content--related--font-size: #{h.rem(15px)};
    --product-card--drawer-content--related--text-decoration: none;
    --product-card--drawer-content--related--margin-top: 0;
  }
}

.closeBtn {
  --product-card--close-button--color: var(--grey-60);
  --product-card--close-button--color--hover: var(--default-color);
  --product-card--close-button--text-transform: unset;

  :global(body.today) & {
    --product-card--close-button--color--hover: var(--orange-30);
    --waffle-drawer-close-icon-fill: var(--orange-30);
    --product-card--close-button--text-transform: uppercase;
  }

  :global(body.select) & {
    --product-card--close-button--color--hover: var(--blue-60);
    --waffle-drawer-close-icon-fill: var(--blue-60);
    --product-card--close-button--text-transform: unset;
  }
}
