@use 'assets/styles/utils/helper' as h;
@import '../sassVariables.module.scss';

.#{$packageOuterClass} {
  .column-of-items {
    .msp-pkg {
      .package-title-top__logo-line,
      .package-title-top-border__top-decoration {
        display: none;
      }
    }

    .package-title-top-border__title {
      margin-top: 0;
      font-family: var(--publico-txt);
      font-size: h.rem(20px);
      font-weight: 700;
      text-transform: none;
      color: var(--blue-40, #3061ff);

      img {
        max-height: 24px;
        width: auto;
      }
    }

    .coi-horizontal-item-divider {
      @include h.breakpoint-s-only {
        background-color: var(--grey-40);
      }
      height: 1px;
      margin: var(--msp-spacing) 0;
      background-color: var(--grey-50);
    }

    .coi-row-mode-horizontal-divider {
      display: none;
      width: 100%;
      margin: var(--msp-spacing) 0;
      height: 1px;
      background-color: var(--grey-50);
    }

    .package-title-top__logo-line {
      display: block;
      // margin: 0;
      // background-color: var(--grey-40);
      // height: 1px;
      // border: 0;
    }
  }

  .column-of-items.has-flex-item {
    display: flex;
    flex-flow: column nowrap;
    align-self: stretch;
  }
}
