@use 'assets/styles/utils/helper' as h;

.tve-view {
  --tve__action-color-base: var(--default-color);
  --tve__action-color-hover: var(--default-color);
  --tve__action-color-active: var(--default-color);

  body.msnbc & {
    --tve__action-color-base: var(--yellow-40);
    --tve__action-color-hover: var(--yellow-20);
    --tve__action-color-active: var(--yellow-10);
  }
}
