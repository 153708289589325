@use 'assets/styles/utils/helper' as h;

.alacarte {
  // Wrapper
  --wrapper--margin: 0 var(--space-20);

  // Wrapper Top
  --wrapper--top--margin: 0;
  --wrapper--top--content--in-rail--margin-top: 0;

  // Top Decoration
  --top-decoration--border-top: 1px solid var(--grey-40);
  --top-decoration--margin-bottom: var(--space-24);
  --top-decoration--display: block;
  --top-decoration--tab--width: var(--space-80);
  --top-decoration--tab--height: var(--space-8);

  // Package Title
  --title--color: var(--black);
  --title--font-family: var(--default-headline-font-family);
  --title--font-size: var(--text-24);
  --title--font-style: normal;
  --title--font-weight: normal;
  --title--letter-spacing: normal;
  --title--line-height: var(--leading-100);

  // Items wrapper
  --items--wrapper--background: transparent;
  --items--wrapper--padding: 0;

  // Content
  --content--background: transparent;
  --content--padding: 0;

  // Button Wrapper
  --button--wrapper--background: transparent;
  --button--wrapper--margin: var(--space-20) 0 0;
  --button--wrapper--padding: 0;

  // Button
  --button--border-color: var(--default-color);
  --button--height: auto;
  --button--margin: 0 auto;
  --button--width: calc(100% - var(--space-40));

  @include h.breakpoint-m {
    --wrapper--margin: 0;
    --wrapper--top--margin: 0;

    // Button
    --button--margin: 0;
    --button--width: 192px;
  }

  @include h.breakpoint-l {
    --button--width: 120px;
  }

  // Modifications
  &.inRail {
    @include h.breakpoint-m {
      --button--margin: 0 auto;
    }

    @include h.breakpoint-l {
      --button--width: 100%;
    }
  }

  :global(body.globalcitizen) & {
    --button--color: var(--red-40);
    --button--border-color: var(--red-40);
  }

  :global(body.think) & {
    --title--line-height: var(--leading-125);
  }

  :global(body.today) & {
    // Top Decoration
    --top-decoration--display: none;
    --wrapper--top--content--in-rail--margin-top: var(--space-20);

    // Package Title
    --title--font-style: italic;
    --title--font-weight: var(--light);
    --title--line-height: var(--leading-125);
    --title--letter-spacing: #{h.rem(0.2px)};
    --title--color: var(--purple-70);

    // Modifications
    &.inRail {
      // Items wrapper
      --items--wrapper--background: var(--white);
      --items--wrapper--padding: var(--space-20) 0;

      // Content
      --content--padding: var(--space-20);

      // Button Wrapper
      --button--wrapper--background: var(--white);
      --button--wrapper--margin: 0;
      --button--wrapper--padding: 0 0 var(--space-20);

      @include h.breakpoint-l {
        --button--width: calc(100% - 40px);
      }
    }
  }

  :global(body.noticias) &,
  :global(body.deportes) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    // button affected by margin from item container only at M
    --button--wrapper--margin: var(--space-40) 0 0;

    @include h.breakpoint-m-only {
      --button--wrapper--margin: var(--space-20) 0 0;
    }

    &.inRail {
      --button--wrapper--margin: var(--space-20) 0 0;
    }
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    // Wrapper Top
    --wrapper--top--content--in-rail--margin-top: var(--space-20);

    // Top Decoration
    --top-decoration--border-top: none;
    --top-decoration--tab--width: 30px;
    --top-decoration--tab--height: 2px;

    // Package Title
    --title--color: var(--blue-60);

    // Button Wrapper
    --button--wrapper--padding: 0;

    @include h.breakpoint-m {
      --title--in-rail--font-size: var(--text-28);
    }

    // Modifications
    &.inRail {
      --wrapper--margin: 0;

      // Wrapper Top
      --wrapper--top--margin: var(--space-20) var(--space-20) 0;

      // Package Title
      --title--color: var(--blue-70);
      --items--wrapper--background: var(--white);
      --items--wrapper--padding: var(--space-20);

      // Content
      --content--background: url('~assets/images/telemundo-stripe-pattern.svg');
      --content--padding: var(--space-20);

      // Button Wrapper
      --button--wrapper--background: var(--white);
      --button--wrapper--margin: 0;
      --button--wrapper--padding: 0 0 var(--space-20);

      // Button
      --button--height: 64px;
      --button--width: 300px;
      --button--margin: 0 auto;

      @include h.breakpoint-m {
        --wrapper--top--margin: 0;
        --title--font-size: var(--text-28);
      }

      @include h.breakpoint-l {
        --button--wrapper--padding: 0 var(--space-20) var(--space-20);
        --button--width: 100%;
      }
    }
  }
}
