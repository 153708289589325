@use 'assets/styles/utils/helper' as h;

.small-tease-visual {
  &__dot {
    height: var(--space-20);
    flex: 0 0 var(--space-20);
    margin-right: 10px;
    color: var(--blue-40);
    text-align: center;
    font-size: h.rem(8px);
    line-height: var(--leading-100);
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      display: inline-block;
    }
  }

  &__play {
    height: var(--space-20);
    flex: 0 0 var(--space-20);
    margin-right: 10px;
    background-color: var(--blue-40);
    color: var(--white);
    text-align: center;
    font-size: h.rem(8px);
    line-height: var(--leading-100);
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      display: inline-block;
    }
  }

  &__visual-wrapper {
    position: relative;
    display: flex;
    flex: 0 0 var(--space-20);
    height: var(--space-20);
    margin-right: h.rem(10px);
    flex-direction: column;

    @include h.breakpoint-m {
      height: var(--space-80);
      flex: 0 0 var(--space-80);
      margin-right: var(--space-16);
    }

    &--compact {
      height: 0 0 var(--space-20);
      flex: var(--space-20);
    }

    &--image {
      display: none;

      @include h.breakpoint-m {
        display: flex;
      }
    }
  }

  &__visual-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__visual {
    display: none;

    @include h.breakpoint-m {
      flex: 0 0 var(--space-60);
      background-color: var(--grey-40);
      display: flex;
    }
  }

  &__video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    height: var(--space-20);
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__video-duration {
    display: none;

    @include h.breakpoint-m {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex: 1 1 var(--space-60);
      background-color: var(--neutral-10);
      color: var(--grey-60);
      font-family: var(--founders-mono);
      font-weight: var(--normal);
      font-size: var(--text-12);
      line-height: var(--leading-100);
      letter-spacing: var(--tracking-tight);
      text-align: center;
    }
  }

  &__video-play {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 0 0 var(--space-20);
    background-color: var(--blue-40);
    color: var(--white);
    font-size: h.rem(8px);
    line-height: var(--leading-100);
    text-align: center;

    @include h.breakpoint-s-only {
      width: var(--space-20);
    }
  }
}
