@use 'assets/styles/utils/helper' as h;

.flexgrid {
  display: flex;
  flex-wrap: wrap;

  @include h.package-spacing;
}

.gridTypeThird {
  margin-top: 0;
}

.column {
  flex-grow: 1;
  margin-bottom: h.rem(28px);

  &:last-child {
    margin-bottom: 0;
  }
}

:global(.pkg) {
  .borderHalf & {
    @include h.responsive-variations(
      padding,
      (
        m: 0 8%,
        l: 0 14%,
        x: 0 6%,
      )
    );
  }

  @include h.breakpoint-m {
    .firstInRow & {
      padding-left: 0;
    }

    .lastInRow & {
      padding-right: 0;
    }
  }
}

.flexgrid :global(.pkg:not(.lead)) {
  margin-bottom: 0;
}

@include h.breakpoint-m {
  .column {
    margin-bottom: 0;
  }

  .borderQuarters {
    border-top: 1px solid var(--grey-30);
    padding: h.rem(30px) 0 h.rem(20px);

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .borderThirds {
    border-top: 1px solid var(--grey-30);
    padding: h.rem(30px) 0;

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .borderHalf {
    border-left: 2px solid var(--grey-30);
  }
}

@include h.breakpoint-l {
  .borderThirds {
    border-top: none;
    border-left: 2px solid var(--grey-30);
    padding: 0 h.rem(30px);
  }
}

@include h.breakpoint-x {
  .borderQuarters {
    border-top: none;
    border-left: 2px solid var(--grey-30);
    padding: 0 h.rem(30px);
  }

  .largeColSpace {
    padding-left: h.rem(40px);
    padding-right: h.rem(40px);

    .firstInRow & {
      margin-right: h.rem(10px);
      margin-left: h.rem(-10px);
    }

    .lastInRow & {
      margin-left: h.rem(10px);
      margin-right: h.rem(-10px);
    }

    .columnFullWidth & {
      margin: 0;
    }
  }
}

.firstInRow {
  padding-left: 0;
  border-left: none;
}

.lastInRow {
  padding-right: 0;
}

.columnFullWidth {
  padding-right: 0;
  padding-left: 0;
  border: none;
}
