@use 'assets/styles/utils/helper' as h;

.waffleCard {
  --waffle-card--scrollable-more-info-background: linear-gradient(rgba(var(--white-rgb), 0), var(--white));
  --waffle-card--boxrail-boxflex-padding: auto;
  --waffle-card--boxrail-boxflex-iframe-margin: auto;
  --waffle-card--background-color: var(--white);
  --waffle-card--unibrow-linkable-color: var(--default-color);
  --waffle-card--unibrow--font-family: var(--founders-mono);
  --waffle-card--unibrow--font-size: #{h.rem(12px)};
  --waffle-card--unibrow--font-weight: var(--light);
  --waffle-card--unibrow-padding: #{h.rem(16px)} 0 #{h.rem(8px)} 0;
  --waffle-card--section--padding: var(--spacing-3) var(--spacing-5) var(--spacing-5);
  --waffle-card--section-more-info--padding: var(--spacing-3) var(--spacing-5) var(--spacing-8);
  --waffle-card--box-shadow-after: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
  --waffle-card--box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  --waffle-card--toggle--padding: var(--spacing-5);

  @include h.breakpoint-x {
    --waffle-card--section--padding: var(--spacing-3) var(--spacing-7) var(--spacing-7);
    --waffle-card--section-more-info--padding: var(--spacing-3) var(--spacing-7) var(--spacing-9);
    --waffle-card--toggle--padding: var(--spacing-7);
  }

  :global(body.entretenimiento) & {
    --waffle-card--background-color: var(--neutral-30);
  }

  :global(body.select) & {
    --waffle-card--unibrow-linkable-color: var(--blue-60);
    --waffle-card--unibrow--font-family: var(--lato);
    --waffle-card--unibrow--font-weight: var(--normal);
  }

  :global(body.today) & {
    --waffle-card--boxrail-boxflex-iframe-margin: 0;
    --waffle-card--boxrail-boxflex-padding: 0;
    --waffle-card--unibrow-padding: #{h.rem(12px)} 0 #{h.rem(9px)} 0;
    --waffle-card--section--padding: #{h.rem(12px 0 20px)};
    --waffle-card--section-more-info--padding: #{h.rem(12px 0 48px)};
    --waffle-card--box-shadow-after: none;
    --waffle-card--box-shadow: none;
    --waffle-card--toggle--padding: 0;

    @include h.breakpoint-x {
      --waffle-card--section--padding: #{h.rem(12px 0 32px)};
      --waffle-card--section-more-info--padding: #{h.rem(12px 0 64px)};
    }
  }

  :global(body.today .waffle-mobile-single-col) & {
    @include h.breakpoint-s-only {
      --waffle-card--section--padding: #{h.rem(12px 20px 20px)};
    }
  }
}
