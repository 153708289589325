@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.magazineLead {
  background-image: var(--mlp--background-image);
  color: var(--mlp--color);
  position: relative;
}

.content {
  flex-grow: 1;
  margin: h.rem(5px) h.rem(20px) 0;
}

.outerTeaseWrapper {
  overflow-x: visible;
  width: calc(100% + 20px);

  @include h.breakpoint-m {
    width: 100%;
  }
}

.inner {
  padding: var(--mlp--inner--padding);
  position: relative;
}

.background {
  background-position: 75% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.16;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.titleWrapper {
  display: flex;
  gap: 0 h.rem(10px);
  justify-content: center;
  margin: h.rem(5px) 0 0;

  @include h.breakpoint-m {
    margin: 0 h.rem(67px);
  }

  @include h.breakpoint-l {
    margin: 0 h.rem(32px);
  }

  &::before,
  &::after {
    content: ' ';
    display: block;
    border-top: var(--mlp--wrapper--border);
    flex: 0 1 auto;
    width: 100%;
    height: h.rem(40px);
    margin-top: h.rem(15px);

    @include h.breakpoint-m {
      height: h.rem(30px);
    }
  }

  &::before {
    border-left: var(--mlp--wrapper--border);
  }

  &::after {
    border-right: var(--mlp--wrapper--border);
  }
}

.title {
  color: var(--mlp--title--color);
  font-family: var(--mlp--title--font-family);
  font-size: var(--mlp--title--font-size);
  font-style: var(--mlp--title--font-style);
  font-weight: var(--mlp--title--font-weight);
  letter-spacing: normal;
  line-height: var(--mlp--title--line-height);
  margin: 0;
  text-transform: var(--mlp--title--transform);
  white-space: nowrap;
}

.seeAllWrapper {
  display: flex;
  margin: h.rem(32px) 0 0;

  @include h.breakpoint-m {
    margin: h.rem(24px) h.rem(67px) 0;
  }

  @include h.breakpoint-l {
    margin: h.rem(24px) h.rem(32px) 0;
  }

  &::before,
  &::after {
    border-bottom: var(--mlp--wrapper--border);
    content: ' ';
    display: block;
    flex: 0 1 auto;
    height: h.rem(40px);
    margin-top: h.rem(-18px);
    width: 100%;

    @include h.breakpoint-m {
      margin-top: h.rem(-15px);
    }
  }

  &::before {
    border-left: var(--mlp--wrapper--border);
  }

  &::after {
    border-right: var(--mlp--wrapper--border);
  }

  .seeAllBtn {
    background-image: linear-gradient(35deg, var(--mlp--see-all-btn--bg-color) 50%, transparent 50%);
    border: 1px solid var(--mlp--see-all-btn--border-color);
    color: var(--mlp--see-all-btn--color);
    min-width: h.rem(170px);
    width: auto;

    &:hover {
      border-color: var(--button-border-hover-color);
      color: var(--mlp--see-all-btn--hover-color);
    }

    @include h.breakpoint-m {
      min-width: h.rem(220px);
    }
  }
}

.teases {
  align-items: space-between;
  cursor: grab;
  display: flex;
  gap: 0 h.rem(25px);
  -ms-overflow-style: none;
  overflow-x: scroll;

  @include h.breakpoint-m {
    gap: 0 h.rem(27px);
  }

  @include h.breakpoint-l {
    cursor: default;
    gap: 0 h.rem(32px);
    margin-left: 0;
    overflow-x: unset;
    width: 100%;
  }

  @include h.breakpoint-x {
    gap: 0 h.rem(50px);
  }
}

.tease {
  background-image: var(--mlp--tease--background-image);
  background-repeat: repeat-x;
  background-size: 5px 1px;
  display: flex;
  flex: 1 0 60%;
  gap: 0 var(--space-8);
  margin-top: h.rem(10px);
  padding: h.rem(30px) 0 0;

  @include h.breakpoint-m {
    flex: 1 0 35%;
    margin-top: h.rem(15px);
    padding-top: h.rem(15px);
  }

  @include h.breakpoint-l {
    flex: 1 1 20%;
    padding-top: h.rem(20px);
  }
}

.headline {
  color: var(--mlp--headline--color);
  font-family: var(--mlp--headline--font-family);
  font-size: var(--mlp--headline--font-size);
  font-style: var(--mlp--headline--font-style);
  font-weight: var(--mlp--headline--font-weight);
  line-height: var(--mlp--headline--line-height);
  margin: 0;
}

.thumbnail {
  display: block;
  flex-shrink: 0;
  height: h.rem(60px);
  margin: 0;
  position: relative;
  width: h.rem(60px);

  @include h.breakpoint-x {
    height: h.rem(80px);
    width: h.rem(80px);
  }

  picture {
    border-radius: var(--mlp--image--border-radius);
    display: block;
    overflow: auto;
  }
}

.thumbnailIcon {
  bottom: -4px;
  left: -4px;
  pointer-events: none;
  position: absolute;
}

.unibrow {
  color: var(--mlp--unibrow--color);
  font-family: var(--mlp--unibrow--font-family);
  font-size: var(--mlp--unibrow--font-size);
  font-weight: var(--mlp--unibrow--font-weight);
  letter-spacing: var(--mlp--unibrow--letter-spacing);
  line-height: var(--mlp--unibrow--line-height);
  margin: 0 0 h.rem(8px);
  text-transform: var(--mlp--unibrow--text-transform);
}
