@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.show-lead {
  position: relative;
  margin-bottom: h.rem(48px);

  .frontPage &.lead {
    margin-bottom: 0;

    @include h.breakpoint-m {
      margin-bottom: h.rem(48px);
    }
  }
}

.show-lead__wrapper {
  background-color: var(--sl-wrapper-bg);
}

.show-lead__wrapper .show-lead__content {
  padding-top: var(--sl-content-padding-top);
  height: var(--sl-content-height);
  justify-content: var(--sl-content-justify);

  @include h.breakpoint-m {
    flex-flow: column nowrap;
  }

  @include h.breakpoint-l {
    flex-flow: row wrap;
  }
}

.show-lead__meta--wrap {
  height: 100%;
  margin: 0 h.rem(20px);

  @include h.breakpoint-m {
    margin: 0;
  }
}

.show-lead__meta-data {
  z-index: 4;
  color: var(--sl-meta-data-color);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: var(--sl-meta-data-justify-content);
  align-items: var(--sl-meta-data-align-items);
  padding-top: var(--sl-meta-data--padding-top);

  @include h.breakpoint-m {
    height: calc(100% - 32px);
    padding-top: var(--sl-meta-data--padding-top);
  }
}

.show-lead__show--name {
  font-size: var(--sl-show-name-font-size);
  line-height: var(--sl-show-name-line-height);
  font-family: var(--sl-show-name-font);
  margin: 0;

  @include h.title-underline(
    var(--sl-show-name-title-underline-bg-color),
    var(--sl-show-name-title-underline-font-size),
    var(--sl-show-name-title-underline-line-height)
  );

  img {
    max-width: h.rem(160px);
    max-height: h.rem(60px);

    @include h.breakpoint-m {
      max-width: h.rem(300px);
      max-height: h.rem(90px);
    }
  }
}

.show-lead__air-time {
  font-size: var(--sl-air-time-font-size);
  font-family: var(--sl-air-time-font-family);
  font-weight: var(--sl-air-time-font-weight);
  line-height: var(--sl-air-time-line-height);
  margin: h.rem(12px) 0 0 0;

  @include h.breakpoint-m {
    margin-top: var(--sl-air-time-margin-top);
  }
}

.show-lead__link-list--wrapper {
  font-family: var(--sl-link-list-wrapper-title-font);
  margin-top: h.rem(12px);
  align-self: normal;

  &::before {
    content: '';
    display: var(--sl-link-list-wrapper-display);
    height: 1px;
    width: h.rem(175px);
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    @include h.breakpoint-m {
      width: h.rem(230px);
    }
  }
}

.show-lead__link-list {
  min-height: 25px;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  cursor: grab;
  line-height: 0;
  padding: h.rem(8px) 0 0 0;
  display: block;
  white-space: nowrap;

  @include h.breakpoint-l {
    overflow: visible;
    padding: h.rem(12px) 0 0 0;
    cursor: unset;
  }
}

.show-lead__link-list::-webkit-scrollbar {
  display: none;
}

.show-lead__link-list--item {
  display: inline-block;
  padding-right: h.rem(30px);
  white-space: nowrap;
  line-height: 0;
  font-style: var(--sl-link-list-item-font-style);

  &:last-of-type {
    padding: 0;
  }

  a {
    display: block;
    font-size: var(--sl-link-list-item-font-size);
    font-weight: var(--sl-link-list-item-font-weight);
    line-height: var(--leading-100);
    text-transform: var(--sl-link-list-item-text-transform);
  }

  a:hover {
    opacity: var(--sl-link-list--item-hover-opacity);
    color: var(--sl-link-list--item-hover-color);
    text-decoration-line: var(--sl-link-list--item-hover-text-decoration-line);
    text-underline-offset: var(--sl-link-list--item-hover-text-decoration-offset);
  }
}
