@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.cvsdk-tease {
  position: relative;

  &__video-outer {
    position: relative;
    width: var(--cvsdk-tease--width);

    @include h.breakpoint-l {
      flex: 0 0 56.5%;
    }

    @include h.breakpoint-x {
      flex-basis: 65.5%;
    }

    .layout-leftRail &,
    .layout-rightRail & {
      @include h.breakpoint-l {
        flex-basis: 100%;
      }
    }

    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
  }

  &__video-inner {
    background-color: var(--black);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & img {
      height: auto;
      width: 100%;
    }
  }

  .straightUp .live & {
    .title {
      color: h.$news-red-30;
    }
  }

  body.today .straight-up & {
    &__outer {
      @include h.breakpoint-l {
        display: flex;
      }
    }

    &__player {
      width: 100%;

      @include h.breakpoint-l {
        width: auto;
      }
    }

    &__info {
      padding: var(--cvsdk-tease--padding);
    }
  }

  &__headline {
    &.storyline {
      font-size: 18px;
      margin-top: 8px;
      margin-bottom: 0;

      @include h.breakpoint-s-only {
        margin-bottom: 16px;
      }
    }
    color: var(--grey-70);
    font-family: var(--cvsdk-tease-headline--font-family);
    font-weight: var(--cvsdk-tease-headline--font-weight);
    line-height: var(--cvsdk-tease-headline--line-height);
    margin: var(--cvsdk-tease-headline--margin);
    position: relative;

    body.today & {
      font-size: h.rem(24px);

      @include h.breakpoint-m {
        font-size: h.rem(28px);
      }
    }
  }

  &__flag {
    align-items: center;
    color: var(--cvsdk-tease-flag--color);
    font-family: var(--cvsdk-tease-flag--font-family);
    font-style: normal;
    font-weight: normal;
    position: relative;
    text-transform: var(--cvsdk-tease-flag--text-transform);
    z-index: 2;
  }

  &__dot {
    background-color: var(--cvsdk-tease-dot--background-color);
    margin: 0 var(--space-4) var(--space-4) 0;
  }

  &__headline-link {
    cursor: pointer;
  }

  // .cvsdk-tease-msnbc
  &-msnbc {
    padding-bottom: h.rem(30px);
    position: relative;

    &__player {
      display: block;
      margin: 0;
      position: relative;
    }

    &__player-link {
      display: block;
      line-height: 0;
      height: 100%;

      .tve-player {
        pointer-events: none;
      }
    }

    &__player-animated {
      display: block;
      overflow: hidden;

      video {
        width: 100%;
        height: auto;
      }
    }

    &__player-live {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: h.get-z-index(sticky-video-parent);
    }

    &__player-action {
      background: transparent;
      border: none;
      cursor: pointer;
      font: inherit;
      left: 0;
      outline: inherit;
      padding: 0;
      position: absolute;
      top: 0;
      z-index: 10;

      &:hover {
        opacity: var(--default-hover-opacity);
      }

      &:active {
        opacity: var(--default-active-opacity);
      }

      &:focus {
        outline: none;
      }
    }

    &__player-icon {
      align-items: center;
      background-color: h.$msnbc-yellow-40;
      display: flex;
      height: h.rem(40px);
      justify-content: center;
      width: h.rem(40px);

      &.dn {
        display: none;
      }
    }

    &__wrap-labels {
      align-items: flex-end;
      display: flex;
      flex-flow: row nowrap;
      margin: 0 0 var(--space-20);
    }

    &__label {
      color: var(--grey-70);
      font: normal var(--text-12)/1 var(--founders-mono);
      letter-spacing: -0.04em;
      text-transform: uppercase;
      margin-left: auto;
    }

    &__countdown {
      font: 600 var(--text-16)/1 var(--founders-cond);
    }

    &__wrap-tags {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      margin: 0 0 var(--space-8);
    }

    &__live-tag {
      font: 600 var(--text-14)/1 var(--founders-cond);
      text-transform: uppercase;
      margin-right: h.rem(6px);

      &::before {
        animation: live-dot-blink 1.5s ease-in-out infinite;
        background-color: h.$live-flag-active-background-color;
        border-radius: 50%;
        content: ' ';
        display: inline-block;
        height: 7px;
        margin-bottom: 1px;
        margin-right: var(--space-4);
        width: 7px;
      }
    }

    &__logo-tag {
      display: inline-block;
      font-size: var(--text-16);
      line-height: 1;
    }

    &__live-tag + &__logo-tag {
      margin-bottom: h.rem(3px);
    }

    &__title {
      font-family: var(--founders-cond);
      font-size: var(--text-28);
      line-height: 1;
      margin: 0;
    }

    &__dek {
      font-weight: normal;
    }

    &__slate__overlay {
      background-color: rgba(48, 97, 255, 0.7);
    }

    .pkg[class*='2up'] &,
    .pkg[class*='3up'] & {
      &__grid-col1 {
        background-image: linear-gradient(to top, var(--grey-20), var(--grey-20) 120px, transparent 120px, transparent);
        padding: 0 var(--space-16) var(--space-16);
        position: relative;

        &::before {
          background-color: var(--grey-20);
          bottom: 0;
          content: ' ';
          display: block;
          height: calc(100% - 60px);
          position: absolute;
          right: 0;
          width: var(--space-16);
        }
      }

      &__grid-col2 {
        background-color: var(--grey-20);
        padding: 0 var(--space-16) var(--space-16);
      }
    }

    .pkg[class*='2up'] & {
      @include h.breakpoint-m {
        &__grid-col1 {
          padding: 0 var(--space-12) var(--space-12);
        }

        &__grid-col2 {
          padding: 0 var(--space-12) var(--space-12);
        }
      }

      &__title {
        @include h.breakpoint-x {
          font-size: var(--text-32);
        }
      }
    }

    .pkg[class*='3up'] & {
      @include h.breakpoint-m {
        &__grid-col1 {
          padding: 0 h.rem(30px) var(--space-12);

          &::before {
            width: h.rem(30px);
          }
        }

        &__grid-col2 {
          padding: 0 h.rem(30px 30px);
        }
      }

      &__title {
        font-size: var(--text-40);

        @include h.breakpoint-x {
          font-size: var(--text-32);
        }
      }
    }

    .pkg.storyline &,
    .pkg.multi-storyline & {
      margin-bottom: var(--space-16);
      padding-bottom: 0;

      &__wrap-labels {
        margin: 0;
        padding: 8px 0 0 8px;
        background-color: var(--grey-30);
        order: 2;
      }

      &__wrap-tags {
        margin: 0;
        order: 1;
      }

      &__title {
        font-size: var(--text-24);
        margin: var(--space-8) 0 0 0;
        order: 3;
      }

      &__label {
        margin-left: 0;
      }

      &__live-tag,
      &__wrap-tags {
        display: none;
      }

      &__live-tag + &__logo-tag {
        margin: 0;
      }

      &__grid-col2 {
        display: flex;
        flex-flow: column nowrap;
      }
    }

    .pkg.cover-spread & {
      margin-bottom: var(--space-16);
      padding-bottom: 0;

      &__wrap-labels {
        margin: var(--space-12) 0 0 0;
        order: 2;
      }

      &__wrap-tags {
        margin: 0;
        order: 1;
      }

      &__title {
        font-size: var(--text-20);
        margin: var(--space-16) 0 0 0;
        order: 3;
      }

      &__label {
        margin-left: 0;
      }

      &__live-tag {
        display: none;
      }

      &__live-tag + &__logo-tag {
        margin: 0;
      }

      &__grid-col1 {
        background-image: linear-gradient(to top, var(--grey-20), var(--grey-20) 60px, transparent 60px, transparent);
        padding: 0 var(--space-12) var(--space-12);
        position: relative;

        @include h.breakpoint-x {
          padding: 0 var(--space-20) var(--space-12);

          &::before {
            background-color: var(--grey-20);
            bottom: 0;
            content: ' ';
            display: block;
            height: 75%;
            position: absolute;
            right: 0;
            width: var(--space-20);
          }
        }
      }

      &__grid-col2 {
        background-color: var(--grey-20);
        padding: 0 var(--space-12) var(--space-12);
        display: flex;
        flex-flow: column nowrap;

        @include h.breakpoint-x {
          padding-left: var(--space-20);
          padding-right: var(--space-20);
        }
      }
    }

    .pkg.cover-spread.cover-spread--rail-adj & {
      @include h.breakpoint-x {
        &__grid-col1 {
          padding-left: 0;
          padding-right: 0;

          &::before {
            content: none;
          }
        }

        &__grid-col2 {
          padding-left: var(--space-12);
          padding-right: var(--space-12);
        }

        &__live-tag {
          display: none;
        }
      }
    }

    .smorgasbord__cvsdk {
      &-col-1 {
        aspect-ratio: 16 / 9;
      }

      &-col-2 {
        margin-top: h.rem(12px);

        .cvsdk-tease-msnbc__title {
          font-size: 24px;

          @include h.breakpoint-m {
            font-size: 28px;
          }

          @include h.breakpoint-l {
            font-size: 32px;
          }

          .cvsdk-tease__flag {
            font-size: 22px;

            @include h.breakpoint-m {
              font-size: 24px;
            }

            @include h.breakpoint-l {
              font-size: 28px;
            }
          }

          .cvsdk-tease-msnbc__dot {
            margin-right: 6px;

            @include h.breakpoint-s-only {
              margin-left: 20px;
            }
          }
        }

        .cvsdk-tease-msnbc__wrap-tags,
        .cvsdk-tease-msnbc__wrap-labels {
          display: none;
        }
      }
    }
  }

  .cvsdkFlag-video-title {
    font-size: 24px;
  }
}
