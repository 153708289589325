@use 'assets/styles/utils/helper' as h;

@mixin cover-spread-tease-row-format-mixin {
  width: 100%;
  display: flex;
  flex-direction: row;

  .cover-spread-tease__image {
    margin-right: var(--space-16);
    margin-bottom: var(--space-16);
    flex-shrink: 0;
  }
}

@mixin cover-spread-tease-no-top-border {
  border-top: 0;
  padding-top: 0;
}

@mixin extra-whitespace {
  padding-top: var(--space-28);
  margin-bottom: var(--space-28);
}

@mixin extra-whitespace-no-top-border {
  margin-bottom: var(--space-28);
}

.cover-spread-tease {
  &--0,
  &--1,
  &--2,
  &--3,
  &--4,
  &--5,
  &--6,
  &--7 {
    border-top: solid var(--space-1) var(--grey-40);
    padding-top: var(--space-16);
  }
}

// 4 total items
.cover-spread-layout-2-slot {
  &-full {
    &--live-blog {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m-to-l-only {
        .cover-spread-tease--0 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l-only {
        .cover-spread-tease--1 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }

  &-rail {
    &--live-blog {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m-only {
        .cover-spread-tease--0 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }
}

// 5 total items
.cover-spread-layout-3-slot {
  &-full {
    &--live-blog {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l-only {
        .cover-spread-tease--0 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
          @include cover-spread-tease-row-format-mixin;
        }

        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }
    }
  }

  &-rail {
    &--live-blog {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }
}

// 6 total items
.cover-spread-layout-4-slot {
  &-full {
    &--live-blog {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }

        .no-eyebrow:not(.cover-spread-tease--0) {
          @include extra-whitespace;
        }

        .cover-spread-tease--0.no-eyebrow {
          @include extra-whitespace-no-top-border;
        }
      }
    }
  }

  &-rail {
    &--live-blog {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }

  &-full,
  &-rail {
    &--live-blog {
      &.cover-spread-layout--flex-column {
        flex-direction: column;
      }
    }
  }
}

.cover-spread-layout-5-slot {
  &-full {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--3 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m-to-l-only {
        .cover-spread-tease--2,
        .cover-spread-tease--3 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--2 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }

  &-rail {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--3 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--2 {
          @include cover-spread-tease-no-top-border;
        }
      }

      @include h.breakpoint-m-only {
        .cover-spread-tease--2,
        .cover-spread-tease--3 {
          @include cover-spread-tease-row-format-mixin;
        }
      }
    }
  }
}

.cover-spread-layout-6-slot {
  &-full {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--3,
        .cover-spread-tease--4 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--2 {
          @include cover-spread-tease-no-top-border;
          @include cover-spread-tease-row-format-mixin;
        }

        .cover-spread-tease--3,
        .cover-spread-tease--4 {
          @include cover-spread-tease-row-format-mixin;
        }

        .cover-spread-tease--4 .cover-spread-tease__image {
          margin-bottom: 0;
        }
      }
    }
  }

  &-rail {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--3,
        .cover-spread-tease--4 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--2 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }
}

.cover-spread-layout-7-slot {
  &-full {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-row-format-mixin;
          @include cover-spread-tease-no-top-border;
        }

        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }

        .cover-spread-tease--2 .cover-spread-tease__image {
          margin-bottom: 0;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--4,
        .cover-spread-tease--5 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--3 {
          @include cover-spread-tease-no-top-border;
          @include cover-spread-tease-row-format-mixin;
        }

        .cover-spread-tease--4,
        .cover-spread-tease--5 {
          @include cover-spread-tease-row-format-mixin;
        }
      }
    }
  }

  &-rail {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--4,
        .cover-spread-tease--5 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--3 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }
}

.cover-spread-layout-8-slot {
  &-full {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-row-format-mixin;
          @include cover-spread-tease-no-top-border;
        }

        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }

        .cover-spread-tease--2 .cover-spread-tease__image {
          margin-bottom: 0;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--4,
        .cover-spread-tease--5 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--3 {
          @include cover-spread-tease-no-top-border;
        }

        .no-eyebrow:not(.cover-spread-tease--3) {
          @include extra-whitespace;
        }

        .cover-spread-tease--3.no-eyebrow {
          @include extra-whitespace-no-top-border;
        }
      }
    }
  }

  &-rail {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--4,
        .cover-spread-tease--5 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--3 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }
}

.cover-spread-layout-9-slot {
  &-full {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }

        .no-eyebrow:not(.cover-spread-tease--0) {
          @include extra-whitespace;
        }

        .cover-spread-tease--0.no-eyebrow {
          @include extra-whitespace-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--5,
        .cover-spread-tease--6 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-x {
        .cover-spread-tease--4 {
          @include cover-spread-tease-no-top-border;
        }

        .no-eyebrow:not(.cover-spread-tease--4) {
          @include extra-whitespace;
        }

        .cover-spread-tease--4.no-eyebrow {
          @include extra-whitespace-no-top-border;
        }
      }
    }
  }

  &-rail {
    &__column--1 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--1,
        .cover-spread-tease--2 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-m {
        .cover-spread-tease--0 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }

    &__column--2 {
      @include h.breakpoint-s-only {
        .cover-spread-tease--5,
        .cover-spread-tease--6 {
          @include cover-spread-tease-row-format-mixin;
        }
      }

      @include h.breakpoint-l {
        .cover-spread-tease--4 {
          @include cover-spread-tease-no-top-border;
        }
      }
    }
  }
}

.cover-spread__column-one .cover-spread-tease:last-child .cover-spread-tease__headline,
.cover-spread__column-two .cover-spread-tease:last-child .cover-spread-tease__headline {
  margin-bottom: 0;
}
