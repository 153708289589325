@use 'assets/styles/utils/helper' as h;

.taboola {
  // Explicit stacking context to prevent overlap with first party elements
  position: relative;
  z-index: h.get-z-index('container');

  @include h.breakpoint-m {
    width: auto;
    margin: auto;
  }

  @include h.breakpoint-l {
    max-width: h.rem(1000px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(1240px);
  }
}
