@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

$coverspread-headline--sm: (s: 32px, m: 40px, l: 48px, x: 60px);
$coverspread-headline--md: (s: 32px, m: 48px, l: 60px, x: 80px);
$coverspread-headline--lg: (s: 60px, m: 96px, l: 96px, x: 128px);

.cover-spread {
  background-color: var(--white);
  position: relative; // Required for MSNBC fronts

  &__wrapper {
    button {
      margin: 0;
      border: 0;
      background-color: transparent;
      padding: 0;
      text-align: left;

      &:focus {
        outline: none;
      }
    }
  }

  /* Change the play button to solid when user hovers anywhere over the cover spread column */
  &__feature {
    cursor: pointer;
    width: 100%;

    &:hover .cover-spread-featured-video__video__play-button {
      opacity: 1;
    }
  }

  &--stuck {
    position: relative;
    z-index: h.get-z-index('sticky-video-parent');
  }

  &--has-header {
    border-top: solid var(--space-4) var(--csp--border-top-color);

    @include h.breakpoint-m {
      padding-top: var(--space-20);
    }
  }

  &--breaking {
    border-top-color: var(--csp--breaking--border-top-color);
  }

  &--has-title {
    padding-top: 0;
  }

  &__headline {
    color: var(--grey-70);
    line-height: var(--leading-100);
    margin: var(--space-20) var(--space-8);
    font-family: var(--founders-cond);
    font-size: var(--text-32);
    text-align: center;

    &--sm {
      @include h.responsive-variations(font-size, $coverspread-headline--sm);
    }

    &--md {
      @include h.responsive-variations(font-size, $coverspread-headline--md);
    }

    &--lg {
      @include h.responsive-variations(font-size, $coverspread-headline--lg);
    }

    &__wrapper {
      @include h.breakpoint-m {
        margin-bottom: var(--space-20);
      }
    }

    &.cover-spread__rail {
      font-size: var(--text-40);
    }
  }

  // Blog variant: Feed of blog posts nested in the Cover Spread
  &__blog {
    @include h.breakpoint-s-only {
      border-top: 5px solid var(--csp--live-blog--border-top-color);
      padding: var(--space-20) 0;

      &--breaking {
        border-top-color: var(--csp--live-blog--breaking--border-top-color);
      }

      &--chatStyle {
        border: none;
      }
    }

    @include h.breakpoint-l-only {
      .cover-spread--rail-adj & {
        border-top: 4px solid var(--csp--live-blog--border-top-color);
        padding: var(--space-20) 0 0;
      }

      &--breaking {
        .cover-spread--rail-adj & {
          border-top-color: var(--csp--live-blog--breaking--border-top-color);
        }
      }
    }
  }

  &__column-featured {
    @include h.breakpoint-x {
      order: 2;
    }
  }

  &__column-one,
  &__column-two {
    @include h.breakpoint-s-only {
      padding: 0 var(--space-20);
    }
  }

  &__column-one {
    @include h.breakpoint-x {
      order: 1;
    }
  }

  &__column-two {
    @include h.breakpoint-x {
      order: 3;
    }
  }

  &--blog &__column-featured {
    @include h.breakpoint-x {
      order: 2;
    }
  }

  &--blog &__column-one {
    @include h.breakpoint-x {
      order: 3;
    }

    // Medium bp only, allow live blog to expand out of row to column below.
    // Column below accounts for this overlap by consuming only 9 columns.
    @include h.breakpoint-m-only {
      position: relative;

      > .cover-spread__blog {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--blog &__column-two {
    @include h.breakpoint-x {
      order: 1;
    }
  }

  .pkg-bottom-border {
    @include h.breakpoint-s-only {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .cvsdk-tease,
  .cvsdk-tease-msnbc {
    z-index: 40;
  }
}
