@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.loadMoreWrapper {
  display: flex;
  justify-content: center;
  margin: var(--feeds-pkg-more-margin);

  .button {
    background-color: var(--white);
    border-radius: 0;
    margin: 0 20px;
    max-width: 300px;

    @include h.breakpoint-m {
      margin: 0;
      max-width: none;
      width: h.rem(313px);
    }

    @include h.breakpoint-l {
      width: h.rem(323px);
    }

    @include h.breakpoint-x {
      width: h.rem(340px);
    }

    :global(.today) {
      background-color: #f8f8f8;
    }

    :global(.select) & {
      border-radius: 100px;
    }
  }
}

.itemsContainer {
  padding-top: var(--feeds-pkg-padding-top);
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);
  margin-top: var(--feeds-pkg-margin-top);

  @include h.breakpoint-m {
    padding-left: 0;
    padding-right: 0;
  }

  @include h.breakpoint-l {
    overflow: hidden;
  }

  :global(.today) & {
    :global(.pkg-ad .mps-slot:not([style*='display: none']):not(.mps-gpt-hidden)) {
      margin-bottom: 0;
      padding-top: 25px;
      padding-bottom: 25px;
      border-top: 1px solid var(--grey-30);

      @include h.breakpoint-m {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }
  }
}
