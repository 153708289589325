@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.list {
  position: relative;
  margin-bottom: 48px;
}

.container {
  margin-bottom: 48px;

  @include h.breakpoint-m {
    margin-bottom: 40px;
  }
}

.firstLayout,
.articleLayout {
  display: grid;
  gap: var(--waffle-list--gap);

  @include h.breakpoint-m {
    grid-template-columns: var(--waffle-list--grid-columns);
  }
}

.articleLayout {
  @include h.breakpoint-l {
    grid-template-columns: 1fr 1fr;
  }
}

.mobileColumns {
  .firstLayout,
  .articleLayout {
    @include h.breakpoint-s-only {
      gap: var(--waffle-list--mobile--gap);
      grid-template-columns: 1fr 1fr;

      :global(.recipe-detail-container) {
        flex-direction: column;
        align-items: start;

        :global(.info-tile)::before {
          content: none;
        }
      }
    }
  }
}

.ad {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  padding: 0;
  width: unset;

  @include h.breakpoint-m {
    margin-bottom: 0;
    padding: 27px;
  }

  @include h.breakpoint-l {
    padding: 0;
  }

  @include h.breakpoint-x {
    padding: 27px;
  }
}

.midAd {
  margin-top: -40px;
  padding: 40px;
}

.midResponsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: h.rem(48px);
  width: 100%;

  @include h.breakpoint-m {
    margin-bottom: 0;
  }

  @include h.breakpoint-l {
    flex-direction: column-reverse;
  }
}

:global(.product-waffle) {
  .container {
    width: 100vw;
    overflow-x: hidden;

    ul {
      grid-auto-rows: 1fr;
      border-left-width: 10px;
      border-right-width: 10px;
    }
  }
}

.gridAd {
  margin-bottom: h.rem(20px);

  .mobileColumns & {
    @include h.breakpoint-s-only {
      grid-column: span 2;
      display: flex;
      justify-content: center;
    }
  }

  @include h.breakpoint-m {
    grid-column: span 3;
  }

  @include h.breakpoint-l {
    grid-column: span 4;
  }

  @include h.breakpoint-x {
    margin-bottom: h.rem(32px);
  }

  > div {
    display: flex;
    justify-content: center;
  }
}
