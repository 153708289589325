@use 'assets/styles/utils/helper' as h;

.smorgasbord-main-story__dek {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: var(--publico-txt);
  color: var(--grey-70);
  margin: 0;

  @include h.breakpoint-s-only {
    display: none;
  }
}
