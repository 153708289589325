@use 'assets/styles/utils/helper' as h;

.schedule {
  cursor: default;
}

.item {
  font-family: var(--founders-cond);
  font-style: normal;
  margin: var(--space-16) 0;

  &:last-of-type {
    margin-bottom: var(--space-20);
  }

  @include h.breakpoint-m-only {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.time {
  color: var(--black);
  display: block;
  font-size: var(--text-18);
  font-weight: var(--semibold);
  line-height: var(--leading-100);
  text-transform: uppercase;
}

.title {
  color: var(--grey-60);
  display: block;
  font-size: var(--text-16);
  line-height: var(--leading-100);
}
