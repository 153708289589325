@use 'assets/styles/utils/helper' as h;

:global(.feeds) {
  --feeds-pkg-more-margin: 24px 0 0;
  --feeds-pkg-padding-top: 0;
  --feeds-pkg-margin-top: 0;

  :global(body.select) {
    --feeds-pkg-more-margin: 15px 0 0;
  }

  :global(body.today) & {
    --feeds-pkg-more-margin: #{h.rem(24px 20px 0)};

    @include h.breakpoint-m {
      --feeds-pkg-more-margin: #{h.rem(24px 0 0 70px)};
    }

    @include h.breakpoint-l {
      --feeds-pkg-more-margin: #{h.rem(24px 0 0 65px)};
      --feeds-pkg-margin-top: -10px;
    }

    @include h.breakpoint-x {
      --feeds-pkg-more-margin: #{h.rem(24px 0 0 85px)};
    }

    .noTimestamp {
      @include h.breakpoint-m {
        --feeds-pkg-more-margin: #{h.rem(24px 0 0)};
      }
    }
  }
}
