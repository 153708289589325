@use 'assets/styles/utils/helper' as h;

.section {
  position: relative;

  @include h.breakpoint-l {
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }
}

@include h.breakpoint-m {
  .twoLead::before {
    @include h.responsive-variations(height, (m: 250px, l: 300px, x: 350px));
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    background-color: var(--grey-20);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.fluidWidth {
  @include h.breakpoint-m {
    margin: auto;
    max-width: 88%;
  }

  @include h.breakpoint-xx {
    max-width: 86%;
  }
}
