@use 'assets/styles/utils/helper' as h;

.duration {
  --duration--color: var(--default-color);

  :global(body.globalcitizen) & {
    --duration--color: var(--red-40);
  }
  align-items: center;
  background-color: var(--grey-20);
  color: var(--duration--color);
  display: inline-flex;
  height: h.rem(20px);
  justify-content: center;
  margin: var(--space-8) 0 0;
}

.label {
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  margin: 0 var(--space-12);
}

.typeIcon {
  height: h.rem(20px);
  position: relative;
  width: h.rem(20px);
}

.isLive {
  color: var(--white);
  background-color: #af2929;

  .typeIcon {
    background-color: var(--red-50);
  }
}
