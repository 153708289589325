@use 'assets/styles/utils/helper' as h;

.section {
  display: flex;
  position: relative;
  background-color: var(--accent-orange-light);

  @include h.breakpoint-m {
    background: linear-gradient(to right, var(--accent-orange-light) 65%, transparent 0%);
  }

  @include h.breakpoint-l {
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }
}

div.container {
  display: flex;
  flex-direction: column;

  @include h.breakpoint-m {
    flex-direction: row;
    min-height: h.rem(165px);
    margin: 0 auto;
    max-width: 1260px;
  }

  @include h.breakpoint-l {
    min-height: h.rem(215px);
  }

  @include h.breakpoint-x {
    min-height: h.rem(270px);
  }

  @include h.breakpoint-xx {
    min-height: h.rem(345px);
  }
}

.title {
  color: var(--grey-60);
  font-family: var(--primary-font);
  font-size: h.rem(20px);
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: h.rem(0);
  margin-block-start: 0;

  @include h.breakpoint-m {
    font-size: h.rem(20px);
    max-width: h.rem(380px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(28px);
    max-width: h.rem(450px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(500px);
  }
}

.description {
  color: var(--grey-60);
  font-family: var(--secondary-font);
  font-size: h.rem(16px);
  line-height: 1.5;
  font-weight: 400;
  margin-top: h.rem(8px);
  margin-bottom: h.rem(16px);

  @include h.breakpoint-l {
    margin-bottom: h.rem(24px);
  }
}

.left {
  padding: h.rem(30px 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.logo {
  height: h.rem(76px);
  object-fit: contain;
  display: flex;
  align-self: center;
  margin-bottom: h.rem(24px);
  position: absolute;
  top: h.rem(20px);
  z-index: 1;

  @include h.breakpoint-m {
    height: h.rem(45px);
    margin-top: 0;
    align-self: flex-start;
    justify-content: flex-start;
    top: 0;
    position: relative;
  }

  @include h.breakpoint-l {
    height: h.rem(58px);
  }

  @include h.breakpoint-xx {
    height: h.rem(75px);
  }
}

.button {
  margin: 0;
  background-color: transparent;
  font-size: h.rem(20px);
  height: h.rem(47px);
  align-self: center;

  @include h.breakpoint-m {
    align-self: flex-start;
  }
}

.disclaimer {
  font-family: var(--secondary-font);
  font-size: h.rem(10px);
  color: var(--grey-60);
  margin-top: h.rem(16px);
  margin-block-end: 0;

  @include h.breakpoint-m {
    margin-top: h.rem(25px);
    max-width: h.rem(380px);
    font-size: h.rem(10px);
  }

  @include h.breakpoint-l {
    margin-top: h.rem(24px);
    max-width: h.rem(450px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(500px);
  }
}

.disclaimerLink {
  @extend .disclaimer;
  text-decoration: underline;
  text-underline-offset: h.rem(3px);
  text-decoration-color: var(--accent-orange);
  text-decoration-thickness: h.rem(2px);
  color: var(--black);
  margin-top: h.rem(4px);

  &:hover {
    opacity: .7;
    color: var(--primary-color);
  }

  @include h.breakpoint-m {
    max-width: h.rem(380px);
    margin-top: h.rem(4px);
  }

  @include h.breakpoint-l {
    max-width: h.rem(450px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(500px);
  }
}

.disclaimerLink.no-disclaimer {
  margin-top: h.rem(16px);
}

.right {
  order: -1;
  clip-path: polygon(0% 0%, 82% 0%, 90% 100%, 8% 100%);
  transform: translateX(5%);
  width: 100%;
  margin-top: h.rem(116px);

  @include h.breakpoint-m {
    clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%);
    order: 0;
    margin-top: 0;
  }

  img {
    @include h.breakpoint-m {
      position: absolute;
      display: block;
      height: 100%;
      object-fit: cover;
    }
  }
}
