@use 'assets/styles/utils/helper' as h;

.lock {
  background-color: transparent;
  border: none;
  color: var(--black);
  font-family: var(--founders-cond);
  font-size: var(--text-16);
  font-style: var(--normal);
  font-weight: var(--semibold);
  margin-left: var(--space-4);
  padding: 0;
  text-align: left;

  &::before {
    margin-right: var(--space-4);
  }

  &:active {
    opacity: var(--default-active-opacity);
  }

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}
