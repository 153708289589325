@use 'assets/styles/utils/helper' as h;

.baconSlider {
  position: relative;
}

.baconWrapper {
  display: flex;
}

.baconSliderScrollContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: var(--bacon-slider-column-width, auto);
  column-gap: var(--bacon-slider-column-gap);
  overflow-x: auto;
  scrollbar-width: none;
  padding-right: var(--bacon-slider-offset-right, 0);
}

.baconSliderScrollContainer::-webkit-scrollbar {
  display: none;
}

.prevBtn {
  position: absolute;
  left: 0;
  height: 100%;
}

.nextBtn,
.prevBtn {
  flex-shrink: 0;
  opacity: 0;
  pointer-events: none;
  background-color: var(--white);
  z-index: 1;
}

.isVisible {
  opacity: 1;
  pointer-events: all;
}

@media (hover: none) {
  .nextBtn,
  .prevBtn {
    opacity: 0;
    pointer-events: none;
    width: 0;
    overflow: hidden;
  }
}
