@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.productWaffle {
  background-color: var(--waffle--background-color);

  :global(body.select .lead-type--waffle .bg-background__color) {
    background-color: var(--white);
  }
}

.productWaffleList {
  grid-gap: 48px 0;
  overflow: hidden;
  padding: 20px 0;

  li {
    border-bottom: 1px var(--grey-40) solid;
    position: relative;
    margin-left: -1px;
  }

  li::before {
    content: '';
    position: absolute;
    background-color: var(--grey-40);
    z-index: 1;
    inline-size: 1px;
    block-size: 100vh;
    inset-inline-start: 0;
  }

  & *:focus-visible {
    outline: 2px solid #0db14b;
    outline-offset: -2px;
  }

  @include h.breakpoint-m {
    border: 0;
    padding: 0;
  }
}
