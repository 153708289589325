@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.two-up__articles {
  @include h.clearfix;
  background-color: var(--two-up-articles-background-color);
  position: relative;

  // TODO: remove when package spacing is standardized for all teases
  & > article {
    // include spacing on the bottom of all teases on mobile
    @include h.package-spacing;

    // last child on mobile should not have spacing due to layout-applied spacing
    &:last-child {
      margin-bottom: 0;
    }

    // all other breakpoints handle spacing at layout-level
    @include h.breakpoint-m {
      margin-bottom: 0;
    }
  }

  @include h.breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .msnbc &.two-up__accent-line {
    border-top: 5px solid #ffbe03;
  }
}

.tease-card--two-up {
  .tease-card__content {
    @include h.breakpoint-m {
      flex-basis: 100%;

      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{50%} - 1px);
      display: flex;
      flex-direction: column;
    }
  }

  .tease-card__featured-author {
    @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(26px), l: h.rem(20px), x: h.rem(26px)));
    @include h.responsive-variations(line-height, (s: h.rem(25px), m: h.rem(30px), l: h.rem(25px), x: h.rem(30px)));

    &--today,
    &--knonwyourvalue,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(23.4px), l: h.rem(18px), x: h.rem(23.4px)));
    }
  }

  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(28px), l: h.rem(24px), x: h.rem(32px)));
    line-height: var(--tease-card-two-up-title-line-heights);

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px), l: h.rem(20px), x: h.rem(24px)));
    }

    &--knonwyourvalue {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(22.5px), l: h.rem(18px), x: h.rem(22.5px)));
    }

    &--select {
      @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(24px), l: h.rem(22px), x: h.rem(24px)));
    }

    .sponsoredcontent {
      font-family: var(--founders-cond);
    }

    .tease-card__headline--deepLink-episode {
      font-size: h.rem(20px);

      @include h.breakpoint-m {
        font-size: h.rem(24px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-l {
        font-size: h.rem(20px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-x {
        font-size: h.rem(28px);
        line-height: var(--leading-100);
      }
    }
  }

  .tease-card--no-main-image {
    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(32px), m: h.rem(40px)));

      &--today {
        @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(32px)));
      }

      &--select {
        @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(24px), l: h.rem(22px), x: h.rem(32px)));
      }
    }
  }
}

.stickyAdTrack--7up .two-up--7up .tease-card .tease-card__info .tease-card__dek {
  line-height: h.rem(21px) !important;
}
