@use 'assets/styles/utils/helper' as h;

.subnav {
  font-family: var(--founders-cond);
  font-size: 18px;
  margin-bottom: 20px;
  position: relative;
  // Adds padding to align with layout for mobile
  @include h.breakpoint-s-only {
    padding: 0 20px;
  }
}

// Links
.links-container-wrapper {
  overflow-x: auto;
  scrollbar-width: none;
  padding-top: 6px;

  &.dropdown-open {
    margin-bottom: 330px;

    @include h.breakpoint-m {
      margin-bottom: 573px;
    }

    @include h.breakpoint-l {
      margin-bottom: 405px;
    }

    @include h.breakpoint-x {
      margin-bottom: 360px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.links-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 5px;
}

.link-item {
  display: inline-block;
  padding: 0 20px 0 0;
  font-weight: 600;
  white-space: nowrap;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 20px;
  }

  &:hover {
    opacity: 70%;
  }
}

// Logos
.desktop-title-img {
  height: 40px;
  display: block;
  width: auto;

  @include h.breakpoint-s-only {
    display: none;
  }
}

.standard-logo-div {
  font-family: var(--publico-txt);
  color: h.$news-blue-40;
  font-size: 20px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  display: block;
  margin: 0 20px 0 0;

  .standard-title-no-nav {
    word-wrap: normal;
    white-space: normal;
  }

  .desktop-title-img {
    height: 24px;
  }
}

.standard-logo-border {
  border-right: 1px solid #ccc;
}

.standard-logo {
  white-space: nowrap;
  margin-right: 20px;

  @include h.breakpoint-s-only {
    max-height: 20px;
    font-size: 20px;
  }
}

.standard-no-logo {
  margin-right: 0;
}

.mobile-img {
  display: none;

  @include h.breakpoint-s-only {
    display: block;
  }
}

.tease-img {
  display: block;

  @include h.breakpoint-s-only {
    display: none;
  }
}

.pkg-logo {
  max-width: fit-content;
  text-align: center;
}

.primary-headline-logo {
  font-size: 18px;
}
// Go Big
.go-big-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; // Allow the container to grow
}

.live-coverage {
  .lbp-card__time {
    color: var(--breaking-red);
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    top: -3px;
  }
}

.go-big-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-big-subnav-title {
  display: flex;
  justify-content: center;
  font-size: 40px;
  margin: 0 50px;
  word-wrap: normal;
  white-space: nowrap; // Prevent text from wrapping
  flex-grow: 1; // Allow the div to grow
  max-width: 700px; // Set the maximum width

  @include h.breakpoint-s-only {
    margin: 0;
    word-wrap: normal;
    white-space: normal;
    max-width: 100%; // Ensure the div does not exceed the viewport width on small screens
  }
}

.mobile-title-img {
  vertical-align: middle;
  display: none;
  max-height: 40px;
  width: auto;

  @include h.breakpoint-s-only {
    display: block;
    height: 24px;
    width: auto;
  }
}

.live-blink-dot {
  background-color: var(--breaking-red);
  height: 7px;
  margin: 0 var(--space-4) 2px var(--space-4);
  width: 7px;
}

.msnbc-bar {
  @include h.breakpoint-s-only {
    height: 5px;
    background-color: #3061ff;
    margin-bottom: 16px;
    margin-right: -20px;
    margin-left: -20px;
  }
}

.hr-line {
  border: 0;
  height: 1px;
  width: 100%;
  background: h.$news-blue-40;

  @include h.breakpoint-s-only {
    display: none;
  }
}

.left-election-theme {
  background: #0495f4;
}

.right-election-theme {
  background: linear-gradient(to right, #6e55dc, #fe3335, #f33);
}

.msnbc-election-theme {
  background: linear-gradient(to right, #dd2929, #009af4);
}

.mobile-hr-line {
  display: none;
  border: 0;
  height: 3px;
  width: 100%;
  background: h.$news-blue-40;
  margin-bottom: 16px;

  @include h.breakpoint-s-only {
    display: block;
  }
}
