@use 'assets/styles/utils/helper' as h;

.mosaic {
  --mosaic--background-color: var(--white);
  --mosaic--border--background-color: var(--default-color);
  --mosaic--border--height: auto;
  --mosaic--border--width: auto;

  // All teases
  --mosaic--tease-card--title--color: var(--default-headline-color);
  --mosaic--tease-card--title--font-size: var(--text-24);
  --mosaic--tease-card--title--line-height: var(--leading-100);

  // Lead tease
  --mosaic--tease-card-lead--title--font-size: var(--text-24);
  --mosaic--tease-card-lead--title--line-height: var(--leading-100);

  @include h.breakpoint-m {
    --mosaic--tease-card-lead--title--font-size: var(--text-40);
  }

  @include h.breakpoint-l {
    --mosaic--tease-card--title--font-size: var(--text-20);
  }

  :global(body.today) & {
    --mosaic--background-color: var(--neutral-10);
  }

  :global(body.think) &,
  :global(body.today) & {
    // All teases
    --mosaic--tease-card--title--font-size: var(--text-18);
    --mosaic--tease-card--title--line-height: var(--leading-125);

    // Lead tease
    --mosaic--tease-card-lead--title--font-size: var(--text-18);
    --mosaic--tease-card-lead--title--line-height: var(--leading-125);

    @include h.breakpoint-m {
      --mosaic--tease-card-lead--title--font-size: var(--text-32);
      --mosaic--tease-card--title--font-size: 22px;
      // Is this way in Figma - https://www.figma.com/file/DapSOzk9kBbjqNiqOC4zfy/PTL---General-%26-Full-Width?node-id=3175%3A977
      --mosaic--tease-card--title--line-height: 30px;
    }

    @include h.breakpoint-l {
      --mosaic--tease-card--title--font-size: var(--text-18);
      // Reset
      --mosaic--tease-card--title--line-height: var(--leading-125);
    }
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    --mosaic--border--height: 5px;
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --mosaic--tease-card--title--color: var(--blue-60);
  }
}
