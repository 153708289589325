@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

$video-live__info-container-width--desktop: 191px;

.video-live {
  background-color: var(--video-live-background-color);
  color: rgb(255, 255, 255);
  margin-bottom: 48px;
  width: 100vw;
  position: relative;
  z-index: h.get-z-index('sticky-video-parent');

  .videoPlayer {
    width: 100%;
  }

  &__inner {
    width: 100%;
  }

  &__stage {
    display: flex;
    flex-direction: column;
  }

  &__info-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: var(--video-live-info---font-size);
    line-height: 24px;
  }
}

@include h.breakpoint-m {
  .video-live .layout-grid-container {
    max-width: 100%;
  }

  .video-live {
    margin-left: var(--video-live--margin-left);
  }
}

@include h.breakpoint-l {
  .video-live .layout-grid-container {
    max-width: calc(1000px - 40px);
  }

  .video-live {
    margin-right: var(--video-live--margin-right);

    &__stage {
      flex-direction: row;
      padding: 16px 40px 21px;
    }

    &__info-container {
      padding: 0 0 0 40px;
      flex-direction: column;
      flex: 0 0 $video-live__info-container-width--desktop;
    }

    &__schedule-container {
      margin: 0 40px;
    }
  }
}

@include h.breakpoint-x {
  .video-live .layout-grid-container {
    max-width: calc(1240px - 40px);
  }
}

.video-live__core-video-container {
  flex-grow: 1;
  aspect-ratio: 16 / 9;
}
