@use 'assets/styles/utils/helper' as h;

.headline {
  --waffle-headline--color: var(--default-text-color);
  --waffle-headline--hover-color: var(--default-color);
  --waffle-headline--font-size: var(--text-24);
  --waffle-headline--font-size--mobile-2up: var(--text-18);
  --waffle-headline--font-weight: var(--semibold);
  --waffle-headline--line-height: var(--leading-100);
  --waffle-headline--product--color: var(--default-text-color);
  --waffle-headline--font-family: var(--default-headline-font-family);
  --waffle-headline--hover-opacity: var(--default-hover-opacity);
  --waffle-headline--text-decoration-line: none;

  :global(body.today) & {
    --waffle-headline--color: var(--grey-70);
    --waffle-headline--product--color: var(--grey-70);
    --waffle-headline--font-family: var(--secondary-font);
    --waffle-headline--font-weight: normal;
    --waffle-headline--font-size: #{h.rem(20px)};
    --waffle-headline--line-height: var(--leading-150);
    --waffle-headline--hover-color: var(--grey-70);
    --waffle-headline--hover-opacity: 1;
    --waffle-headline--text-decoration-line: underline;
    --waffle-headline--text-decoration-color: var(--accent-blue);
    --waffle-headline--text-decoration-thickness: #{h.rem(2px)};
    --waffle-headline--text-underline-offset: #{h.rem(5px)};
  }

  :global(body.think) & {
    --waffle-headline--font-size--mobile-2up: var(--text-16);
    --waffle-headline--font-weight: var(--normal);
    --waffle-headline--line-height: var(--leading-125);

    @include h.breakpoint-m {
      --waffle-headline--font-size: var(--text-20);
    }

    @include h.breakpoint-x {
      --waffle-headline--font-size: var(--text-24);
    }
  }

  :global(body.select) & {
    --waffle-headline--color: var(--grey-70);
    --waffle-headline--font-size: #{h.rem(24px)};
    --waffle-headline--font-size--mobile-2up: #{h.rem(24px)};
    --waffle-headline--font-weight: var(--bold);
    --waffle-headline--line-height: var(--leading-125);
    --waffle-headline--product--color: var(--blue-60);
  }
}
