@use 'assets/styles/utils/helper' as h;

.background {
  background-color: var(--background--background-color);
  content: '';
  display: block;
  height: var(--background--height);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.bigHeadline {
  display: none;

  @include h.breakpoint-m {
    display: block;
    font-family: var(--publico-hed);
    font-size: h.rem(180px);
    font-style: italic;
    font-weight: 300;
    left: 20px;
    line-height: 1.2;
    overflow: hidden;
    overflow-x: hidden;
    position: absolute;
    top: -50px;
    white-space: nowrap;
    width: calc(100% - 20px);
    z-index: -1;
  }

  @include h.breakpoint-l {
    font-size: h.rem(225px);
    left: calc(((100vw - 960px) / 2) + 20px);
    top: -100px;
  }

  @include h.breakpoint-x {
    font-size: h.rem(300px);
    left: calc(((100vw - 1200px) / 2) + 20px);
    top: -170px;
    width: 100%;
  }
}

.bigHeadlineSolid {
  color: var(--purple-70);
  opacity: 0.05;
}

.bigHeadlineDots {
  position: absolute;
  display: none;
  opacity: 0.05;

  @supports (-webkit-background-clip: text) {
    background-image: radial-gradient(var(--default-headline-color) 34%, transparent 35%);
    background-size: 5px 5px;
    background-clip: text;
    display: block;
    color: transparent;
    top: 5px;
    left: 5px;

    @include h.breakpoint-x {
      top: 10px;
    }
  }
}
