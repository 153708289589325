@use 'assets/styles/utils/helper' as h;

.dynamicUp {
  position: relative;
}

.teaseCards {
  @include h.clearfix;
  background-color: transparent;
  position: relative;

  @include h.breakpoint-m {
    display: flex;
    justify-content: space-between;
  }
}

//
// FULL WIDTH
//
.oneUpFull {
  :global(.tease-card__title) {
    font-size: var(--text-24);
    line-height: var(--leading-100);

    @include h.breakpoint-m {
      font-size: var(--text-40);
    }
  }
}

.twoUpFull {
  :global(.tease-card) {
    @include h.breakpoint-m {
      flex-basis: calc(50% - 1px);
      flex-direction: column;

      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
    }
  }

  :global(.tease-card__title) {
    font-size: var(--text-24);
    line-height: var(--leading-100);

    @include h.breakpoint-m {
      font-size: var(--text-28);
    }

    @include h.breakpoint-l {
      font-size: var(--text-24);
    }

    @include h.breakpoint-x {
      font-size: var(--text-32);
    }
  }

  :global(.tease-card__featured-author) {
    font-size: var(--text-20);
    line-height: var(--leading-100);

    @include h.breakpoint-m {
      font-size: h.rem(26px);
    }

    @include h.breakpoint-l {
      font-size: var(--text-24);
    }

    @include h.breakpoint-x {
      font-size: h.rem(26px);
    }
  }
}

.threeUpFull {
  .teaseCards {
    flex-wrap: wrap;

    @include h.breakpoint-l {
      flex-wrap: nowrap;
    }
  }

  :global(.tease-card) {
    @include h.breakpoint-m {
      flex-basis: calc(50% - 1px);

      &:first-of-type {
        flex-basis: calc(100%);
        margin-bottom: var(--space-48);
      }
    }

    @include h.breakpoint-l {
      flex-basis: calc(25% - 1.33px);

      &:first-of-type {
        flex-basis: calc(50% - 1.33px);
      }
    }
  }

  :global(.tease-card__title) {
    font-size: var(--text-24);
    line-height: var(--leading-100);

    @include h.breakpoint-m {
      font-size: var(--text-18);
    }

    @include h.breakpoint-l {
      font-size: var(--text-20);
    }

    @include h.breakpoint-x {
      font-size: var(--text-24);
    }
  }

  :global(.tease-card__featured-author) {
    font-size: var(--text-20);
    line-height: h.rem(25px);
  }
}

//
// RAIL ADJACENT
//

.oneUpRailAdj {
  :global(.tease-card__title) {
    font-size: var(--text-24);
    line-height: var(--leading-100);

    @include h.breakpoint-m {
      font-size: var(--text-40);
    }

    @include h.breakpoint-l {
      font-size: var(--text-32);
    }

    @include h.breakpoint-x {
      font-size: var(--text-40);
    }
  }
}

.twoUpRailAdj {
  .teaseCards {
    @include h.breakpoint-m {
      flex-wrap: nowrap;
    }
  }

  :global(.tease-card) {
    @include h.breakpoint-m {
      flex-basis: calc(50% - 1px);

      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
    }
  }

  :global(.tease-card__title) {
    font-size: var(--text-24);
    line-height: var(--leading-100);

    @include h.breakpoint-m {
      font-size: var(--text-28);
    }
  }

  :global(.tease-card__featured-author) {
    @include h.breakpoint-m {
      font-size: var(--text-20);
      line-height: h.rem(25px);
    }
  }
}

.threeUpRailAdj {
  .teaseCards {
    flex-wrap: wrap;

    @include h.breakpoint-x {
      flex-wrap: nowrap;
    }
  }

  :global(.tease-card) {
    @include h.breakpoint-m {
      flex-basis: calc(50% - 1px);

      &:first-of-type {
        flex-basis: calc(100%);
        margin-bottom: var(--space-48);
      }
    }

    @include h.breakpoint-x {
      flex-basis: calc(25% - 1.33px);

      &:first-of-type {
        flex-basis: calc(50% - 1.33px);
        margin-right: auto;
        margin-bottom: 0;
      }

      &:last-of-type {
        margin-left: auto;
      }
    }
  }

  :global(.tease-card__title) {
    font-size: var(--text-24);
    line-height: var(--leading-100);

    @include h.breakpoint-m {
      font-size: var(--text-18);
    }
  }

  :global(.tease-card__featured-author) {
    font-size: var(--text-24);
    line-height: h.rem(25px);
  }
}

//
// IN RAIL??
//

//
// Modifications, separated to avoid specificity errors
//

.oneUpFull :global(.tease-card--no-main-image .tease-card__title),
.oneUpRailAdj :global(.tease-card--no-main-image .tease-card__title) {
  font-size: var(--text-28);
  line-height: var(--leading-100);

  @include h.breakpoint-m {
    font-size: var(--text-48);
  }

  @include h.breakpoint-l {
    font-size: var(--text-40);
  }

  @include h.breakpoint-x {
    font-size: var(--text-48);
  }
}

.twoUpFull :global(.tease-card--no-main-image .tease-card__title),
.twoUpRailAdj :global(.tease-card--no-main-image .tease-card__title) {
  font-size: var(--text-32);
  line-height: var(--leading-100);

  @include h.breakpoint-m {
    font-size: var(--text-40);
  }
}

.threeUpFull :global(.tease-card--no-main-image .tease-card__title),
.threeUpRailAdj :global(.tease-card--no-main-image .tease-card__title) {
  font-size: var(--text-28);
  line-height: var(--leading-100);

  @include h.breakpoint-l {
    font-size: var(--text-24);
  }

  @include h.breakpoint-x {
    font-size: var(--text-32);
  }
}

.twoUpFull,
.threeUpFull,
.twoUpRailAdj,
.threeUpRailAdj {
  .tease-card {
    @include h.breakpoint-s-only {
      margin-bottom: var(--space-48);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
