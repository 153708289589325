@use 'assets/styles/utils/helper' as h;

.smorgasbord-meta-content {
  &__content-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__span {
    line-height: var(--leading-100);

    &--headline-hidden {
      margin-top: var(--space-16);
    }
  }

  &__headline {
    color: var(--grey-70);
    font-family: var(--founders-cond);
    font-size: var(--text-18);
    line-height: var(--leading-100);
    font-weight: var(----semibold);
    margin: 0 0 h.rem(4px) 0;

    &:hover {
      opacity: var(--opacity-70);
    }

    &--related {
      font-size: var(--text-16);

      .smorgasbord-meta-content__live-blog-header {
        font-family: var(--publico-txt);
        color: h.$breaking-red;
        font-size: var(--text-14);
      }
    }

    &--hidden {
      @include h.breakpoint-m {
        display: none;
      }
    }

    &--M {
      margin-top: var(--space-12);
      font-size: var(--text-24);

      @include h.breakpoint-m-to-l-only {
        font-size: var(--text-20);
      }

      @include h.breakpoint-x {
        font-size: var(--text-20);
      }
    }

    &--M1 {
      margin-top: var(--space-12);
      font-size: var(--text-24);

      @include h.breakpoint-m-only {
        margin-top: 0;
        font-size: var(--text-24);
      }

      @include h.breakpoint-l-only {
        margin-top: 0;
        font-size: var(--text-28);
      }

      @include h.breakpoint-x {
        font-size: var(--text-20);
      }
    }

    &--M2 {
      font-size: var(--text-24);
      margin-top: var(--space-12);

      @include h.breakpoint-m-only {
        margin-top: 0;
        font-size: var(--text-24);
      }

      @include h.breakpoint-l-only {
        margin-top: 0;
        font-size: var(--text-24);
      }

      @include h.breakpoint-x {
        font-size: var(--text-20);
      }
    }

    &--L,
    &--XL {
      margin-top: var(--space-12);
      font-size: var(--text-24);

      @include h.breakpoint-m-only {
        font-size: var(--text-28);
      }

      @include h.breakpoint-l {
        font-size: var(--text-32);
      }
    }

    &--XL {
      @include h.breakpoint-x {
        font-size: var(--text-40);
      }
    }

    &--related-live-blog {
      margin-top: 0;
    }
  }

  &__author,
  &__eyebrow,
  &__slash {
    display: inline;
    font-family: var(--founders-mono);
    font-size: var(--text-12);
    font-weight: var(--normal);
    letter-spacing: var(--tracking-tight);
    line-height: var(--leading-100);
    margin: 0;
    text-transform: uppercase;
  }

  &__author {
    color: var(--grey-60);

    & a {
      color: var(--blue-40);
    }
  }

  &__eyebrow {
    color: var(--blue-40);
    margin: 0;

    &:hover {
      opacity: var(--opacity-70);
    }
  }

  &__slash {
    color: var(--grey-70);
  }

  &__live-blog {
    &--L {
      margin-top: var(--space-12);
    }

    .live-blog-pkg__headline {
      font-family: var(--publico-txt);
      color: h.$breaking-red;
      font-size: var(--text-16);
    }

    &--M,
    &--M1,
    &--M2 {
      .live-blog-pkg__headline {
        @include h.responsive-variations(font-size, (s: var(--text-20), x: var(--text-18)));
      }
    }

    &--L,
    &--XL {
      .live-blog-pkg__headline {
        @include h.responsive-variations(font-size, (s: var(--text-20), m: var(--text-24)));
      }
    }
  }
}
