@use 'assets/styles/utils/helper' as h;

.local-news-weather {
  --weather-hourly--font-family: var(--publico-hed);
  --weather-h2--font-family: var(--founders-cond);
  --weather-h2--font-size: 68px;
  --weather-h2--margin: 0;
  --weather-h2--font-weight: normal;
  --weather-details--font-family: var(--founders-cond);
  --weather-details--font-size: 20px;
  --weather-details--font-weight: 600;
  --weather-details--line-height: 100%;
  --weather-desc--font-family: var(--publico-hed);
  --weather-desc--font-size: 14px;
  --weather-low-high--font-family: var(--founders-cond);
  --weather-low-hight-val--font-weight: 600;
  --weather-desc-short--font-family: var(--publico-txt);
  --weather-desc-short--font-size: 16px;
  --weather-img-first--margin: 5px 12px 5px 0;
  --weather-current-details--margin-bottom: 12px;
  --weather-hourly-img--margin: 8px 0;
  --weather-hourly-span--margin-left: 0;
  --weather-btn--color: var(--blue-40);
  --weather-btn-hover--bkg-color: var(--blue-40);
  --weather-low-high-desc--font-family: var(--publico-hed);
  --weather-low-high-desc--font-size: 14px;

  @include h.breakpoint-m {
    --weather-h2--font-size: 78px;
    --weather-img-first--margin: 8px 16px 8px 0;
    --weather-desc--font-size: 16px;
    --weather-desc-short--font-size: 20px;
    --weather-current-details--margin-bottom: 17px;
    --weather-hourly-img--margin: 9px 0 0;
    --weather-low-high-desc--font-size: 16px;
  }

  body.today & {
    --weather-h2--font-family: var(--secondary-font);
    --weather-h2--font-size: #{h.rem(50px)};
    --weather-h2--margin: 0;
    --weather-h2--font-weight: 700;
    --weather-hourly--font-family: var(--secondary-font);
    --weather-hourly--font-size: #{h.rem(18px)};
    --weather-hourly-p--font-size: #{h.rem(20px)};
    --weather-hourly-p--line-height: 100%;
    --weather-hourly-span--margin-left: #{h.rem(4px)};
    --weather-details--font-family: var(--secondary-font);
    --weather-details--font-size: #{h.rem(16px)};
    --weather-details--font-weight: 700;
    --weather-details--line-height: 150%;
    --weather-desc--font-family: var(--tertiary-font);
    --weather-desc--font-size: #{h.rem(16px)};
    --weather-low-high--font-family: var(--secondary-font);
    --weather-low-high-desc--font-family: var(--secondary-font);
    --weather-low-high-desc--font-size: #{h.rem(18px)};
    --weather-low-hight-val--font-weight: 700;
    --weather-desc-short--font-family: var(--tertiary-font);
    --weather-desc-short--font-size: #{h.rem(18px)};
    --weather-img-first--margin: #{h.rem(0 7px 13px 0)};
    --weather-current-details--margin-bottom: #{h.rem(8px)};
    --weather-hourly-img--margin: #{h.rem(5px 0)};
    --weather-btn--color: var(--grey-70);
    --weather-btn-hover--bkg-color: var(--accent-orange);

    @include h.breakpoint-m {
      --weather-h2--margin: #{h.rem(8px 0 0)};
      --weather-h2--font-size: #{h.rem(60px)};
      --weather-img-first--margin: #{h.rem(0 10px 12px 0)};
      --weather-current-details--margin-bottom: #{h.rem(17px)};
      --weather-details--font-size: #{h.rem(20px)};
      --weather-details--line-height: 100%;
      --weather-hourly-img--margin: #{h.rem(6px 0 0)};
    }
  }
}
