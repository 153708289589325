@use 'assets/styles/utils/helper' as h;

.videoSlate {
  --video-slate-flag-default-background-color: var(--default-color);
  --video-slate-flag-max-height: 30%;
  --video-slate-flag-max-width: 30%;
  --video-slate-flag-icon-fill: var(--white);
  --video-slate-flag-icon-height: 33%;
  --video-slate-flag-icon-width: auto;
  --video-slate-flag-icon-margin-left: 0;
  --video-slate-flag-icon-display: block;
  --video-slate-flag-default-opacity: 0.8;
  --video-slate-icon-after-display: none;
  --video-slate-flag-height: calc(20vw * 0.56);
  --video-slate-flag-width: 20vw;
  --video-slate-flag-border-radius: 0;
  --video-slate-flag-border: none;
  --video-slate-flag-live-background-color: #{h.$news-red-30};

  @include h.breakpoint-m {
    --video-slate-flag-height: calc(120px * 0.56);
    --video-slate-flag-width: 120px;
  }

  @include h.breakpoint-x {
    --video-slate-flag-height: calc(160px * 0.56);
    --video-slate-flag-width: 160px;
  }

  body.select & {
    --video-slate-flag-default-background-color: var(--blue-60);
  }

  body.today & {
    --video-slate-flag-default-background-color: rgba(var(--accent-orange-light-rgb), .8);
    --video-slate-flag-max-height: #{h.rem(125px)};
    --video-slate-flag-max-width: #{h.rem(125px)};
    --video-slate-flag-default-color: var(--primary-color);
    --video-slate-flag-icon-height: 23.2%;
    --video-slate-flag-icon-width: 20.8%;
    --video-slate-flag-icon-margin-left: 6%;
    --video-slate-flag-icon-display: none;
    --video-slate-flag-default-opacity: 1;
    --video-slate-icon-after-display: block;
    --video-slate-icon-after-bkg-color: var(--primary-color);
    --video-slate-flag-height: #{h.rem(70px)};
    --video-slate-flag-width: #{h.rem(70px)};
    --video-slate-flag-border-radius: #{h.rem(71px)};
    --video-slate-flag-border: 1px solid var(--primary-color);
    --video-slate-flag-live-background-color: rgba(var(--accent-orange-light-rgb), .8);

    @include h.breakpoint-m {
      --video-slate-flag-height: #{h.rem(125)};
      --video-slate-flag-width: #{h.rem(125)};
      --video-slate-flag-border-radius: 50%;
    }
  }

  body.today &:hover {
    --video-slate-flag-default-color: var(--grey-70);
    --video-slate-icon-after-bkg-color: var(--grey-70);
    --video-slate-flag-default-background-color: var(--accent-orange);
  }
}
