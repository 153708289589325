@use 'assets/styles/utils/helper' as h;

:root {
  --related-content-tease-media-margin-right: 8px;
  --related-content-tease-media-width-mobile: 90px;
  --related-content-tease-media-height-mobile: 60px;
  --related-content-tease-media-width: 110px;
  --related-content-tease-media-height: 73.333px;
  --related-content-tease-media-display: flex;
  --related-content-tease-media-justify-content: center;
  --related-content-tease-media-align-items: center;
  --related-content-tease-media-flex-shrink: 0;
  --related-content-tease-media-overflow: hidden;
  --related-content-tease-media-position: relative;
  --related-content-tease-media-picture-width: auto;
  --related-content-tease-media-picture-height: 100%;
  --related-content-tease-media-picture-img-width: auto;
  --related-content-tease-media-picture-img-height: 100%;
  --related-content-tease-media-icon-position: absolute;
  --related-content-tease-media-icon-bottom: 0;
  --related-content-tease-media-icon-left: 0;
  --related-content-tease-media-icon-height: 25px;
  --related-content-tease-media-icon-width: 25px;
}
