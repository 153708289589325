@use 'assets/styles/utils/helper' as h;

.rail__main {
  @include h.breakpoint-l {
    position: relative;
  }
}

.rail__container {
  @include h.breakpoint-l {
    display: flex;

    .layout-container & {
      width: 100%;
    }

    body.today .layout-container & {
      width: auto;
    }
  }
}

.rail__wrapper {
  @include h.breakpoint-l {
    position: absolute; // to clip height
    width: 100%;
    height: 100%;
  }
}

.rail__content {
  @include h.breakpoint-l {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > * {
      flex: 0 0 auto;
    }
  }

  > * {
    @include h.package-spacing;
  }

  .ad-container {
    @include h.breakpoint-l {
      margin-bottom: 0;
      width: auto;
      align-self: center;
    }
  }

  // Packages after collection title use package spacing.
  .has-title & > :nth-child(n+2) {
    @include h.breakpoint-l {
      @include h.package-spacing;
    }
  }
}
