// stylelint-disable no-descending-specificity
// stylelint has a problem with the usage and order of :global()

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens';

.teaseList {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto !important; // to override parent's direct descendant selector (0 0 auto)
  min-height: 0;
  position: relative;
  z-index: 1;
}

.wrapperOuter {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  min-height: 0;

  & > * {
    flex: 0 0 auto;
  }

  @include h.breakpoint-l {
    height: 100%;
  }
}

.wrapperInner {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  height: 100%;
  margin: var(--tl--wrapper--margin);
  min-height: 0; // required for Firefox/IE
  padding: var(--tl--wrapper--padding);
  position: relative;
  width: var(--tl--wrapper--width);

  &::before {
    background: var(--tl--wrapper--before--background);
    bottom: var(--tl--wrapper--before--bottom);
    content: var(--tl--wrapper--before--content);
    display: var(--tl--wrapper--before--display);
    height: var(--tl--wrapper--before--height);
    left: var(--tl--wrapper--before--left);
    overflow: hidden;
    position: var(--tl--wrapper--before--position);
    right: var(--tl--wrapper--before--right);
    top: var(--tl--wrapper--before--top);
    width: var(--tl--wrapper--before--width);
    z-index: var(--tl--wrapper--before--z-index);
  }

  &::after {
    background: var(--tl--wrapper--after--background);
    bottom: var(--tl--wrapper--after--bottom);
    content: var(--tl--wrapper--after--content);
    display: var(--tl--wrapper--after--display);
    height: var(--tl--wrapper--after--height);
    left: var(--tl--wrapper--after--left);
    overflow: hidden;
    position: var(--tl--wrapper--after--position);
    right: var(--tl--wrapper--after--right);
    top: var(--tl--wrapper--after--top);
    width: var(--tl--wrapper--after--width);
    z-index: var(--tl--wrapper--after--z-index);
  }
}

.listOuter {
  border-top: var(--tl--list-outer--border-top);
  background-color: var(--tl--list-outer--background-color);
  flex: 0 1 auto;
  margin: var(--tl--list-outer--margin);
  min-height: 0;
  position: relative;

  @include h.breakpoint-s-only {
    li:nth-child(n+6) {
      display: none;
    }
  }

  @include h.breakpoint-m-only {
    li:nth-child(n+6) {
      display: none;
    }
  }

  @include h.breakpoint-l {
    overflow: hidden;
  }

  &::after {
    @include h.breakpoint-l {
      content: '';
      width: 100%;
      height: h.rem(80px);
      background-image: linear-gradient(180deg, #fff0, #fff);
      pointer-events: none;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  .hasSeeAll & {
    margin: var(--tl--list-outer-with-button--margin);
  }
}

.listInner {
  display: block;
  width: 100%;
  color: var(--tl--list-inner--color);
  font-family: var(--tl--list-inner--font-family);
  font-size: var(--tl--list-inner--font-size);
  font-weight: normal;
  line-height: var(--tl--list-inner--line-height);

  & > * {
    background: var(--tl--item--background);
    border-bottom: var(--tl--item--border-bottom);
    display: var(--tl--item--display);
    position: relative;
  }
}

:global(.tease-list__item), // ad, taboola
.item {
  margin: var(--tl--item--margin);
  padding: var(--tl--item--padding);

  &::before {
    background-color: var(--tl--dot--color);
    border-radius: 50%;
    content: var(--tl--dot--content);
    display: var(--tl--dot--display);
    height: h.rem(8px);
    left: 0;
    position: absolute;
    top: h.rem(2px);
    width: h.rem(8px);
  }

  &.hidden {
    display: none;
  }

  &.alternate,
  &.isItemAd,
  &.isNativeAd,
  &.select {
    &::before {
      content: none;
    }
  }

  :global(body.today) &,
  :global(:not(body.today)) .isNotCover & {
    &:nth-child(n+8) {
      display: none;

      @include h.breakpoint-l {
        display: var(--tl--item--display);
      }
    }
  }
}

.adSource {
  color: var(--grey-50);
  font-family: var(--founders-cond);
  font-size: var(--text-14);
  font-weight: var(--semibold);
  line-height: var(--leading-100);
  margin-bottom: 0;
}

.teaseEyebrow {
  color: var(--tl--eyebrow--color);
  font-family: var(--tl--eyebrow--font-family);
  font-size: var(--tl--eyebrow--font-size);
  font-weight: var(--tl--eyebrow--font-weight);
  letter-spacing: var(--tl--eyebrow--letter-spacing);
  line-height: var(--leading-100);
  margin: 0 0 h.rem(8px);
  text-transform: uppercase;

  .isNativeAd:not(.alternate) & {
    background-color: var(--grey-70);
    color: var(--white);
  }

  .isNativeAd.alternate & {
    color: var(--black);
  }
}

// Branding - currently only applied to Select
.item.select .teaseEyebrow a {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &::before {
    background: url('https://nodeassets.nbcnews.com/cdnassets/logos/select/select.svg') no-repeat 0 0/contain;
    content: '';
    display: inline-block;
    height: 15px;
    margin: 0 4px 0 0;
    padding: 0;
    width: 55px;
  }
}

.teaseTimestamp {
  font-family: var(--tl--timestamp--font-family);
  font-size: var(--tl--timestamp--font-size);
  font-weight: var(--tl--timestamp--font-weight);
  letter-spacing: var(--tl--timestamp--letter-spacing);
  margin: 0 0 h.rem(8px);
  line-height: var(--leading-100);

  &::first-letter {
    text-transform: capitalize;
  }

  .alternate &,
  .isItemAd &,
  .isNativeAd &,
  .select & {
    display: none;
  }
}

:global(.tease-list__title), // ad, taboola
.teaseTitle {
  color: currentColor;
  font-size: inherit;
  margin: 0;
}

.teasePicture {
  flex: 0 0 auto;
  display: block;
  height: var(--tl--picture--height);
  margin: var(--tl--picture--margin);
  position: relative;
  width: var(--tl--picture--width);

  .alternate & {
    order: -1;
  }
}

.teasePictureImage {
  border-radius: var(--tl--picture--image--border-radius);
  display: block;
  overflow: auto;
}

.typeIcon {
  bottom: var(--tl--type-icon--bottom);
  left: var(--tl--type-icon--left);
  position: absolute;
  z-index: 2;
}

.seeAllButtonWrapper {
  background: var(--tl--button-wrapper--background);
  flex: 0 0 auto;
  margin: var(--tl--button-wrapper--margin);
  padding: var(--tl--button-wrapper--padding);
}

.teaseList .seeAllButton {
  margin: 0 auto;
  width: var(--tl--button--width);
}

.ad {
  margin: var(--space-48) 0 0;

  .hasAdAbove & {
    order: -1;
    margin: 0 0 var(--space-48);
  }
}

// Items displayed as grid at M breakpoint
:global(body.today),
:global(body.telemundo),
:global(body.entretenimiento),
:global(body.shows) {
  @include h.breakpoint-m-only {
    .listOuter {
      padding: 20px;
    }

    .listInner {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .item {
      margin: 0;

      &:nth-child(-n+8) {
        flex-basis: calc(50% - 10px);
      }

      &:first-child {
        flex-basis: 100%;
      }
    }
  }
}

// Override taboola font size for Telemundo verticals
:global(body.noticias),
:global(body.deportes),
:global(body.telemundo),
:global(body.entretenimiento),
:global(body.shows) {
  .item :global(.video-label.video-title) {
    font-size: h.rem(18px) !important;

    @include h.breakpoint-m {
      font-size: h.rem(32px) !important;
    }

    @include h.breakpoint-l {
      font-size: h.rem(18px) !important;
    }
  }

  .isItemAd :global(div#taboola-homepage-text-link---bento) {
    padding: 0;
  }
}

.teaseList.isCover :global(.package-title-top-border__top-decoration) {
  // Remove top-most package border when used on cover
  border: none;
}
