@use 'assets/styles/utils/helper' as h;

.localNewsStories {
  position: relative;
  overflow: hidden;
  min-height: min-content;
  width: 100%;
}

.map {
  align-items: center;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.9);
  background-position: center;
  bottom: 0;
  height: 100%;
  left: 0;
  padding-top: h.rem(60px);
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(125%);
  transition: transform ease-in-out 0.4s;
  width: 100%;
  z-index: 1;

  @include h.breakpoint-m {
    padding-top: h.rem(80px);
  }

  .isMenuOpened & {
    transform: translateX(0);
    transition: transform ease-in-out 0.4s;
  }
}

.head {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.heading {
  color: var(--grey-70);
  font-family: var(--founders-cond);
  font-size: h.rem(28px);
  font-weight: var(--semibold);
  line-height: var(--leading-100);
  margin-bottom: h.rem(13px);
  margin-top: h.rem(20px);
  text-transform: uppercase;

  @include h.breakpoint-m {
    font-size: h.rem(40px);
    margin-bottom: h.rem(21.5px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(48px);
    margin-top: h.rem(14px);
  }
}

.headerBtn {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  height: h.rem(40px);
  position: relative;
  width: h.rem(40px);
  z-index: 2;

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}

.menu {
  background-color: var(--grey-70);
  border-radius: h.rem(5px);
  cursor: pointer;
  height: h.rem(4px);
  opacity: 1;
  pointer-events: all;
  position: absolute;
  transition: opacity 0.1s 0.3s;
  width: h.rem(3.75px);

  @include h.breakpoint-m {
    height: h.rem(5px);
    width: h.rem(5px);
  }

  &::before,
  &::after {
    background-color: var(--grey-70);
    content: '';
    position: absolute;
    border-radius: h.rem(5px);
    height: h.rem(4px);
    width: h.rem(3.75px);

    @include h.breakpoint-m {
      height: h.rem(5px);
      width: h.rem(5px);
    }
  }

  &::before {
    right: h.rem(7px);

    @include h.breakpoint-m {
      right: h.rem(9px);
      transition: right 0.1s ease-out 0.3s;
    }
  }

  &::after {
    left: h.rem(7px);

    @include h.breakpoint-m {
      left: h.rem(9px);
      transition: left 0.1s ease-out 0.3s;
    }
  }

  .isMenuOpened & {
    opacity: 0;
    transition: opacity 0.1s 0.1s;

    &::before {
      right: 0;
      transition: right 0.1s ease-out;
    }

    &::after {
      left: 0;
      transition: left 0.1s ease-out;
    }
  }
}

.headerBtn svg {
  height: h.rem(20px);
  left: h.rem(10px);
  position: absolute;
  top: h.rem(12px);
  transform: scaleX(0);
  transition: transform 0.15s 0.2s;
  width: h.rem(20px);

  path {
    stroke: var(--grey-70);
    stroke-width: h.rem(2px);
    transform-origin: 50% 50%;

    &:first-child {
      transform: rotate(135deg);
      transition: transform 0.15s;
    }

    &:last-child {
      transform: rotate(45deg);
      transition: transform 0.15s;
    }
  }

  .isMenuOpened & {
    transform: scaleX(1);
    transition: transform 0.1s 0.1s;

    path {
      transform: rotate(0deg);
      transition: transform 0.15s 0.3s;
    }
  }
}

.storiesHead {
  color: var(--grey-100);
  margin-bottom: h.rem(16px);

  @include h.breakpoint-m {
    align-items: center;
    display: flex;
  }
}

.storiesLogo {
  height: h.rem(15px);
  width: h.rem(25px);

  @include h.breakpoint-m {
    height: h.rem(22px);
    width: h.rem(37px);
  }
}

.storiesDek {
  display: inline-block;
  font-family: var(--founders-cond);
  font-size: h.rem(20px);
  margin: 0 12px 4px 5px;
  text-transform: uppercase;

  @include h.breakpoint-m {
    font-size: h.rem(30px);
  }
}

.affiliateLink {
  color: var(--blue-40);
  display: inline-block;
  font-family: var(--founders-cond);
  font-size: h.rem(16px);
  font-weight: var(--semibold);
  line-height: h.rem(15px);
  text-align: center;
  text-transform: uppercase;

  @include h.breakpoint-m {
    font-size: h.rem(18px);
  }

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}

.affiliateLabel {
  border-bottom: 3px solid var(--blue-40);
  padding-bottom: 3px;
}

.storiesGrid {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: h.rem(16px);

  @include h.breakpoint-m {
    grid-column-gap: h.rem(32px);
    grid-template-columns: 1fr 1fr;
    margin-bottom: h.rem(30px);
  }

  @include h.breakpoint-l {
    grid-column-gap: h.rem(40px);
  }
}

.story {
  margin-bottom: h.rem(20px);
}

.storyTitle {
  color: var(--black);
  font-family: var(--founders-cond);
  font-size: h.rem(20px);
  line-height: var(--leading-100);
  margin: 0 0 h.rem(8px);

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}

.storyTimestamp {
  color: var(--grey-60);
  font-family: var(--founders-mono);
  font-size: h.rem(14px);
  font-weight: var(--normal);
  letter-spacing: var(--tracking-tight);
  line-height: var(--leading-100);
  margin: 0;
}
