@use 'assets/styles/utils/helper' as h;

:root {
  --pkg-border-bottom: 1px solid var(--grey-40);
  --pkg-border-right: 1px solid var(--grey-40);
  --pkg-border-top: 1px solid var(--grey-40);
}

.news {
  --pkg-border-bottom: 1px solid var(--grey-50);
  --pkg-border-right: 1px solid var(--grey-50);
  --pkg-border-top: 1px solid var(--grey-50);
}
