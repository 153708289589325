@use 'assets/styles/utils/helper' as h;

.imageCard {
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;

  &.latest {
    justify-content: space-between;
  }

  @include h.breakpoint-s-only {
    &.primary,
    &.secondary {
      border-top: 1px solid #ccc;
      padding: 16px 0 0;
    }
  }

  @include h.breakpoint-m {
    &.primary {
      align-self: flex-start;
      flex-flow: row wrap;
      flex: 1 0 100%;
    }

    &.secondary {
      flex-direction: column;
    }
  }
}

.tease {
  font-size: 0;
  position: relative;

  .latest & {
    order: 2;
  }

  @include h.breakpoint-m {
    .primary & {
      width: 100%;
    }
  }
}

.info {
  position: relative;

  .latest & {
    order: 1;
    padding: 0 0 h.rem(16px 20px);
  }
}

.image {
  height: auto;
  object-fit: cover;
  position: relative;
  width: 98px;

  @include h.breakpoint-m {
    .primary &,
    .secondary & {
      width: 100%;
    }
  }

  .latest & {
    width: 60px;
  }
}

.headline {
  color: var(--neutral-60);
  font-family: var(--lato);
  font-weight: var(--bold);
  font-size: h.rem(16px);
  line-height: 1.2;
  margin: 0;

  .primary &,
  .secondary & {
    color: var(--grey-70);
    line-height: 1;
    margin: 0 0 h.rem(12px);
  }

  @include h.breakpoint-m {
    .primary &,
    .secondary & {
      font-size: h.rem(14px);
      line-height: 1.2;
      margin: 0 0 h.rem(8px);
    }
  }

  @include h.breakpoint-l {
    .primary &,
    .secondary & {
      font-size: h.rem(16px);
      line-height: 1.375; // 22px at 100% zoom
    }
  }
}

.eyebrow {
  font-size: h.rem(12px);
  font-weight: var(--bold);
  line-height: 1.2;
  margin: 0 0 h.rem(8px);
  padding: 0;
  text-transform: uppercase;

  a {
    color: var(--blue-60);

    &:hover {
      opacity: var(--opacity-70);
    }
  }

  .latest & {
    @include h.breakpoint-l {
      font-size: h.rem(14px);
    }
  }
}

.ellipse {
  background-color: var(--blue-50);
  border-radius: 50%;
  height: h.rem(8px);
  left: h.rem(-4px);
  position: absolute;
  top: h.rem(4px);
  width: h.rem(8px);
}

.byline {
  color: var(--blue-60);
  font-family: var(--lato);
  font-size: h.rem(12px);
  font-weight: var(--normal);
  margin: 0;
  text-transform: uppercase;
}
