@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.video-live-banner {
  display: flex;
  background: var(--schedule-live-banner-background);
  flex-direction: column;
  cursor: pointer;
}

.video-live-banner__icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  a,
  a:active,
  a:focus {
    outline: none;
  }
}

.video-live-banner__icons svg {
  margin: 16px 8px;
}

.video-live-banner__text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 20px;
  padding: 16px 0 0;
  user-select: none;
}

.video-live-banner__text {
  display: block;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  font-family: var(--schedule-live-banner-font-family);

  &:hover {
    border-bottom-color: var(--white);
  }
}

.video-live-banner__cta {
  font-size: 12px;
  line-height: 12px;
  margin: 7px 0 0 8px;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  font-family: var(--schedule-live-banner-cta-font-family);

  &:hover {
    border-bottom-color: var(--white);
  }
}

@include h.breakpoint-m {
  .video-live-banner__text-container {
    flex-direction: row;
  }

  .video-live-banner__icons svg {
    margin: 16px 35px;
  }
}
