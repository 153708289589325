@use 'sass:math';
@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.straightUp {
  background-color: var(--white);
  position: relative;
}

.inner {
  margin-top: var(--su--inner--margin-top);
  position: relative;

  &::before {
    background-color: var(--su--inner-before--background-color);
    content: var(--su--inner-before--content);
    display: var(--su--inner-before--display);
    height: h.rem(5px);
    left: 0;
    position: absolute;
    top: h.rem(-5px);
    width: 100%;
    z-index: 2;
  }
}

.videoContainer {
  align-items: center;
  // Tease image ratio differs from video ratio
  aspect-ratio: 2 / 1;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  z-index: h.get-z-index('video-parent');
  width: var(--su--card-video-container--width);

  .isRailAdjacent & {
    @include h.breakpoint-l {
      width: 100%;
    }
  }
}

.videoResize {
  // Need to reset width for video to convert between
  // 2:1 ratio of tease to 16:9 of video
  aspect-ratio: 16 / 9;
  height: 100%;
}

.teaseCardContent {
  display: var(--su--card-content--display);

  @include h.breakpoint-l {
    :global(.layout-leftRail) &,
    :global(.layout-rightRail) & {
      flex-wrap: wrap;
    }
  }
}

.teaseCardPicture {
  align-self: var(--su--card-picture--align-self);
  line-height: 0;

  @include h.breakpoint-l {
    :global(.unibrow) {
      width: h.rem(180px);
    }

    :global(.typeicon) {
      width: h.rem(180px);
      height: h.rem(100px);
      top: h.rem(-68px);
      left: 0;
      font-size: h.rem(32px);
      transition: transform .2s;
      transform: scaleY(.32) scaleX(.1777);
      transform-origin: bottom right;
    }

    &:hover {
      :global(.typeicon) {
        transform: none;
      }
    }
  }
}

.teaseCardPictureLink {
  overflow: var(--su--card-picture-link--overflow);
  width: var(--su--card-picture-link--width);

  @include h.breakpoint-l {
    display: block;
    position: relative;
  }

  video {
    position: absolute;
  }

  .isRailAdjacent & {
    flex: 1;

    @include h.breakpoint-l {
      width: 100%;
    }
  }
}

.teaseCardInfo {
  display: var(--su--tease-card-info--display);
  padding: var(--su--tease-card-info--padding);
}

.teaseCardTitle {
  font-size: var(--su--tease-title--font-size);
  line-height: var(--su--tease-title--line-height);

  @include h.breakpoint-m {
    margin-right: h.rem(40px);
  }

  @include h.breakpoint-l {
    margin-right: 0;
  }
}

.teaseCardDek {
  font-size: var(--su--tease-dek--font-size);
  font-family: var(--su--tease-dek--font-family);
  line-height: var(--leading-150);
  margin-top: h.rem(10px);
  color: var(--su--tease-dek--color);

  @include h.breakpoint-m {
    margin-right: h.rem(40px);
  }

  @include h.breakpoint-l {
    margin-right: 0;
    padding: 0;
  }
}

.teaseCardLiveText {
  color: var(--black);
}

.deepLink {
  :global(.unibrow) {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: h.rem(16px);
    height: h.rem(40px);
    position: relative;
    letter-spacing: var(--tracking-tight);
    line-height: var(--leading-100);
    margin: h.rem(-40px) 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    width: auto;

    @include h.breakpoint-m {
      font-size: h.rem(20px);
      height: h.rem(80px);
      margin: h.rem(-80px) 0 0;
      min-width: h.rem(370px);
    }

    // Text
    > :first-child {
      margin: 0 auto 0 0;
      padding: 0 h.rem(20px);

      @include h.breakpoint-m {
        padding: 0 h.rem(40px);
      }
    }

    // External link icon
    :global(.unibrow__external-link-svg) {
      height: auto;
      width: h.rem(16px);

      @include h.breakpoint-m {
        width: h.rem(20px);
      }
    }

    // Icon
    :global(.typeicon) {
      font-size: h.rem(12px);
      height: h.rem(40px);
      left: unset;
      margin: 0;
      position: relative;
      top: unset;
      transform: none;
      width: h.rem(40px);

      @include h.breakpoint-m {
        font-size: h.rem(24px);
        height: h.rem(80px);
        width: h.rem(80px);
      }
    }
  }
}
