/* stylelint-disable no-descending-specificity */

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.pancake {
  margin-left: 20px;
  margin-right: 20px;

  :global(.layout-index-1) & {
    background-color: var(--pancake--background-color);
    padding-top: 20px; // Matches border for pancake package items
    position: relative;
  }

  @include h.breakpoint-m {
    margin-left: unset;
    margin-right: unset;
  }
}

.title {
  color: var(--pkg-title--color);
  font-family: var(--pkg-title--font-family);
  font-size: var(--pkg-title--font-size);
  font-style: var(--pkg-title--font-style);
  font-weight: var(--pkg-title--font-weight);
  margin: 0 0 16px;
  position: relative;
  text-transform: var(--pkg-title--text-transform);

  &::before {
    border-top: 1px solid var(--default-color);
    content: var(--pkg-title--before--content);
    display: block;
    left: var(--pkg-title--before--left);
    position: absolute;
    top: var(--pkg-title--before--top);
    width: var(--pkg-title--before--width);
  }
}

.titleWithUnderline {
  border-bottom: var(--pkg-title--border-bottom);
  color: var(--pkg-title--color);
  font-family: var(--pkg-title--font-family);
  font-size: var(--pkg-title--font-size);
  font-style: var(--pkg-title--font-style);
  font-weight: var(--pkg-title--font-weight);
  margin: var(--pkg-title--margin);
  padding: var(--pkg-title--padding);
  position: relative;
  text-transform: var(--pkg-title--text-transform);

  &::before {
    border-top: 1px solid var(--default-color);
    content: var(--pkg-title--before--content);
    display: block;
    left: var(--pkg-title--before--left);
    position: absolute;
    top: var(--pkg-title--before--top);
    width: var(--pkg-title--before--width);
  }
}

.unibrow {
  color: var(--unibrow--color);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  font-weight: var(--light);
  letter-spacing: var(--tracking-tight);
  line-height: var(--leading-100);
  margin: 0 0 var(--spacing-2);
  text-transform: uppercase;

  &.opinion {
    color: #ff5042;
  }
}

.headline {
  color: var(--headline--color);
  font-family: var(--headline--font-family);
  font-weight: var(--headline--font-weight);
  line-height: var(--headline--line-height);
  margin: 0;
}

// Grid customization for pancake
@include h.breakpoint-l-only {
  :global(.grid-col-12.grid-col-7-5-l) {
    width: calc((100% / 12) * 7.5);
  }

  :global(.grid-col-12.grid-col-4-5-l) {
    width: calc((100% / 12) * 4.5);
  }
}

// Align picture and info `side by side` ('floated')
.fl_0 {
  display: block;
}

.fl_1 {
  display: flex;
  align-items: flex-start;
}

@include h.breakpoint-m {
  .fl_0_m {
    display: block;
  }

  .fl_1_m {
    display: flex;
    align-items: flex-start;
  }
}

@include h.breakpoint-l {
  .fl_0_l {
    display: block;
  }

  .fl_1_l {
    display: flex;
    align-items: flex-start;
  }
}

@include h.breakpoint-x {
  .fl_0_xl {
    display: block;
  }

  .fl_1_xl {
    display: flex;
    align-items: flex-start;
  }
}

.info {
  flex: 1 0;
}

.tease {
  position: relative;
}

.keyUpdates {
  order: 1;

  @include h.breakpoint-m {
    order: 2;
  }
}

.contentColumn {
  order: 2;

  @include h.breakpoint-m {
    order: 1;
  }
}

.teasePictureLink {
  position: relative;
  display: block;
  line-height: 0;
  margin-bottom: 12px;

  .typeIcon {
    position: absolute;
    bottom: 0;
  }

  .fl_0 & {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .fl_1 & {
    margin-bottom: 0;
    margin-right: 16px;
  }

  @include h.breakpoint-m {
    .fl_0_m & {
      margin-bottom: 12px;
      margin-right: 0;
    }

    .fl_1_m & {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  @include h.breakpoint-l {
    .fl_0_l & {
      margin-bottom: 12px;
      margin-right: 0;
    }

    .fl_1_l & {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  @include h.breakpoint-x {
    .fl_0_xl & {
      margin-bottom: 12px;
      margin-right: 0;
    }

    .fl_1_xl & {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}

//// Image size overrides ////
.items3 {
  @include h.breakpoint-m {
    .S .teasePictureLink {
      display: none;
    }
  }

  &.fullWidth {
    @include h.breakpoint-l {
      .item1 .teasePictureLink,
      .item2 .teasePictureLink {
        display: block;
      }
    }

    @include h.breakpoint-x {
      .S .teasePictureLink img {
        width: 100px;
      }
    }
  }

  &.railAdjacent {
    @include h.breakpoint-l {
      .item1 .teasePictureLink,
      .item2 .teasePictureLink {
        display: none;
      }
    }
  }
}

.items4 {
  .item3 .teasePictureLink {
    display: none;
  }

  @include h.breakpoint-m {
    .item2 .teasePictureLink {
      display: none;
    }
  }

  &.fullWidth {
    @include h.breakpoint-l {
      .item1 .teasePictureLink {
        display: none;
      }

      .item2 .teasePictureLink {
        display: block;
      }
    }

    @include h.breakpoint-x {
      .item1 .teasePictureLink {
        display: block;
      }

      .item2 .teasePictureLink,
      .item3 .teasePictureLink {
        display: block;

        .teasePicture img {
          width: 100px;
        }
      }
    }
  }

  &.railAdjacent {
    .item1 .teasePictureLink {
      display: none;

      @include h.breakpoint-m {
        display: block;
      }

      @include h.breakpoint-l {
        display: none;
      }

      @include h.breakpoint-x {
        display: block;
      }
    }
  }
}

.items6 {
  .item2 .teasePictureLink {
    @include h.breakpoint-m {
      display: none;
    }
  }

  &.fullWidth {
    .item0 .teasePicture img,
    .item1 .teasePicture img {
      @include h.breakpoint-l {
        width: 200px;
      }

      @include h.breakpoint-x {
        width: 100%;
      }
    }
  }
}

.items7 {
  .item3 .teasePictureLink {
    @include h.breakpoint-m {
      display: none;
    }
  }

  &.fullWidth {
    .item1 .teasePicture img,
    .item2 .teasePicture img {
      @include h.breakpoint-l {
        width: 200px;
      }

      @include h.breakpoint-x {
        width: 100%;
      }
    }
  }
}

.items8 {
  .item2 .teasePictureLink,
  .item3 .teasePictureLink,
  .item4 .teasePictureLink {
    @include h.breakpoint-m {
      display: none;
    }
  }

  &.fullWidth {
    .item0 .teasePicture img,
    .item1 .teasePicture img {
      @include h.breakpoint-l {
        width: 200px;
      }

      @include h.breakpoint-x {
        width: 160px;
      }
    }
  }

  &.railAdjacent {
    .item2 .teasePictureLink,
    .item3 .teasePictureLink {
      @include h.breakpoint-l {
        display: block;
      }

      @include h.breakpoint-x {
        display: none;
      }
    }
  }
}

//// Tease borders ////
@mixin pancakeTeaseBorderOn {
  border-top: var(--tease--border);
  margin-top: h.rem(16px);
  padding-top: h.rem(16px);
}

@mixin pancakeTeaseBorderOff {
  border: none;
  margin: 0;
  padding: 0;
}

@mixin nthBorderOn($nth) {
  &:nth-child(#{$nth}) .tease {
    @include pancakeTeaseBorderOn;
  }
}

@mixin nthBorderOff($nth) {
  &:nth-child(#{$nth}) .tease {
    @include pancakeTeaseBorderOff;
  }
}

.S {
  .tease {
    @include pancakeTeaseBorderOn;
  }

  .items2.fullWidth & {
    @include h.breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .items2.railAdjacent & {
    @include h.breakpoint-m {
      @include nthBorderOff(n);
    }

    @include h.breakpoint-l {
      @include nthBorderOn(n);
    }

    @include h.breakpoint-x {
      @include nthBorderOff(n);
    }
  }

  .items3.fullWidth & {
    @include h.breakpoint-l {
      @include nthBorderOff(n);
    }

    @include h.breakpoint-x {
      @include nthBorderOn(2);
    }
  }

  .items4.fullWidth & {
    @include h.breakpoint-x {
      @include nthBorderOff(1);
    }
  }

  .items5.fullWidth & {
    @include h.breakpoint-x {
      @include nthBorderOff(1);
    }
  }

  .items6.fullWidth &,
  .items7.fullWidth & {
    @include h.breakpoint-x {
      @include nthBorderOff('-n+2');
    }
  }

  .items8 & {
    @include h.breakpoint-m {
      @include nthBorderOff(1);
    }
  }

  .items8.fullWidth & {
    @include h.breakpoint-l {
      @include nthBorderOff('-n+2');
    }

    @include h.breakpoint-x {
      @include nthBorderOff('-n+3');
    }
  }

  .items8.railAdjacent & {
    @include h.breakpoint-l {
      @include nthBorderOn(n);
    }

    @include h.breakpoint-x {
      @include nthBorderOff('-n+2');
    }
  }
}

.M {
  .tease {
    @include pancakeTeaseBorderOn;
  }

  .items2 &,
  .items4 & {
    &:nth-child(1) .tease {
      @include pancakeTeaseBorderOff;
    }

    @include h.breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .items3 & {
    &:nth-child(1) .tease {
      @include pancakeTeaseBorderOff;
    }

    @include h.breakpoint-m {
      @include nthBorderOff(n);
    }

    @include h.breakpoint-l {
      @include nthBorderOn(2);
    }
  }

  .items4.railAdjacent & {
    @include h.breakpoint-l {
      @include nthBorderOn(2);
    }

    @include h.breakpoint-x {
      @include nthBorderOff(2);
    }
  }

  .items5.fullWidth & {
    @include h.breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .items5.railAdjacent & {
    @include h.breakpoint-m {
      @include nthBorderOff(n);
    }

    @include h.breakpoint-l {
      @include nthBorderOn(2);
    }

    @include h.breakpoint-x {
      @include nthBorderOff(n);
    }
  }

  .items6 & {
    @include h.breakpoint-m {
      @include nthBorderOff(n);
    }
  }

  .items7 & {
    @include h.breakpoint-m {
      @include nthBorderOff(1);
    }
  }

  .items7.fullWidth & {
    @include h.breakpoint-x {
      @include nthBorderOff(n);
    }
  }

  .items8 & {
    &:nth-child(1) .tease {
      @include pancakeTeaseBorderOff;
    }

    @include h.breakpoint-m {
      @include nthBorderOn(2);
    }
  }

  .items8.fullWidth & {
    @include h.breakpoint-l {
      @include nthBorderOff(1);
    }

    @include h.breakpoint-x {
      @include nthBorderOff(2);
    }
  }

  .items8.railAdjacent & {
    @include h.breakpoint-l {
      @include nthBorderOff(2);
    }

    @include h.breakpoint-x {
      @include nthBorderOn(2);
    }
  }
}

.pancakeBottomBorder {
  width: 100%;
  border: none;
  border-top: var(--pkg-border-bottom);
  margin: 24px 20px 0 24px;

  @include h.breakpoint-s-only {
    margin-left: 0;
    margin-right: 0;
  }
}
