.waffle {
  --waffle--background-color: var(--neutral-10);

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --waffle--background-color: var(--white);
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    --waffle--background-color: #f1f0ed;
  }

  :global(body.select) & {
    --waffle--background-color: var(--blue-30);
  }

  :global(body.eonline) & {
    --waffle--background-color: transparent;
  }

  :global(body.today) & {
    --waffle--background-color: var(--white);
  }
}
