@use 'assets/styles/utils/helper' as h;

.info {
  display: block;
  margin: var(--space-12) 0;
  position: relative;
  width: 100%;
}

.title {
  color: var(--grey-70);
  font-family: var(--founders-cond);
  font-size: var(--text-20);
  font-style: var(--normal);
  font-weight: var(--semibold);
  line-height: var(--text-18);
}

.flag {
  font-family: var(--founders-cond);
  font-size: var(--text-14);
  font-style: var(--normal);
  font-weight: var(--semibold);
  line-height: var(--leading-100);
  margin-right: var(--space-8);
  text-transform: uppercase;

  // Nested to increase specificity to override <InlineBlinkingLiveDot /> styling
  .dot {
    background-color: var(--news-red-30);
    height: 7px;
    margin-bottom: 2px;
    margin-right: var(--space-4);
    width: 7px;
  }
}

.link {
  &:active {
    opacity: var(--default-active-opacity);
  }

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}
