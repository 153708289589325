@use 'assets/styles/utils/helper' as h;

.container {
  @include h.responsive-variations(
    grid-column-gap,
    (s: 0, m: h.rem(25px), l: h.rem(35px), x: h.rem(40px), xx: h.rem(50px))
  );
  margin: 0 auto h.rem(40px);
  position: relative;
  padding-top: h.rem(24px);

  @include h.breakpoint-m {
    margin: 0 auto h.rem(60px);
    padding-top: h.rem(40px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    width: 88%;
    max-width: 1260px;
  }
}

@include h.breakpoint-m {
  .titleWrap1 { grid-area: 1 / 1 / 2 / 2; }
  .picture1 { grid-area: 2 / 1 / 3 / 2; }
  .related1 { grid-area: 3 / 1 / 4 / 2; }
  .titleWrap2 { grid-area: 1 / 2 / 2 / 3; }
  .picture2 { grid-area: 2 / 2 / 3 / 3; }
  .related2 { grid-area: 3 / 2 / 4 / 3; }
}

.picture {
  position: relative;

  .feature-lead__picture--link {
    display: block;
  }
}

.picture1 {
  margin-right: h.rem(20px);

  :global(.teasePicture) {
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
  }

  :global(.image-credit__text) {
    margin-right: -8px;
  }

  // parallelogram shape
  &::before {
    content: '';
    width: 65%;
    height: 30%;
    background-color: var(--grey-30);
    position: absolute;
    top: 2.5%;
    right: 3%;
    display: block;
    transform: rotate(168deg) skewY(11deg);
  }

  // diagonal line
  &::after {
    content: '';
    width: 1px;
    height: 55%;
    background-color: var(--secondary-color);
    position: absolute;
    top: 4%;
    right: 5.5%;
    display: block;
    transform: rotate(169deg);
  }

  @include h.breakpoint-m {
    margin-right: 0;

    :global(.teasePicture) {
      clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
    }

    &::before,
    &::after {
      display: none;
    }

    :global(.image-credit__text) {
      margin-right: 5%;
    }
  }
}

@include h.breakpoint-m {
  .picture2 {
    :global(.teasePicture) {
      clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

.titleWrap {
  @include h.responsive-variations(margin, (s: 0 h.rem(20px) h.rem(13px), m: 0 0 h.rem(13px)));
}

.titleWrap2 {
  @include h.responsive-variations(margin-top, (s: h.rem(48px), m: 0));
  @include h.responsive-variations(padding-left, (m: 5%));
}

.title {
  @include h.responsive-variations(font-size, (s: h.rem(20px), l: h.rem(24px), xx: h.rem(28px)));
  font-family: var(--primary-font);
  font-weight: 400;
  line-height: var(--leading-150);
  color: var(--grey-60);
  margin: 0;

  a:hover {
    text-decoration: underline;
    text-decoration-color: var(--accent-blue);
    text-decoration-thickness: 2px;
    text-underline-offset: 7px;
  }

  @include h.breakpoint-m {
    color: var(--grey-70);
  }
}

.related {
  @include h.responsive-variations(margin, (s: 0 h.rem(20px), m: 0));
}

.related1 {
  @include h.breakpoint-m {
    margin-right: 5%;
  }
}
