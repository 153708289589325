.container {
  text-align: center;
  overflow-x: auto;
  white-space: nowrap;

  // Hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.rightFade {
  mask-image: linear-gradient(-90deg, rgba(255, 255, 255, 1) 90%, transparent 100%);
}

.leftFade {
  mask-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 90%, transparent 100%);
}
