@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.bigStoryTakeover {
  position: relative;
}

.bigStoryTakeoverUnibrow {
  color: var(--bst--unibrow--color);
  font-family: var(--founders-mono);
  font-size: h.rem(12px);
  font-weight: 100;
  line-height: 1;
  margin-bottom: .5rem;
  margin-top: 0;
  text-transform: uppercase;
}

.bigStoryTakeoverHeadline {
  color: var(--bst--headline--color);
  font-family: var(--default-headline-font-family);
  font-size: h.rem(32px);
  font-weight: var(--bst--headline--font-weight);
  line-height: var(--default-headline-leading);
  margin-bottom: 0;
  margin-top: .5rem;
  text-transform: var(--bst--headline--text-transform);

  @include h.breakpoint-m {
    font-size: h.rem(60px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(48px);
    margin-right: 1.5rem;
  }

  @include h.breakpoint-x {
    font-size: h.rem(60px);
    margin-right: 0;
  }
}

.bigStoryTakeoverTimestamp {
  color: var(--bst--timestamp--color);
  font-family: var(--founders-mono);
  font-size: h.rem(12px);
  font-weight: 100;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.bigStoryTakeoverMeta {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  padding-bottom: 3rem;
  padding-top: 2rem;
  position: relative;
  z-index: 1;

  @include h.breakpoint-m {
    margin-left: 0;
    margin-right: 0;
  }

  &::before {
    background-color: var(--bst--background-color);
    bottom: 0;
    content: '';
    left: -20px;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;

    @include h.breakpoint-l {
      left: calc(-50vw + 460px);
    }

    @include h.breakpoint-x {
      left: calc(-50vw + 580px);
    }
  }
}

.bigStoryTakeoverTease {
  margin-bottom: 2rem;
  position: relative;

  &::before {
    @include h.breakpoint-m {
      background-color: var(--bst--background-color);
      content: '';
      height: h.rem(64px);
      left: -20px;
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: -1;
    }

    @include h.breakpoint-l {
      left: calc(-50vw + 460px);
    }

    @include h.breakpoint-x {
      left: calc(-50vw + 580px);
    }
  }
}

.bigStoryTakeoverBorderTop {
  display: block;
  margin-left: var(--space-20);
  position: absolute;
  width: calc(100% - 20px);
  z-index: 1;

  :global(.today) & {
    display: none;
  }
}

.bigStoryTakeoverHeroLink {
  background-color: var(--bst--hero-link--background-color);
  border-top: 5px solid var(--bst--hero-link--border-color);
  cursor: pointer;
  display: block;
  overflow: visible;
  position: relative;
  z-index: 3;
}

.bigStoryTakeoverHeroImage {
  display: block;
  overflow: visible;
  position: relative;
}

.bigStoryTakeoverPlayButton {
  background-color: var(--bst--play-button--background-color);
  border: none;
  box-shadow: none;
  font-size: var(--bst--play-button--font-size);
  height: var(--bst--play-button--height);
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform-origin: top left;
  transition: width 0.2s, height 0.2s;
  width: var(--bst--play-button--width);
  z-index: 2;

  .bigStoryTakeoverHeroLink:hover & {
    @include h.breakpoint-l {
      height: h.rem(100px);
      width: h.rem(200px);

      .bigStoryTakeoverPlayIcon {
        font-size: h.rem(20px);
      }
    }
  }
}

.bigStoryTakeoverPlayIcon {
  background-color: transparent;
  font-size: inherit;
  height: 100%;
  transition: font-size 0.2s;
  width: 100%;
}

.bigStoryTakeoverVideo {
  background: var(--black);
  height: 100%;
  left: 0;
  position: absolute;
  top: 5px;
  width: 100%;
  z-index: 1000;

  @include h.breakpoint-l {
    height: h.rem(337.5px);
  }

  @include h.breakpoint-x {
    height: h.rem(484px);
  }
}

.bstBreakingFlagWrapper {
  display: inline-block;
  margin: 0 auto var(--space-20) 0;

  :global(.striped-eyebrow) {
    position: relative;
    z-index: 0;
  }

  :global(.striped-eyebrow__text) {
    align-items: center;
    background-color: var(--red-30);
    color: var(--white);
    display: flex;
    font-family: var(--founders-cond);
    font-size: var(--text-16);
    font-weight: var(--bold);
    height: var(--breaking-tag--height);
    justify-content: center;
    line-height: var(--leading-100);
    padding: h.rem(8px) h.rem(20px);
  }

  :global(.stripe-pattern) {
    z-index: -1;
  }
}
