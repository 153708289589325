@use 'assets/styles/utils/helper' as h;

.entry {
  align-items: flex-end;
  border-bottom: 1px dashed var(--grey-40);
  display: flex;
  flex-direction: row;
  margin: var(--space-12) 0;
  padding: 0 0 var(--space-12);

  &.full {
    border-bottom: 1px solid var(--grey-40);
  }
}

.entryImageWrapper {
  margin: 0 var(--space-12) calc(var(--space-12) * -1) 0;
}

.entryAnchor {
  display: flex;
}

.entryImage {
  width: 107px;
  height: auto;

  @include h.breakpoint-m {
    width: 118px;
  }
}

.entryStartTime {
  color: var(--grey-60);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  line-height: 1;
  letter-spacing: -4%;
  margin: 0 0 var(--space-8);
  padding: 0;
}

.entryTitle {
  color: var(--grey-70);
  font-family: var(--default-headline-font-family);
  font-size: var(--text-18);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  margin: 0;
  padding: 0;

  .full & {
    @include h.breakpoint-m {
      font-size: 28px;
      line-height: 28px;
    }
  }

  &:hover {
    opacity: var(--opacity-70);
  }
}

.entryDescription {
  color: var(--grey-60);
  font-family: var(--publico-txt);
  font-size: var(--text-14);
  line-height: 21px;
  margin-bottom: 0;
}
