@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.waffle {
  background-color: var(--waffle--background-color);
  // Ensure bottom spacing is the correct background color
  // Waffle is the only package on the page
  padding-bottom: var(--space-48);
  margin-bottom: 0 !important;

  &:active {
    outline: none;
  }

  .loading {
    margin-top: h.rem(80px);
  }
}
