@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

// To note: exact widths and heights are provided here temporarily,
// as we do not currently have 3:2 cropping in Nebula, or named 3:2 transformations.
// We are cropping with CSS for the time being, until there work done to support otherwise.

.storyline-media {
  display: inline-block;
  position: relative;

  .teasePicture img {
    object-fit: cover;
    object-position: center;
  }

  &.live-video {
    z-index: 40;
  }

  @include h.breakpoint-s-only {
    width: 100%;

    .video-subheadline {
      margin-bottom: 16px;
    }
  }
}

.storyline-media.image-ratio {
  @include h.breakpoint-s-only {
    position: relative;
    width: 100%;
    padding-bottom: 66.67%;

    .teasePicture img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.storyline-media.media-small {
  &.data-viz {
    @include h.breakpoint-m {
      max-height: var(--data-viz--height);
      width: var(--media--small--m--width);
      height: var(--data-viz--height);
    }
  }

  @include h.breakpoint-m {
    width: var(--media--small--m--width);
    height: var(--media--small--m--height);
  }

  .teasePicture img {
    @include h.breakpoint-m {
      width: var(--media--small--m--width);
      height: var(--media--small--m--height);
    }
  }
}

.storyline-media.storyline-small-video {
  @include h.breakpoint-m {
    width: var(--media--small--m--width);
  }

  .cvsdk-tease,
  .cvsdk-tease-msnbc,
  .video-container,
  .videoPlayer {
    @include h.breakpoint-m {
      width: var(--media--small--video--m--width);
    }
  }
}

.storyline-media.media-medium {
  &.data-viz {
    @include h.breakpoint-m {
      max-height: var(--data-viz--height);
      width: var(--media--medium--m--width);
    }

    @include h.breakpoint-l {
      max-height: var(--data-viz--height);
      width: var(--media--medium--l--width);
    }

    @include h.breakpoint-x {
      max-height: var(--data-viz--height);
      width: var(--media--medium--x--width);
    }
  }

  @include h.breakpoint-m {
    max-height: var(--media--medium--m--height);
  }

  @include h.breakpoint-l {
    max-height: var(--media--medium--l--height);
  }

  @include h.breakpoint-x {
    max-height: var(--media--medium--x--height);
  }

  &.live-video {
    @include h.breakpoint-m {
      width: var(--media--medium--video--m--width);
    }

    @include h.breakpoint-l {
      width: var(--media--medium--video--l--width);
    }

    @include h.breakpoint-x {
      width: var(--media--medium--video--x--width);
    }
  }

  .teasePicture img {
    @include h.breakpoint-m {
      width: var(--media--medium--m--width);
      height: var(--media--medium--m--height);
    }

    @include h.breakpoint-l {
      width: var(--media--medium--l--width);
      height: var(--media--medium--l--height);
    }

    @include h.breakpoint-x {
      width: var(--media--medium--x--width);
      height: var(--media--medium--x--height);
    }
  }
}

.storyline-media.multistory-large {
  @include h.breakpoint-x {
    width: 410px;
  }
}

.storyline-media.multistory-large-video {
  width: 410px;

  .video-container,
  .videoPlayer {
    width: 400px;

    @include h.breakpoint-m {
      width: var(--media--large--video--m--width);
    }

    @include h.breakpoint-l {
      width: var(--media--large--video--l--width);
    }
  }
}

/*
  this class exists due to the fact that
  the 16:9 images used as the teaser for videos
  inside the storyline won't be able to fill up
  the media-medium div. So, when we have a video inside
  of our medium storyline, we're going to have a max height
  instead that collapses the div, removing the extra white space
*/
.storyline-media.storyline-medium-video {
  @include h.breakpoint-m-only {
    width: var(--media--medium--video--m--width);
  }

  @include h.breakpoint-l-only {
    width: var(--media--medium--video--l--width);
  }

  @include h.breakpoint-x {
    width: var(--media--medium--video--x--width);
  }

  .cvsdk-tease,
  .video-container,
  .cvsdk-tease-msnbc,
  .videoPlayer {
    @include h.breakpoint-m {
      width: var(--media--medium--video--m--width);
    }

    @include h.breakpoint-l {
      width: var(--media--medium--video--l--width);
    }

    @include h.breakpoint-x {
      width: var(--media--medium--video--x--width);
    }
  }
}

.storyline-media.media-large {
  &.data-viz {
    @include h.breakpoint-m {
      max-height: var(--data-viz--height);
      width: var(--media--large--m--width);
    }

    @include h.breakpoint-l {
      max-height: var(--data-viz--height);
      width: var(--media--large--l--width);
    }

    @include h.breakpoint-x {
      max-height: var(--data-viz--height);
      width: var(--media--large--x--width);
    }
  }

  @include h.breakpoint-m {
    max-height: var(--media--large--m--height);
  }

  @include h.breakpoint-l {
    max-height: var(--media--large--l--height);
  }

  @include h.breakpoint-x {
    max-height: var(--media--large--x--height);
  }

  &.live-video {
    @include h.breakpoint-m {
      width: var(--media--large--video--m--width);
    }

    @include h.breakpoint-l {
      width: var(--media--large--video--l--width);
    }

    @include h.breakpoint-x {
      width: var(--media--large--video--x--width);
    }
  }

  .teasePicture img {
    @include h.breakpoint-m {
      width: var(--media--large--m--width);
      height: media(--media--large--m--height);
    }

    @include h.breakpoint-l {
      width: var(--media--large--l--width);
      height: var(--media--large--l--height);
    }

    @include h.breakpoint-x {
      width: var(--media--large--x--width);
      height: var(--media--large--x--height);
    }
  }
}

.storyline-media.storyline-large-video {
  @include h.breakpoint-m {
    width: var(--media--large--video--m--width);
  }

  @include h.breakpoint-l {
    width: var(--media--large--video--l--width);
  }

  @include h.breakpoint-x {
    width: var(--media--large--video--x--width);
  }

  .cvsdk-tease,
  .cvsdk-tease-msnbc,
  .video-container,
  .videoPlayer {
    @include h.breakpoint-m {
      width: var(--media--large--video--m--width);
    }

    @include h.breakpoint-l {
      width: var(--media--large--video--l--width);
    }

    @include h.breakpoint-x {
      width: var(--media--large--video--x--width);
    }
  }
}

.storyline-media.liveblog-layoutMedia {
  .cvsdk-tease {
    margin-bottom: var(--cvsdk--tease--margin--bottom);

    @include h.breakpoint-m {
      margin-bottom: var(--cvsdk--tease--margin--m--bottom);
    }
  }

  &.media-large,
  &.storyline-large-video {
    &.data-viz {
      @include h.breakpoint-m {
        max-height: var(--data-viz--height);
        width: var(--media--large--m--width);
      }

      @include h.breakpoint-l {
        max-height: var(--data-viz--height);
        width: var(--media--large--l--width);
      }

      @include h.breakpoint-x {
        max-height: var(--data-viz--height);
        width: var(--media--large--x--width);
      }
    }

    .teasePicture img {
      @include h.breakpoint-x {
        width: var(--media--large--x--width);
        height: var(--media--large--x--height);
      }
    }

    .cvsdk-tease,
    .video-container,
    .videoPlayer {
      @include h.breakpoint-x {
        width: var(--media--large--video--x--width);
      }
    }
  }

  &.media-medium,
  &.storyline-medium-video {
    .teasePicture img {
      @include h.breakpoint-x {
        width: var(--media--medium--x--width);
        height: var(--media--medium--x--height);
      }
    }

    .cvsdk-tease,
    .video-container,
    .videoPlayer {
      @include h.breakpoint-x {
        width: var(--media--medium--video--x--height);
      }
    }
  }
}

.video-subheadline {
  font-size: 24px;
  font-family: var(--cvsdk-tease-headline--font-family);
  font-weight: var(--cvsdk-tease-headline--font-weight);
  line-height: var(--cvsdk-tease-headline--line-height);
  margin: var(--cvsdk-tease-headline--margin);
  margin-top: 8px;
  margin-bottom: 0;
  position: relative;

  .video-subline-link {
    cursor: text;

    a {
      position: relative;
      bottom: 3px;
    }

    .video-subline-badge {
      display: inline;
      position: relative;
      bottom: 3px;
    }
  }
}

.nbcSportsEmbed {
  background: #000;
  aspect-ratio: 16 / 10.4;
  border: none;
  padding: 16px 0 0;
  margin: 0 0 -4px;
  width: 100%;

  @include h.breakpoint-s-only {
    background: none;
    margin: 0;
    padding: 0;
  }
}
