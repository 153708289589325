@use 'assets/styles/utils/helper' as h;

.tve-provider {
  &-base {
    height: 20px;
  }

  img {
    width: auto;
    height: 100%;
    margin: 0 0 0 auto;
  }
}
