@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.key-updates {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: h.rem(20px);
  margin-right: h.rem(20px);
  z-index: 1;

  @include h.breakpoint-m {
    margin-left: 0;
    margin-right: 0;
  }

  @include h.breakpoint-l {
    margin-top: h.rem(72px);
    height: calc(100% - 72px);
  }

  &::before {
    @include h.breakpoint-l {
      z-index: -1;
      content: '';
      position: absolute;
      display: block;
      width: calc(100% + 80px);
      top: h.rem(-40px);
      left: h.rem(-40px);
      bottom: 0;
      background: var(--white);
    }
  }

  .key-updates__button {
    padding-top: h.rem(24px);
    padding-bottom: h.rem(24px);
    margin: h.rem(24px) h.rem(20px);
    align-self: center;
    width: var(--key-updates-button-width);
  }
}

.key-updates__title {
  font-family: var(--key-updates-title-font-family);
  font-size: var(--key-updates-title-font-size);
  line-height: var(--key-updates-title-font-line-height);
  font-weight: var(--key-updates-title-font-weight);
  color: var(--key-updates-title-color);
  border-top: 1px solid var(--key-updates-title-border-top-color);
  text-transform: uppercase;
  margin-top: 0;

  &::before {
    content: '';
    display: block;
    width: h.rem(80px);
    border-bottom: h.rem(8px) solid var(--key-updates-title-pseudo-bg-color);
    margin-bottom: h.rem(20px);
  }
}

.key-updates--dotted-line {
  margin-bottom: h.rem(20px);
  background-image: linear-gradient(to right, #223049 20%, transparent 0);
  background-size: 5px 100%;
  height: 1px;
}

.key-updates__list {
  color: var(--key-updates-list-color);
  font-size: var(--key-updates-list-font-size);
  line-height: var(--key-updates-list-line-height);
  margin-left: 1rem;
  list-style: disc;
}

.key-updates__list-item {
  margin-bottom: h.rem(9px);
  display: list-item;
}

.key-updates--breaking {
  .key-updates__title::before {
    border-bottom-color: var(--key-updates-breaking-color);
  }

  .key-updates__list-item::before {
    background-color: var(--key-updates-breaking-color);
  }

  .key-updates__button {
    color: var(--key-updates-breaking-color);
    border-color: var(--key-updates-breaking-color);
    background-image: linear-gradient(35deg, var(--key-updates-breaking-color) 50%, transparent 50%) !important;

    &:hover {
      color: var(--white);
    }
  }
}
