/* stylelint-disable no-descending-specificity */
@use 'assets/styles/utils/helper' as h;

.pkg.storyline {
  .pkg-bottom-border {
    @include h.breakpoint-s-only {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  // NBC News
  .storyline-media.hide-playmaker-label .cvsdk-tease .cvsdk-tease__headline.storyline {
    display: none;
  }

  .cvsdk-tease .cvsdk-tease__headline.storyline,
  .cvsdk-tease-msnbc .cvsdk-tease-msnbc__title {
    background-color: var(--grey-30);
    margin-top: 0;
    font-size: 18px;
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;

    .badge-label.playmaker-badge-label {
      bottom: 2px;
    }

    .badge-label.playmaker-badge-label.badge-type-LIVE {
      bottom: 4px;
      top: unset;
    }

    .badge-label.playmaker-badge-label.badge-type-WATCH {
      bottom: 0;
      top: unset;

      img {
        margin-top: -2px;
      }
    }
  }

  .storyline-media.hide-playmaker-label .cvsdk-tease-msnbc .cvsdk-tease-msnbc__title {
    display: none;
  }
}

.right-rail-tabbed__container--left > .pkg.storyline:first-child {
  margin-top: 20px;
}
