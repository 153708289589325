@use 'assets/styles/utils/helper' as h;

.package-title-top-border {
  --package-alternative-margin-left: 0 #{h.rem(20px)};
  --package-alternative-background-image: none;
  --package-alternative-background-size: unset;
  --package-alternative-background-repeat: initial;
  --package-alternative-background-position-y: initial;
  --package-alternative-top-border-color: var(--grey-40);
  --package-alternative-top-decoration-background-color: var(--default-color);
  --package-alternative-top-decoration-width: #{h.rem(80px)};
  --package-alternative-top-decoration-height: #{h.rem(8px)};
  --package-alternative-top-decoration-display: block;
  --package-title-top-border-font-family: var(--founders-cond);
  --package-title-top-border-font-style: normal;
  --package-title-top-border-font-size: #{h.rem(24px)};
  --package-title-top-border-font-weight: 600;
  --package-title-top-border-font-color: var(--default-headline-color);
  --package-title-top-border-line-height: var(--leading-100);
  --package-title-top-border-margin: #{h.rem(22px)} 0 #{h.rem(20px)};
  --package-title-top-border-letter-spacing: 0;
  --package-title-top-border-padding: 0;
  --package-title-top-border-transform: uppercase;
  --package-title-align-self: unset;
  --package-title-top-border-link-color: var(--default-color);
  --package-title-top-border-link-font: var(--founders-cond);

  @include h.breakpoint-m {
    --package-alternative-margin-left: 0;
  }

  body.entretenimiento & {
    --package-alternative-top-decoration-width: #{h.rem(30px)};
    --package-alternative-top-decoration-height: #{h.rem(2px)};
    --package-title-top-border-margin: #{h.rem(12px)} 0 #{h.rem(20px)};
    --package-title-top-border-font-size: #{h.rem(28px)};
  }

  body.telemundo &,
  body.shows & {
    --package-alternative-top-border-color: transparent;
    --package-alternative-top-decoration-width: #{h.rem(60px)};
    --package-alternative-top-decoration-height: #{h.rem(1px)};
    --package-title-top-border-margin: #{h.rem(12px)} 0 #{h.rem(20px)};
    --package-title-top-border-font-size: #{h.rem(28px)};
  }

  body.today & {
    --package-alternative-top-border-color: transparent;
    --package-alternative-top-decoration-width: #{h.rem(60px)};
    --package-alternative-top-decoration-height: #{h.rem(1px)};
    --package-title-top-border-font-family: var(--publico-hed);
    --package-title-top-border-font-style: italic;
    --package-title-top-border-line-height: var(--leading-125);
    --package-title-top-border-transform: none;
    --package-title-top-border-font-weight: 300;
    --package-title-top-border-font-color: var(--purple-70);
    --package-title-top-border-margin: #{h.rem(20px 0 0 1px)};
    --package-title-top-border-letter-spacing: #{h.rem(0.2px)};
  }

  body.globalcitizen & {
    --package-alternative-top-decoration-background-color: var(--red-40);
  }

  body.select & {
    --package-title-top-border-font-family: var(--lato);
    --package-title-top-border-font-size: #{h.rem(18px)};
    --package-alternative-top-decoration-display: none;
    --package-title-top-border-font-color: var(--grey-70);
    --package-title-top-border-font-weight: 900;
    --package-title-align-self: flex-start;
    --package-title-top-border-margin: 0;
    --package-title-top-border-padding: 0 #{h.rem(16px)} 0 0;
    --package-alternative-margin-left: 0 #{h.rem(20px)} #{h.rem(15px)};
    --package-alternative-background-image: var(--default-background-image);
    --package-alternative-background-size: #{h.rem(18px)};
    --package-alternative-background-repeat: repeat-x;
    --package-alternative-background-position-y: center;
    --package-title-top-border-transform: none;

    @include h.breakpoint-m {
      --package-title-top-border-font-size: #{h.rem(32px)};
      --package-alternative-margin-left: 0 0 #{h.rem(15px)};
    }
  }
}
