@use 'assets/styles/utils/helper' as h;

$multi-up-rail-eyebrow-color: var(--default-color) !default;
$multi-up-rail-eyebrow-background-color: var(--white) !default;
$multi-up-rail-icon-background: var(--default-color) !default;
$tease-card-eyebrow-border-color: var(--default-color) !default;
$multi-up-rail-gutter-desktop: 40px !default;
$multi-up-rail-tease-card-two-up-featured-author-line-heights: (m: h.rem(25px)) !default;
$multi-up-rail-tease-card-three-up-title-line-heights: 100% !default;
$multi-up-rail-tease-card-three-up-featured-author-line-heights: (s: h.rem(25px), m: h.rem(25px), l: h.rem(25px), x: h.rem(25px)) !default;
$multi-up-rail-tease-card-four-up-featured-author-line-heights: (m: h.rem(30px), x: h.rem(25px)) !default;

:root {
  --multi-up-rail-article-background-color: var(--white);
  --multi-up-rail-tease-card-two-up-title-line-heights: 100%;
  --multi-up-rail-tease-card-two-up-featured-author-font-sizes: #{h.rem(20px)};
  --multi-up-rail-tease-card-four-up-line-height: 100%;
}

.today {
  --multi-up-rail-article-background-color: var(--neutral-10);
  --multi-up-rail-tease-card-two-up-featured-author-font-sizes: #{h.rem(18px)};
  --multi-up-rail-tease-card-four-up-line-height: 125%;
}

.think {
  --multi-up-rail-tease-card-two-up-featured-author-font-sizes: #{h.rem(18px)};
  --multi-up-rail-tease-card-four-up-line-height: 125%;
}

.select {
  --multi-up-rail-tease-card-two-up-title-line-heights: 120%;
}
