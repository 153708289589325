@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.local-news-weather__error {
  display: flex;
  align-items: center;
  margin-bottom: h.rem(32px);

  img {
    width: 76px;
    height: auto;
    margin-right: h.rem(20px);
  }
}

.local-news-weather {
  display: flex;

  @include h.breakpoint-m {
    margin-bottom: 20px;
  }

  .local-news-weather__next-btn,
  .local-news-weather__prev-btn {
    border: none;
    background: #0000;
    border-width: 0;
    color: var(--weather-btn--color);
    display: none;
    outline: none;
    font-size: 2rem;
    padding: 0;

    @include h.breakpoint-m {
      display: block;
    }
  }

  .local-news-weather__next-btn {
    border-left: 1px solid #ccc;
    margin-left: 10px;
    padding-left: 8px;
    padding-right: 3px;

    div {
      transform: rotate(270deg);
    }
  }

  .local-news-weather__prev-btn {
    opacity: 0;
    width: 0;
    transition: opacity 0.5s ease-in-out, width 0.1s 0.05s, transform 0.5s ease-in-out;
    transform: translateX(-36px);

    div {
      transform: rotate(90deg);
    }

    &.showPreviousButton {
      opacity: 1;
      transform: translateX(0);
      width: 50px;
      border-right: 1px solid var(--grey-40);
      margin-right: 10px;
      padding-left: 3px;
      padding-right: 8px;
      transition: opacity 0.5s ease-in-out, width 0.1s 0.05s;
    }
  }

  .local-news-weather__next-btn:hover,
  .local-news-weather__prev-btn:hover {
    background-color: var(--weather-btn-hover--bkg-color);
    color: #fff;
  }
}

.local-news-weather__scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 20px 0;
  scroll-behavior: smooth;
  min-height: 108px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  @include h.breakpoint-m {
    min-height: 136px;
    padding: 4px 0 2px;
  }

  .local-news-weather__hourly {
    p,
    span {
      font-family: var(--weather-hourly--font-family);
      font-size: var(--weather-hourly--font-size);
    }

    p {
      margin: 0;
      font-size: var(--weather-hourly-p--font-size);
      line-height: var(--weather-hourly-p--line-height);
    }

    span {
      margin-left: var(--weather-hourly-span--margin-left);
    }
  }

  .local-news-weather__current,
  .local-news-weather__hourly,
  .local-news-weather__current-weather-desc {
    min-width: 70px;
    border-right: 1px solid var(--grey-30);
    text-align: center;

    @include h.breakpoint-m {
      min-width: 90px;
    }

    img:first-of-type {
      min-width: 30px;
      height: auto;
      margin: var(--weather-hourly-img--margin);

      @include h.breakpoint-m {
        min-width: 48px;
      }
    }

    h2 {
      font-family: var(--weather-h2--font-family);
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      color: var(--grey-70);
      margin: 0 0 6px;

      @include h.breakpoint-m {
        font-size: 32px;
        margin-bottom: 8px;
      }
    }
  }

  .local-news-weather__hourly:last-of-type {
    border-right: none;

    @include h.breakpoint-l {
      margin-right: 25px;
    }
  }

  > .local-news-weather__current,
  > .local-news-weather__current-weather-desc {
    min-width: 157px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include h.breakpoint-m {
      min-width: 195px;
    }

    div:first-child {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    h2 {
      font-family: var(--weather-h2--font-family);
      font-style: normal;
      font-weight: var(--weather-h2--font-weight);
      font-size: var(--weather-h2--font-size);
      line-height: 100%;
      color: var(--grey-70);
      margin: var(--weather-h2--margin);
    }

    img:first-of-type {
      min-width: 52px;
      height: auto;
      margin: var(--weather-img-first--margin);

      @include h.breakpoint-m {
        min-width: 72px;
      }
    }

    .local-news-weather__details {
      margin: 0;
      padding: 0 18px;
      font-family: var(--weather-details--font-family);
      font-style: normal;
      font-weight: var(--weather-details--font-weight);
      font-size: var(--weather-details--font-size);
      line-height: var(--weather-details--line-height);
      color: var(--grey-70);
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: var(--weather-current-details--margin-bottom);

      @include h.breakpoint-m {
        padding: 0 20px;
      }
    }

    .local-news-weather__low-high {
      font-family: var(--weather-low-high--font-family);
      font-style: normal;
      font-size: 18px;
      line-height: 100%;
      color: var(--grey-60);
      margin: 0 0 4px;

      @include h.breakpoint-m {
        font-size: 20px;
      }
    }

    .local-news-weather__val {
      font-weight: var(--weather-low-hight-val--font-weight);
    }

    .local-news-weather__desc {
      font-family: var(--weather-desc--font-family);
      line-height: 140%;
      font-size: var(--weather-desc--font-size);
      font-weight: 400;
      text-transform: capitalize;
    }

    .local-news-weather__low-high .local-news-weather__desc {
      font-family: var(--weather-low-high-desc--font-family);
      font-size: var(--weather-low-high-desc--font-size);
    }

    .local-news-weather__desc--short {
      font-family: var(--weather-desc-short--font-family);
      font-style: normal;
      font-weight: normal;
      font-size: var(--weather-desc-short--font-size);
      line-height: 150%;
      text-transform: none;
      color: var(--grey-70);
      margin: 0;

      @include h.breakpoint-m {
        font-size: 18px;
      }
    }
  }

  > .local-news-weather__current-weather-desc {
    .local-news-weather__details:last-of-type {
      margin-bottom: 0;
    }
  }
}
