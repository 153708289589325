@use 'assets/styles/utils/helper' as h;

.description {
  color: var(--grey-60);
  display: inline-block;
  font-family: var(--publico-txt);
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
}

.baguette {
  display: flex;
  flex-direction: column;
  margin-left: var(--space-20);
  margin-bottom: 24px;
  margin-right: var(--space-20);
  max-width: calc(100% - 40px);
  width: 100%;

  @include h.breakpoint-m {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  &:has(.header) {
    margin-top: 0;
  }

  .header {
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: 1px solid var(--grey-40);
    padding-bottom: h.rem(4px);

    @include h.breakpoint-m {
      border-bottom: 1px solid var(--grey-50);
    }
  }

  .headline {
    color: var(--grey-70);
    font-family: var(--founders-cond);
    font-size: h.rem(24px);
    font-weight: 600;
    line-height: var(--leading-100);
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;

    & > a {
      color: var(--blue-40);

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .description {
    margin-bottom: 0;
  }

  .itemsWrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include h.breakpoint-m {
      align-items: initial;
      flex-direction: row;
      justify-content: initial;
    }
  }

  .logo {
    line-height: 0;
  }
}
