@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.mosaic {
  position: relative;
}

.border {
  background-color: var(--mosaic--border--background-color);
  height: var(--mosaic--border--height);
  width: var(--mosaic--border--width);
}

.content {
  background-color: var(--mosaic--background-color);

  @include h.breakpoint-m {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @include h.breakpoint-l {
    display: grid;
    gap: 2px;
    grid-template-areas:
      'top-left center top-right'
      'bottom-left center bottom-right';
    margin-bottom: 0;
  }
}

.teaseContent {
  @include h.breakpoint-s-only {
    // Add package spacing to all but last tease
    &:nth-of-type(-n+4) {
      @include h.package-spacing;
    }
  }

  @include h.breakpoint-m-only {
    // Full width first tease
    &:first-child {
      width: 100%;
    }

    // Side by side for remaining 4 teases
    &:nth-child(n+2) {
      width: calc(50% - 2px);
    }
  }

  @include h.breakpoint-m {
    margin-bottom: 0;

    // Add package spacing to first three teases
    &:nth-of-type(-n+3) {
      @include h.package-spacing;
    }
  }

  @include h.breakpoint-l {
    &:nth-child(1) {
      grid-area: center;
    }

    &:nth-child(2) {
      grid-area: top-left;
    }

    &:nth-child(3) {
      grid-area: top-right;
    }

    &:nth-child(4) {
      grid-area: bottom-left;
    }

    &:nth-child(5) {
      grid-area: bottom-right;
    }

    &:nth-child(n+2) {
      width: calc(240px - 2px);
    }
  }

  @include h.breakpoint-x {
    &:nth-child(n+2) {
      width: calc(300px - 2px);
    }
  }
}

.teaseFeaturedAuthor {
  font-size: 20px;
  line-height: 25px;

  @include h.breakpoint-m {
    font-size: 24px;
    line-height: 30px;
  }

  @include h.breakpoint-l {
    font-size: 20px;
    line-height: 25px;
  }

  @include h.breakpoint-x {
    font-size: 24px;
    line-height: 30px;
  }
}

.teaseFeaturedAuthorImage {
  margin-left: h.rem(40px);
  margin-right: 0;
  position: relative;

  @include h.breakpoint-m {
    margin: h.rem(0 20px);
    width: calc(100% - 40px);
  }
}

.teaseTitle {
  color: var(--mosaic--tease-card--title--color);
  margin-bottom: h.rem(5px);

  .teaseContent:first-child & {
    font-size: var(--mosaic--tease-card-lead--title--font-size);
    line-height: var(--mosaic--tease-card-lead--title--line-height);
  }

  .teaseContent:nth-child(n+2) & {
    font-size: var(--mosaic--tease-card--title--font-size);
    line-height: var(--mosaic--tease-card--title--line-height);
  }
}

.teaseDek {
  .teaseContent:first-child & {
    @include h.breakpoint-m {
      font-size: h.rem(15px);
      line-height: h.rem(22px);
    }
  }
}
