@use 'assets/styles/utils/helper' as h;

.bigStoryTakeover {
  --bst--background-color: var(--default-color);

  // Unibrow
  --bst--unibrow--color: var(--white);

  // Headline
  --bst--headline--color: var(--white);
  --bst--headline--font-weight: 400;
  --bst--headline--text-transform: none;

  // Timestamp
  --bst--timestamp--color: var(--white);

  // Hero
  --bst--hero-link--background-color: transparent;
  --bst--hero-link--border-color: var(--default-color);

  // Play button
  --bst--play-button--background-color: var(--default-color);
  --bst--play-button--font-size: 6px;
  --bst--play-button--height: 32px;
  --bst--play-button--width: var(--bst--play-button--height);

  @include h.breakpoint-m {
    --bst--play-button--font-size: 8px;
    --bst--play-button--height: 40px;
  }

  :global(body.news) & {
    --bst--background-color: var(--blue-70);
  }

  :global(body.msnbc) & {
    --bst--hero-link--border-color: var(--yellow-40);
  }

  :global(body.today) & {
    --bst--play-button--background-color: var(--purple-70);
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    --bst--background-color: var(--blue-60);
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --bst--background-color: var(--neutral-30);

    // Unibrow
    --bst--unibrow--color: var(--red-50);

    // Headline
    --bst--headline--color: var(--blue-60);
    --bst--headline--font-weight: 600;
    --bst--headline--text-transform: uppercase;

    // Timestamp
    --bst--timestamp--color: var(--grey-60);
  }

  &.bigStoryTakeoverBreakingNews {
    // Hero
    --bst--hero-link--background-color: var(--red-30);
    --bst--hero-link--border-color: var(--red-30);

    // Play Button
    --bst--play-button--background-color: var(--red-30);

    :global(body.noticias) &,
    :global(body.deportes) &,
    :global(body.telemundo) &,
    :global(body.entretenimiento) &,
    :global(body.shows) & {
      --bst--hero-link--background-color: var(--red-40);
      --bst--hero-link--border-color: var(--red-40);
      --bst--play-button--background-color: var(--red-40);
    }
  }
}
