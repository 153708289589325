@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens.scss' as t;

.multi-up-rail {
  clear: both;
}

.multi-up-rail__articles {
  position: relative;
  z-index: 2;

  @include h.breakpoint-m {
    display: flex;
    justify-content: space-between;
  }

  .multi-up-rail__three-up & {
    @include h.breakpoint-m {
      flex-wrap: wrap;
    }

    @include h.breakpoint-x {
      flex-wrap: nowrap;
    }
  }

  .multi-up-rail__four-up & {
    @include h.breakpoint-m {
      flex-wrap: wrap;
    }

    @include h.breakpoint-x {
      flex-wrap: nowrap;
    }
  }
}

.multi-up-rail__article {
  background-color: var(--multi-up-rail-article-background-color);
  flex-basis: calc(#{50%} - 1px);

  @include h.breakpoint-m {
    &:first-of-type {
      margin-right: auto;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }

  @include h.package-spacing;

  .multi-up-rail__two-up & {
    @include h.breakpoint-m {
      margin-bottom: 0;
    }
  }

  .multi-up-rail__three-up & {
    @include h.breakpoint-m {
      flex-basis: calc(#{50%} - 1px);

      &:nth-of-type(n+2) {
        margin-bottom: 0;
      }
    }

    @include h.breakpoint-x {
      flex-basis: calc(#{25%} - 1.33px);
      margin-bottom: 0;
    }
  }

  .multi-up-rail__four-up & {
    @include h.breakpoint-m {
      flex-basis: calc(#{50%} - 1px);

      &:nth-of-type(n+3) {
        margin-bottom: 0;
      }
    }

    @include h.breakpoint-x {
      flex-basis: calc(#{25%} - 1.5px);

      &:first-of-type {
        margin-right: unset;
      }

      &:last-of-type {
        margin-left: unset;
      }
      margin-bottom: 0;
    }
  }

  .multi-up-rail__three-up &:first-child {
    @include h.breakpoint-m {
      flex-basis: 100%;
    }

    @include h.breakpoint-x {
      flex-basis: calc(#{50%} - 1.33px);
    }
  }
}

.multi-up-rail__eyebrow {
  color: t.$multi-up-rail-eyebrow-color;
  background-color: t.$multi-up-rail-eyebrow-background-color;
  height: h.rem(32px);
  min-width: h.rem(130px);
  margin: h.rem(-32px) 0 0;
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  padding-left: h.rem(20px);
  padding-right: h.rem(20px);

  @include h.breakpoint-m {
    float: none;
    border-top: 5px solid;
    height: 60px;
    margin: h.rem(-60px) 0 0;
    padding-left: h.rem(30px);
  }

  @include h.breakpoint-l {
    padding: h.rem(16px 0 4px 40px);
  }

  @include h.breakpoint-x {
    float: left;
    border-top: none;
    height: h.rem(32px);
    padding: h.rem(16px 16px 4px 16px);
  }

  .multi-up-rail__icon {
    background: t.$multi-up-rail-icon-background;
    width: h.rem(32px);
    height: h.rem(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 100%;
    top: 0;
    font-size: h.rem(8px);
    color: var(--white);

    @include h.breakpoint-m {
      top: h.rem(-5px);
      left: auto;
      right: 100%;
      width: h.rem(40px);
      height: h.rem(40px);
    }

    @include h.breakpoint-x {
      width: h.rem(32px);
      height: h.rem(32px);
      left: 100%;
      top: 0;
    }
  }
}

// Tease Card TwoUp
.multi-up-rail__tease-card--two-up {
  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(24px), x: h.rem(28px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), l: h.rem(24px)));
    }
    line-height: var(--multi-up-rail-tease-card-two-up-title-line-heights);
  }

  .tease-card__featured-author {
    @include h.responsive-variations(line-height, t.$multi-up-rail-tease-card-two-up-featured-author-line-heights);

    @include h.breakpoint-m {
      font-size: var(--multi-up-rail-tease-card-two-up-featured-author-font-sizes);
    }
  }
}

// Tease Card threeUp
.multi-up-rail__tease-card--three-up {
  .tease-card__picture {
    position: relative;
  }

  .tease-card__content {
    width: 100%;

    @include h.breakpoint-x {
      margin-bottom: 0;
    }
  }

  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), l: h.rem(24px), x: h.rem(18px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(18px), l: h.rem(18px), x: h.rem(13.5px)));
    }

    @include h.responsive-variations(line-height, t.$multi-up-rail-tease-card-three-up-title-line-heights);
  }

  .tease-card__featured-author {
    @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(24px), l: h.rem(24px), x: h.rem(24px)));

    &--today,
    &--think {
      @include h.responsive-variations(
        font-size, (s: h.rem(21.6px), m: h.rem(21.6px), l: h.rem(21.6px), x: h.rem(21.6px))
      );
    }

    @include h.responsive-variations(line-height, t.$multi-up-rail-tease-card-three-up-featured-author-line-heights);
  }

  &.tease-card__main-tease .tease-card__eyebrow {
    @include h.breakpoint-m {
      border-color: t.$tease-card-eyebrow-border-color;
      position: absolute;
      height: h.rem(55px);
      width: calc(100% - 88px);
      left: h.rem(88px);
      bottom: 0;
      padding: h.rem(16px 0 0 30px);
      border-top: 5px solid;

      .icon {
        position: absolute;
        top: h.rem(-5px);
        left: h.rem(-40px);
        width: h.rem(40px);
        height: h.rem(40px);
      }
    }

    @include h.breakpoint-l {
      width: calc(100% - 40px);
      left: h.rem(40px);
    }

    @include h.breakpoint-x {
      left: 0;
      bottom: 0;
      height: inherit;
      width: inherit;
      border-top: none;
      padding: h.rem(16px 16px 4px);

      .icon {
        left: 100%;
        top: 0;
        bottom: 0;
        width: h.rem(32px);
        height: h.rem(32px);
      }
    }
  }

  .tease-card__info {
    &.tease-card__headline:only-child {
      margin-bottom: 0;
    }
  }

  &.tease-card__main-tease .tease-card__info {
    @include h.breakpoint-m {
      width: calc(100% - 118px);
      margin-left: 118px;
      padding: 0;
    }

    @include h.breakpoint-l {
      width: calc(100% - (#{t.$multi-up-rail-gutter-desktop} + 30px));
      margin-left: t.$multi-up-rail-gutter-desktop + 30px;
    }

    @include h.breakpoint-x {
      width: auto;
      margin: 0;
      padding: h.rem(20px 16px 0);
    }
  }
}

// Tease Card fourUp
.multi-up-rail__tease-card--four-up {
  .tease-card__content {
    width: 100%;

    @include h.breakpoint-x {
      margin-bottom: 0;
    }
  }

  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), x: h.rem(18px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px), l: h.rem(18px), x: h.rem(16px)));
    }
    line-height: var(--multi-up-rail-tease-card-four-up-line-height);
  }

  .tease-card__featured-author {
    @include h.responsive-variations(font-size, (m: h.rem(26px), x: h.rem(20px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (m: h.rem(23.4px), x: h.rem(18px)));
    }

    @include h.responsive-variations(line-height, t.$multi-up-rail-tease-card-four-up-featured-author-line-heights);
  }
}

.tease-card--no-main-image {
  .multi-up-rail__tease-card--two-up & {
    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(32px), m: h.rem(40px), l: h.rem(32px), x: h.rem(40px)));

      &--today {
        @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(32px), l: h.rem(20px), x: h.rem(32px)));
      }

      &--select {
        @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(24px), l: h.rem(22px), x: h.rem(32px)));
      }
    }
  }

  .multi-up-rail__tease-card--three-up & {
    .tease-card__title {
      line-height: h.rem(30px);

      @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), l: h.rem(24px), x: h.rem(32px)));

      &--today {
        @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(24px), x: h.rem(20px)));
      }
    }
  }

  .multi-up-rail__tease-card--four-up & {
    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), l: h.rem(32px), x: h.rem(24px)));

      &--today,
      &--think {
        @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(20px), x: h.rem(18px)));
      }
      line-height: var(--multi-up-rail-tease-card-four-up-line-height);
    }
  }
}

.multi-up-rail__tease-card--three-up.tease-card__main-tease {
  &.tease-card__main-tease .tease-card__title {
    margin: h.rem(0 0 25px);
    font-size: h.rem(24px);
    line-height: 100%;

    @include h.breakpoint-m {
      font-size: h.rem(32px);
      margin-bottom: h.rem(12px);
      flex: 1 1 50%;
    }

    @include h.breakpoint-l {
      font-size: h.rem(32px);
      margin-right: h.rem(40px);
      width: 100%;
    }

    @include h.breakpoint-x {
      font-size: h.rem(28px);
      margin-right: 0;
    }
  }

  .tease-card--no-main-image .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), x: h.rem(32px)));

    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(24px), x: h.rem(20px)));
    }
  }
}

.rail__container,
.today-cover-tabbed__column {
  .tease-card--two-up .tease-card--no-main-image .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(32px), m: h.rem(40px), l: h.rem(32px), x: h.rem(40px)));

    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(32px), l: h.rem(20px), x: h.rem(32px)));
    }

    &--select {
      @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(24px), l: h.rem(22px), x: h.rem(32px)));
    }
  }

  .threeUp .tease-card--no-main-image.tease-card__content .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), l: h.rem(24px), x: h.rem(32px)));

    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(24px), x: h.rem(20px)));
    }
  }

  .threeUp .tease-card--no-main-image:first-of-type .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), x: h.rem(32px)));

    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(24px), x: h.rem(20px)));
    }
  }

  .threeUp .tease-card--no-main-image:last-of-type .tease-card__title {
    // 2 - but not today
    @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), l: h.rem(24px), x: h.rem(32px)));

    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(32px), x: h.rem(20px)));
    }
  }
}
