@use 'assets/styles/utils/helper' as h;

.container {
  margin-bottom: h.rem(40px);
  padding-top: h.rem(32px);

  @include h.breakpoint-m {
    margin-bottom: 1rem;
  }

  @include h.breakpoint-l {
    display: flex;
  }
}

.picture {
  @include h.responsive-variations(width, (m: 57%));
  @include h.responsive-variations(margin-right, (s: h.rem(20px), m: h.rem(48px), l: 6%));
  align-self: flex-start;
  position: relative;
  margin-bottom: h.rem(34px);

  // parallelogram shape
  &::before {
    content: '';
    width: 65%;
    height: 30%;
    background-color: var(--grey-30);
    position: absolute;
    top: -4%;
    right: 3%;
    display: block;
    transform: rotate(168deg) skewY(11deg);
  }

  // diagonal line
  &::after {
    content: '';
    width: 1px;
    height: 50%;
    background-color: var(--secondary-color);
    position: absolute;
    top: 0;
    right: 6.3%;
    display: block;
    transform: rotate(169deg);
  }

  .feature-lead__picture--link {
    display: block;
  }

  :global(.teasePicture) {
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
  }
}

.title {
  @include h.responsive-variations(font-size, (s: h.rem(32px), m: h.rem(24px), xx: h.rem(32px)));
  @include h.responsive-variations(margin-top, (s: h.rem(9px), m: h.rem(5px), xx: h.rem(9px)));
  font-family: var(--primary-font);
  font-weight: 400;
  line-height: var(--leading-150);
  color: var(--grey-60);
  margin-bottom: h.rem(9px);

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--accent-blue);
    text-decoration-thickness: 2px;
    text-underline-offset: 7px;
  }
}

.dek {
  @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(14px), l: h.rem(18px), xx: h.rem(24px)));
  font-family: var(--tertiary-font);
  line-height: var(--leading-150);
  color: var(--grey-60);
  margin-top: 0;
  margin-bottom: 0;
}

.info {
  @include h.responsive-variations(width, (m: 30%));
  @include h.responsive-variations(padding, (s: 0 h.rem(20px), m: 0));
}

.infoContent {
  width: 100%;

  @include h.breakpoint-m {
    display: flex;
  }
}

.related {
  @include h.breakpoint-m-only {
    display: none;
  }
}

.related2 {
  display: none;
  padding-left: h.rem(40px);
  margin-bottom: h.rem(35px);

  @include h.breakpoint-m-only {
    display: block;
  }
}
