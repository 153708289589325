@use 'assets/styles/utils/helper' as h;

.authorCard {
  @include h.clearfix;
}

.textGroup {
  float: right;
  width: calc(100% - 66px);
  padding-left: var(--space-4);
  padding-right: var(--space-8);
}

.pic {
  background-color: var(--yellow-40);
  border-radius: 50%;
  height: var(--space-60);
  width: var(--space-60);

  .missing {
    background-color: transparent;
  }
}

.firstName,
.lastName {
  color: var(--grey-70);
  display: block;
  font-family: var(--founders-cond);
  font-size: var(--text-18);
  font-weight: var(--semibold);
}

.lastName::before {
  content: '\2060';
}

.shortBio {
  display: none;

  @include h.breakpoint-m {
    color: var(--grey-70);
    display: block;
    font-family: var(--publico-hed);
    font-size: var(--text-12);
    font-weight: 300;
    line-height: var(--text-18);
    margin-top: var(--space-8);
  }
}
