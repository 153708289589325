@use 'assets/styles/utils/helper' as h;

.relatedArticle {
  // container styling
  display: flex;
  flex-flow: row wrap;
  margin-bottom: auto;
  width: 100%; // needed for IE11

  @media only screen and (min-width: h.$grid-m-width) {
    width: calc(100% - 52px); // needed for IE11
  }

  .info {
    flex: 1;
  }

  .picture {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    line-height: 0;
    margin: 0 h.rem(12px) auto 0;
    position: relative;

    picture {
      display: block;
      height: h.rem(80px);
      width: h.rem(80px);

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .unibrow {
    font-family: var(--founders-mono);
    font-size: h.rem(12px);
    font-weight: normal;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    color: var(--blue-40);
  }

  .headline {
    font-family: var(--founders-cond);
    font-size: h.rem(20px);
    font-weight: 600;
    line-height: 1;
    margin: h.rem(4px) 0 0;

    @include h.breakpoint-m {
      font-size: h.rem(18px);
    }

    @include h.breakpoint-x {
      font-size: h.rem(20px);
    }
  }

  .externalLink {
    font-family: var(--founders-cond);
    font-size: h.rem(14px);
    font-weight: normal;
    display: flex;
    align-items: center;
    color: var(--blue-40);
    margin-top: h.rem(10px);

    :global(.icon) {
      font-size: h.rem(11px);
      margin: h.rem(4px) 0 0 h.rem(4px);
    }
  }

  .author {
    font-family: var(--founders-mono);
    font-size: h.rem(12px);
    line-height: 1;
    margin: h.rem(10px) 0 0;
    padding: 0;

    > * {
      &::before {
        content: ', ';
      }

      &:last-child::before {
        content: ' & ';
      }

      &:first-child::before {
        content: '';
      }
    }

    a {
      color: var(--blue-40);
    }
  }
}
