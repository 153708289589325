@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.leadSectionFront {
  position: relative;
}

.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: var(--header-content--justify-content);
  height: var(--header-content--height);
  margin: var(--header-content--margin);
}

.headerTitle {
  color: var(--header-title--color);
  font-family: var(--header-title--font-family);
  font-size: var(--header-title--font-size);
  font-style: var(--header-title--font-style);
  font-weight: var(--header-title--font-weight);
  line-height: var(--header-title--line-height);
  margin-bottom: h.rem(10px);
  margin-top: 0;
  position: relative;
  text-transform: var(--header-title--text-transform);

  @include h.breakpoint-l {
    width: h.rem(320px);
  }

  &::before {
    border-top: 1px solid var(--header-title--before-border-color);
    content: var(--header-title--before-content);
    display: block;
    left: h.rem(-20px);
    position: absolute;
    top: h.rem(17px);
    width: h.rem(10px);

    @include h.breakpoint-m {
      width: h.rem(40px);
      left: h.rem(-48px);
    }

    @include h.breakpoint-l {
      top: h.rem(30px);
      width: h.rem(60px);
      left: h.rem(-80px);
    }
  }
}

.headerDek {
  color: var(--header-dek--color);
  font-family: var(--publico-txt);
  font-size: var(--text-14);
  font-style: normal;
  line-height: var(--leading-150);
  margin-bottom: var(--space-20);
  margin-top: var(--space-12);

  @include h.breakpoint-l {
    margin-bottom: 0;
  }
}

.twoUp {
  @include h.package-spacing;
}

.rail {
  padding-top: var(--content-offset--padding-top);

  @include h.breakpoint-m-max {
    :global(.rail__content) {
      // Override '.rail__content > *'
      > :first-child,
      :global(.ad) {
        margin-bottom: 0;
      }
    }
  }
}

.rail,
.content {
  @include h.breakpoint-l {
    padding-top: var(--content-offset--padding-top);
  }
}

:global(body.better) .rail::before,
:global(body.mach) .rail::before {
  @include h.pattern-dots(var(--neutral-10));
  content: '';
  position: absolute;
  top: h.rem(60px);
  left: h.rem(20px);
  display: block;
  width: h.rem(80px);
  height: h.rem(40px);

  @include h.breakpoint-m {
    top: 0;
    left: calc(4vw + 32px);
  }

  @include h.breakpoint-l {
    left: 0;
  }
}
