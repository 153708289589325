@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.package-title-top-border {
  margin: var(--package-alternative-margin-left);
  background-image: var(--package-alternative-background-image);
  background-size: var(--package-alternative-background-size);
  background-repeat: var(--package-alternative-background-repeat);
  background-position-y: var(--package-alternative-background-position-y);

  &__top-decoration {
    display: var(--package-alternative-top-decoration-display);

    .stackCommerceList & {
      border-top: 0 !important;
    }

    .hideNewsTitle & {
      border: 1px solid transparent;
    }

    &::before {
      background: var(--package-alternative-top-decoration-background-color);
      content: '';
      width: var(--package-alternative-top-decoration-width);
      height: var(--package-alternative-top-decoration-height);
      position: absolute;

      .stackCommerceList & {
        content: none;
      }

      // on mobile, news have tabs - no top embelishment needed
      .hideNewsTitle & {
        content: none;

        @include h.breakpoint-l {
          content: '';
        }
      }

      body.news & {
        .showNewsTitle {
          left: 20px;

          @include h.breakpoint-m {
            left: 0;
          }
        }
      }
    }
  }

  &__title {
    align-self: var(--package-title-align-self);
    font-family: var(--package-title-top-border-font-family);
    font-style: var(--package-title-top-border-font-style);
    font-weight: var(--package-title-top-border-font-weight);
    line-height: var(--package-title-top-border-line-height);
    color: var(--package-title-top-border-font-color);
    margin: var(--package-title-top-border-margin);
    padding: var(--package-title-top-border-padding);
    text-transform: var(--package-title-top-border-transform);
    position: relative;
    font-size: var(--package-title-top-border-font-size);
    letter-spacing: var(--package-title-top-border-letter-spacing);

    .hideNewsTitle & {
      display: none;

      @include h.breakpoint-l {
        display: block;
      }
    }

    .stackCommerceList & {
      font-family: var(--founders-txt);
      margin: 0;
    }

    // This approach for preventing duplicate TeaseList titles should probably
    // be used for all brands (and replace the showNewsTitle/hideNewsTitle class
    // that currently is toggled)
    body.telemundo &,
    body.entretenimiento &,
    body.shows & {
      .right-rail-tabbed__tabs.right-rail-tabbed__tabs--visible + .rail__container & {
        display: none;
      }
    }
  }

  &__link {
    color: var(--package-title-top-border-link-color);
    font-family: var(--package-title-top-border-link-font);
    font-size: h.rem(18px);
    font-style: normal;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: h.rem(20px);
  }
}

.package-title-top__logo-line {
  display: none;
  margin: 0;
  background-color: var(--grey-40);
  height: 1px;
  border: 0;

  @include h.breakpoint-s-only {
    display: block;
  }

  @include h.breakpoint-m-only {
    display: block;
  }
}
