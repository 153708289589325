@use 'assets/styles/utils/helper' as h;

.wrapper {
  display: grid;
  gap: 16px 30px;
  grid-template-columns: 1fr 1fr;
  margin: auto 20px;

  @include h.breakpoint-m {
    grid-template-columns: repeat(3, 1fr);
    margin: auto;
  }

  @include h.breakpoint-l {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  @include h.breakpoint-x {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  // overrides for bacon author card
  > * {
    flex: 1 0;

    :nth-child(2) {
      width: auto;
    }
  }
}
