@use 'assets/styles/utils/helper' as h;

.pkg-sponsor {
  --pkg-sponsor--margin: 24px auto 28px;
  --pkg-sponsor--color: inherit;
  --pkg-sponsor--font-family: inherit;
  --pkg-sponsor-label--margin: 0 0 12px;
  --pkg-sponsor-label--line-height: auto;
  --pkg-sponsor-logo--width: 150px;
  --pkg-sponsor-logo--height: auto;

  body.select & {
    --pkg-sponsor--margin: 24px auto 20px;
  }

  body.today & {
    --pkg-sponsor--color: var(--grey-60);
    --pkg-sponsor--font-family: var(--secondary-font);
    --pkg-sponsor-label--margin: #{h.rem(0 0 10px)};
    --pkg-sponsor--margin: #{h.rem(14px)} auto;
    --pkg-sponsor-label--line-height: 100%;
    --pkg-sponsor-logo--width: auto;
    --pkg-sponsor-logo--height: 50px;

    @include h.breakpoint-m {
      --pkg-sponsor--margin: #{h.rem(16px)} auto;
    }

    @include h.breakpoint-l {
      --pkg-sponsor--margin: #{h.rem(32px)} auto;
      --pkg-sponsor-logo--height: 80px;
    }
  }
}
