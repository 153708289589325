@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.related-content-tease {
  --related-content-author-space: 6px;
  /* stylelint-disable */
  container-type: inline-size;
  container-name: related-content-tease;
  /* stylelint-enable */
  @include h.breakpoint-s-only {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  margin-top: 16px;
  margin-bottom: 16px;

  &__headline {
    font-size: var(--related-content-tease-headline-font-size);

    &-link {
      color: var(--related-content-tease-headline-link-color);
      line-height: var(--related-content-tease-headline-link-line-height);
      font-family: var(--related-content-tease-headline-link-font-family);
      font-weight: var(--related-content-tease-headline-link-font-weight);
    }

    &--with-media {
      display: var(--related-content-tease-headline-with-media-display);
      margin-left: var(--related-content-tease-headline-with-media-margin-left);

      &--narrow {
        margin-left: var(--related-content-tease-headline-with-media-narrow-margin-left);
      }

      &--vertical-center {
        display: flex;
        align-items: center;
      }
    }

    &--tall {
      @include h.breakpoint-m {
        min-height: 86px;
      }
    }

    /* stylelint-disable */
    &--no-media {
      @container (width < 225px) {
        display: flex;
        flex-direction: column;
      }
    }
    /* stylelint-enable */
  }

  &.last-related {
    @include h.breakpoint-s-only {
      margin-bottom: 0;
    }

    @include h.breakpoint-l {
      margin-bottom: 0;
    }
  }

  &--label,
  &--byline {
    flex-direction: column;
    align-items: baseline;
  }

  &__badge-label {
    display: inline-block;

    span {
      position: relative;
      bottom: 4px;
      margin-right: 8px;

      &.badge-type-WATCH {
        top: unset;
      }
    }

    &--narrow {
      height: var(--related-content-tease-badge-label-narrow-height);
      display: var(--related-content-tease-badge-label-narrow-display);
    }
  }

  &--no-media {
    span {
      bottom: 2px;
    }
  }

  &__box {
    display: var(--related-content-tease-box-display);

    &--narrow {
      flex-direction: var(--related-content-tease-box-narrow-flex-direction);
      justify-content: var(--related-content-tease-box-narrow-justify-content);
      align-items: var(--related-content-tease-box-narrow-aligh-items);
      gap: var(--related-content-tease-box-narrow-gap);
    }
  }

  .related-content-tease__headline--with-media--narrow {
    width: 100%;
  }

  // space above byline/author and below dek
  .author-top-spacer {
    height: var(--related-content-author-space, 8px);
  }

  .related-content-byline {
    font-family: var(--founders-mono);
    font-size: h.rem(12px);
    font-weight: 400;
    color: var(--grey-70, #2a2a2a);
  }
}
/* stylelint-disable */
.multistoryline {
  .related-content-tease__headline {
    &--with-media {
      .related-content-tease__badge-label {
        padding-bottom: 3px;
      }
    }
  }

  align-items: start;
  a {
    @container (width < 225px) {
      display: none;
    }
  }
  @container (width < 225px) {
    .related-content-tease__headline {
      margin-left: 0;
      a {
        display: block;
      }
    }
  }
}
/* stylelint-enable */

.small-storyline.standard {
  .related-content-tease__box {
    @include h.breakpoint-x {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .related-content-tease__headline {
    @include h.breakpoint-x {
      margin-left: 0;
    }
  }
}
