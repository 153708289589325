@use 'assets/styles/utils/helper' as h;

.tve__cta {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  line-height: 1;
  width: 100%;
  height: h.rem(44px);
  z-index: 100;

  @include h.breakpoint-l {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__text-wrap {
    flex: 1 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 h.rem(16px);

    @include h.breakpoint-l {
      justify-content: flex-end;
      min-width: h.rem(80px);
    }
  }

  &__countdown {
    font-family: var(--founders-cond);
    font-size: h.rem(20px);
    display: block;
    margin: h.rem(-4px) 0 0;
    width: h.rem(40px);

    @include h.breakpoint-l {
      font-size: h.rem(24px);
      width: h.rem(48px);
      text-align: center;
    }
  }

  &__text {
    &__anonymous {
      font-family: var(--founders-cond);
      font-size: h.rem(20px);

      @include h.breakpoint-l {
        font-size: h.rem(24px);
      }
    }

    &__temppass {
      font-family: var(--founders-mono);
      font-size: h.rem(12px);
      letter-spacing: -0.04em;
      text-transform: uppercase;
      margin-right: h.rem(12px);

      &__label--small {
        display: block;

        @include h.breakpoint-l {
          display: none;
        }
      }

      &__label--large {
        display: none;

        @include h.breakpoint-l {
          display: block;
        }
      }

      @include h.breakpoint-l {
        transition-property: opacity, margin, width;
        transition-duration: 0.4s, 0.1s, 0.1s;
        transition-delay: 0s, 0.3s, 0.3s;
        opacity: 1;
        width: 200px;

        &--hidden {
          opacity: 0;
          width: 0;
          margin: 0;
        }
      }
    }
  }

  // Similar positioning between anonymous and tempPass call to actions
  &__anonymous,
  &__authed-temporary {
    color: var(--grey-70);
    background-color: var(--white);

    @include h.breakpoint-l {
      position: absolute;
      top: 0;
      right: 40%;
      width: auto;
      height: h.rem(60px);
      background-color: rgba(var(--white-rgb), 0.8);

      .liveBlog & {
        right: 0%;
      }
    }

    @include h.breakpoint-x {
      height: h.rem(80px);
    }
  }

  // Secondary action button positioned over info box
  &__anonymous-secondary {
    display: none;

    @include h.breakpoint-l {
      display: block;
      position: absolute;
      top: h.rem(16px);
      right: h.rem(40px);
      z-index: 1;
    }
  }

  &__authed-full {
    justify-content: flex-end;
    padding-right: h.rem(30px);

    @include h.breakpoint-l {
      position: absolute;
      top: h.rem(16px);
      right: h.rem(40px);
      height: auto;
      width: auto;
      padding: 0;
    }
  }
}
