.productWaffle {
  --waffle--background-color: var(--white);

  :global(body.select) & {
    --waffle--background-color: var(--white);
  }

  :global(body.today) & {
    --waffle--background-color: var(--white);
  }
}

.productWaffleList {
  --waffle-list--mobile--gap: 48px 0;
}
