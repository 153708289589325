@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.standardLead {
  position: relative;
  z-index: 1;
}

.picture {
  display: block;
  position: relative;
  width: 100%;
  z-index: 0;

  @include h.breakpoint-l {
    width: auto;
  }

  picture {
    display: block;
  }
}

.info {
  background-color: var(--standard-lead--info--background-color);
  padding-left: var(--space-48);
  padding-right: var(--space-20);
  padding-top: h.rem(5px);
  position: relative;
  width: 100%;

  @include h.breakpoint-m {
    margin-left: h.rem(88px);
    margin-top: h.rem(-60px);
    padding-left: h.rem(30px);
    padding-right: h.rem(30px);
    width: calc(100% - 88px);
  }

  @include h.breakpoint-l {
    margin-left: var(--space-40);
    padding-left: var(--space-40);
    padding-right: 0;
    width: auto;
  }

  @include h.breakpoint-x {
    margin-left: h.rem(60px);
  }

  .noImage & {
    margin-left: 0;
  }

  &::before {
    background-color: var(--standard-lead--info-before--background-color);
    content: '';
    height: h.rem(5px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.eyebrow {
  color: var(--default-color);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  font-weight: 100;
  line-height: var(--leading-100);
  margin-bottom: var(--space-8);
  margin-top: var(--space-16);
  position: relative;
  text-transform: uppercase;

  span {
    color: var(--default-color);
  }
}

.title {
  font-size: var(--text-24);
  font-family: var(--standard-lead--title--font-family);
  font-weight: normal;
  line-height: var(--leading-100);
  color: var(--standard-lead--title--color);
  margin: var(--space-8) 0;

  @include h.breakpoint-m {
    font-size: var(--text-48);
  }
}

.icon {
  position: absolute;
  left: 0;
  top: 0;

  @include h.breakpoint-m {
    left: h.rem(-30px);
  }

  @include h.breakpoint-x {
    left: h.rem(-32px);
  }

  .noImage & {
    display: none;
  }
}
