@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.localNewsPackage {
  height: max-content;
  margin: 0 20px 30px;
  min-height: 400px;
  overflow: hidden;
  position: relative;

  @include h.breakpoint-m {
    margin: 0 0 48px;
    min-height: min-content;
  }
}

.header {
  align-items: flex-end;
  border-bottom: var(--header-border-bottom);
  color: var(--blue-40);
  display: flex;
  font-family: var(--publico-txt);
  font-size: var(--text-16);
  font-style: var(--normal);
  font-weight: var(--bold);
  justify-content: space-between;
  line-height: var(--leading-100);
  padding-bottom: 10px;
}

.myNewsLink {
  align-items: center;
  display: flex;
  font-family: var(--founders-cond);
  font-size: var(--text-18);
  font-weight: var(--semibold);
  gap: 0 5px;
  line-height: var(--leading-100);

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}

.myNewsLabel {
  border-bottom: 3px solid var(--blue-40);
  padding-bottom: 3px;
  text-transform: uppercase;
}

.overlay {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 253, 0.9);
  background-image: url('~assets/images/unauth-overlay-bg-376.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  height: 400px;
  text-align: center;
  top: 30px;
  width: 100%;
  z-index: 1;

  @include h.breakpoint-m {
    height: 590px;
    background-size: cover;
    background-image: url('~assets/images/unauth-overlay-bg-758.jpg');
  }

  @include h.breakpoint-l {
    background-size: contain;
    background-image: url('~assets/images/unauth-overlay-bg-1000.jpg');
  }

  @include h.breakpoint-x {
    background-image: url('~assets/images/unauth-overlay-bg-1200.jpg');
  }

  &.unauthenticated {
    display: block;
  }
}

.overlayHed {
  color: var(--blue-40);
  font-family: var(--founders-cond);
  font-weight: var(--semibold);
  font-size: h.rem(28px);
  line-height: var(--leading-100);
  margin: 0 20px 12px;
  padding-top: 30px;

  @include h.breakpoint-m {
    font-size: h.rem(48px);
    margin-bottom: 20px;
    padding-top: 60px;
  }

  @include h.breakpoint-l {
    font-size: h.rem(40px);
    margin-left: 44px;
    margin-right: 45px;
  }

  @include h.breakpoint-x {
    font-size: h.rem(48px);
    margin-left: 95px;
    margin-right: 95px;
    padding-top: 80px;
  }
}

.overlayDek {
  font-family: var(--publico-txt);
  font-size: h.rem(14px);
  line-height: var(--leading-150);
  text-align: center;
  color: var(--grey-100);
  margin-bottom: 25px;

  @include h.breakpoint-m {
    font-size: h.rem(18px);
    margin-bottom: 30px;
  }
}

.overlayImg {
  height: 47px;
  margin: 0 auto 21px;
  width: 47px;

  @include h.breakpoint-m {
    height: 77px;
    margin-bottom: 30px;
    width: 77px;
  }
}

.overlay .overlayButton {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 54px;
  justify-content: center;
  margin: 0 auto;
  width: 295px;

  @include h.breakpoint-m {
    width: 375px;
  }
}
