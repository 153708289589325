@use 'assets/styles/utils/helper' as h;

.wide-tease-item__wrapper {
  // Component wrapper
  --wide-tease-padding: #{h.rem(20px)};
  // Item wrapper
  --wide-tease-item-border: 1px dashed var(--grey-50);
  --wide-tease-item-padding: #{h.rem(24px)} 0;
  --wide-tease-last-item-height: 7rem;
  --wide-tease-no-art-padding: 0;
  --wide-tease-last-background-gradient: var(--white);
  --wide-tease-last-background-gradient-rgb: var(--white-rgb);
  // Item unibrow and timestamps
  --wide-tease-item-timestamp-margin-left: #{h.rem(12px)};
  --wide-tease-item-timestamp-display: inline-block;
  // Item unibrow
  --wide-tease-item-unibrow-font-size: var(--text-12);
  --wide-tease-item-unibrow-color: var(--default-color);
  --wide-tease-item-unibrow-font-family: var(--founders-mono);
  --wide-tease-item-unibrow-font-weight: normal;
  --wide-tease-item-unibrow-line-height: inherit;
  --wide-tease-item-unibrow-letter-spacing: unset;
  --wide-tease-item-unibrow-text-transform: uppercase;
  --wide-tease-item-unibrow-margin: #{h.rem(0 0 8px 0)};
  // Item timestamp
  --wide-tease-item-timestamp-font-size: var(--text-12);
  --wide-tease-item-timestamp-color: var(--grey-60);
  --wide-tease-item-timestamp-font-family: var(--founders-mono);
  --wide-tease-item-timestamp-font-weight: normal;
  --wide-tease-item-timestamp-letter-spacing: #{h.rem(-.5px)};
  // Item headline
  --wide-tease-item-headline-font-size: #{h.rem(24px)};
  --wide-tease-item-headline-color: var(--default-headline-color);
  --wide-tease-item-headline-font-family: var(--default-headline-font-family);
  --wide-tease-item-headline-font-weight: 600;
  --wide-tease-item-headline-line-height: var(--default-headline-leading);
  --wide-tease-item-headline-margin: #{h.rem(0 0 8px)};
  --wide-tease-item-headline-hover-text-decoration: none;
  --wide-tease-item-headline-hover-opacity: .7;

  // Item description
  --wide-tease-item-description-font-family: var(--publico-txt);
  --wide-tease-item-description-font-size: var(--text-14);
  --wide-tease-item-description-color: var(--grey-60);
  --wide-tease-item-description-line-height: 1.5;
  --wide-tease-item-description-flex-basis: 66.6%;
  // Item image
  --wide-tease-item-image-margin: #{h.rem(4px 0 0 9px)};
  --wide-tease-item-image-width: #{h.rem(106px)};
  --wide-tease-item-image-hover-transform: none;

  @include h.breakpoint-m {
    --wide-tease-padding: #{h.rem(40px 0)};
    --wide-tease-last-item-height: 5rem;
    --wide-tease-no-art-padding: #{h.rem(90px)};
    --wide-tease-item-unibrow-timestamp-margin: #{h.rem(0 15px 0 0)};
    --wide-tease-item-unibrow-timestamp-width: #{h.rem(70px)};
    --wide-tease-item-timestamp-margin-left: 0;
    --wide-tease-item-timestamp-display: block;
    --wide-tease-item-unibrow-margin: #{h.rem(0 0 8px)};
    --wide-tease-item-description-flex-basis: 100%;
    --wide-tease-item-image-margin: #{h.rem(0 0 0 30px)};
    --wide-tease-item-image-width: #{h.rem(213px)};
  }

  @include h.breakpoint-l {
    --wide-tease-no-art-padding: 0;
    --wide-tease-item-unibrow-timestamp-width: #{h.rem(65px)};
    --wide-tease-item-image-margin: #{h.rem(0 0 0 40px)};
    --wide-tease-item-image-width: #{h.rem(180px)};
  }

  @include h.breakpoint-x {
    --wide-tease-padding: #{h.rem(48px 0)};
    --wide-tease-no-art-padding: #{h.rem(100px)};
    --wide-tease-item-unibrow-timestamp-width: #{h.rem(85px)};
    --wide-tease-item-image-width: #{h.rem(260px)};
  }

  body.select & {
    --wide-tease-item-padding: #{h.rem(24px)} 0 #{h.rem(15px)};
    --wide-tease-item-timestamp-margin-left: #{h.rem(8px)};
    --wide-tease-item-unibrow-letter-spacing: 0;
    --wide-tease-item-timestamp-color: #555;
    --wide-tease-item-headline-color: #2a2a2a;
    --wide-tease-item-headline-font-size: 22px;
    --wide-tease-item-headline-font-weight: 900;
    --wide-tease-item-unibrow-font-family: var(--lato);
    --wide-tease-item-unibrow-color: var(--blue-60);
    --wide-tease-item-headline-line-height: 100%;
    --wide-tease-item-headline-font-family: var(--lato);
    --wide-tease-item-timestamp-font-family: var(--lato);
    --wide-tease-item-description-font-family: var(--lato);
    --wide-tease-item-description-font-size: #{h.rem(14px)};
    --wide-tease-item-description-color: #555;
    --wide-tease-item-description-line-height: #{h.rem(21px)};
  }

  body.think & {
    --wide-tease-item-headline-font-size: var(--text-20);
    --wide-tease-item-headline-line-height: 1.25;
    --wide-tease-item-headline-font-weight: 300;
  }

  body.telemundo & {
    --wide-tease-item-headline-color: var(--blue-60);
  }

  body.today & {
    --wide-tease-item-headline-font-size: var(--text-20);
    --wide-tease-item-headline-line-height: 1.5;
    --wide-tease-item-border: 1px solid var(--grey-30);
    --wide-tease-item-headline-color: var(--black);
    --wide-tease-item-headline-font-family: var(--secondary-font);
    --wide-tease-item-headline-margin: #{h.rem(0 0 4px)};
    --wide-tease-item-description-font-family: var(--tertiary-font);
    --wide-tease-item-description-font-size: #{h.rem(16px)};
    --wide-tease-item-description-color: var(--black);
    --wide-tease-item-description-line-height: 1.5;
    --wide-tease-item-timestamp-font-size: #{h.rem(14px)};
    --wide-tease-item-timestamp-color: var(--grey-60);
    --wide-tease-item-timestamp-font-family: var(--secondary-font);
    --wide-tease-item-timestamp-font-weight: normal;
    --wide-tease-item-timestamp-letter-spacing: 0;
    --wide-tease-item-headline-hover-opacity: 1;
    --wide-tease-item-headline-hover-text-decoration: underline;
    --wide-tease-item-headline-hover-text-decoration-color: var(--accent-blue);
    --wide-tease-item-unibrow-color: var(--grey-70);
    --wide-tease-item-unibrow-font-family: var(--secondary-font);
    --wide-tease-last-background-gradient: var(--white);
    --wide-tease-last-background-gradient-rgb: var(--white-rgb);
    --wide-tease-item-padding: #{h.rem(16px)} 0;
    --wide-tease-item-image-hover-transform: rotate(1deg);
    --wide-tease-item-unibrow-font-weight: 700;
    --wide-tease-item-headline-font-weight: 400;

    @include h.breakpoint-m {
      --wide-tease-item-info-order: 3;
    }
  }
}

.wide-tease-item__wrapper--full-width {
  body.today & {
    --wide-tease-item-info-order: initial;

    @include h.breakpoint-m {
      --wide-tease-item-image-margin: #{h.rem(0 20px 0 0)};
      --wide-tease-item-info-order: 1;
    }

    @include h.breakpoint-l {
      --wide-tease-item-image-margin: #{h.rem(0 40px 0 0)};
    }
  }
}
