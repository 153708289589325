@use 'assets/styles/utils/helper' as h;

.dayPicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid var(--grey-40);
  border-bottom: 1px solid var(--grey-40);
  top: 0;
  background: var(--white);

  @include h.breakpoint-s-only {
    overflow: scroll;
    margin-bottom: 30px;
  }
}

.dayPickerDay {
  font-family: var(--default-headline-font-family);
  color: var(--grey-60);
  font-size: var(--text-18);
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.dayPickerActive {
  border-bottom: 1px solid var(--default-color);
}
