@use 'assets/styles/utils/helper' as h;

.teaseImage {
  position: relative;

  picture img {
    height: auto;
  }
}

.teaseWrapper {
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.teaseBackground {
  background-image: url('~assets/images/waffleBackground.svg') no-repeat center center;
  background-size: 101%; // use 101% to cover 1px on the right of card
  padding-top: 100%;
  position: relative;
}
