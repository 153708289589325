@use 'assets/styles/utils/helper' as h;

.container {
  position: relative;
  overflow: hidden;
  margin: h.rem(40px) auto;

  @include h.breakpoint-m {
    margin: h.rem(55px) auto;
  }
}

.international {
  min-height: h.rem(380px);
  padding: h.rem(54px) h.rem(24px);
  background-color: var(--accent-orange-light);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky.international {
  margin: 0;
  padding: 0;
  min-height: unset;

  @include h.breakpoint-m {
    .inner {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: h.rem(275px);
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.innerPositioned {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: h.rem(54px) h.rem(24px);
}

.innerLoaded {
  background-color: var(--accent-orange-light);
}

.innerLoading {
  background-color: var(--grey-20);
}

.banner {
  width: h.rem(165px);
  height: h.rem(28px);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='165' height='28' viewBox='0 0 165 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.5 0H141.818L133.545 14L141.818 28H22.5L32 14L22.5 0Z' fill='%23FF503C'/%3E%3Cpath d='M13 1L15.9187 9.98278H25.3637L17.7225 15.5344L20.6412 24.5172L13 18.9656L5.35879 24.5172L8.27747 15.5344L0.636266 9.98278H10.0813L13 1Z' fill='%23FF503C'/%3E%3Cpath d='M152 1L154.919 9.98278H164.364L156.723 15.5344L159.641 24.5172L152 18.9656L144.359 24.5172L147.277 15.5344L139.636 9.98278H149.081L152 1Z' fill='%23FF503C'/%3E%3C/svg%3E");
  font-family: var(--secondary-font);
  font-weight: 700;
  line-height: h.rem(30px);
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  margin-bottom: h.rem(10px);
  position: relative;
  font-size: h.rem(15px);
}

p.heading {
  position: relative;
  color: var(--grey-70);
  text-align: center;
  font-family: var(--primary-font);
  font-size: h.rem(33px);
  line-height: 1.5;
  margin: h.rem(24px) auto 0;

  .sticky & {
    padding: h.rem(0 50px);
    margin-top: h.rem(5px);
  }
}

.subtitle {
  font-family: var(--secondary-font);
  font-size: h.rem(18px);
  font-weight: 700;
  text-align: center;
  padding: h.rem(0 30px);
  margin: h.rem(10px 0 5px);

  @include h.breakpoint-m {
    margin: 0;
  }
}

.headingInternational {
  position: relative;
  color: var(--grey-70);
  text-align: center;
  font-family: var(--primary-font);
  font-size: h.rem(33px);
  line-height: 1.5;
  margin: h.rem(0 32px);
}

.signUpButton {
  background: transparent;
  color: inherit;

  .sticky & {
    margin-top: h.rem(20px);
  }
}

.loginButton {
  color: var(--grey-70);

  /* Mada Bold/14-21 Mada Bold */
  font-family: var(--secondary-font);
  font-size: h.rem(14px);
  font-weight: var(--bold);
  line-height: 1.5;
  margin-top: h.rem(17px);

  button {
    appearance: none;
    background: none;
    border: none;
    font-weight: inherit;
    padding: 0;
    border-bottom: h.rem(2px) solid var(--accent-orange);
    color: inherit;
  }
}

.circleWrapper {
  opacity: 0.3;
  position: absolute;
  width: h.rem(320px);

  svg {
    width: 100%;
    aspect-ratio: 366 / 176;
  }

  &.circleLeft {
    left: 0;
    bottom: 0;
    transform: rotate(180deg) translateX(50%);
  }

  &.circleRight {
    top: 0;
    right: 0;
    transform: translateX(50%);
  }
}

.accountGate {
  margin: 0 auto;

  @include h.breakpoint-m {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 700px;

    .inner {
      display: flex;
      justify-content: center;
    }
  }

  .circleWrapper {
    width: h.rem(250px);

    @include h.breakpoint-m {
      width: h.rem(380px);
    }

    @include h.breakpoint-x {
      width: h.rem(480px);
    }

    &.circleRight {
      transform: translateX(50%);
    }
  }
}
