@use 'assets/styles/utils/helper' as h;

.msnbcDailyHeader {
  .scrollLink {
    display: block;
    position: absolute;
    content: ' ';
    margin-top: -190px;
    height: 190px;
    visibility: hidden;
    pointer-events: none;
  }

  // container styling
  position: relative;

  .inner {
    margin: 0 h.rem(20px) h.rem(12px);
    padding: h.rem(32px) 0 0;
    text-align: center;

    @include h.breakpoint-m {
      align-items: flex-end;
      border-bottom: h.rem(1px) solid var(--black);
      display: flex;
      flex-flow: row nowrap;
      margin: 0 h.$grid-col-width-tablet h.rem(28px);
      padding: h.rem(32px) 0 h.rem(20px);
      text-align: left;
    }

    @include h.breakpoint-l {
      margin: 0 h.rem(30px) h.rem(28px);
    }
  }

  .title {
    font-family: var(--founders-cond);
    font-size: h.rem(48px);
    font-weight: normal;
    line-height: 1;
    margin: 0 0 h.rem(8px) 0;
    text-transform: uppercase;
    color: var(--grey-70);

    @include h.breakpoint-m {
      margin: 0 h.rem(30px) 0 0;
    }

    @include h.breakpoint-x {
      margin-bottom: 2px;
    }

    a {
      transition: color 0.25s ease-in-out;

      &:hover {
        color: var(--blue-40);
        opacity: 1;
      }
    }
  }

  .description {
    font-family: var(--publico-hed);
    font-weight: normal;
    font-size: h.rem(14px);
    line-height: 1.5;

    @include h.breakpoint-m {
      flex-grow: 0;
      flex-shrink: 1;
      font-size: h.rem(24px);
      line-height: 1.25;
    }

    @include h.breakpoint-x {
      font-size: h.rem(32px);
    }
  }
}
