@use 'assets/styles/utils/helper' as h;

.entityHub {
  background-color: var(--neutral-10);
  border-top: 1px solid var(--grey-30);
  margin-bottom: var(--space-48);
  position: relative;
  padding: var(--space-48) var(--space-20);

  @include h.breakpoint-m {
    padding: var(--space-48) var(--space-40);
  }
}

.wrapper {
  margin: auto;
  max-width: 1160px;
}

.title {
  display: block;
  font-family: var(--founders-cond);
  font-size: var(--text-28);
  font-weight: var(--medium);
  line-height: var(--text-28);
  margin: 0;
  padding-bottom: var(--space-16);

  @include h.breakpoint-m {
    font-size: var(--text-32);
    line-height: var(--text-32);
  }
}

.border {
  background-image: linear-gradient(to right, var(--blue-40) 60px, var(--yellow-40) 58px, var(--yellow-40) 69px, var(--red-30) 59px, var(--red-30) 78px, transparent 4px);
  border-top: 1px solid var(--grey-40);
  content: ' ';
  display: block;
  height: var(--space-8);
  margin-bottom: var(--space-16);
  width: 100%;
}

.entities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-16) 0;

  @include h.breakpoint-m {
    grid-template-columns: repeat(3, 1fr);
  }

  @include h.breakpoint-l {
    grid-template-columns: repeat(4, 1fr);
  }

  @include h.breakpoint-x {
    grid-template-columns: repeat(5, 1fr);
  }

  &:not(.allRows) {
    @include h.breakpoint-s-only {
      :nth-child(n + 9) {
        display: none;
      }
    }

    @include h.breakpoint-m-only {
      :nth-child(n + 7) {
        display: none;
      }
    }

    @include h.breakpoint-l-only {
      :nth-child(n + 9) {
        display: none;
      }
    }

    @include h.breakpoint-x {
      :nth-child(n + 11) {
        display: none;
      }
    }
  }
}

.entity {
  margin: 0;

  @include h.breakpoint-s-only {
    &:nth-of-type(n + 3) {
      border-top: 1px solid var(--grey-40);
      padding: var(--space-16) 0 0 0;
    }
  }

  @include h.breakpoint-m-only {
    &:nth-child(n + 4) {
      border-top: 1px solid var(--grey-40);
      padding: var(--space-16) 0 0 0;
    }
  }

  @include h.breakpoint-l-only {
    &:nth-child(n + 5) {
      border-top: 1px solid var(--grey-40);
      padding: var(--space-16) 0 0 0;
    }
  }

  @include h.breakpoint-x {
    &:nth-child(n + 6) {
      border-top: 1px solid var(--grey-40);
      padding: var(--space-16) 0 0 0;
    }
  }
}

.entityHub .actionButton {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: var(--space-32) auto 0;
  max-width: 360px;
  padding: var(--space-24);
}
