@use 'assets/styles/utils/helper' as h;

.localNewsError {
  min-height: min-content;
  position: relative;
}

.text {
  font-family: var(--publico-txt);
  margin: var(--space-16) 0 var(--space-80);
}

.btn {
  margin: 0 0 var(--space-32);

  @include h.breakpoint-m {
    width: h.rem(340px);
  }
}
