@use 'assets/styles/utils/helper' as h;

.paidContentHeader {
  margin: 0 0 40px;
  position: relative;

  @include h.breakpoint-m {
    width: 300px;
  }
}

.header {
  border-bottom: 2px solid var(--grey-60);
}

.headline {
  font-family: var(--founders-cond);
  font-size: var(--text-18);
  color: #f6f6f6;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.icons {
  display: flex;
  margin-top: 16px;

  svg:first-child {
    margin-right: 20px;
  }

  div:nth-child(3) {
    margin-left: 20px;
  }
}

.line {
  height: 21px;
  border-right: 1px solid var(--grey-60);
  margin-top: 5px;
  opacity: 0.5;
}
