@use 'assets/styles/utils/helper' as h;
@import './sassVariables.module.scss';

.#{$packageOuterClass} {
  --msp-spacing: 20px;

  .pkg-bottom-border {
    margin: var(--package-spacing) 0 0;
  }
}
