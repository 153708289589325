@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--social-share-menu-container--bottom-margin);
  column-gap: var(--social-share-menu-container--column-gap);

  @media print {
    display: none;
  }
}

.link {
  line-height: 0;
}

.link,
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--social-share-menu-copy-tooltip-icon--color);

  &:hover {
    cursor: pointer;
  }
}

.shareThis {
  font-family: var(--founders-mono);
  font-size: h.rem(12px);
  line-height: h.rem(12px);
  text-transform: uppercase;
  letter-spacing: -0.5px;
  white-space: normal;
  color: var(--social-share-menu-share-this--text-color);
  margin-right: var(--social-share-menu-share-this--margin-right);

  @include h.breakpoint-m {
    white-space: nowrap;
  }

  :global(.LiveBlogCard) & {
    margin-right: h.rem(4px);
  }
}

.item {
  background-color: transparent;
  color: var(--social-share-menu-item-icon--color);

  .link,
  .button {
    border: var(--social-share-menu-item--border);
    background-color: var(--social-share-menu-item--background-color);
    transition: background-color 0.25s, border-color 0.25s;
    font-size: var(--social-share-menu-icon--font-size);
    border-radius: 50%;
    width: var(--social-share-menu-icon--width);
    height: var(--social-share-menu-icon--height);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--social-share-menu-item-hover--background-color);
        opacity: var(--social-share-menu-item-hover--opacity);
      }
    }
  }
}

.facebook {
  order: var(--social-share-menu-facebook--order);
}

.twitter {
  display: var(--social-share-menu-twitter--display);
  order: var(--social-share-menu-twitter--order);
}

.pinterest {
  display: var(--social-share-menu-pinterest--display);
  order: var(--social-share-menu-pinterest--order);
}

.copy {
  order: var(--social-share-menu-copy--order);
  position: relative;
  padding: 0;
}

.embed {
  order: var(--social-share-menu-embed--order);
  position: relative;
  padding: 0;
}

.sms.item {
  display: var(--social-share-menu-sms--display);
  order: var(--social-share-menu-sms--order);

  .link {
    font-size: var(--social-share-menu-sms-icon--font-size);
    padding-top: 2px;
  }
}

.print {
  display: flex;
  order: var(--social-share-menu-print--order);
  margin: var(--social-share-menu-print--margin);

  .button .icon {
    color: var(--social-share-menu-print-icon--color);
  }

  .text {
    display: var(--social-share-menu-print-text--display);
    font-family: var(--secondary-font);
    margin-left: 8px;
    font-size: var(--text-12);
    text-transform: uppercase;
  }
}

.save {
  display: var(--social-share-menu-save--display);
  order: var(--social-share-menu-save--order);
  border-left: var(--social-share-menu-save--border-left);
  padding: var(--social-share-menu-save--padding);
  height: var(--social-share-menu-save--height);

  &:hover {
    opacity: 1;
  }

  &:empty {
    display: none;
  }
}

.remove {
  border-left: var(--social-share-menu-remove--border-left);
  padding: var(--social-share-menu-remove--padding);
}

.copyTooltip,
.embedTooltip {
  align-items: center;
  color: var(--social-share-menu-copy-tooltip--color);
  background-color: var(--social-share-menu-copy-tooltip--background-color);
  padding: var(--social-share-menu-copy-tooltip-padding);
  display: flex;
  justify-content: center;
  line-height: 1.5;
  top: var(--social-share-menu-copy-tooltip--top);
  left: var(--social-share-menu-copy-tooltip--left);
  transform: var(--social-share-menu-copy-tooltip--transform);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  transition: visibility 200ms, opacity 200ms linear;
  white-space: nowrap;
  z-index: 2;

  &.alignRight {
    right: 0;
    left: unset;
  }

  .text {
    font-family: var(--social-share-menu-copy-tooltip--font-family);
    font-weight: var(--social-share-menu-copy-tooltip--font-weight);
    font-size: var(--social-share-menu-copy-tooltip--font-size);
    line-height: 1.5;
    letter-spacing: var(--social-share-menu-copy-tooltip--letter-spacing);
    padding: var(--social-share-menu-copy-tooltip-text--padding);
  }

  span:global(.icon) {
    color: var(--social-share-menu-copy-tooltip--color);
    font-size: var(--social-share-menu-copy-tooltip-icon--font-size);
    margin: var(--social-share-menu-copy-tooltip-icon--margin);
    opacity: var(--social-share-menu-copy-tooltip-icon--opacity);
  }

  &.success {
    opacity: 1;
    visibility: visible;
  }
}
