.promo3Card {
  --promo-headline-font: var(--publico-hed);
  --promo-headline-font-style: italic;
  --promo-headline-font-weight: var(--light);
  --promo-headline-color: var(--purple-70);
  --promo-card-dek-color: var(--grey-70);
  --promo-card-border-bottom: none;
  --promo-card--separator-border__top: 35px;
  --promo-card--separator-border__bottom: 0;

  :global(body.today) & {
    --promo-headline-font: var(--secondary-font);
    --promo-headline-font-style: normal;
    --promo-headline-font-weight: var(--normal);
    --promo-headline-color: var(--grey-70);
    --promo-card-dek-color: var(--grey-60);
    --promo-card-dek-font-family: var(--secondary-font);
    --promo-card-border-bottom: 1px var(--grey-40) solid;
    --promo-card--separator-border__top: 0;
    --promo-card--separator-border__bottom: 0;
  }
}
