@use 'assets/styles/utils/helper' as h;

.header {
  color: var(--grey-70);
  font-family: var(--founders-cond);
  font-size: h.rem(28px);
  font-weight: var(--semibold);
  line-height: var(--leading-100);
  margin-bottom: h.rem(13px);
  margin-top: h.rem(20px);

  @include h.breakpoint-m {
    font-size: h.rem(40px);
    margin-bottom: h.rem(21.5px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(48px);
  }
}

.map {
  margin: 0 auto;
  background-image: url('~assets/images/us_map.svg');
  background-repeat: no-repeat;
  width: h.rem(335px);
  height: h.rem(233px);
  display: flex;
  align-items: center;
  justify-content: center;

  @include h.breakpoint-m {
    width: h.rem(666px);
    height: h.rem(464px);
  }
}

.form {
  position: relative;
  margin-bottom: h.rem(30px);
  margin-right: h.rem(30px);

  @include h.breakpoint-m {
    margin-bottom: h.rem(70px);
    margin-right: h.rem(60px);
  }
}

.submitBtn {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: var(--white);
  cursor: pointer;
  font-family: var(--founders-cond);
  font-size: h.rem(14px);
  font-weight: var(--semibold);
  line-height: h.rem(40px);
  padding: 0;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: var(--default-hover-opacity);
  }

  @include h.breakpoint-m {
    font-size: h.rem(20px);
    line-height: h.rem(26px);
    top: h.rem(21px);
  }
}

.errorMsg {
  display: none;
}

.zipcodeInput {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--white);
  border-radius: 0;
  caret-color: rgba(255, 255, 255, 0.5);
  color: var(--white);
  font-family: var(--publico-txt);
  font-size: h.rem(16px);
  height: h.rem(40px);
  line-height: var(--leading-150);
  padding: 0;
  width: h.rem(189px);

  @include h.breakpoint-m {
    font-size: h.rem(22px);
    height: h.rem(67px);
    width: h.rem(280px);
  }

  &::placeholder {
    color: var(--white);
    font-family: var(--publico-txt);
    font-size: h.rem(16px);
    line-height: var(--leading-150);
    opacity: 0.5;
    transition: opacity 0.1s ease-in-out;

    @include h.breakpoint-m {
      font-size: h.rem(22px);
      line-height: h.rem(26px);
    }
  }

  &:focus {
    outline: none;

    &::placeholder {
      opacity: 0;
    }
  }

  &:invalid {
    border-bottom: 1px solid #ffb4b4;

    & + .errorMsg {
      display: inline-block;

      p {
        font-family: var(--publico-txt);
        font-weight: var(--normal);
        font-size: h.rem(11px);
        line-height: h.rem(26px);
        color: #ffb4b4;
        position: absolute;
        width: h.rem(158px);
        left: h.rem(3px);
        top: h.rem(43px);
        margin: 0;

        @include h.breakpoint-m {
          font-size: h.rem(16px);
          top: h.rem(80px);
        }
      }
    }

    & ~ .submitBtn:hover {
      opacity: 1;
    }
  }
}
