@use 'assets/styles/utils/helper' as h;

.container {
  --breaking-tag--height: 40px;
  --big-headline-tease-top-offset: 48px;
  --background-color: var(--default-color);
  --border-bottom: 4px solid var(--default-color);
  --dek--color: var(--white);
  --unibrow--color: var(--white);
  --updated--color: var(--white);
  --headline--color: var(--white);
  --headline--line-height: var(--leading-100);
  --headline--font-size: var(--text-32);

  @include h.breakpoint-m {
    --headline--font-size: var(--text-60);
  }

  @include h.breakpoint-x {
    --headline--font-size: var(--text-80);
  }

  &.hasTease {
    --headline--font-size: var(--text-32);

    @include h.breakpoint-l {
      --headline--font-size: var(--text-48);
    }
  }

  &.massive {
    --headline--font-size: var(--text-48);

    @include h.breakpoint-m {
      --headline--font-size: var(--text-80);
    }

    @include h.breakpoint-m {
      --headline--font-size: var(--text-96);
    }

    @include h.breakpoint-x {
      --headline--font-size: var(--text-128);
    }
  }

  &.hasTease.massive {
    --headline--font-size: var(--text-48);

    @include h.breakpoint-m {
      --headline--font-size: var(--text-60);
    }

    @include h.breakpoint-l {
      --headline--font-size: var(--text-80);
    }

    @include h.breakpoint-x {
      --headline--font-size: var(--text-96);
    }
  }

  :global(body.news) & {
    --background-color: var(--blue-70);
    --border-bottom: 4px solid var(--blue-40);
  }

  :global(body.today) & {
    --headline--line-height: var(--leading-125);

    @include h.breakpoint-l {
      --headline--line-height: var(--leading-100);
    }
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    --background-color: var(--blue-60);
    --border-bottom: 4px solid var(--blue-60);
    --unibrow--color: var(--white);
  }

  :global(body.entretenimiento) &,
  :global(body.shows) &,
  :global(body.telemundo) & {
    --background-color: var(--neutral-30);
    --border-bottom: 4px solid var(--red-40);
    --dek--color: var(--grey-60);
    --headline--color: var(--blue-60);
    --unibrow--color: var(--red-50);
    --updated--color: var(--red-50);
  }
}
