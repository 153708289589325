@use 'assets/styles/utils/helper' as h;

.schedule {
  border-top: 1px solid var(--grey-40);
  margin: 0 var(--space-20);
  position: relative;

  @include h.breakpoint-m {
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 80px;
    height: 8px;
    background-color: var(--default-color);
    top: 0;
  }

  &.minify {
    margin: 0;
  }
}

.header {
  border-top-color: var(--default-color);
  border-top-width: 80px;
  color: var(--grey-70);
  font-family: var(--default-headline-font-family);
  font-size: var(--text-24);
  font-weight: 600;
  line-height: var(--text-24);
  margin: var(--space-24) 0;
}

.list {
  margin: 0 5%;

  .minified & {
    margin: 0;
  }
}

.nextDay {
  border-bottom: 1px solid var(--grey-40);
  color: var(--grey-60);
  font-family: var(--default-headline-font-family);
  font-size: var(--text-18);
  line-height: var(--text-18);
  padding: 0 0 var(--space-12);
  text-align: center;
}
