@use 'assets/styles/utils/helper' as h;

.leadOneUp {
  --container--padding: 0;
  // Tease and info appear adjacent at all breakpoints
  --content--align-items: initial;
  --content--display: flex;
  --content--flex-wrap: wrap;
  --content--min-height: initial;
  --content--overflow: hidden;
  // Picture order changes between mobile and larger breakpoints
  --picture--flex: 1 0 100%;
  --picture--margin: 0;
  --picture--order: 1;
  --picture--z-index: 0;
  // Content tease order changes between mobile and larger breakpoints
  --info--margin: 0;
  --info--order: 2;
  --info--flex: 1 0 100%;
  --info--width: auto;
  --info--z-index: 1;
  // Pseudo element used for neutral-color content tease background
  --info--before--background-color: var(--default-neutral);
  --info--before--border: none;
  --info--before--bottom: initial;
  --info--before--content: '';
  --info--before--display: block;
  --info--before--height: calc(100% + 40px);
  --info--before--left: 40px;
  --info--before--min-height: initial;
  --info--before--position: absolute;
  --info--before--right: initial;
  --info--before--top: -20px;
  --info--before--width: calc(100% - 80px);
  --info--before--z-index: 0;
  // Pseudo element used for content tease top border
  --info--after--background-color: var(--default-color);
  --info--after--content: '';
  --info--after--display: block;
  --info--after--height: 1px;
  --info--after--left: 40px;
  --info--after--position: absolute;
  --info--after--top: 0;
  --info--after--width: 100%;
  --info--after--z-index: 2;
  // Content tease inner container
  --info--inner--background-color: transparent;
  --info--inner--padding: 0 60px 48px 20px;
  --info--inner--z-index: 1;
  // Unibrow positioning - used only by Think - Lead
  --unibrow--bottom: initial;
  --unibrow--left: initial;
  --unibrow--margin: var(--space-24) 0 var(--space-12) 0;
  --unibrow--position: relative;
  // Headline font and spacing
  --title--color: var(--default-headline-color);
  --title--font-size: var(--text-24);
  --title--font-weight: var(--normal);
  --title--margin: var(--space-12) 0 0;
  // Quote icon - used only by Think - Lead
  --quote--color: var(--default-color);
  --quote--display: none;
  --quote--font-size: var(--text-24);
  // Featured Author image - used only by Think - Lead
  --featured-author-image--display: none;

  @include h.breakpoint-m {
    --content--flex-wrap: nowrap;
    // Picture order changes between mobile and larger breakpoints
    --picture--flex: 1 0 395px;
    --picture--order: 2;
    --picture--z-index: 2;
    // Content tease order changes between mobile and larger breakpoints
    --info--flex: 1 0 300px;
    --info--order: 1;
    --info--z-index: 1;
    // Pseudo element used for neutral-color content tease background
    --info--before--bottom: 0;
    --info--before--height: calc(100% + 68px);
    --info--before--left: 30px;
    --info--before--top: 28px;
    --info--before--width: 100%;
    // Pseudo element used for content tease top border
    --info--after--left: 0;
    --info--after--top: 68px;
    // Content tease inner container
    --info--inner--padding: 68px 20px 48px 0;
    // Other, non-structural element changes
    --title--font-size: var(--text-32);
  }

  @include h.breakpoint-l {
    // Disable hiding overflow
    --content--overflow: visible;
    // Picture size change
    --picture--flex: 0 1 680px;
    // Content tease order changes between mobile and larger breakpoints
    --info--flex: 1 0 240px;
    // Pseudo element used for neutral-color content tease background
    --info--before--height: calc(100% + 40px);
    --info--before--top: 0;
    --info--before--right: 0;
    --info--before--width: 410px;
    // Pseudo element used for content tease top border
    --info--after--left: 0;
    --info--after--top: 40px;
    // Content tease inner container
    --info--inner--padding: 40px 20px 0 0;
    // Other, non-structural element changes
    --title--font-size: var(--text-28);

    &.railAdjacent {
      // Picture size change
      --picture--flex: 0 1 320px;
      // Content tease order changes between mobile and larger breakpoints
      --info--flex: 1 0 234px;
      // Pseudo element used for neutral-color content tease background
      --info--before--height: calc(100% - 30px);
      --info--before--top: 30px;
      // Pseudo element used for content tease top border
      --info--after--top: 70px;
      // Content tease inner container
      --info--inner--padding: 70px 20px 48px 0;
      // Other, non-structural element changes
      --title--font-size: var(--text-28);
    }
  }

  @include h.breakpoint-x {
    // Content tease constrained to content height
    --content--align-items: flex-start;
    // Picture size change
    --picture--flex: 0 1 860px;
    // Content tease order changes between mobile and larger breakpoints
    --info--flex: 1 0 300px;
    // Pseudo element used for neutral-color content tease background
    --info--before--bottom: 0;
    --info--before--height: calc(100% - 30px);
    --info--before--min-height: 320px;
    --info--before--right: unset;
    --info--before--top: 30px;
    // Pseudo element used for content tease top border
    --info--after--top: 70px;
    // Content tease inner container
    --info--inner--padding: 70px 20px 48px 0;
    // Other, non-structural element changes
    --title--font-size: var(--text-40);

    &.railAdjacent {
      // Picture size change
      --picture--flex: 0 1 460px;
      // Content tease order changes between mobile and larger breakpoints
      --info--flex: 1 0 300px;
      // Pseudo element used for neutral-color content tease background
      --info--before--bottom: 0;
      --info--before--height: calc(100% - 30px);
      --info--before--right: unset;
      // Content tease inner container
      --info--inner--padding: 70px 20px 48px 0;
      // Other, non-structural element changes
      --title--font-size: var(--text-40);
    }
  }

  // Today
  :global(body.today) & {
    --title--font-size: var(--text-20);
    --info--before--background-color: var(--white);

    @include h.breakpoint-m {
      --title--font-size: var(--text-24);

      &.railAdjacent {
        --title--font-size: var(--text-24);
      }
    }

    @include h.breakpoint-x {
      --title--font-size: var(--text-32);

      &.railAdjacent {
        --title--font-size: var(--text-32);
      }
    }
  }

  // Telemundo
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --info--before--background-color: var(--neutral-30);
    --title--color: var(--blue-60);
  }

  // Think
  :global(body.think) & {
    --title--font-size: var(--text-20);

    @include h.breakpoint-m {
      --title--font-size: var(--text-24);

      &.railAdjacent {
        --title--font-size: var(--text-24);
      }
    }

    @include h.breakpoint-x {
      --title--font-size: var(--text-32);

      &.railAdjacent {
        --title--font-size: var(--text-32);
      }
    }

    // Lead package has different treatment only on Think
    &.isLead {
      --container--padding: var(--space-12) 0 0;
      // Display full width solid color background behind tease
      --container--before--background-color: var(--default-color);
      --container--before--content: '';
      --container--before--display: block;
      --container--before--height: 350px;
      --container--before--left: 0;
      --container--before--position: absolute;
      --container--before--top: 0;
      --container--before--width: 100vw;
      --container--before--z-index: 0;
      // Content teases appears above image
      --content--align-items: initial;
      --content--display: block;
      --content--flex-wrap: unset;
      --content--overflow: unset;
      // Content teases appears above image
      --picture--flex: unset;
      --picture--z-index: 0;
      // Control position and size of contentt tease with margin and with
      --info--margin: -24px 0 0 70px;
      --info--flex: unset;
      --info--width: calc(100% - 70px);
      // Pseudo element changes to offset border
      --info--before--background-color: transparent;
      --info--before--border: 1px solid #e5e3dd;
      --info--before--bottom: 20px;
      --info--before--content: '';
      --info--before--height: auto;
      --info--before--left: -20px;
      --info--before--right: 20px;
      --info--before--top: -20px;
      --info--before--width: 100%;
      --info--before--z-index: 1;
      // Pseudo element previously used for top border no longer needed
      --info--after--content: none;
      --info--after--display: none;
      // Content tease inner container
      --info--inner--background-color: var(--neutral-10);
      --info--inner--padding: 24px 40px 148px 24px;
      --info--inner--z-index: 0;
      // Unibrow positioned at bottom of content tease
      --unibrow--bottom: 60px;
      --unibrow--left: 20px;
      --unibrow--margin: 0;
      --unibrow--position: absolute;
      // Headline spacing different for lead tease
      --title--margin: var(--space-24) 0 0;
      // Display elements unique to lead tease
      --quote--display: block;
      --featured-author-image--display: block;

      // Variations to size of tease image
      @include h.breakpoint-m {
        --container--padding: var(--space-40) 0 0;
        --container--before--left: -20px;
        --content--min-height: var(--picture--width);
        --picture--position: absolute;
        --picture--right: 0;
        --picture--top: 0;
        --picture--width: 440px;
        --picture--z-index: 0;
        --info--margin: 40px 0 0 20px;
        --info--width: 50%;
      }

      @include h.breakpoint-l {
        --container--before--left: calc(((100vw - 960px) / 2) * -1);
        --content--min-height: calc(var(--picture--width) / 2);
        --picture--margin: 0;
        --picture--width: 680px;
        --info--width: 340px;
        --title--font-size: var(--text-24);
      }

      @include h.breakpoint-x {
        --container--before--left: calc(((100vw - 1200px) / 2) * -1);
        --picture--width: 870px;
        --info--width: 340px;
      }
    }
  }
}
