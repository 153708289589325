@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.standard-layout {
  margin: 0 20px;

  @include h.breakpoint-m {
    margin: 0;
  }
}

// this selects the last vertical related
// in the side bar of relateds in the storyline
.standard-layout__adjacent-related > div:last-child > .related-content-tease {
  margin-bottom: 0;
}

.standard-layout__container-side {
  width: 100%;

  @include h.breakpoint-m {
    width: var(--side-related--m--screen--width);
  }

  @include h.breakpoint-l {
    width: var(--side--related--l--screen--width);
  }

  @include h.breakpoint-x {
    &.media-medium {
      width: var(--side--related--x--screen--medium--media--width);
    }

    &.media-large {
      width: var(--side--related--x--screen--large--media--width);
    }
  }
}

.standard-layout__main-content-container {
  display: flex;
  flex-direction: column-reverse;
  column-gap: var(--flex--column--gap);

  @include h.breakpoint-m {
    flex-direction: row;
  }
}

.standard-layout.important-story {
  .standard-layout__main-content-container {
    @include h.breakpoint-m {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  .important-headline-only-with-media {
    margin-bottom: 16px;
  }

  .headline-center {
    text-align: center;
  }

  .hidePositionStandard {
    display: none;

    @include h.breakpoint-s-only {
      display: block;
    }
  }

  .articleInfoContainerMoved {
    @include h.breakpoint-s-only {
      display: none;
    }
  }

  .hidePositionMoved {
    display: none;
  }
}

.standard-layout.standard-story {
  .container-side__text-content {
    margin-right: h.rem(20px);
    display: none;
  }

  @include h.breakpoint-m {
    .standard-layout__container-top {
      .standard-layout__dek,
      .standard-layout__author-timestamp-container {
        display: none;
      }
    }

    .container-side__text-content {
      display: block;

      .standard-layout__badge,
      .storyline__headline {
        display: none;
      }
    }

    .container-side__text-content.no-dek.no-author-timestamp {
      margin-right: 0;
    }
  }

  @include h.breakpoint-l {
    .standard-layout__container-top {
      display: none;
    }

    .container-side__text-content {
      .standard-layout__badge,
      .storyline__headline {
        display: block;
      }
    }

    .container-side__text-content.no-dek.no-author-timestamp {
      margin-right: h.rem(20px);
    }
  }
}

.standard-layout__bottom-related {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  column-gap: var(--flex--column--gap);

  @include h.breakpoint-m {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.standard-layout__author-timestamp-container {
  color: #2a2a2a;
  font-size: h.rem(12px);

  time {
    color: #555;
  }
}

.my-line {
  border: none;
  border-top: 1px solid var(--grey-40);
  height: 1px;
  margin: 0;

  @include h.breakpoint-m {
    border-top: 1px solid var(--grey-30);
  }
}

.standard-layout__bottom-related-card {
  @include h.breakpoint-m {
    width: var(--side--related--x--screen--medium--media--width);
  }

  &--media-align {
    @include h.breakpoint-m {
      width: var(--bottom--related--under--media--m--screen--width);
    }

    @include h.breakpoint-l {
      width: var(--bottom--related--under--media--l--screen--width);
    }

    @include h.breakpoint-x {
      width: var(--bottom--related--under--media--x--screen--width);
    }

    &.media-large {
      @include h.breakpoint-m {
        width: var(--bottom--related--under--large--media--m--screen--width);
      }

      @include h.breakpoint-l {
        width: var(--bottom--related--under--large--media--l--screen--width);
      }

      @include h.breakpoint-x {
        width: var(--bottom--related--under--large--media--x--screen--width);
      }
    }
  }

  &--side {
    flex-grow: 1;

    @include h.breakpoint-l {
      width: var(--bottom--side--related--l--screen--width);
    }

    @include h.breakpoint-x {
      &.media-medium {
        width: var(--side--related--x--screen--medium--media--width);
      }

      &.media-large {
        width: var(--side--related--x--screen--large--media--width);
      }
    }
  }

  &--no--media {
    width: 100%;

    @include h.breakpoint-m {
      width: var(--bottom-related--no--media--l--screen--width);
    }

    @include h.breakpoint-x {
      width: var(--bottom-related--no--media--x--screen--width);
    }
  }
}

.standard-layout__bottom-related-break-line {
  display: none;

  @include h.breakpoint-m {
    display: block;
  }
}

.standard-layout__bottom-horizontal-rule {
  border: none;
  border-top: 1px solid rgba(158, 158, 158, 1);
  height: 1px;
  margin-top: 8px;
  margin-bottom: 24px;
}

.standard-layout__bottom-related-margin > div {
  @include h.breakpoint-m {
    margin-bottom: 0;
  }
}
