@use 'assets/styles/utils/helper' as h;

.sportsHeader {
  margin-top: -25px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }
}

.newsLogo {
  width: 214.83px;
  height: 21.36px;

  @include h.breakpoint-s-only {
    width: 112.88px;
    height: 11.23px;
  }
}

.sportsLogo {
  width: 157.56px;
  height: 42.73px;

  @include h.breakpoint-s-only {
    width: 88px;
    height: 24px;
  }
}

.plusIcon {
  padding-left: 8px;
  padding-right: 8px;

  @include h.breakpoint-s-only {
    width: 5%;
  }

  @include h.breakpoint-m {
    width: 3%;
  }

  @include h.breakpoint-l {
    width: 4%;
  }

  @include h.breakpoint-x {
    width: 3%;
  }

  @media (min-width: 561px) and (max-width: 800px) {
    width: 5%;
  }

  @media (max-width: 560px) {
    width: 7%;
  }
}
