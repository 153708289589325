@use 'assets/styles/utils/helper' as h;

.smorgasbord-small-tease {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-16);
  border-top: 1px solid var(--grey-50);
  padding-top: var(--space-12);

  &__related-content-wrapper {
    margin-top: var(--space-8);
    padding-top: h.rem(7px);
    display: flex;
    position: relative;

    &::after {
      content: '';
      width: 50%;
      border-top: 1px solid var(--grey-50);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__main-content-wrapper {
    display: flex;
  }
}
