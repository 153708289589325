@use 'assets/styles/utils/helper' as h;

.loading {
  width: 100%;
  animation: fadeIn 1s infinite alternate;

  @include h.breakpoint-m {
    width: 90%;
  }

  @include h.breakpoint-l {
    width: 80%;
  }

  > div {
    div {
      height: h.rem(14px);
      background: var(--grey-30);

      @include h.breakpoint-m {
        height: h.rem(40px);
      }
    }

    div:first-child {
      width: 100%;
      margin-top: h.rem(24px);
      margin-bottom: h.rem(8px);

      @include h.breakpoint-m {
        margin-top: h.rem(40px);
      }
    }

    div:last-child {
      width: 96%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0.5;
    }

    to {
      opacity: 1;
    }
  }
}
