@use 'assets/styles/utils/helper' as h;

.ad--midresponsive {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  clear: both;
  text-align: center;

  &.ad--loaded,
  &.ad--preview {
    margin-bottom: h.rem(60px);

    @include h.breakpoint-l {
      margin-bottom: h.rem(80px);
    }
  }

  // Fallback if loaded class not applied on ad load
  &:not(.ad--loaded) {
    div[data-mps-slot^='midresponsive'] {
      &:not([style*='display: none']) {
        margin-bottom: h.rem(60px);

        @include h.breakpoint-l {
          margin-bottom: h.rem(80px);
        }
      }
    }
  }
}
