.standardLead {
  --standard-lead--info--background-color: var(--default-neutral);
  --standard-lead--info-before--background-color: var(--default-color);
  --standard-lead--title--font-family: var(--default-headline-font-family);
  --standard-lead--title--color: var(--grey-70);
}

:global(body.today) .standardLead {
  --standard-lead--title--color: var(--purple-70);
}

:global(body.noticias) .standardLead,
:global(body.deportes) .standardLead,
:global(body.telemundo) .standardLead,
:global(body.entretenimiento) .standardLead,
:global(body.shows) .standardLead {
  --standard-lead--info--background-color: var(--white);
}

:global(body.telemundo) .standardLead,
:global(body.entretenimiento) .standardLead,
:global(body.shows) .standardLead {
  --standard-lead--title--color: var(--blue-60);
}
