@use 'assets/styles/utils/helper' as h;

.show-lead {
  --sl-wrapper-bg: var(--black);
  --sl-meta-data-color: var(--white);
  --sl-show-name-font: var(--default-headline-font-family);
  --sl-show-name-font-size: #{h.rem(25px)};
  --sl-show-name-line-height: 1em;
  --sl-show-name-title-underline-bg-color: transparent;
  --sl-air-time-font-size: #{h.rem(16px)};
  --sl-air-time-font-family: var(--default-headline-font-family);
  --sl-air-time-font-weight: var(--bold);
  --sl-air-time-line-height: var(--leading-100);
  --sl-air-time-margin-top: #{h.rem(24px)};
  --sl-link-list-wrapper-title-font: var(--default-headline-font-family);
  --sl-link-list-wrapper-display: block;
  --sl-link-list-item-font-style: inherit;
  --sl-link-list-item-font-weight: var(--normal);
  --sl-link-list-item-font-size: #{h.rem(16px)};
  --sl-link-list-item-text-transform: none;
  --sl-link-list--item-hover-opacity: var(--default-hover-opacity);
  --sl-link-list--item-hover-color: inherit;
  --sl-meta-data-justify-content: flex-end;
  --sl-meta-data-align-items: flex-start;
  --sl-content-justify: flex-start;
  --sl-content-height: #{h.rem(204px)};
  --sl-content-padding-top: var(--navbar-mobile-height);

  @include h.breakpoint-m {
    --sl-show-name-font-size: #{h.rem(55px)};
    --sl-show-name-line-height: #{h.rem(50px)};
    --sl-air-time-font-size: #{h.rem(20px)};
    --sl-link-list-item-font-size: #{h.rem(18px)};
    --sl-content-padding-top: var(--navbar-default-height);
    --sl-content-height: #{h.rem(316px)};
  }

  body.msnbc & {
    --sl-show-name-font-size: #{h.rem(40px)};
    --sl-air-time-font-family: var(--publico-txt);
    --sl-air-time-font-size: #{h.rem(14px)};
    --sl-air-time-line-height: 1.25rem;
    --sl-air-time-font-weight: var(--normal);

    @include h.breakpoint-m {
      --sl-show-name-font-size: #{h.rem(55px)};
      --sl-air-time-font-size: #{h.rem(16px)};
    }
  }

  body.telemundo &,
  body.entretenimiento &,
  body.shows & {
    --sl-wrapper-bg: var(--blue-70);
    --sl-air-time-font-weight: var(--semibold);
    --sl-link-list-item-font-weight: var(--semibold);
    --sl-link-list-item-text-transform: uppercase;
  }

  body.news & {
    --sl-air-time-font-family: var(--publico-txt);
    --sl-air-time-font-size: #{h.rem(14px)};
    --sl-air-time-line-height: 1.25rem;
    --sl-air-time-font-weight: var(--normal);

    @include h.breakpoint-m {
      --sl-air-time-font-size: #{h.rem(16px)};
    }
  }

  body.today & {
    --sl-wrapper-bg: var(--white);
    --sl-show-name-font: var(--primary-font);
    --sl-meta-data-justify-content: center;
    --sl-meta-data-align-items: center;
    --sl-meta-data-color: var(--grey-70);
    --sl-content-justify: center;
    --sl-link-list-wrapper-title-font: var(--secondary-font);
    --sl-link-list-wrapper-display: none;
    --sl-link-list-item-font-weight: 700;
    --sl-link-list-item-font-style: normal;
    --sl-air-time-font-family: var(--secondary-font);
    --sl-air-time-font-weight: 400;
    --sl-air-time-margin-top: #{h.rem(32px)};
    --sl-show-name-font-size: #{h.rem(43px)};
    --sl-show-name-title-underline-bg-color: var(--grey-20);
    --sl-show-name-title-underline-font-size: #{h.rem(42px)};
    --sl-show-name-title-underline-line-height: 1;
    --sl-link-list--item-hover-opacity: 100;
    --sl-link-list--item-hover-color: var(--orange-30);
    --sl-link-list--item-hover-text-decoration-line: underline;
    --sl-link-list--item-hover-text-decoration-offset: #{h.rem(7px)};
    --sl-meta-data--padding-top: #{h.rem(10px)};
    --sl-content-padding-top: 0;
    --sl-content-height: #{h.rem(176px)};

    @include h.breakpoint-m {
      --sl-link-list-item-font-size: #{h.rem(24px)};
      --sl-air-time-font-size: #{h.rem(24px)};
      --sl-meta-data--padding-top: #{h.rem(28px)};
      --sl-show-name-title-underline-line-height: 2;
      --sl-content-padding-top: #{h.rem(20px)};
      --sl-content-height: #{h.rem(256px)};
    }
  }
}
