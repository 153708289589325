@use 'assets/styles/utils/helper' as h;

$slideshow-outer-gutter: 20px !default;

:root {
  --tease-card-slideshow-line-height: 100%;
  --three-up-articles-background-color: var(--white);
  --tease-card-slideshow-no-main-image-title-line-height: 100%;
}

$tease-card-slideshow-featured-author-line-heights: (
  s: h.rem(25px),
  m: h.rem(30px), l: h.rem(25px), ) !default;

$tease-card-slideshow-last-child-featured-author-line-heights: (
  l: h.rem(30px),
  x: h.rem(25px),
) !default;

$tease-card--with-paid-content-slideshow-featured-author-line-heights: (
  s: h.rem(25px),
  m: h.rem(30px),
  l: h.rem(25px),
) !default;

$tease-card-with-paid-content-slideshow-title-line-heights: (
  s: h.rem(25px),
  m: h.rem(30px),
  l: h.rem(25px),
) !default;

.today {
  --three-up-articles-background-color: var(--neutral-10);
  --tease-card-slideshow-line-height: 125%;
  --tease-card-slideshow-no-main-image-title-line-height: 125%;
}

.think {
  --tease-card-slideshow-line-height: 125%;
  --tease-card-slideshow-no-main-image-title-line-height: 125%;
}

.select {
  --tease-card-slideshow-line-height: 120%;
}
