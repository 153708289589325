.pageArrowButton {
  background-color: var(--white);
  padding: 0 9px;
  cursor: pointer;
  height: 100%;
  outline: none;
  align-self: stretch;
  color: var(--blue-40);
  border: 0 solid var(--grey-40);

  span {
    display: block;
  }

  &:hover {
    background-color: var(--blue-40);
    color: var(--white);
  }
}

.rightBorder {
  border-right-width: 1px;
  margin-right: 12px;
}

.leftBorder {
  border-left-width: 1px;
  margin-left: 12px;
}
