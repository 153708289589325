@use 'assets/styles/utils/helper' as h;

.news-tease__container {
  --news-tease-unibrow-color: var(--default-color);
  --news-tease-headline-color: var(--grey-70);
  --news-tease-headline-font-family: var(--founders-cond);
  --news-tease-headline-font-size: #{h.rem(24px)};
  --news-tease-headline-line-height: #{h.rem(25px)};
  --news-tease-source-text-color: var(--default-color);
  --news-tease-source-font-family: var(--founders-cond);
  --news-tease-source-font-size: #{h.rem(11px)};
  --news-tease-source-line-height: #{h.rem(15px)};
  --news-tease-description-text-color: var(--grey-60);
  --news-tease-description-font-family: var(--publico-txt);
  --news-tease-description-font-size: #{h.rem(14px)};
  --news-tease-description-line-height: #{h.rem(22px)};

  body.think & {
    --news-tease-headline-font-family: var(--publico-hed);
    --news-tease-headline-font-size: #{h.rem(18px)};
  }

  body.today & {
    --news-tease-unibrow-color: var(--purple-70);
    --news-tease-headline-color: var(--purple-70);
    --news-tease-headline-font-family: var(--publico-hed);
    --news-tease-headline-font-size: #{h.rem(18px)};
    --news-tease-source-text-color: var(--purple-70);
    --news-tease-source-font-family: var(--founders-mono);
  }

  body.msnbc & {
    --news-tease-source-text-color: var(--blue-70);
  }

  body.noticias & {
    --news-tease-description-line-height: #{h.rem(21px)};
  }

  body.deportes & {
    --news-tease-description-line-height: #{h.rem(21px)};
  }

  body.entretenimiento & {
    --news-tease-description-line-height: #{h.rem(21px)};
  }
}
