@use 'assets/styles/utils/helper' as h;

.navigation {
  --navigation--border-color: rgba(var(--white-rgb), 0.3);
  --navigation--font-family: var(--founders-cond);
  --navigation--font-size: var(--text-16);
  --navigation--font-style: normal;
  --item--border-color: var(--grey-20);
  --item--header--color: var(--white);
  --item--rail--color: var(--default-color);

  @include h.breakpoint-m {
    --navigation--font-size: var(--text-24);
  }
}

:global(body.news) .navigation {
  --item--border-color: rgba(0, 0, 0, 0.2);
  --item--rail--color: var(--blue-70);
}

:global(body.today) .navigation {
  --navigation--border-color: rgba(var(--purple-70-rgb), 0.2);
  --navigation--font-style: italic;
  --navigation--font-family: var(--publico-hed);
  --item--header--color: var(--default-color);
  --item--border-color: rgba(var(--purple-70-rgb), 0.2);
}

:global(body.noticias) .navigation {
  --item--rail--color: var(--black);
}

:global(body.telemundo) .navigation,
:global(body.entretenimiento) .navigation,
:global(body.shows) .navigation {
  --navigation--border-color: rgba(var(--blue-60-rgb), 0.2);
  --item--border-color: rgba(var(--blue-60-rgb), 0.2);
}
