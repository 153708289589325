@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.pkg-sponsor {
  color: var(--pkg-sponsor--color);
  font-family: var(--pkg-sponsor--font-family);
  margin: var(--pkg-sponsor--margin);
}

.pkg-sponsor__label {
  display: block;
  margin: var(--pkg-sponsor-label--margin);
  line-height: var(--pkg-sponsor-label--line-height);
}

.pkg-sponsor__link {
  display: block;
}

.pkg-sponsor__logo {
  width: var(--pkg-sponsor-logo--width);
  height: var(--pkg-sponsor-logo--height);
}
