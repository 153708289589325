@use 'assets/styles/utils/helper' as h;

.live-blog {
  // Button
  --live-blog-package-button-color: var(--default-color);
  --live-blog-package-button-border-color: var(--default-color);
  --live-blog-package-button-wrapper-padding-top: var(--space-20);
  --live-blog-package-button-justify-content: center;

  // Package Top Decoration
  --live-blog-package-top-decoration-border-color: var(--grey-40);
  --live-blog-package-top-decoration-tab-color: var(--default-color);
  --live-blog-package-top-decoration-tab-width: var(--space-80);
  --live-blog-package-top-decoration-tab-height: var(--space-8);
  --live-blog-package-top-decoration-tab-top: 0;

  // Card list wrapper
  --live-blog-package-body-decoration-color: var(--default-color);
  --live-blog-package-body-decoration-offset: var(--space-80);
  --live-blog-package-body-decoration-offset-m: var(--space-100);

  body.today & {
    // Package Top Decoration
    --live-blog-package-top-decoration-border-color: transparent;
    --live-blog-package-top-decoration-tab-width: var(--space-32);
    --live-blog-package-top-decoration-tab-height: var(--space-1);
    --live-blog-package-top-decoration-tab-top: -1px; // cover transparent border which prevents margin collapse
    --live-blog-package-button-wrapper-padding-top: 0;
    --live-blog-package-button-justify-content: left;
  }

  body.globalcitizen & {
    // Button
    --live-blog-package-button-color: var(--red-40);
    --live-blog-package-button-border-color: var(--red-40);
  }
}
