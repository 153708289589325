@use 'assets/styles/utils/helper' as h;

.storyline-media {
  // small media
  --media--small--m--width: 220px;
  --media--small--m--height: 147px;
  --media--small--video--m--width: 220px;

  // medium media
  --media--medium--m--height: 275px;
  --media--medium--m--width: 412px;
  --media--medium--l--height: 214px;
  --media--medium--l--width: 321px;
  --media--medium--x--height: 280px;
  --media--medium--x--width: 420px;
  --media--medium--video--m--width: 412px;
  --media--medium--video--l--width: 321px;
  --media--medium--video--x--width: 420px;

  // media large
  --media--large--m--height: 275px;
  --media--large--m--width: 412px;
  --media--large--l--height: 214px;
  --media--large--l--width: 321px;
  --media--large--x--height: 307px;
  --media--large--x--width: 460px;
  --media--large--video--m--width: 412px;
  --media--large--video--l--width: 321px;
  --media--large--video--x--width: 460px;

  // live blog layout
  --playmaker--tease--margin--bottom: -16px;
  --playmaker--tease--margin--m--bottom: 0;

  // dataviz
  --data-viz--height: 100%;
}
