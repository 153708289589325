@use 'assets/styles/utils/helper' as h;

.container {
  --social-share-menu-container--column-gap: #{h.rem(5px)};
  --social-share-menu-container--bottom-margin: 0;
  --social-share-menu-icon--font-size: #{h.rem(12px)};
  --social-share-menu-icon--width: #{h.rem(46px)};
  --social-share-menu-icon--height: #{h.rem(40px)};
  --social-share-menu-sms-icon--font-size: #{h.rem(13px)};
  --social-share-menu-item--border: none;
  --social-share-menu-item-hover--background-color: var(--accent-green);
  --social-share-menu-item-hover--opacity: 1;
  --social-share-menu-pinterest--display: flex;
  --social-share-menu-sms--display: flex;
  --social-share-menu-print--display: flex;
  --social-share-menu-save--display: none;
  --social-share-menu-save--padding: #{h.rem(0 0 0 8px)};
  --social-share-menu-item-icon--color: var(--grey-70);
  --social-share-menu-item--background-color: none;
  --social-share-menu-copy-tooltip--top: #{h.rem(38px)};
  --social-share-menu-copy-tooltip--left: 0;
  --social-share-menu-copy-tooltip--transform: translateX(0);
  --social-share-menu-copy-tooltip--color: var(--white);
  --social-share-menu-copy-tooltip--background-color: #399e5a;
  --social-share-menu-copy-tooltip-padding: 0 #{h.rem(5px)} #{h.rem(1px)} 0;
  --social-share-menu-copy-tooltip-icon--margin: 0;
  --social-share-menu-copy-tooltip--font-family: var(--founders-mono);
  --social-share-menu-copy-tooltip--font-weight: 400;
  --social-share-menu-copy-tooltip--font-size: #{h.rem(14px)};
  --social-share-menu-copy-tooltip--letter-spacing: -0.04em;
  --social-share-menu-print--margin: 0 0 0 #{h.rem(16px)};
  --social-share-menu-print-text--display: block;
  --social-share-menu-print-icon--color: var(--black);
  --social-share-menu-copy-tooltip-text--padding: 0;
  --social-share-menu-copy-tooltip-icon--opacity: 1;
  --social-share-menu-copy-tooltip-icon--font-size: #{h.rem(20px)};
  --social-share-menu-copy-tooltip-icon--color: inherit;
  --social-share-menu-share-this--text-color: none;

  @include h.breakpoint-m {
    --social-share-menu-sms--display: none;
    --social-share-menu-icon--width: #{h.rem(57px)};
  }

  :global(body.news) & {
    --social-share-menu-container--column-gap: #{h.rem(20px)};
    --social-share-menu-icon--font-size: #{h.rem(16px)};
    --social-share-menu-icon--width: #{h.rem(16px)};
    --social-share-menu-icon--height: #{h.rem(16px)};
    --social-share-menu-item--border: none;
    --social-share-menu-sms-icon--font-size: #{h.rem(15px)};
    --social-share-menu-item-icon--color: #2a2a2a;
    --social-share-menu-item--background-color: none;
    --social-share-menu-item-hover--background-color: none;
    --social-share-menu-item-hover--opacity: 0.7;
    --social-share-menu-pinterest--display: none;
    --social-share-menu-print--display: none;
    --social-share-menu-save--display: flex;
    --social-share-menu-save--border-left: #{h.rem(1.5px)} solid #ccc;
    --social-share-menu-save--padding: #{h.rem(0 12px 0 8px)};
    --social-share-menu-copy-tooltip-padding: 0 #{h.rem(5px)} #{h.rem(1px)} 0;
    --social-share-menu-copy-tooltip--top: #{h.rem(30px)};
    --social-share-menu-copy-tooltip--left: 0;
    --social-share-menu-copy-tooltip--transform: translateX(0);
    --social-share-menu-copy-tooltip-icon--margin: 0;
    --social-share-menu-copy-tooltip--font-family: var(--founders-mono);
    --social-share-menu-copy-tooltip--font-size: #{h.rem(14px)};
    --social-share-menu-copy-tooltip--font-weight: 400;
    --social-share-menu-copy-tooltip--letter-spacing: -0.04em;
    --social-share-menu-copy-tooltip-text--padding: #{h.rem(4px 0 0 0)};
    --social-share-menu-copy-tooltip-icon--opacity: 0.85;
    --social-share-menu-copy-tooltip-icon--font-size: #{h.rem(24px)};
    --social-share-menu-remove--padding: #{h.rem(0 0 0 21px)};
    --social-share-menu-remove--border-left: #{h.rem(1.5px)} solid var(--grey-50);

    @include h.breakpoint-m {
      --social-share-menu-icon--font-size: #{h.rem(20px)};
      --social-share-menu-icon--width: #{h.rem(20px)};
      --social-share-menu-icon--height: #{h.rem(20px)};
    }
  }

  :global(body.videoPage) {
    --social-share-menu-copy-tooltip--color: var(--black);
    --social-share-menu-copy-tooltip--background-color: var(--white);
  }

  :global(body.msnbc) & {
    --social-share-menu-icon--font-size: #{h.rem(24px)};
    --social-share-menu-copy-tooltip-icon--color: var(--grey-50);
    --social-share-menu-pinterest--display: none;
    --social-share-menu-share-this--text-color: var(--grey-50);

    @include h.breakpoint-m {
      --social-share-menu-icon--width: #{h.rem(40px)};
      --social-share-menu-icon--height: #{h.rem(40px)};
    }
  }

  :global(body.noticias) & {
    --social-share-menu-icon--font-size: #{h.rem(24px)};
    --social-share-menu-copy-tooltip-icon--color: var(--grey-50);
    --social-share-menu-pinterest--display: none;
    --social-share-menu-share-this--text-color: var(--grey-50);
    --social-share-menu-icon--width: #{h.rem(45px)};
    --social-share-menu-icon--height: #{h.rem(40px)};
  }

  :global(body.today) & {
    --social-share-menu-print-text--display: none;
    --social-share-menu-save--display: flex;
    --social-share-menu-container--column-gap: #{h.rem(21.6px)};
    --social-share-menu-icon--font-size: #{h.rem(20px)};
    --social-share-menu-icon--width: #{h.rem(20px)};
    --social-share-menu-icon--height: #{h.rem(20px)};
    --social-share-menu-item--border: none;
    --social-share-menu-item--background-color: none;
    --social-share-menu-sms-icon--font-size: #{h.rem(19px)};
    --social-share-menu-item-hover--background-color: none;
    --social-share-menu-item-hover--opacity: 0.7;
    --social-share-menu-item-icon--color: var(--secondary-color);
    --social-share-menu-print-icon--color: var(--secondary-color);
    --social-share-menu-facebook--order: -2;
    --social-share-menu-pinterest--order: -1;
    --social-share-menu-save--border-left: #{h.rem(1px)} solid var(--grey-50);
    --social-share-menu-save--height: #{h.rem(40px)};
    --social-share-menu-copy-tooltip--background-color: var(--secondary-color);
    --social-share-menu-copy-tooltip--color: var(--white);
    --social-share-menu-copy-tooltip--top: calc(100% + var(--space-8));
    --social-share-menu-print--margin: 0;
    --social-share-menu-share-this--display: none;
    --social-share-menu-copy-tooltip--font-family: var(--secondary-font);
    --social-share-menu-copy-tooltip-padding: #{h.rem(9px)} #{h.rem(20px)};
    --social-share-menu-copy-tooltip--left: 50%;
    --social-share-menu-copy-tooltip--transform: translateX(-50%);
    --social-share-menu-copy-tooltip--font-size: #{h.rem(16px)};
    --social-share-menu-copy-tooltip--letter-spacing: 0.06em;
    --social-share-menu-copy-tooltip-icon--margin: #{h.rem(0 14px 0 4px)};

    @include h.breakpoint-m {
      --social-share-menu-copy-tooltip--left: #{h.rem(-30px)};
    }
  }

  :global(body.news.videoPage) & {
    --social-share-menu-save--border-left: #{h.rem(1.5px)} solid #555;
    --social-share-menu-item-icon--color: #999;
  }

  :global(body.select.liveBlogPage) & {
    --social-share-menu-item-icon--color: var(--grey-50);
    --social-share-menu-icon--font-size: #{h.rem(24px)};
    --social-share-menu-icon--width: #{h.rem(45px)};
    --social-share-menu-share-this--text-color: #555;
    --social-share-menu-sms-icon--font-size: #{h.rem(25px)};
    --social-share-menu-share-this--margin-right: 0;

    @include h.breakpoint-m {
      --social-share-menu-share-this--margin-right: #{h.rem(11px)};
    }
  }

  :global(body.msnbc.liveBlogPage) & {
    --social-share-menu-copy-tooltip-icon--color: var(--grey-60);
    --social-share-menu-sms-icon--font-size: #{h.rem(26px)};
    --social-share-menu-icon--width: #{h.rem(41.6px)};
  }

  :global(body.noticias.liveBlogPage) &,
  :global(body.telemundo.liveBlogPage) &,
  :global(body.entretenimiento.liveBlogPage) &,
  :global(body.deportes.liveBlogPage) &,
  :global(body.shows.liveBlogPage) & {
    --social-share-menu-share-this--margin-right: #{h.rem(15px)};
    --social-share-menu-share-this--text-color: var(--grey-50);
    --social-share-menu-sms-icon--font-size: #{h.rem(20px)};
    --social-share-menu-icon--font-size: #{h.rem(24px)};
    --social-share-menu-icon--width: #{h.rem(44px)};
    --social-share-menu-item-icon--color: var(--grey-60);
  }

  :global(body.entretenimiento.liveBlogPage) &,
  :global(body.shows.liveBlogPage) & {
    --social-share-menu-share-this--text-color: var(--default-color);
  }

  :global(body.shows.videoPage) &,
  :global(body.noticias.videoPage) &,
  :global(body.entretenimiento.videoPage) &,
  :global(body.telemundo.videoPage) &,
  :global(body.deportes.videoPage) & {
    --social-share-menu-save--border-left: #{h.rem(1.5px)} solid #555;
    --social-share-menu-item-icon--color: var(--default-color);
    --social-share-menu-share-this--text-color: var(--default-color);
    --social-share-menu-icon--font-size: #{h.rem(24px)};
    --social-share-menu-icon--width: #{h.rem(44px)};
    --social-share-menu-icon--height: #{h.rem(44px)};

    @include h.breakpoint-m {
      --social-share-menu-icon--width: #{h.rem(50px)};
      --social-share-menu-icon--height: #{h.rem(50px)};
    }
  }

  :global(body.deportes.videoPage) &,
  :global(body.noticias.videoPage) & {
    --social-share-menu-share-this--text-color: var(--grey-50);
    --social-share-menu-item-icon--color: var(--grey-50);
  }

  :global(body.today.videoPage) & {
    --social-share-menu-item-icon--color: var(--secondary-30);
    --social-share-menu-copy-tooltip--background-color: var(--secondary-30);
    --social-share-menu-pinterest--display: none;
  }

  :global(body.today.articlePage) &,
  :global(body.today.liveBlogPage) &,
  :global(body.today.recipePage) & {
    --social-share-menu-facebook--order: -2;
    --social-share-menu-pinterest--order: -1;
  }
}
