@use 'assets/styles/utils/helper' as h;

.pancake {
  --pancake--background-color: var(--white);
  --pkg-border-bottom: none;

  // Package title
  --pkg-title--border-bottom: none;
  --pkg-title--color: var(--default-color);
  --pkg-title--font-family: var(--publico-txt);
  --pkg-title--font-size: var(--text-16);
  --pkg-title--font-style: none;
  --pkg-title--font-weight: bold;
  --pkg-title--margin: 0 0 12px 0;
  --pkg-title--padding: 0 0 10px 0;
  --pkg-title--text-transform: none;

  // Package title decoration
  --pkg-title--before--content: none;
  --pkg-title--before--left: -20px;
  --pkg-title--before--top: 12px;
  --pkg-title--before--width: 30px;

  // Tease
  --tease--border: solid 1px var(--grey-40);

  // Unibrow
  --unibrow--color: var(--default-color);

  // Headline
  --headline--color: var(--default-headline-color);
  --headline--font-family: var(--default-headline-font-family);
  --headline--font-weight: var(--normal);
  --headline--line-height: var(--default-headline-leading);

  :global(body.news) & {
    --pkg-border-bottom: 1px solid var(--grey-50);
  }

  :global(body.msnbc) & {
    --pkg-border-bottom: 1px solid var(--grey-40);
  }

  :global(body.globalcitizen) & {
    --pkg-title--color: var(--red-40);
    --unibrow--color: var(--red-40);
  }

  :global(body.think) & {
    --headline--font-family: var(--founders-cond);
    --headline--line-height: var(--leading-100);
  }

  :global(body.today) & {
    --pancake--background-color: var(--neutral-10);
    --pkg-title--font-family: var(--publico-hed);
    --pkg-title--font-size: var(--text-24);
    --pkg-title--font-style: italic;
    --pkg-title--font-weight: var(--light);
    --pkg-title--margin: 0 0 var(--space-24) 0;
    --pkg-title--padding: 0 0 0 var(--space-20);
    --pkg-title--before--content: '';
    --tease--border: solid 1px var(--grey-30);
    --headline--font-weight: var(--light);

    @include h.breakpoint-m {
      --pkg-title--margin: 0 0 var(--space-24) var(--space-60);
      --pkg-title--padding: 0 0 0 var(--space-16);
      --pkg-title--before--left: -60px;
      --pkg-title--before--top: 12px;
      --pkg-title--before--width: 60px;
    }

    @include h.breakpoint-x {
      --pkg-title--font-size: var(--text-32);
      --pkg-title--before--top: 16px;
    }
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    --pkg-border-bottom: 1px solid var(--grey-40);
    --pkg-title--border-bottom: 1px solid var(--grey-40);
  }

  :global(body.telemundo) &,
  :global(body.shows) &,
  :global(body.entretenimiento) & {
    --pkg-border-bottom: 1px solid var(--grey-40);
    --pkg-title--color: var(--blue-60);
    --pkg-title--font-family: var(--founders-cond);
    --pkg-title--font-size: var(--text-28);
    --pkg-title--font-weight: var(--semibold);
    --pkg-title--margin: 0 0 var(--space-24) 0;
    --pkg-title--padding: 0 0 0 var(--space-20);
    --pkg-title--text-transform: uppercase;
    --pkg-title--before--content: '';
    --pkg-title--before--top: var(--space-16);
    --headline--color: var(--blue-60);

    @include h.breakpoint-m {
      --pkg-title--font-size: var(--text-32);
      --pkg-title--margin: 0 0 var(--space-24) var(--space-60);
      --pkg-title--padding: 0 0 0 var(--space-16);
      --pkg-title--before--left: -60px;
      --pkg-title--before--width: 60px;
    }
  }
}
