@use 'assets/styles/utils/helper' as h;

.videoPkg {
  --vpkg--package--margin-right: auto;
  --vpkg--package--margin-left: auto;
  --vpkg--package-title--display: flex;
  --vpkg--package-title--background-color: var(--default-color);
  --vpkg--package-title--color: var(--white);
  --vpkg--package-title--margin-left: calc(20px + 2vw + (8.33333% - 2.16667vw));
  --vpkg--package-title--width: auto;
  --vpkg--package-title--padding: #{h.rem(32px)};
  --vpkg--package-title--align-self: stretch;
  --vpkg--package-title--min-height: 0;
  --vpkg--columnVideo--flex-basis: auto;
  --vpkg--columnVideo--margin-right: 0;
  --vpkg--columnVideo--margin-bottom: 0;
  --vpkg--videoInner--display: none;
  --vpkg--videoOuter--display: block;
  --vpkg--grid--max-width: none;
  --vpkg--grid--margin: 0;

  // Package title style element
  --vpkg--package-title-line--border-top: none;
  --vpkg--package-title-line--content: none;

  // Package title text
  --vpkg--package-title-text--border-color: var(--default-color);
  --vpkg--package-title-text--color: var(--white);
  --vpkg--package-title-text--font-family: var(--default-headline-font-family);
  --vpkg--package-title-text--font-style: normal;
  --vpkg--package-title-text--font-weight: normal;
  --vpkg--package-title-text--font-size: var(--text-24);
  --vpkg--package-title-text--line-height: 1;

  // Package title description
  --vpkg--package-title-description--font-family: var(--publico-hed);
  --vpkg--package-title-description--font-size: #{h.rem(14px)};
  --vpkg--package-title-description--line-height: 1.25;
  --vpkg--package-title-description--margin: 6px 0 0;

  // Video title
  --vpkg--video-title--font-family: var(--founders-cond);
  --vpkg--video-title--font-size: var(--text-20);
  --vpkg--video-title--line-height: var(--leading-125);
  --vpkg--video-title--margin-top: #{h.rem(44px)};
  --vpkg--video-duration--font-family: var(--founders-mono);
  --vpkg--videoInner--height: auto;
  --vpkg--columnList--display: block;
  --vpkg--columnList--flex-direction: row;
  --vpkg--columnList--flex: 0 0 auto;
  --vpkg--columnList--padding: 0;
  // Video see all wrapper and button
  --vpkg--see-all-wrapper--background-color: var(--grey-70);
  --vpkg--see-all-btn--color: var(--white);
  --vpkg--see-all-btn--background-image: linear-gradient(35deg, var(--white) 50%, transparent 50%);
  --vpkg--see-all-btn--border: 1px solid var(--white);
  --vpkg--see-all-btn--hover--color: var(--grey-70);

  @include h.breakpoint-m-only {
    --vpkg--scroll--height: #{h.rem(177px)};
  }

  @include h.breakpoint-m {
    --vpkg--package--margin-right: -20px;
    --vpkg--package--margin-left: -20px;
    --vpkg--package--width: calc(100% + 40px);
    --vpkg--list--display: flex;
    --vpkg--package-title--align-self: flex-end;
    --vpkg--package-title-text--font-size: var(--text-32);
    --vpkg--package-title--width: #{h.rem(482px)};
    --vpkg--package-title--padding: #{h.rem(24px 48px)};
    --vpkg--package-title--min-height: #{h.rem(130px)};
    --vpkg--videoInner--display: block;
  }

  @include h.breakpoint-l {
    --vpkg--package--margin-right: auto;
    --vpkg--package--margin-left: auto;
    --vpkg--package--width: 100%;
    --vpkg--list--display: block;
    --vpkg--package-title--width: #{h.rem(400px)};
    --vpkg--package-title--min-height: #{h.rem(100px)};
    --vpkg--columnVideo--flex-basis: #{h.rem(560px)};
    --vpkg--videoInner--height: #{h.rem(315px)};
    --vpkg--columnList--flex: 0 0 #{h.rem(400px)};
    --vpkg--grid--display: flex;
  }

  @include h.breakpoint-x {
    --vpkg--video-title--font-size: var(--text-24);
    --vpkg--package-title--width: #{h.rem(480px)};
    --vpkg--package-title--padding: #{h.rem(32px 48px)};
    --vpkg--package-title--min-height: #{h.rem(120px)};
    --vpkg--columnVideo--flex-basis: #{h.rem(680px)};
    --vpkg--videoInner--height: #{h.rem(383px)};
    --vpkg--columnList--flex: 0 0 #{h.rem(520px)};
  }

  :global(body.news) & {
    --vpkg--package-title--background-color: var(--blue-70);
  }

  :global(body.msnbc) & {
    --vpkg--package-title-description--margin: #{h.rem(12px 0 1px)};
    --vpkg--see-all-btn--color: var(--yellow-40);
    --vpkg--see-all-btn--background-image: linear-gradient(35deg, var(--yellow-40) 50%, transparent 50%);
    --vpkg--see-all-btn--border: 1px solid var(--yellow-40);
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    --vpkg--package-title--background-color: var(--blue-60);

    // Package title differences
    --vpkg--package-title-text--font-size: var(--text-32);

    @include h.breakpoint-m {
      --vpkg--package-title-text--font-size: var(--text-32);
      --vpkg--package-title-text--line-height: var(--space-40);
    }
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --vpkg--package-title--background-color: var(--neutral-30);
    --vpkg--package-title--color: var(--grey-60);

    // Enable package title style element
    --vpkg--package-title-line--border-top: 1px solid var(--default-color);
    --vpkg--package-title-line--content: '';

    // Package title differences
    --vpkg--package-title-text--color: var(--blue-60);
    --vpkg--package-title-text--font-size: var(--text-28);

    // Package title description margin
    --vpkg--package-title-description--margin: #{h.rem(12px 0 1px)};

    // See all button
    --vpkg--see-all-wrapper--background-color: var(--white);
    --vpkg--see-all-btn--color: var(--default-color);
    --vpkg--see-all-btn--background-image: linear-gradient(35deg, var(--default-color) 50%, transparent 50%);
    --vpkg--see-all-btn--border: 1px solid var(--default-color);
    --vpkg--see-all-btn--hover--color: var(--white);

    @include h.breakpoint-m {
      --vpkg--package-title-text--font-size: var(--text-32);
    }
  }

  :global(body.today) & {
    --vpkg--package-title--background-color: var(--white);
    --vpkg--package-title--color: var(--grey-60);
    --vpkg--list--display: block;
    --vpkg--scroll--height: auto;
    --vpkg--columnList--display: flex;
    --vpkg--columnList--flex-direction: column;
    --vpkg--buttonTilt--width: 40%;
    --vpkg--buttonTilt--align-self: flex-end;
    --vpkg--buttonTilt--margin-top: #{h.rem(3px)};
    --vpkg--buttonTilt--margin-right: 0;
    --vpkg--columnList--padding: #{0 h.rem(20px)};
    --vpkg--grid--display: block;
    --vpkg--videoOuter--display: none;
    --vpkg--videoInner--display: block;
    --vpkg--grid--max-width: #{h.rem(1600px)};
    --vpkg--grid--margin: 0 auto;

    // Enable package title style element
    --vpkg--package-title-line--border-top: 1px solid var(--default-color);
    --vpkg--package-title-line--content: '';

    // Package title differences
    --vpkg--package-title-text--color: var(--purple-70);
    --vpkg--package-title-text--font-style: italic;
    --vpkg--video-title--margin-top: #{h.rem(100px)};

    // Package title description margin
    --vpkg--package-title-description--margin: #{h.rem(12px 0 1px)};

    // Video title font differences
    --vpkg--video-title--font-family: var(--secondary-font);
    --vpkg--video-title--font-size: #{h.rem(14px)};
    --vpkg--video-title--line-height: 1.5;
    --vpkg--video-duration--font-family: var(--secondary-font);
    --vpkg--videoInner--height: auto;

    // See all button
    --vpkg--see-all-wrapper--background-color: var(--white);
    --vpkg--see-all-btn--color: var(--default-color);
    --vpkg--see-all-btn--background-image: linear-gradient(35deg, var(--default-color) 50%, transparent 50%);
    --vpkg--see-all-btn--border: 1px solid var(--default-color);
    --vpkg--see-all-btn--hover--color: var(--white);
    --vpkg--see-all-btn--justify-content: center;

    @include h.breakpoint-m {
      --vpkg--package--margin-right: auto;
      --vpkg--package--margin-left: auto;
      --vpkg--package--width: 100%;
      --vpkg--columnList--padding: 0;
      --vpkg--video-title--font-size: #{h.rem(24px)};
      --vpkg--videoOuter--display: block;
      --vpkg--list--display: flex;
      --vpkg--buttonTilt--width: 30%;
      --vpkg--buttonTilt--margin-top: #{h.rem(30px)};
      --vpkg--buttonTilt--margin-right: #{h.rem(28px)};
      --vpkg--columnVideo--margin-right: 0;
      --vpkg--columnVideo--margin-bottom: #{h.rem(30px)};
      --vpkg--columnList--flex: 0 0 100%;
      --vpkg--see-all-btn--justify-content: flex-end;
    }

    @include h.breakpoint-l {
      --vpkg--list--display: block;
      --vpkg--grid--display: flex;
      --vpkg--buttonTilt--width: 100%;
      --vpkg--buttonTilt--align-self: flex-start;
      --vpkg--buttonTilt--margin-top: #{h.rem(0)};
      --vpkg--buttonTilt--margin-right: #{h.rem(16px)};
      --vpkg--columnVideo--flex-basis: 63.4%;
      --vpkg--columnVideo--margin-right: #{h.rem(16px)};
      --vpkg--columnList--flex: 1 1 auto;
    }

    @include h.breakpoint-x {
      --vpkg--columnVideo--flex-basis: 66.45%;
      --vpkg--columnVideo--margin-right: 1.25%;
    }

    @include h.breakpoint-xx {
      --vpkg--columnVideo--flex-basis: 64.375%;
    }
  }
}
