@use 'assets/styles/utils/helper' as h;

.teaseList {
  --tl--wrapper--margin: 0 var(--space-20);
  --tl--wrapper--padding: 0;
  --tl--wrapper--width: auto;

  // Pseudo element used for decoration
  --tl--wrapper--before--background: transparent;
  --tl--wrapper--before--bottom: 0;
  --tl--wrapper--before--content: none;
  --tl--wrapper--before--display: none;
  --tl--wrapper--before--left: 0;
  --tl--wrapper--before--position: absolute;
  --tl--wrapper--before--right: 0;
  --tl--wrapper--before--top: 0;
  --tl--wrapper--before--width: auto;
  --tl--wrapper--before--z-index: -1;

  // Pseudo element used for decoration
  --tl--wrapper--after--background: transparent;
  --tl--wrapper--after--bottom: 0;
  --tl--wrapper--after--content: none;
  --tl--wrapper--after--display: none;
  --tl--wrapper--after--left: 0;
  --tl--wrapper--after--position: absolute;
  --tl--wrapper--after--right: 0;
  --tl--wrapper--after--top: 0;
  --tl--wrapper--after--width: auto;
  --tl--wrapper--after--z-index: -1;

  // List wrapper
  --tl--list-outer--background-color: transparent;
  --tl--list-outer--border-top: 1px dashed var(--grey-50);
  --tl--list-outer--margin: 0;
  --tl--list-outer-with-button--margin: 0 0 28px 0;

  // List defaults
  --tl--list-inner--color: var(--default-headline-color);
  --tl--list-inner--font-family: var(--default-headline-font-family);
  --tl--list-inner--font-size: var(--text-20);
  --tl--list-inner--line-height: 20px;

  // Item element
  --tl--item--background: transparent;
  --tl--item--border-bottom: 1px dashed var(--grey-50);
  --tl--item--display: block;
  --tl--item--margin: var(--space-20) 0 0;
  --tl--item--padding: 0 0 var(--space-20) var(--space-20);

  // Item bullet
  --tl--dot--color: var(--default-color);
  --tl--dot--content: '';
  --tl--dot--display: block;

  // Item timestamp
  --tl--timestamp--font-family: var(--founders-mono);
  --tl--timestamp--font-size: var(--text-12);
  --tl--timestamp--font-weight: var(--light);
  --tl--timestamp--letter-spacing: var(--tracking-tight);

  // Item eyebrow
  --tl--eyebrow--color: var(--default-color);
  --tl--eyebrow--font-family: var(--founders-mono);
  --tl--eyebrow--font-size: var(--text-12);
  --tl--eyebrow--font-weight: var(--light);
  --tl--eyebrow--letter-spacing: var(--tracking-tight);
  --tl--eyebrow--before--background: transparent;
  --tl--eyebrow--before--content: none;
  --tl--eyebrow--before--display: none;
  --tl--eyebrow--before--height: 0;
  --tl--eyebrow--before--margin: 0;
  --tl--eyebrow--before--padding: 0;
  --tl--eyebrow--before--width: 0;

  // Item tease picture
  --tl--picture--margin: 10px 0 0;
  --tl--picture--height: auto;
  --tl--picture--width: auto;
  --tl--picture--image--border-radius: 0;

  // Item type icon
  --tl--type-icon--bottom: 0;
  --tl--type-icon--left: 0;

  // List see all button
  --tl--button-wrapper--background: transparent;
  --tl--button-wrapper--margin: 0;
  --tl--button-wrapper--padding: 0;
  --tl--button--width: 240px;

  @include h.breakpoint-m {
    --tl--wrapper--margin: 0;
    --tl--list-inner--font-size: var(--text-32);
    --tl--list-inner--line-height: var(--space-32);
    --tl--item--margin: var(--space-32) 0 0;
    --tl--item--padding: 0 0 var(--space-32) var(--space-32);
    --tl--picture--margin: 30px 0 0;
  }

  @include h.breakpoint-l {
    --tl--list-inner--font-size: var(--text-18);
    --tl--list-inner--line-height: var(--space-20);
    --tl--item--margin: var(--space-20) 0 0;
    --tl--item--padding: 0 0 var(--space-20) var(--space-20);
    --tl--picture--margin: 10px 0 0;
  }

  :global(body.globalcitizen) & {
    --tl--dot--color: var(--red-40);
    --tl--eyebrow--color: var(--red-40);
  }

  :global(body.think) & {
    --tl--list-inner--font-size: 13.5px;
    --tl--list-inner--line-height: 20px;

    @include h.breakpoint-m {
      --tl--list-inner--font-size: 22.5px;
      --tl--list-inner--line-height: 30px;
    }

    @include h.breakpoint-l {
      --tl--list-inner--font-size: 13.5px;
      --tl--list-inner--line-height: 20px;
    }
  }

  :global(body.today) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --tl--wrapper--margin: 0;
    --tl--list-outer--background-color: var(--white);
    --tl--list-outer--border-top: none;
    --tl--list-outer--margin: var(--space-20);
    --tl--list-outer-with-button--margin: var(--space-20) var(--space-20) 0 var(--space-20);
    --tl--button-wrapper--background: var(--white);

    // Dotted line between list item elements
    --tl--item--background: linear-gradient(to right, var(--grey-50) 17%, var(--white) 0%) repeat-x top/6px 1px;
    --tl--item--border-bottom: none;
    --tl--item--display: flex;
    --tl--item--margin: var(--space-20);
    --tl--item--padding: var(--space-20) 0 0;

    // Square images
    --tl--picture--margin: 0 8px 0 0;
    --tl--picture--height: 60px;
    --tl--picture--width: 60px;
    --tl--picture--image--border-radius: 50%;

    // Type icon offset
    --tl--type-icon--bottom: -4px;
    --tl--type-icon--left: -4px;

    @include h.breakpoint-m {
      --tl--picture--height: 80px;
      --tl--picture--width: 80px;
    }
  }

  :global(body.today) & {
    --tl--wrapper--margin: 25px 0 0 0;
    --tl--wrapper--width: auto;

    // Think orange border on three sides
    --tl--wrapper--before--background: var(--default-color);
    --tl--wrapper--before--content: '';
    --tl--wrapper--before--display: block;
    --tl--wrapper--before--right: 80px;

    // Offset dotted background
    --tl--wrapper--after--background: radial-gradient(rgba(var(--default-color-rgb), 0.3) 1px, transparent 1px) 1px 1px/5px 5px transparent;
    --tl--wrapper--after--bottom: auto;
    --tl--wrapper--after--content: '';
    --tl--wrapper--after--display: block;
    --tl--wrapper--after--height: 485px;
    --tl--wrapper--after--left: auto;
    --tl--wrapper--after--right: 0;
    --tl--wrapper--after--top: -20px;
    --tl--wrapper--after--width: calc(100% - 40px);
    --tl--wrapper--after--z-index: -1;

    // Font sizes unique
    --tl--list-inner--font-size: var(--text-16);
    --tl--list-inner--line-height: var(--leading-125);

    // Button sizing unique
    --tl--button-wrapper--margin: 0 var(--space-20) var(--space-20);
    --tl--button-wrapper--padding: var(--space-20) 0;

    @include h.breakpoint-m {
      --tl--wrapper--width: calc(100% + 20px);
      --tl--button--width: 300px;
    }

    @include h.breakpoint-l {
      --tl--button--width: calc(100% - 40px);
    }
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    // Stripped background
    --tl--wrapper--before--background: url('~assets/images/telemundo-stripe-pattern.svg');
    --tl--wrapper--before--content: '';
    --tl--wrapper--before--display: block;

    // Font sizes unique
    --tl--list-inner--font-size: var(--text-18);
    --tl--list-inner--line-height: var(--leading-100);

    // Button sizing unique
    --tl--button-wrapper--margin: 0 var(--space-20) var(--space-20);
    --tl--button-wrapper--padding: var(--space-20) 0;
  }

  :global(body.select) & {
    --tl--list-outer--border-top: 1px dashed var(--grey-50);
    --tl--item--padding: 0 0 var(--space-20) 0;

    // Remove list item bullet
    --tl--dot--content: none;
    --tl--dot--display: none;

    // Item eyebrow
    --tl--eyebrow--color: var(--blue-60);
    --tl--eyebrow--font-family: var(--lato);
    --tl--eyebrow--font-weight: 400;
    --tl--eyebrow--letter-spacing: 0;

    // --text-## variables are not the same on Select.
    --tl--eyebrow--font-size: 12px;
    --tl--list-inner--font-size: 18px;
  }
}
