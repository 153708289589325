@use 'assets/styles/utils/helper' as h;

.item {
  border-top: 1px solid var(--grey-40);
  display: flex;
  flex: 0 0 25%;
  margin-bottom: var(--space-16);
  margin-right: 0;
  padding-top: var(--space-16);
  width: 100%;

  @include h.breakpoint-m {
    flex: 1 1 25%;
    margin-bottom: 0;
    margin-right: 0;
    width: initial;
  }

  &:not(:last-of-type) {
    margin-right: h.rem(30px);

    @include h.breakpoint-l {
      margin-right: var(--space-40);
    }
  }

  &.noBorder {
    border-top: none;
    padding-top: var(--space-16);

    @include h.breakpoint-m {
      padding-top: var(--space-12);
    }

    &:not(:first-of-type) {
      border-top: 1px dashed var(--grey-40);

      @include h.breakpoint-m {
        border-top: none;
      }
    }
  }

  &.lastItem {
    @include h.breakpoint-s-only {
      margin-bottom: 0;
    }
  }

  .author {
    color: var(--grey-70);
    display: inline;
    font-size: var(--text-12);
    font-family: var(--founders-mono);
    font-weight: 400;
    line-height: var(--leading-100);
    letter-spacing: -0.04em;
    text-transform: uppercase;

    & a {
      color: var(--blue-40);

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .eyebrow {
    color: var(--blue-40);
    display: inline;
    font-size: var(--text-12);
    font-family: var(--founders-mono);
    font-weight: 400;
    line-height: var(--leading-100);
    margin: 0;
    letter-spacing: -0.04em;
    text-transform: uppercase;

    &:hover {
      opacity: 0.7;
    }
  }

  .headline {
    color: var(--grey-70);
    font-size: var(--text-18);
    font-family: var(--founders-cond);
    font-weight: 600;
    line-height: var(--leading-100);
    margin: 0;

    &:hover {
      opacity: 0.7;
    }
  }

  .metaWrapper {
    margin-top: h.rem(4px);
    line-height: var(--leading-100);
  }

  .slash {
    color: var(--grey-70);
    font-size: var(--text-12);
    font-family: var(--founders-mono);
    font-weight: 400;
    line-height: var(--leading-100);
    margin: 0;
  }
}
