@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.navigation {
  border-top: 1px solid var(--navigation--border-color);
  font-family: var(--navigation--font-family);
  font-size: var(--navigation--font-size);
  font-style: var(--navigation--font-style);
  line-height: 1;
  overflow-x: auto;
  padding: var(--space-20) 0;

  &.header {
    display: flex;
    flex-direction: row;
  }

  &.rail {
    display: none;
  }

  @include h.breakpoint-l {
    padding: 0;
    border: none;
    overflow: none;

    &.header {
      display: none;
      margin-top: var(--space-48);
    }

    &.rail {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--space-48);
    }
  }
}

.item {
  flex-shrink: 0;
  margin-right: h.rem(30px);

  .header & {
    color: var(--item--header--color);
  }

  .rail & {
    color: var(--item--rail--color);
  }

  @include h.breakpoint-m {
    margin-right: var(--space-40);
  }

  @include h.breakpoint-l {
    border-bottom: 1px solid var(--item--border-color);
    margin-bottom: var(--space-20);
    margin-right: 0;
    padding-bottom: var(--space-20);

    &:last-child {
      padding-bottom: 0;
      padding-right: 0;
      margin-bottom: 0;
      border-bottom-width: 0;
    }
  }

  a:hover {
    opacity: var(--opacity-70);
  }
}
