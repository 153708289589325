@use './theme.tokens' as t;
@use 'assets/styles/utils/helper' as h;

.inline-blinking-live-dot {
  height: var(--space-8);
  width: var(--space-8);
  background-color: var(--live-dot--background-color);
  border-radius: 50%;
  display: inline-block;
  animation: live-dot-blink 1.5s ease-in-out infinite;
  vertical-align: middle;
}
