@use 'assets/styles/utils/helper' as h;

.show-lead-background__wrapper,
.show-lead-background__gradient {
  --show-lead-background-image-content-after: '';
  --show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), #000 80%);
  --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--default-gradient-value-1-rgb), 1), rgba(var(--default-gradient-value-2-rgb), 1));
  --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--default-gradient-value-1-rgb), 0.7), rgba(var(--default-gradient-value-2-rgb), 0.7));

  @include h.breakpoint-m {
    --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--default-gradient-value-1-rgb), 0.8) 10%, rgba(var(--default-gradient-value-2-rgb), 0.7));
  }

  @include h.breakpoint-l {
    --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--default-gradient-value-1-rgb), 1) 20%, rgba(var(--default-gradient-value-2-rgb), 1));
  }

  @include h.breakpoint-x {
    --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--default-gradient-value-1-rgb), 1) 25%, rgba(var(--default-gradient-value-2-rgb), 1));
    --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--default-gradient-value-1-rgb), 0.8) 25%, rgba(var(--default-gradient-value-2-rgb), 0.7));
  }

  body.today & {
    --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 1), rgba(var(--gradient-value-2-rgb), 1));
    --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 0.7), rgba(var(--gradient-value-2-rgb), 0.7));

    @include h.breakpoint-m {
      --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 0.8) 20%, rgba(var(--gradient-value-2-rgb), 0.7));
    }

    @include h.breakpoint-l {
      --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 1) 20%, rgba(var(--gradient-value-2-rgb), 1));
    }

    @include h.breakpoint-x {
      --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 1) 25%, rgba(var(--gradient-value-2-rgb), 1));
      --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 0.8) 25%, rgba(var(--gradient-value-2-rgb), 0.7));
    }
  }

  body.msnbc & {
    --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 1), rgba(var(--gradient-value-2-rgb), 1));
    --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 0.8), rgba(var(--gradient-value-2-rgb), 0.46));

    @include h.breakpoint-m {
      --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 0.8) 20%, rgba(var(--gradient-value-2-rgb), 0.46));
    }

    @include h.breakpoint-l {
      --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 1) 20%, rgba(var(--gradient-value-2-rgb), 1));
    }

    @include h.breakpoint-x {
      --show-lead-background-gradient: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 1) 25%, rgba(var(--gradient-value-2-rgb), 1));
      --show-lead-background-gradient-with-image: linear-gradient(111deg, rgba(var(--gradient-value-1-rgb), 0.8) 25%, rgba(var(--gradient-value-2-rgb), 0.46));
    }
  }

  body.noticias & {
    --show-lead-background-gradient: linear-gradient(72.88deg, rgba(var(--gradient-value-1-rgb), 1) 31.65%, rgba(var(--gradient-value-2-rgb), 0.1) 89.57%);
    --show-lead-background-gradient-with-image: linear-gradient(72.88deg, rgba(var(--gradient-value-1-rgb), 1) 31.65%, rgba(var(--gradient-value-2-rgb), 0.1) 89.57%);

    @include h.breakpoint-m {
      --show-lead-background-gradient-with-image: linear-gradient(103.08deg, rgba(var(--gradient-value-1-rgb), 1) 24.46%, rgba(var(--gradient-value-2-rgb), 0.1) 94.95%);
    }

    @include h.breakpoint-l {
      --show-lead-background-gradient: linear-gradient(100.35deg, rgba(var(--gradient-value-1-rgb), 1) 25.84%, rgba(var(--gradient-value-2-rgb), 0.1) 95.45%);
    }

    @include h.breakpoint-x {
      --show-lead-background-gradient: linear-gradient(102.77deg, rgba(var(--gradient-value-1-rgb), 1) 25.84%, rgba(var(--gradient-value-2-rgb), 0.1) 95.45%);
      --show-lead-background-gradient-with-image: linear-gradient(102.77deg, rgba(var(--gradient-value-1-rgb), 1) 25.84%, rgba(var(--gradient-value-2-rgb), 0.1) 95.45%);
    }
  }

  body.deportes & {
    --show-lead-background-gradient: linear-gradient(72.88deg, rgba(var(--gradient-value-1-rgb), 1) 31.65%, rgba(var(--gradient-value-2-rgb), 0.1) 89.57%);
    --show-lead-background-gradient-with-image: linear-gradient(72.88deg, rgba(var(--gradient-value-1-rgb), 1) 31.65%, rgba(var(--gradient-value-2-rgb), 0.1) 89.57%);

    @include h.breakpoint-m {
      --show-lead-background-gradient-with-image: linear-gradient(103.08deg, rgba(var(--gradient-value-1-rgb), 1) 24.46%, rgba(var(--gradient-value-2-rgb), 0.1) 94.95%);
    }

    @include h.breakpoint-l {
      --show-lead-background-gradient: linear-gradient(100.35deg, rgba(var(--gradient-value-1-rgb), 1) 25.84%, rgba(var(--gradient-value-2-rgb), 0.1) 95.45%);
    }

    @include h.breakpoint-x {
      --show-lead-background-gradient: linear-gradient(102.77deg, rgba(var(--gradient-value-1-rgb), 1) 25.84%, rgba(var(--gradient-value-2-rgb), 0.1) 95.45%);
      --show-lead-background-gradient-with-image: linear-gradient(102.77deg, rgba(var(--gradient-value-1-rgb), 1) 25.84%, rgba(var(--gradient-value-2-rgb), 0.1) 95.45%);
    }
  }

  body.telemundo & {
    --show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), var(--blue-70) 80%);
    --show-lead-background-gradient: linear-gradient(72.88deg, rgba(var(--blue-70-rgb), 1) 35%, rgba(var(--blue-70-rgb), 0.1) 70%);
    --show-lead-background-gradient-with-image: linear-gradient(72.88deg, rgba(var(--blue-70-rgb), 1) 35%, rgba(var(--blue-70-rgb), 0.1) 70%);

    @include h.breakpoint-m {
      --show-lead-background-gradient-with-image: linear-gradient(103.08deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }

    @include h.breakpoint-l {
      --show-lead-background-gradient: linear-gradient(103.35deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }

    @include h.breakpoint-x {
      --show-lead-background-gradient: linear-gradient(102.77deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
      --show-lead-background-gradient-with-image: linear-gradient(102.77deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }
  }

  body.entretenimiento & {
    --show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), var(--blue-70) 80%);
    --show-lead-background-gradient: linear-gradient(72.88deg, rgba(var(--blue-70-rgb), 1) 35%, rgba(var(--blue-70-rgb), 0.1) 70%);
    --show-lead-background-gradient-with-image: linear-gradient(72.88deg, rgba(var(--blue-70-rgb), 1) 35%, rgba(var(--blue-70-rgb), 0.1) 70%);

    @include h.breakpoint-m {
      --show-lead-background-gradient-with-image: linear-gradient(103.08deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }

    @include h.breakpoint-l {
      --show-lead-background-gradient: linear-gradient(103.35deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }

    @include h.breakpoint-x {
      --show-lead-background-gradient: linear-gradient(102.77deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
      --show-lead-background-gradient-with-image: linear-gradient(102.77deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }
  }

  body.shows & {
    --show-lead-background-image-fade: linear-gradient(to right, rgba(255, 0, 0, 0), var(--blue-70) 80%);
    --show-lead-background-gradient: linear-gradient(72.88deg, rgba(var(--blue-70-rgb), 1) 35%, rgba(var(--blue-70-rgb), 0.1) 70%);
    --show-lead-background-gradient-with-image: linear-gradient(72.88deg, rgba(var(--blue-70-rgb), 1) 35%, rgba(var(--blue-70-rgb), 0.1) 70%);

    @include h.breakpoint-m {
      --show-lead-background-gradient-with-image: linear-gradient(103.08deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }

    @include h.breakpoint-l {
      --show-lead-background-gradient: linear-gradient(103.35deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }

    @include h.breakpoint-x {
      --show-lead-background-gradient: linear-gradient(102.77deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
      --show-lead-background-gradient-with-image: linear-gradient(102.77deg, rgba(var(--blue-70-rgb), 1) 25%, rgba(var(--blue-70-rgb), 0.1) 70%);
    }
  }
}
