@use 'assets/styles/utils/helper' as h;

.disclaimer {
  background-color: #f1f1f1;
  color: var(--neutral-60);
  font-family: var(--lato);
  font-size: h.rem(14px);
  font-weight: var(--normal);
  justify-content: center;
  line-height: 1.2;
  margin-bottom: h.rem(32px);
  padding: h.rem(20px);
  text-align: center;

  a {
    color: #0477c9;
    text-decoration: none;
  }

  @include h.breakpoint-m {
    margin-bottom: h.rem(24px);
  }

  @include h.breakpoint-l {
    line-height: 1.1;
  }

  @include h.breakpoint-x {
    margin-bottom: h.rem(28px);
  }
}
