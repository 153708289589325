@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.alacarte {
  width: 100%;

  &.shrinkToFit {
    flex: 0 1 auto !important; // must override parent's direct descendant selector (0 0 auto)
    min-height: 0; // required for Firefox

    @include h.breakpoint-l {
      @include h.ie-flex-basis;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: var(--wrapper--margin);
  position: relative;
}

.wrapperTop {
  position: relative;
  margin: var(--wrapper--top--margin);
}

.topDecoration {
  display: none;

  .inRail & {
    display: var(--top-decoration--display);
    margin-bottom: var(--top-decoration--margin-bottom);
    border-top: var(--top-decoration--border-top);

    &::before {
      height: var(--top-decoration--tab--height);
      width: var(--top-decoration--tab--width);
    }
  }
}

.title {
  color: var(--title--color);
  font-family: var(--title--font-family);
  font-size: var(--title--font-size);
  font-style: var(--title--font-style);
  font-weight: var(--title--font-weight);
  letter-spacing: var(--title--letter-spacing);
  line-height: var(--title--line-height);
  margin: 0;
}

.description {
  color: var(--grey-60);
  margin: var(--space-8) 0 0;
  font-family: var(--publico-txt);
  font-weight: var(--normal);
  font-size: var(--text-14);
  line-height: var(--leading-150);
}

.content {
  background: var(--content--background);
  flex-grow: 1;
  padding: var(--content--padding);
  position: relative;

  .shrinkToFit & {
    flex: 0 1 auto !important; // must override parent's direct descendant selector (0 0 auto)
    min-height: 0; // required for Firefox

    @include h.breakpoint-l {
      @include h.ie-flex-basis;
    }
  }
}

.wrapperTop + .content {
  margin-top: 20px;

  .inRail & {
    margin-top: var(--wrapper--top--content--in-rail--margin-top);
  }
}

.itemsWrapper {
  background: var(--items--wrapper--background);
  padding: var(--items--wrapper--padding);
  position: relative;
  flex-grow: 1;

  .shrinkToFit & {
    flex: 0 1 auto;
    min-height: 0; // required for Firefox

    @include h.breakpoint-l {
      @include h.ie-flex-basis;
      overflow: hidden;
    }

    // Overflow curtain
    &::after {
      @include h.breakpoint-l {
        background-image: linear-gradient(to bottom, rgba(var(--white-rgb), 0), var(--white));
        bottom: 0;
        content: '';
        height: var(--space-80);
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
      }
    }
  }
}

.items {
  width: 100%;

  @include h.breakpoint-m-only {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  :global(body.today) .inRail & {
    width: calc(100% - (var(--space-20) * 2));
    margin: 0 var(--space-20);
  }
}

.buttonWrapper {
  background: var(--button--wrapper--background);
  margin: var(--button--wrapper--margin);
  padding: var(--button--wrapper--padding);
  position: relative;
  width: 100%;

  .button {
    border: 1px solid var(--button--border-color);
    height: var(--button--height);
    margin: var(--button--margin);
    width: var(--button--width);
  }
}

:global(body.today) {
  .inRail {
    .content {
      // Solid color offset background
      &::before {
        background-color: var(--default-color);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 80px;
        top: 0;
        z-index: 1;
      }

      // Dots
      &::after {
        @include h.pattern-dots(var(--default-color));
        content: '';
        height: 75%; // hard-coded due to design spec
        opacity: var(--opacity-50);
        position: absolute;
        right: 0;
        top: -20px;
        width: calc(100% - 190px);
        z-index: 0;

        @include h.breakpoint-m-only {
          width: 40%; // hard-coded due to design spec
        }

        @include h.breakpoint-l {
          width: calc(100% - 68px);
        }
      }
    }

    .itemsWrapper,
    .buttonWrapper {
      z-index: 2;
    }
  }
}
