@use 'assets/styles/utils/helper' as h;

.right {
  @include h.package-collection-title;
  position: relative;

  :global(.today .hasTitle) & {
    @include h.breakpoint-l {
      margin-top: h.rem(40px);
    }
  }

  &::before {
    :global(.news) & {
      @include h.breakpoint-m {
        content: '';
        width: h.rem(40px);
        height: 100%;
        position: absolute;
        top: 0;
        left: h.rem(-40px);
      }

      @include h.breakpoint-l {
        width: calc((100vw - 920px) / 2);
        left: auto;
        right: calc((100vw - 920px) / -2);
      }

      @include h.breakpoint-x {
        width: calc((100vw - 1160px) / 2);
        right: calc((100vw - 1160px) / -2);
      }
    }
  }
}
