@use 'assets/styles/utils/helper' as h;

.cover-spread {
  --csp--border-top-color: var(--default-color);
  --csp--breaking--border-top-color: var(--news-red-30);
  --csp--live-blog--border-top-color: var(--default-color);
  --csp--live-blog--breaking--border-top-color: var(--news-red-30);

  body.msnbc & {
    --csp--border-top-color: var(--yellow-40);
  }
}
