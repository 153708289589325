@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.item {
  display: flex;
  position: relative;
  margin: 0 0 var(--space-20);
  width: 100%;

  &.inRail {
    // Borders only in rail
    border-top: 1px dashed var(--grey-50);
    padding: var(--space-12) 0 0;
    margin: var(--space-20) 0 0;

    @include h.breakpoint-m-only {
      width: calc(50% - 15px);
    }
  }

  &.isFeatured {
    align-items: flex-start;
    border-top: none;
    padding: 0;

    &.inRail {
      flex-flow: column;
    }

    @include h.breakpoint-m-only {
      flex-flow: row nowrap;

      &.inRail {
        flex-flow: row nowrap;
        width: 100%;
      }
    }
  }
}

.placeholder {
  display: none;
}

.headline {
  color: var(--tease-headline--color);
  font-family: var(--tease-headline--font-family);
  font-size: var(--tease-headline--font-size);
  font-weight: normal;
  line-height: var(--tease-headline--line-height);
  margin: 0;
  word-wrap: break-word;

  a:hover {
    opacity: var(--opacity-70);
  }

  .item:first-child & {
    font-size: var(--tease-headline--first-child--font-size);
  }
}

.imageWrapper {
  flex-shrink: 0;
  height: var(--image-wrapper--height);
  margin: 0 var(--space-12) 0 0;
  position: relative;
  width: var(--image-wrapper--width);

  .isFeatured.inRail & {
    height: auto;
    margin: 0 0 var(--space-12) 0;
    min-width: initial;
    width: 100%;

    @include h.breakpoint-m-only {
      margin: 0 var(--space-12) 0 0;
      width: h.rem(160px);
    }
  }
}

.typeIcon {
  bottom: 0;
  left: 0;
  position: absolute;
}

.unibrow {
  color: var(--unibrow--color);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  font-weight: normal;
  line-height: 1;
  margin: var(--unibrow--margin);
  text-transform: uppercase;

  .isOpinion & {
    color: var(--unibrow--opinion--color);
  }
}

.image.radius {
  display: block;
  overflow: auto;
  border-radius: 50%;
}

:global(body.today) .inRail,
:global(body.telemundo) .inRail,
:global(body.entretenimiento) .inRail,
:global(body.shows) .inRail {
  // Borders only in rail
  border-top: none;
  background-image: linear-gradient(to right, var(--grey-60) 17%, var(--white) 0%);
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;

  &:first-child {
    margin: 0;
    padding: var(--space-12) 0 0;
  }

  // Breakpoint `m` has alternate item layout for body variation
  @include h.breakpoint-m-only {
    &:first-child {
      flex-basis: 100%;
    }
  }
}

.headlineContainer {
  width: 100%;
}

.headlineContainerWithImage {
  width: calc(100% - 100px);
}

.aLaCarteItemSourceName {
  text-transform: uppercase;
  color: #2a2a2a;
  font-family: FoundersGroteskCond, 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 0;
}
