@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

// Still needed for XL breakpoint and 2 items, since image and info are side-by-side
.news-tease__col-2 {
  @include h.breakpoint-x {
    .news-tease__image-container + .news-tease__info {
      margin-left: h.rem(20px);

      .news-tease__headline {
        margin-top: 0;
      }
    }
  }
}

.news-tease__container {
  margin-bottom: h.rem(32px);
}

.news-tease__timestamp {
  font-size: h.rem(12px);
  line-height: 1;
  font-family: var(--founders-mono);
  font-weight: 100;
}

.news-tease__unibrow {
  color: var(--news-tease-unibrow-color);
  font-family: var(--publico-txt);
  font-size: h.rem(16px);
  font-weight: bold;
  padding-left: h.rem(20px);
  margin: 0;

  @include h.breakpoint-m {
    padding-left: 0;
  }
}

.news-tease__headline {
  color: var(--news-tease-headline-color);
  font-family: var(--news-tease-headline-font-family);
  font-size: var(--news-tease-headline-font-size);
  line-height: var(--news-tease-headline-line-height);
  margin: h.rem(10px 0);
}

.news-tease__live-title {
  color: var(--breaking-red);
}

.news-tease__description {
  color: var(--news-tease-description-text-color);
  font-family: var(--news-tease-description-font-family);
  font-size: var(--news-tease-description-font-size);
  line-height: var(--news-tease-description-line-height);
  margin: 0;
}

.news-tease__ruler {
  border-bottom: 1px solid var(--black);
  opacity: 0.2;
  margin-bottom: h.rem(12px);
  margin-top: h.rem(8px);
}

.news-tease__info-wrapper {
  &--one-col {
    @include h.breakpoint-m {
      display: flex;
    }
  }

  &--two-col {
    @include h.breakpoint-l {
      display: flex;
      flex-direction: column;
    }

    @include h.breakpoint-x {
      flex-direction: row;
    }
  }
}

.news-tease__info--multi-col {
  padding-left: h.rem(20px);
  padding-right: h.rem(20px);

  @include h.breakpoint-m {
    padding-left: 0;
    padding-right: 0;
  }
}

.news-tease__info--one-col--no-image {
  padding-left: h.rem(20px);

  @include h.breakpoint-m {
    padding-left: 0;
  }
}

.news-tease__info--one-col--with-image {
  padding-left: h.rem(20px);
}

.news-tease__image-container {
  z-index: 1;
  position: relative;
  vertical-align: top;

  picture {
    vertical-align: text-bottom;
  }
}

.news-tease__image-container--first-item {
  @include h.breakpoint-m {
    display: none;
  }

  @include h.breakpoint-x {
    display: block;
  }
}
