@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--grey-60);
  cursor: pointer;
  font-family: var(--button--font-family);
  font-size: var(--text-14);
  font-weight: var(--semibold);
  justify-content: flex-start;
  outline: none;
  padding: var(--space-20);
  text-decoration: none;
  text-transform: uppercase;
  transition: color 100ms linear;
  width: unset;

  @include h.breakpoint-x {
    padding-bottom: var(--space-32);
  }

  &:hover {
    color: var(--default-color);
  }

  &:focus {
    outline: 0;
    outline-offset: 0;
  }
}

.buttonText {
  min-width: 30px;
  display: inline-block;
}

.arrow::before {
  display: inline-block;
  font-size: var(--text-18);
  margin: 0 0 0 4px;
  transition: transform 200ms linear;

  .isActive & {
    transform: rotate(-180deg);
  }
}
