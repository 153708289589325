@use 'assets/styles/utils/helper' as h;

.title {
  font-family: var(--founders-cond);
  font-size: var(--text-24);
  font-weight: var(--semibold);
  font-style: var(--normal);
  line-height: var(--leading-100);
  margin-bottom: var(--space-8);

  &:hover {
    opacity: var(--default-hover-opacity);
  }

  &:active {
    opacity: var(--default-active-opacity);
  }

  @include h.breakpoint-m-only {
    display: inline-block;
    margin-right: var(--space-8);
  }
}
