@use 'assets/styles/utils/helper' as h;

.tve__schedule {
  font-family: var(--founders-cond);
  font-size: h.rem(16px);
  line-height: 1;
  display: flex;
  flex-direction: column;

  @include h.breakpoint-m {
    flex-direction: row;
  }

  @include h.breakpoint-x {
    flex-direction: column;
  }

  &__list-wrap {
    overflow: hidden;
    max-height: h.rem(208px);
    transition: height ease 0.5s;
    border-style: solid;
    border-color: var(--white);
    border-width: 1px 0;

    @include h.breakpoint-m {
      max-height: unset;
      border-width: 0 1px;
    }

    @include h.breakpoint-x {
      max-height: h.rem(156px);
      border-width: 1px 0;
    }

    @media only screen and (min-width: 1440px) {
      max-height: h.rem(208px);
    }
  }

  &__list {
    transition: transform ease 0.5s;

    @include h.breakpoint-m {
      white-space: nowrap;
    }
  }

  &__loading {
    text-align: center;
    padding: h.rem(24px) 0;

    span {
      display: inline-block;
      width: h.rem(64px);
      text-align: left;
    }
  }

  &__program {
    white-space: normal;

    @include h.breakpoint-s-only {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      height: h.rem(52px);
      border-bottom: 1px solid var(--grey-60);
    }

    @include h.breakpoint-m-to-l-only {
      display: inline-flex;
      flex-flow: column nowrap;
      width: 25%;
      min-height: h.rem(80px);
      padding: h.rem(4px 10px);
      border-right: 1px solid var(--grey-60);
    }

    @include h.breakpoint-x {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      height: h.rem(52px);
      border-bottom: 1px solid var(--grey-60);
    }

    &__time {
      display: inline-block;
      text-transform: uppercase;

      &::after {
        content: ':';
        margin-right: h.rem(6px);

        @include h.breakpoint-m {
          content: none;
          margin: 0;
        }

        @include h.breakpoint-x {
          content: ':';
          margin-right: h.rem(6px);
        }
      }
    }

    &__title {
      display: inline-block;
      color: var(--grey-40);
    }

    &__sub {
      font-family: var(--founders-mono);
      font-size: h.rem(12px);
      letter-spacing: -0.04em;
      width: 100%;

      @include h.breakpoint-m {
        margin-top: h.rem(6px);
      }

      @include h.breakpoint-x {
        margin-top: 0;
      }
    }
  }

  &__navigation {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    font-size: h.rem(30px);
    line-height: 1;
    height: h.rem(28px);

    @include h.breakpoint-m {
      height: auto;
      width: h.rem(28px);
    }

    @include h.breakpoint-x {
      height: h.rem(28px);
      width: auto;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;
      outline: none;
      color: var(--grey-60);
      cursor: default;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      .icon {
        display: inline-flex;
        height: 15px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
      }

      &__prev,
      &__next {
        &--enabled {
          cursor: pointer;
          color: var(--white);
        }
      }

      &__prev .icon {
        transform: rotate(180deg);

        @include h.breakpoint-m {
          transform: rotate(90deg);
        }

        @include h.breakpoint-x {
          transform: rotate(180deg);
        }
      }

      &__next .icon {
        @include h.breakpoint-m {
          transform: rotate(270deg);
        }

        @include h.breakpoint-x {
          transform: rotate(0);
        }
      }

      &__prev--enabled:hover .icon {
        transform: rotate(180deg) scale(1.25);

        @include h.breakpoint-m {
          transform: rotate(90deg) scale(1.25);
        }

        @include h.breakpoint-x {
          transform: rotate(180deg) scale(1.25);
        }
      }

      &__next--enabled:hover .icon {
        transform: scale(1.25);

        @include h.breakpoint-m {
          transform: rotate(270deg) scale(1.25);
        }

        @include h.breakpoint-x {
          transform: rotate(0) scale(1.25);
        }
      }
    }
  }
}
