@use 'assets/styles/utils/helper' as h;

.timeline {
  position: relative;

  &::before {
    --border-offset: 16px;
    content: '';
    display: block;
    width: calc(100% - var(--border-offset));
    height: 1px;
    border-top: 1px dashed var(--grey-40);
    left: var(--border-offset);
    position: absolute;
    top: var(--space-4);
  }
}

:global(.timeline).isEmbed {
  margin: 0;
  padding-bottom: var(--space-12);
  border-bottom: 1px solid var(--grey-50);
}

.notchEmbedded {
  border-top-color: var(--grey-50);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: var(--space-12) 0;
  font-family: var(--founders-cond);
  font-weight: var(--semibold);
}

.title {
  line-height: 1;
  margin: var(--space-8) 0 0 0;
  font-size: var(--text-24);
}

.timelineSlider {
  margin-top: -5px;
}

.nextButton {
  box-shadow: -20px 0 4px -4px rgba(255, 255, 255, 0.7);
}

.prevButton {
  box-shadow: 20px 0 4px -4px rgba(255, 255, 255, 0.7);
}
