@use 'assets/styles/utils/helper' as h;

.storyline__headline {
  color: #000;
  font-size: h.rem(28px);
  line-height: var(--leading-100);
  margin: h.rem(0 0 8px 0);

  @include h.breakpoint-m {
    font-size: h.rem(24px);
  }

  &.headlineOnly {
    margin: 0;
  }
}

.storyline__headline.large {
  font-size: h.rem(28px);
}

.storyline__headline.lead {
  font-size: h.rem(28px);

  @include h.breakpoint-m {
    font-size: h.rem(32px);
  }
}

.storyline__headline.important {
  font-size: h.rem(32px);

  @include h.breakpoint-m {
    font-size: h.rem(40px);
  }
}

.storyline__headline.important.large,
.storyline__headline.important.lead {
  font-size: h.rem(32px);

  @include h.breakpoint-m {
    font-size: h.rem(60px);
  }
}
