@use 'assets/styles/utils/helper' as h;

.video-live-banner {
  --schedule-live-banner-background: var(--grey-60);
  --schedule-live-banner-font-family: var(--founders-cond);
  --schedule-live-banner-cta-font-family: var(--founders-mono);

  body.today & {
    --schedule-live-banner-background: linear-gradient(90deg, #500e70 0%, #ae1641 100%);
    --schedule-live-banner-font-family: var(--secondary-font);
    --schedule-live-banner-cta-font-family: var(--secondary-font);
  }
}
