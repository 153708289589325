@use 'assets/styles/utils/helper' as h;

.weatherPkg {
  @include h.responsive-variations(margin-left, (s: h.rem(20px), m: 0));
  @include h.responsive-variations(margin-right, (s: h.rem(20px), m: 0));
  padding-bottom: h.rem(10px);
}

.weatherContainer {
  min-height: 225px;
}

.header {
  @include h.responsive-variations(margin-bottom, (s: h.rem(16px), m: h.rem(20px)));
  border-bottom: 1px solid var(--grey-30);
  color: var(--grey-70);
}

.location {
  font-family: var(--primary-font);
  line-height: 150%;
  font-weight: normal;
  font-size: h.rem(32px);
  margin-right: h.rem(16px);
}

.editZip {
  font-family: var(--secondary-font);
  font-size: h.rem(16px);
  line-height: 150%;
  border: none;
  background: transparent;
  text-decoration-color: var(--accent-orange);
  text-decoration-line: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  padding: 0;

  &:hover {
    color: var(--primary-color);
  }
}

.container {
  @include h.responsive-variations(padding, (
    s: h.rem(12px 0 30px),
    m: h.rem(24px 0 30px),
    l: h.rem(25px 0),
  ));
  border-top: 1px solid var(--grey-40);
  border-bottom: 1px solid var(--grey-40);
}

.errorContainer {
  position: relative;

  :global(.local-news-weather__error) {
    margin: 0 h.rem(100px) 0 0;
  }

  .editZip {
    position: absolute;
    top: 25px;
    right: 0;
  }

  p {
    font-family: var(--secondary-font);
    font-size: h.rem(24px);
    line-height: 1.5;
  }
}

.zipContainer {
  @include h.responsive-variations(flex-direction, (s: column, m: row));
  @include h.responsive-variations(flex-wrap, (s: wrap, l: nowrap));
  display: flex;
  align-items: center;
}

.icon {
  @include h.responsive-variations(height, (s: h.rem(50px), m: h.rem(70px)));
}

.zipText {
  @include h.responsive-variations(margin, (s: h.rem(12px 0), m: h.rem(0 20px)));
  @include h.responsive-variations(flex, (s: 0 1 auto, m: 0 0 h.rem(440px), l: 1 1 h.rem(440px)));
  font-family: var(--secondary-font);
  font-size: h.rem(24px);
  line-height: 150%;
  color: var(--grey-70);
}

.form {
  @include h.responsive-variations(flex, (s: 0 1 auto, m: 1 0 h.rem(380px)));
  @include h.responsive-variations(margin-top, (m: h.rem(20px), l: 0));
  @include h.responsive-variations(width, (s: 100%, m: auto));
  display: flex;
  justify-content: flex-end;
}

.input {
  @include h.responsive-variations(flex, (s: 1 1 auto, m: 0 0 h.rem(230px)));
  height: h.rem(50px);
  width: 100%;
  border: 1px solid var(--grey-60);
  border-right: none;
  font-family: var(--secondary-font);
  font-size: h.rem(24px);
  line-height: 150%;
  color: var(--grey-70);
  padding: h.rem(2px 10px);
}

.submitZip {
  flex: 0 0 h.rem(150px);
  background-color: transparent;
  font-family: var(--secondary-font);
  font-size: h.rem(20px);
  color: var(--grey-70);
  border: 1px solid var(--primary-color);
  height: h.rem(50px);
  width: h.rem(150px);
  text-transform: uppercase;

  &:hover {
    background-color: var(--accent-orange);
    border-color: var(--accent-orange);
    color: var(--white);
  }
}

.loading {
  width: 100%;
  animation: fadeIn 1s infinite alternate;
}

.loadingHeader {
  @include h.responsive-variations(margin-bottom, (s: h.rem(16px), m: h.rem(20px)));
  background: var(--grey-30);
  width: 40%;
  height: h.rem(49px);
}

.loadingWeather {
  @include h.responsive-variations(height, (s: h.rem(161px), m: h.rem(147px)));
  background: var(--grey-30);
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
