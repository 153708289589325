@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.waffleBadge {
  background-color: var(--waffle-badge--background-color);
  color: var(--white);
  height: var(--space-80);
  min-width: var(--space-80);
  padding: var(--space-8);
}

.top {
  font-family: var(--publico-hed);
  font-size: var(--text-18);
  line-height: var(--leading-100);
  height: h.rem(18px);
}

.bottom {
  font-family: var(--waffle-badge--bottom--font-family);
  font-style: var(--waffle-badge--bottom--font-style);
  font-size: var(--text-48);
  text-align: right;
  text-transform: uppercase;
  line-height: var(--leading-100);
  height: 60%;
}

:global(body.today) {
  .centerBadge {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
