@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.collection__container {
  position: relative;
}

.collection__background-color {
  background-color: var(--collection__header--background-color);

  @each $vertical, $color in t.$collection-primary-color-map {
    .#{$vertical}-module & {
      background-color: transparentize($color: $color, $amount: 0.5);
    }
  }
}

.collection__background-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 19.74%, rgba(0, 0, 0, 0.5) 70.79%), rgba(0, 0, 0, 0.2);
}

.collection__background-picture {
  filter: grayscale(30%);
}

.collection__header {
  border-color: var(--collection__header--border-color);
  border-style: solid;
  border-width: h.rem(5px) 0 0;
  position: relative;

  @each $vertical, $color in t.$collection-modulealternate-color-map {
    .#{$vertical}-module & {
      border-color: $color;
    }
  }
}

.collection__headings {
  align-items: center;
  color: var(--white);
  margin-bottom: h.rem(32px); // Overlap with articles
  text-align: center;

  @include h.breakpoint-m {
    margin-bottom: h.rem(80px); // Overlap with articles
  }

  @include h.breakpoint-l {
    margin-bottom: h.rem(32px); // Overlap with articles
  }

  @include h.breakpoint-x {
    margin-bottom: h.rem(80px); // Overlap with articles
  }
}

.collection__articles {
  background: var(--collection-articles-background);
  margin: h.rem(-32px) h.rem(20px) 0; // Overlap with headings
  padding: h.rem(20px 20px) 0;
  position: relative;

  @include h.breakpoint-m {
    margin-top: h.rem(-80px); // Overlap with headings
  }

  @include h.breakpoint-l {
    margin-top: h.rem(-32px); // Overlap with headings
  }

  @include h.breakpoint-x {
    margin-top: h.rem(-80px); // Overlap with headings
  }
}

.collection__heading {
  font-size: var(--collection__heading--font-size);
  line-height: var(--collection__heading--line-height);
  margin: var(--collection__heading--margin);
  font-family: var(--collection__heading--font-family);
  font-style: var(--collection__heading--font-style);
  font-weight: var(--collection__heading--font-weight);
  position: relative;
  text-transform: var(--collection__heading--text-transform);
}

.collection__subheading {
  font-size: var(--collection__subheading--font-sizes);
  line-height: var(--collection__subheading--line-heights);
  font-family: var(--publico-txt);
  font-weight: normal;
  margin: 0;
}

.collection__picture {
  flex: 0 0 h.rem(80px);
  height: h.rem(80px);
  margin-right: h.rem(20px);
  position: relative;
  font-size: 0;
}

.collection__icon {
  position: absolute;
  bottom: 0;
  z-index: 2;
  background: var(--collection-icon-background-color);
  width: h.rem(30px);
  height: h.rem(30px);

  @each $vertical, $color in t.$collection-modulealternate-color-map {
    .#{$vertical}-module & {
      background: $color;
    }
  }

  @include h.breakpoint-x {
    width: h.rem(32px);
    height: h.rem(32px);
  }
}

.collection__info {
  flex-grow: 1;
}

.collection__eyebrow {
  color: var(--collection-eyebrow-color);

  @each $vertical, $color in t.$collection-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }
}

.collection__title {
  color: var(--collection__title--color);
  font-family: var(--collection__title--font-family);
  font-size: var(--collection__title--font-size);
  font-weight: var(--collection__title--font-weight);
  line-height: h.rem(20px);
  margin: 0;
}

.collection__article {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: h.rem(20px) 0;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--grey-40);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:last-child::after {
    height: h.rem(80px);
  }

  &.collection__article--hasFeaturedAuthor {
    .collection__title {
      font-family: var(--collection-featured-author-title-font-family);
      font-size: var(--collection-featured-author-title-font-size);
      font-weight: normal;
      line-height: h.rem(18px);
    }

    .collection__info {
      margin-left: h.rem(10px);
    }

    .collection__quote {
      color: var(--collection-featured-author-quote-color);
      font-size: h.rem(20px);
      margin-top: h.rem(4px);

      @each $vertical, $color in t.$collection-modulealternate-color-map {
        .#{$vertical}-module & {
          color: $color;
        }
      }
    }
  }
}

.collection__author {
  color: var(--collection-author-color);
  font-family: var(--publico-txt);
  font-size: h.rem(15px);
  font-weight: bold;
  line-height: h.rem(17px);

  &::after {
    content: ' ';
  }

  @each $vertical, $color in t.$collection-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }
}

.collection__more {
  color: var(--collection-more-color);
  font-family: var(--founders-cond);
  font-size: h.rem(20px);
  line-height: h.rem(20px);
  text-align: right;
  position: relative;
  margin-bottom: h.rem(30px);
  margin-right: h.rem(20px);
  text-transform: var(--collection-more-text-transform);

  @include h.breakpoint-m {
    margin: 0 h.rem(40px);
  }

  @include h.breakpoint-x {
    margin: 0 h.rem(60px);
  }

  &:hover {
    a {
      position: relative;

      &::before {
        background: currentColor;
        content: '';
        display: block;
        left: 0;
        width: 100%;
        height: h.rem(3px);
        position: absolute;
        bottom: h.rem(-2px);
      }
    }
  }

  @each $vertical, $color in t.$collection-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }
}

.collectionBillboard {
  .collection__headings {
    padding: h.rem(24px 40px);

    @include h.breakpoint-m {
      padding-top: h.rem(32px);
      padding-bottom: h.rem(32px);
    }

    @include h.breakpoint-x {
      padding-top: h.rem(48px);
      padding-bottom: h.rem(48px);
    }
  }

  .collection__articles {
    @include h.breakpoint-m {
      display: flex;
      flex-wrap: wrap;
    }

    @include h.breakpoint-l {
      display: block;
    }

    @include h.breakpoint-x {
      display: flex;
      padding: h.rem(30px 30px 0);
      margin-left: h.rem(30px);
      margin-right: h.rem(30px);
    }

    &::after {
      @include h.breakpoint-x {
        width: calc(100% - 60px);
        left: h.rem(30px);
      }
    }
  }

  .collection__article {
    @include h.breakpoint-m {
      flex-shrink: 1;
      flex-basis: calc(50% - 20px);
    }

    &::after {
      left: h.rem(-30px);
      width: calc(100% + 60px);
    }

    &:nth-child(even) {
      @include h.breakpoint-m {
        margin-right: h.rem(40px);
      }

      @include h.breakpoint-l {
        margin-right: 0;
      }

      @include h.breakpoint-x {
        margin-right: h.rem(40px);
      }
    }

    &:first-child {
      @include h.breakpoint-m {
        flex: 0 0 100%;
        padding-top: 0;

        &::before {
          content: none;
        }
      }

      @include h.breakpoint-x {
        padding-bottom: h.rem(30px);
      }

      .collection__picture {
        @include h.breakpoint-m {
          flex-basis: h.rem(320px);
          height: h.rem(160px);
        }

        @include h.breakpoint-l {
          flex-basis: h.rem(160px);
        }

        @include h.breakpoint-x {
          flex-basis: h.rem(360px);
          height: h.rem(180px);
        }
      }

      .collection__title {
        @include h.breakpoint-m {
          font-family: var(--collection-first-article-title-font-family);
          font-size: var(--collection-first-article-title-font-size);
          font-weight: var(--collection-first-article-title-font-weight);
          line-height: 30px;
        }
      }
    }

    &:nth-last-child(-n + 2) {
      &::after {
        @include h.breakpoint-m {
          height: h.rem(80px);
        }
      }
    }

    &:nth-last-child(2) {
      &::after {
        @include h.breakpoint-l {
          height: calc(100% + 40px);
        }

        @include h.breakpoint-x {
          height: h.rem(80px);
        }
      }
    }

    &.collection__article--hasFeaturedAuthor:first-child {
      .collection__quote {
        margin-top: h.rem(6px);
      }

      .collection__author {
        font-family: var(--publico-txt);
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;

        @include h.breakpoint-m {
          font-size: 24px;
          line-height: 30px;
        }
      }

      .collection__title {
        font-size: var(--collection-article-featured-author-title-font-sizes);
        font-family: var(--collection-article-featured-author-title-font-family);
        font-weight: normal;
        line-height: 25px;

        @include h.breakpoint-m {
          line-height: 30px;
        }
      }
    }
  }
}

.collectionPoster {
  .collection__header {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    .collection__background-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .collection__background-content {
      height: 100%;
    }
  }

  .collection__headings {
    padding: h.rem(20px 40px);
  }
}
