@use 'assets/styles/utils/helper' as h;

.listItem {
  --vli--background-color: transparent;
  --vli--display: block;
  --vli--height: auto;
  --vli--width: auto;
  --vli--margin: 0;
  --vli--hover--background-color: var(--grey-60);
  --vli--active--background-color: transparent;
  --vli--hover--after-content: '';
  --vli--image--flex-basis: auto;
  --vli--image--width: auto;
  --vli--image--min-width: none;
  --vli--image--margin-bottom: 0;
  --vli--image--aspect-ratio: unset;

  // Playing state
  --vli--playing--background: var(--default-color);
  --vli--playing--opacity: var(--opacity-70);
  --vli--playing--font-family: var(--founders-mono);

  // Line between list items
  --vli--line--background-color: var(--white);

  // Wrapper around title and duration, stacked and aligned left
  --vli--content--align-items: flex-start;
  --vli--content--display: flex;
  --vli--content--flex: auto;
  --vli--content--flex-flow: column nowrap;
  --vli--content--justify-content: flex-start;
  --vli--content--gap: 5px 0;
  --vli--content--padding: 20px;
  --vli--content--position: absolute;
  --vli--content--width: 100%;
  --vli--clamp--max-height: unset;
  --vli--clamp--overflow: auto;
  --vli--clamp--line-clamp: inherit;

  // Video title
  --vli--title--color: var(--white);
  --vli--title--font-family: var(--founders-cond);
  --vli--title--font-size: #{h.rem(18px)};
  --vli--title--line-height: var(--space-20);
  --vli--title--min-height: auto;
  --vli--title--hover--opacity: 1;
  --vli--active--image-after-content: none;

  // Video Duration
  --vli--duration--color: inherit;
  --vli--duration--font-family: var(--founders-mono);
  --vli--duration--font-size: var(--text-10);
  --vli--duration--line-height: var(--leading-100);
  --vli--duration--margin: 0;
  --vli--duration--opacity: 0.5;
  --vli--aspect-ratio: 2;

  @include h.breakpoint-m {
    --vli--height: #{h.rem(160px)};
    --vli--width: #{h.rem(320px)};
  }

  @include h.breakpoint-l {
    --vli--background-color: var(--grey-70);
    --vli--display: flex;
    --vli--height: #{h.rem(100px)};
    --vli--width: auto;
    --vli--hover--background-color: var(--grey-60);
    --vli--active--background-color: var(--grey-60);
    --vli--content--position: static;
    --vli--image--flex-basis: #{h.rem(200px)};
    --vli--clamp--max-height: calc(var(--vli--title--line-height) * 2);
    --vli--clamp--line-clamp: 2;
    --vli--clamp--overflow: hidden;

    // Items centered vertically
    --vli--content--flex: 0 1 auto;
    --vli--content--flex-flow: column nowrap;
    --vli--content--justify-content: center;
    --vli--content--gap: 9px 0;
    --vli--content--padding: 0 24px 0 16px;

    // Maintain legacy spacing
    --vli--title--min-height: var(--space-40);

    // Legacy difference between font size and line height
    --vli--duration--font-size: #{h.rem(11px)};
    --vli--duration--line-height: #{h.rem(10px)}; // Should this be 100%?
    --vli--duration--margin: 0;
    --vli--aspect-ratio: 4;
  }

  @include h.breakpoint-x {
    --vli--height: #{h.rem(120px)};
    --vli--image--flex-basis: #{h.rem(240px)};
    --vli--clamp--max-height: calc(var(--vli--title--line-height) * 3);
    --vli--clamp--line-clamp: 3;
    // Minor padding adjustment
    --vli--content--padding: 0 24px 0 20px;
    --vli--aspect-ratio: 13 / 3;
  }

  :global(body.msnbc) & {
    --vli--line--background-color: var(--yellow-40);
    --vli--playing--background: var(--yellow-40);
    --vli--playing--opacity: 0.75;
  }

  :global(body.deportes) &,
  :global(body.noticias) & {
    --vli--title--font-size: var(--text-16);
    --vli--title--line-height: var(--leading-100);

    // Duration aligned right, below title?
    --vli--duration--font-size: var(--text-12);
    --vli--duration--line-height: var(--leading-100);
    --vli--duration--margin: 0 0 0 auto;

    @include h.breakpoint-m {
      --vli--title--font-size: var(--text-18);
      --vli--title--line-height: #{h.rem(22px)}; // Should this be 125%?
      --vli--duration--font-size: var(--text-10);
    }

    @include h.breakpoint-l {
      --vli--title--font-size: var(--text-14);
      --vli--title--line-height: #{h.rem(17px)}; // Should this be 125%?
      --vli--duration--font-size: #{h.rem(11px)};
      --vli--duration--line-height: #{h.rem(10px)}; // Should this be 100%?
      --vli--duration--margin: 0;
    }

    @include h.breakpoint-x {
      --vli--title--font-size: var(--text-16);
      --vli--title--line-height: var(--leading-125);
    }
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --vli--hover--background-color: var(--neutral-30);
    --vli--line--background-color: var(--blue-60);

    // Title and duration shown side by side, aligned to the bottom
    --vli--content--align-items: flex-end;
    --vli--content--flex-flow: row nowrap;
    --vli--content--justify-content: space-between;
    --vli--content--gap: 0 10px;
    --vli--content--padding: 20px;

    // Title differences
    --vli--title--font-size: var(--text-16);
    --vli--title--line-height: var(--leading-100);

    // Duration differences
    --vli--duration--color: var(--white);
    --vli--duration--margin: 0 0 2px 0;

    @include h.breakpoint-l {
      --vli--background-color: var(--white);
      --vli--active--background-color: var(--neutral-30);

      // Title and duration stacked and aligned left
      --vli--content--align-items: flex-start;
      --vli--content--flex-flow: column nowrap;
      --vli--content--justify-content: center;
      --vli--content--gap: 9px 0;
      --vli--content--padding: 0 24px 0 20px;

      // Title differences
      --vli--title--color: var(--blue-60);

      // Duration differences
      --vli--duration--color: var(--grey-50);
      --vli--duration--font-size: var(--text-12);
      --vli--duration--margin: 0;
    }
  }

  :global(body.today) & {
    --vli--display: flex;
    --vli--height: auto;
    --vli--title--color: var(--grey-70);
    --vli--title--font-family: var(--secondary-font);
    --vli--title--font-size: #{h.rem(16px)};
    --vli--title--line-height: 1.5;
    --vli--title--hover--opacity: 0.7;
    --vli--title--min-height: auto;
    --vli--image--width: 32.5%;
    --vli--image--min-width: #{h.rem(122px)};
    --vli--image--aspect-ratio: 2;
    --vli--hover--background-color: transparent;
    --vli--line--background-color: var(--grey-50);
    --vli--hover--after-content: none;
    --vli--playing--background: transparent;
    --vli--playing--font-family: var(--secondary-font);
    --vli--active--background-color: transparent;
    --vli--active--image-after-content: 'NOW PLAYING';
    --vli--content--justify-content: center;
    --vli--content--gap: 0 10px;
    --vli--content--padding: 0 20px;
    --vli--content--position: static;
    --vli--content--flex-flow: column wrap;
    --vli--margin: #{h.rem(0 0 12px 0)};
    --vli--content--align-items: flex-start;
    --vli--clamp--line-clamp: 2;
    --vli--clamp--overflow: hidden;

    // we cannot use the list item aspect ratio for today
    --vli--aspect-ratio: unset;

    @include h.breakpoint-m-only {
      --vli--image--margin-bottom: #{h.rem(16px)};
      --vli--content--justify-content: flex-start;
      --vli--content--padding: 0;
    }

    @include h.breakpoint-m {
      --vli--display: block;
      --vli--width: #{h.rem(220px)};
      --vli--margin: #{h.rem(0 0 0 20px)};
      --vli--image--width: auto;
      --vli--image--flex-basis: 12.2%;
      --vli--content--flex: 0 1 auto;
      --vli--clamp--max-height: calc(var(--vli--title--line-height) * 2);
      --vli--clamp--line-clamp: 3;
    }

    @include h.breakpoint-l {
      --vli--display: flex;
      --vli--width: unset;
      --vli--margin: #{h.rem(0 0 12px 0)};
      --vli--background-color: var(--white);
      --vli--content--gap: 9px 0;
      --vli--clamp--max-height: calc(var(--vli--title--line-height) * 2);
      --vli--clamp--line-clamp: 2;
      --vli--image--flex-basis: auto;
      --vli--content--width: 61.5%;
      --vli--content--flex: 0 0 auto;
      --vli--image--width: 38.5%;
    }

    @include h.breakpoint-x {
      --vli--content--width: 55.75%;
      --vli--image--width: 44.25%;
      --vli--clamp--line-clamp: 3;
      --vli--margin: #{h.rem(0 0 15px 0)};
    }

    @include h.breakpoint-xx {
      --vli--content--width: 60%;
      --vli--image--width: 40%;
      --vli--margin: #{h.rem(0 0 20px 0)};
    }
  }
}
