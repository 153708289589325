@use 'assets/styles/utils/helper' as h;

.waffleBadge {
  --waffle-badge--background-color: var(--default-color);
  --waffle-badge--bottom--font-family: var(--founders-cond);
  --waffle-badge--bottom--font-style: normal;

  :global(body.news) & {
    --waffle-badge--background-color: var(--blue-40);
  }

  :global(body.noticias) & {
    --waffle-badge--background-color: var(--blue-60);
  }

  :global(body.globalcitizen) & {
    --waffle-badge--background-color: var(--red-40);
  }

  :global(body.today) &,
  :global(body.think) & {
    --waffle-badge--bottom--font-family: var(--publico-head);
    --waffle-badge--bottom--font-style: italic;
  }

  :global(body.today) & {
    --waffle-badge--background-color: var(--grey-70);
    --waffle-badge--bottom--font-family: var(--primary-font);
    --waffle-badge--bottom--font-style: normal;
  }
}
