@use 'assets/styles/utils/helper' as h;

.eventCard {
  display: flex;
  flex-direction: column;
  z-index: 0;

  &::after,
  &::before {
    content: '';
    display: block;
  }

  // dot
  &::before {
    order: 1;
    background-color: var(--blue-40);
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 14px;
  }

  // arrow
  &::after {
    order: 2;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 8px;
    border-color: transparent transparent var(--neutral-10) transparent;
    margin-left: 12px;
  }
}

.eventContent {
  width: 288px;
  background-color: var(--neutral-10);
  order: 3;
  padding: var(--space-12);
  display: grid;
  grid-template-rows: auto 96px;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'headline headline'
    'image text';
  font-family: var(--publico-txt);
}

.eventContent.hasCTA {
  grid-template-rows: auto 72px auto;
  grid-template-areas:
    'headline headline'
    'image text'
    'image cta';

  &:hover {
    background-color: var(--grey-20);
  }

  &:active {
    background-color: var(--grey-30);
  }
}

.eventHeadline,
.eventText {
  // stylelint-disable comment-empty-line-before
  /*! autoprefixer: off */
  display: -webkit-box; // stylelint-disable value-no-vendor-prefix
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  // stylelint-disable-next-line declaration-block-no-duplicate-properties
  display: box;
}

.eventHeadline {
  font-family: var(--founders-cond);
  font-weight: var(--semibold);
  font-size: var(--text-18);
  line-height: var(--leading-100);
  margin: 0 0 8px;
  grid-area: headline;
  -webkit-line-clamp: 1;
}

.eventContent.hasCTA .eventTitle {
  margin-bottom: 8px;
}

.eventText {
  display: box;
  margin: 0;
  font-size: var(--text-16);
  line-height: var(--leading-150);
  grid-area: text;
  -webkit-line-clamp: 4;
}

.eventContent.hasCTA .eventText {
  -webkit-line-clamp: 3;
}

.eventArt {
  grid-area: image;
  margin-right: 8px;
  position: relative;
}

.eventImage {
  width: 96px;
  display: block;

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.eventCTA {
  grid-area: cta;
  font-size: var(--text-14);
  line-height: var(--leading-100);
  font-family: var(--founders-cond);
  text-transform: uppercase;
  color: var(--grey-60);
  margin-top: 10px;
  justify-self: end;
  text-decoration: none;
}

.playIcon {
  position: absolute;
  bottom: 0;
}
