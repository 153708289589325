/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

$column-sizes: 1, 2, 3, 4, 5;

/* $max-row is an arbitrary upper limit, set specifically to fix CSS grid on IE11.  This number can be changed, if need be.
IE11 requires cells to be explicityly assigned to rows and columns for grid to display properly. */
$max-row: 50;

.package-grid {
  &.package-grid--zone-type-normal,
  &.package-grid--zone-type-railAdjacent {
    margin-bottom: var(--space-48); // add package spacing

    .package-grid__column {
      position: relative;
      display: flex;
      border-top: 1px solid transparent; // space for pseudoelement below
      padding-top: var(--space-20);
      margin-top: var(--space-20);

      // Small and medium breakpoints must use pseudoelement to allow the packages below
      // to dictate their own gutter display spacing.
      &::before {
        content: '';
        position: absolute;
        width: calc(100% - var(--space-40));
        height: 1px;
        background-color: var(--grey-40);
        top: 0;
        left: var(--space-20);

        // M layouts include gutter
        @include h.breakpoint-m {
          width: 100%;
          left: 0;
        }
      }
    }

    .pkg {
      margin-bottom: var(--space-28); // resize individual package spacing

      &:last-child {
        margin-bottom: 0;

        @include h.breakpoint-l {
          margin-bottom: var(--space-28); // resize individual package spacing
        }
      }
    }

    @include h.breakpoint-l {
      display: -ms-grid;
      display: grid;
      padding-top: var(--space-36);
      border-top: var(--pkg-border-top);

      .package-grid__column {
        margin-top: 0;
        border-top: none;
        border-right: var(--pkg-border-right);
        border-bottom: var(--pkg-border-bottom);
        padding: 0 var(--space-20);

        &::before {
          content: none;
        }
      }

      @each $column-size in $column-sizes {
        &.package-grid--columns-#{$column-size} {
          grid-template-columns: repeat($column-size, 1fr);
          grid-template-rows: auto;

          /* Every item after the first row (item wrap) */
          .package-grid__column:nth-child(n+#{$column-size + 1}) {
            margin-top: var(--space-36);
          }

          /* IE11 requires cells to be explicityly assigned to rows and columns */

          @for $row from 1 through $max-row {
            /* first column in row */
            .package-grid__column:nth-child(#{$column-size * ($row - 1) + 1}) {
              padding-left: 0;
              -ms-grid-row: #{$row};
              -ms-grid-column: 1;
            }

            /* middle columns in row */
            @for $col from 2 to $column-size {
              .package-grid__column:nth-child(#{$column-size * ($row - 1) + $col}) {
                -ms-grid-row: #{$row};
                -ms-grid-column: #{$col};
              }
            }

            /* last column in row */
            .package-grid__column:nth-child(#{$column-size * ($row - 1) + $column_size}) {
              border-right: none;
              -ms-grid-row: #{$row};
              -ms-grid-column: #{$column-size};
            }
          }
        }
      }
    }
  }

  &.package-grid--zone-type-rail {
    margin-bottom: 0; // reset margin bottom since it's on .pkg
  }
}
