@use 'assets/styles/utils/helper' as h;

.msnbcDailyBorder {
  // container rules
  background-image: linear-gradient(to right, var(--blue-40), var(--blue-40) 8px, var(--red-30) 8px, var(--red-30) 16px, #fff 16px, #fff 24px, var(--yellow-40) 24px);
  content: ' ';
  display: block;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
