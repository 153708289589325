@use 'assets/styles/utils/helper' as h;

.smorgasbord__headline {
  display: inline-block;
  font-size: var(--text-32);
  font-weight: 600;
  text-align: center;
  font-family: var(--founders-cond);
  line-height: var(--leading-100);
  color: var(--grey-70);
  margin-top: var(--space-24);
  margin-bottom: var(--space-16);
  width: 100%;

  @include h.breakpoint-m {
    font-size: var(--text-40);
  }

  @include h.breakpoint-x {
    font-size: var(--text-48);
  }

  &--cvsdk {
    margin-top: var(--space-20);
    margin-bottom: var(--space-8);
    display: inline-block;
  }
}

a:hover,
a:active {
  .smorgasbord__headline {
    opacity: var(--opacity-70);
  }
}

.smorgasbord {
  + *:not(.baguette):not(.ad) {
    margin-top: 48px;
  }

  + .ad + *:not(.baguette) {
    margin-top: 48px;
  }

  .cvsdk-tease__headline-link {
    font-size: var(--text-24);
    line-height: var(--text-24);

    @include h.breakpoint-m {
      font-size: var(--text-28);
      line-height: var(--text-28);
    }

    @include h.breakpoint-l {
      font-size: var(--text-32);
      line-height: var(--text-32);
    }
  }

  .pkg-bottom-border {
    @include h.breakpoint-s-only {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.smorgasbord--threeKeyVisuals,
.smorgasbord--threeKeyVisualsWithoutRail {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  & .smorgasbord__column1 {
    display: flex;
    flex: 1 0 100%;
    flex-direction: row;
    order: 2;
    flex-wrap: wrap;

    & > :first-child {
      @include h.breakpoint-x {
        margin-top: 0;
      }
    }
  }

  & .smorgasbord__column2 {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    order: 1;

    & > .smorgasbord-tease:first-child {
      margin-top: 0;
    }

    & > .smorgasbord-tease--tag:first-child {
      margin-top: 28px; // 12px is half the absolutely positioned tag + 16px design token = 28px

      @include h.breakpoint-m {
        margin-top: 0;
      }
    }
  }

  & .smorgasbord__column3 {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    order: 3;

    & > :first-child {
      margin-top: var(--space-32);

      @include h.breakpoint-m {
        margin-top: 0;
      }
    }
  }

  @include h.breakpoint-m-only {
    & .smorgasbord__column1 {
      flex: 1 0 100%;
      flex-direction: row;
      order: 3;
      margin-right: 0;
    }

    & .smorgasbord__column2 {
      flex: 1 0 400px;
      flex-direction: column;
      order: 1;
      margin-right: var(--space-40);
    }

    & .smorgasbord__column3 {
      flex: 1 1 200px;
      flex-direction: column;
      order: 2;
    }
  }

  @include h.breakpoint-l-only {
    flex-direction: row;

    & .smorgasbord__column1 {
      flex: 0 0 100%;
      flex-direction: row;
      order: 3;
      margin-right: 0;
    }

    & .smorgasbord__column2 {
      flex: 1 1 calc(67% - var(--space-20));
      flex-direction: column;
      order: 1;
      margin-right: var(--space-40);
    }

    & .smorgasbord__column3 {
      flex: 1 1 calc(33% - var(--space-20));
      flex-direction: column;
      order: 2;
    }
  }

  @include h.breakpoint-x {
    flex-direction: row;

    & .smorgasbord__column1 {
      display: flex;
      flex: 1 1 calc(20% - var(--space-20));
      flex-direction: column;
      margin-right: var(--space-40);
      order: 1;
    }

    & .smorgasbord__column2 {
      display: flex;
      flex: 1 1 calc(60% - var(--space-40));
      flex-direction: column;
      margin-right: var(--space-40);
      order: 2;
    }

    & .smorgasbord__column3 {
      display: flex;
      flex: 1 1 calc(20% - var(--space-20));
      flex-direction: column;
      order: 3;
    }
  }
}

.smorgasbord--threeKeyVisuals + .pkg-ad {
  @include h.breakpoint-s-only {
    margin-top: var(--space-48);
  }
}
