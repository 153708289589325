@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.container {
  padding: var(--container--padding);
  position: relative;

  &::before {
    background-color: var(--container--before--background-color);
    content: var(--container--before--content);
    display: var(--container--before--display);
    height: var(--container--before--height);
    left: var(--container--before--left);
    position: var(--container--before--position);
    top: var(--container--before--top);
    width: var(--container--before--width);
    z-index: var(--container--before--z-index);
  }
}

.content {
  @include h.clearfix;
  align-items: var(--content--align-items);
  display: var(--content--display);
  flex-flow: row var(--content--flex-wrap);
  min-height: var(--content--min-height);
  overflow: var(--content--overflow);
  position: relative;
  z-index: 1;
}

.picture {
  display: block;
  flex: var(--picture--flex);
  line-height: 0;
  order: var(--picture--order);
  position: var(--picture--position);
  top: var(--picture--top);
  right: var(--picture--right);
  width: var(--picture--width);
  z-index: var(--picture--z-index);
}

.info {
  flex: var(--info--flex);
  margin: var(--info--margin);
  order: var(--info--order);
  position: relative;
  width: var(--info--width);
  z-index: var(--info--z-index);

  &::before {
    background-color: var(--info--before--background-color);
    border: var(--info--before--border);
    bottom: var(--info--before--bottom);
    content: var(--info--before--content);
    display: var(--info--before--display);
    height: var(--info--before--height);
    left: var(--info--before--left);
    min-height: var(--info--before--min-height);
    pointer-events: none;
    position: var(--info--before--position);
    right: var(--info--before--right);
    top: var(--info--before--top);
    width: var(--info--before--width);
    z-index: var(--info--before--z-index);
  }

  &::after {
    background-color: var(--info--after--background-color);
    content: var(--info--after--content);
    display: var(--info--after--display);
    height: var(--info--after--height);
    left: var(--info--after--left);
    pointer-events: none;
    position: var(--info--after--position);
    top: var(--info--after--top);
    width: var(--info--after--width);
    z-index: var(--info--after--z-index);
  }
}

.infoInner {
  background-color: var(--info--inner--background-color);
  padding: var(--info--inner--padding);
  position: relative;
  z-index: var(--info--inner--z-index);
}

.iconLink {
  display: inline-block;
}

.unibrow {
  bottom: var(--unibrow--bottom);
  color: var(--default-color);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  font-weight: var(--normal);
  left: var(--unibrow--left);
  line-height: var(--leading-100);
  margin: var(--unibrow--margin);
  position: var(--unibrow--position);
  text-transform: uppercase;
}

.title {
  color: var(--title--color);
  font-family: var(--default-headline-font-family);
  font-size: var(--title--font-size);
  font-weight: var(--title--font-weight);
  line-height: var(--default-headline-leading);
  margin: var(--title--margin);
}

.quote {
  color: var(--quote--color);
  display: var(--quote--display);
  font-size: var(--quote--font-size);
}

.featuredAuthor {
  color: var(--default-color);
  font-family: var(--founders-cond);
  font-weight: var(--semibold);
}

.featuredAuthorImage {
  bottom: 20px;
  display: var(--featured-author-image--display);
  height: auto;
  position: absolute;
  right: 19px;
  width: 100px;
}
