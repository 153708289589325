@use 'assets/styles/utils/helper' as h;

.tease {
  position: relative;
}

.link {
  display: block;
  line-height: 0;
  position: relative;
}

.play {
  bottom: 0;
  height: var(--space-32);
  left: 0;
  position: absolute;
  width: var(--space-32);

  @include h.breakpoint-m {
    height: var(--space-40);
    width: var(--space-40);
  }

  .breaking & {
    background-color: var(--breaking-red);
  }
}
