@use 'assets/styles/utils/helper' as h;

.straightUp {
  --su--inner--margin-top: 5px;
  --su--inner-before--content: '';
  --su--inner-before--display: block;
  --su--inner-before--background-color: var(--default-color);
  --su--tease-title--font-size: var(--text-24);
  --su--tease-title--line-height: var(--leading-100);
  --su--tease-dek--font-size: #{h.rem(14px)};
  --su--tease-dek--font-family: inherit;
  --su--tease-dek--color: var(--grey-60);
  --su--tease-card-info--display: block;
  --su--tease-card-info--padding: #{h.rem(30px 16px 0)};
  --su--card-picture--align-self: auto;
  --su--card-picture-link--overflow: visible;
  --su--card-picture-link--width: auto;
  --su--card-video-container--width: 100%;

  @include h.breakpoint-m {
    --su--tease-title--font-size: var(--text-40);
    --su--tease-card-info--display: flex;
  }

  @include h.breakpoint-l {
    --su--tease-title--font-size: 30px;
    --su--tease-card-info--padding: #{h.rem(40px 40px 0)};
    --su--card-content--display: flex;
    --su--tease-card-info--display: block;
    --su--card-picture-link--overflow: hidden;
    --su--card-picture-link--width: #{h.rem(520px)};
    --su--card-video-container--width: #{h.rem(520px)};
  }

  @include h.breakpoint-x {
    --su--tease-title--font-size: var(--text-40);
    --su--card-picture-link--width: #{h.rem(760px)};
    --su--card-video-container--width: #{h.rem(760px)};
  }

  :global(body.better) &,
  :global(body.think) & {
    --su--inner--margin-top: auto;
    --su--inner-before--display: none;
  }

  :global(body.msnbc) & {
    --su--inner-before--background-color: var(--yellow-40);
  }

  :global(body.noticias) &,
  :global(body.deportes) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --su--tease-title--font-size: var(--text-24);

    @include h.breakpoint-m {
      --su--tease-title--font-size: var(--text-28);
    }

    @include h.breakpoint-l {
      --su--tease-title--font-size: var(--text-28);
    }

    @include h.breakpoint-x {
      --su--tease-title--font-size: var(--text-40);
    }
  }

  &.live {
    :global(body.news) &,
    :global(body.msnbc) & {
      --su--inner-before--background-color: var(--red-30);
    }
  }

  :global(body.today) & {
    --su--inner-before--content: none;
    --su--card-content--display: block;
    --su--tease-card-info--padding: #{h.rem(16px 20px 0)};
    --su--tease-title--font-size: #{h.rem(28px)};
    --su--tease-title--line-height: 1.5;
    --su--tease-dek--font-size: #{h.rem(16px)};
    --su--tease-dek--font-family: var(--secondary-font);
    --su--tease-dek--color: var(--grey-70);
    --su--card-picture--align-self: flex-start;
    --su--card-picture-link--overflow: visible;

    @include h.breakpoint-m {
      --su--tease-card-info--display: block;
      --su--tease-card-info--padding: #{h.rem(20px 0 0)};
    }

    @include h.breakpoint-l {
      --su--tease-card-info--padding: #{h.rem(0 0 0 40px)};
    }

    @include h.breakpoint-x {
      --su--card-picture-link--width: #{h.rem(705px)};
      --su--card-video-container--width: #{h.rem(705px)};
    }
  }
}
