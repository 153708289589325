@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.media-label {
  display: flex;

  &__eyebrow {
    font-size: var(--text-12);
    font-family: var(--founders-mono);
    font-weight: var(--light);
    background-color: var(--white);
    color: var(--grey-60);
    height: h.rem(32px);
    line-height: var(--leading-100);
    margin: h.rem(-32px) 0 0;
    padding: 10px 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;

    &--slideshow {
      width: var(--media-label-slideshow-unibrow-width);
    }

    &--video {
      width: var(--media-label-video-unibrow-width);
    }
  }

  &__type-icon {
    &--inline {
      color: var(--media-label-type-icon-inline-color);
      font-size: var(--text-10);
      position: absolute;
    }

    &--block {
      align-items: center;
      display: inline-flex;
      font-size: var(--media-label-type-icon-inline-font-size);
      color: var(--white);
      height: 100%;
    }
  }

  &__icon-container {
    margin-top: h.rem(-32px);
    padding: h.rem(0 8px);
    position: relative;
    text-align: center;
    width: var(--media-label-type-icon-width);
    z-index: 2;
  }

  &__icon-background {
    background-color: var(--media-label-icon-background-color);
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
}
