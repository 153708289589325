@use 'assets/styles/utils/helper' as h;

.leadSectionFront {
  --background--background-color: var(--default-color);
  --background--height: 350px; // height with 60px mobile nav
  --content-offset--padding-top: var(--space-60);
  --header-content--justify-content: center;
  --header-content--height: 250px;
  --header-content--margin: 0 20px 48px;
  --header-title--color: var(--white);
  --header-title--font-family: var(--founders-cond);
  --header-title--font-size: var(--text-32);
  --header-title--font-style: normal;
  --header-title--font-weight: normal;
  --header-title--line-height: var(--leading-100);
  --header-title--text-transform: uppercase;
  --header-title--before-content: none;
  --header-title--before-border-color: var(--default-color);
  --header-dek--color: var(--white);

  @include h.breakpoint-m {
    --background--height: 480px; // height with 60px mobile nav
    --header-content--height: 378px;
    --header-content--margin: 0 32px 48px;
    --header-title--font-size: var(--text-48);
  }

  @include h.breakpoint-l {
    --background--height: 380px; // height with 60px small nav
    --content-offset--padding-top: var(--space-120);
    --header-content--justify-content: center;
    --header-content--height: calc(var(--background--height) - var(--content-offset--padding-top));
    --header-content--margin: 0 0 48px;
    --header-title--font-size: var(--text-60);
  }

  @include h.breakpoint-x {
    --background--height: 370px; // height with 60px small nav
    --header-content--margin: 0 0 48px;
  }
}

:global(body.news) .leadSectionFront {
  --background--background-color: var(--blue-70);
}

:global(body.better) .leadSectionFront,
:global(body.think) .leadSectionFront {
  @include h.breakpoint-l {
    --background--height: 380px; // height with 80px desktop nav
    --content-offset--padding-top: var(--space-80);
  }

  @include h.breakpoint-x {
    --background--height: 270px; // height with 80px desktop nav
  }
}

:global(body.today) .leadSectionFront {
  --background--background-color: var(--white);
  --header-content--height: 200px;
  --header-title--color: var(--purple-70);
  --header-title--font-family: var(--publico-hed);
  --header-title--font-size: var(--text-32);
  --header-title--font-style: italic;
  --header-title--font-weight: light;
  --header-title--text-transform: capitalize;
  --header-title--before-content: '';
  --header-dek--color: var(--grey-60);

  @include h.breakpoint-m {
    --header-content--height: 378px;
    --header-title--font-size: var(--text-60);
  }

  @include h.breakpoint-l {
    --background--height: 380px; // height with 60px small nav
    --header-content--height: calc(var(--background--height) - var(--content-offset--padding-top));
  }

  @include h.breakpoint-x {
    --background--height: 370px; // height with 60px small nav
  }
}

:global(body.noticias) .leadSectionFront,
:global(body.deportes) .leadSectionFront {
  --background--background-color: var(--blue-60);

  @include h.breakpoint-l {
    --background--height: 380px; // height with 60px small nav
    --content-offset--padding-top: var(--space-80);
  }

  @include h.breakpoint-x {
    --background--height: 270px; // height with 80px desktop nav
  }
}

:global(body.telemundo) .leadSectionFront,
:global(body.entretenimiento) .leadSectionFront,
:global(body.shows) .leadSectionFront {
  --background--background-color: var(--neutral-30);
  --header-title--color: var(--blue-60);
  --header-title--font-weight: var(--semibold);
  --header-title--text-transform: none;
  --header-title--before-content: '';
  --header-dek--color: var(--grey-60);

  @include h.breakpoint-l {
    --background--height: 380px; // height with 60px small nav
    --content-offset--padding-top: var(--space-80);
  }

  @include h.breakpoint-x {
    --background--height: 270px; // height with 80px desktop nav
  }
}

:global(body.sponsoredcontent) .leadSectionFront {
  --background--background-color: var(--grey-70);
  --header-content--height: 270px;

  @include h.breakpoint-m {
    --background--height: 560px;
    --header-content--height: 290px;
  }

  @include h.breakpoint-l {
    --background--height: 470px;
    --content-offset--padding-top: var(--space-80);
    --header-content--height: calc(var(--background--height) - var(--content-offset--padding-top));
    --header-content--justify-content: flex-start;
  }

  @include h.breakpoint-x {
    --background--height: 476px;
  }
}
