@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.embedPackage {
  position: relative;
}

.leadPackageAdjacent {
  background-color: var(--embed-package-bg-color);
}

.topBorder {
  border-top: solid 1px var(--grey-40);
}

.stacked {
  width: 100%;
}

.related {
  // NOTE: Give related content a little more space since embeds are often square
  margin-top: var(--space-1);
}

.embedPackage:not(.customClasses) {
  @include h.breakpoint-s-only {
    padding: 0 var(--space-20);
  }

  &.leadPackageAdjacent {
    @include h.breakpoint-m {
      padding: var(--space-24) var(--space-24) 0;
      margin-left: calc(var(--space-20) * -1);
      margin-right: calc(var(--space-20) * -1);
    }
  }

  &.topBorder {
    padding-top: var(--space-24);
  }
}

.embedBottomBorder {
  padding-top: 0;
  padding-bottom: 20px;

  @include h.breakpoint-s-only {
    padding-right: 20px;
    padding-left: 20px;
  }

  hr {
    border: none;
    border-top: 1px solid var(--grey-50);
    height: 1px;
    margin: 0;
  }
}
