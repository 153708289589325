@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.textSummary {
  color: var(--text-summary--color);
  font-family: var(--text-summary--font-family);
  font-size: var(--text-summary--font-size);
  line-height: var(--default-text-leading);
  margin: 0;
  padding: 12px 0 0;

  a {
    border-bottom: 1px solid var(--default-color);
    opacity: 1;
    padding-bottom: 2px;
    position: relative;
    transition: opacity 100ms linear, color 100ms linear;

    &:hover {
      color: var(--default-color);
    }

    &:active {
      opacity: var(--default-active-opacity);
    }
  }
}

.moreInfoWrapper {
  transition: height 0.1s ease-in, opacity 0.1s ease-in;
}

// stylelint-disable-next-line no-descending-specificity
.relatedLink a {
  color: var(--default-color);
  font-family: var(--founders-cond);
  font-size: var(--text-18);
  font-weight: var(--semibold);
  line-height: var(--leading-125);
  border-bottom: solid h.rem(2px) var(--accent-orange);
  text-transform: uppercase;

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}

.relatedLink span {
  &::before {
    font-size: var(--text-12);
    margin-left: h.rem(4px);
    vertical-align: initial;
  }
}
