@use 'assets/styles/utils/helper' as h;

.liveVideoPromo {
  padding: 0 var(--space-20);

  @include h.breakpoint-m {
    padding: 0;
  }

  .cta {
    padding: var(--space-16) var(--space-4);
  }
}

.heading {
  margin-bottom: var(--space-4);
  position: relative;
}

.countDownSignIn {
  background-color: transparent;
  border: none;
  color: var(--grey-70);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  font-style: normal;
  font-weight: normal;
  letter-spacing: var(--tracking-tight);
  line-height: var(--leading-100);
  padding: var(--space-4) 0 var(--space-8) 0;
  text-transform: uppercase;

  @include h.breakpoint-m-only {
    display: inline-block;
  }
}

.content {
  @include h.breakpoint-m-only {
    display: grid;
    gap: 60px;
    grid-template-columns: 50% 1fr;
  }
}

.player {
  display: block;
  position: relative;
  aspect-ratio: 16 / 9;
  width: 100%;
}

.playerAnimated video {
  width: 100%;
}

.link {
  display: block;
  line-height: 0;
  pointer-events: auto;

  iframe {
    pointer-events: none;
  }
}

.lock {
  background-color: var(--yellow-40);
  border: none;
  left: 0;
  padding: 12px 9px;
  position: absolute;
  top: 0;
  z-index: 5;

  &:active {
    opacity: var(--default-active-opacity);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}

.liveVideoPromo :global(.icon-volume-level-0) {
  border: none;

  &:active {
    opacity: var(--default-active-opacity);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}
