@use 'assets/styles/utils/helper' as h;

.copy-to-clipboard {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    display: flex;
    align-items: center;
  }

  &__tooltip {
    background-color: var(--white);
    border: 1px solid var(--grey-50);
    border-radius: 3px;
    color: var(--grey-70);
    display: none;
    font-size: var(--text-14);
    line-height: var(--text-16);
    opacity: 0;
    padding: var(--space-8);
    position: absolute;
    transition: opacity 100ms linear;
    font-family: var(--secondary-font);
  }

  .copied + &__tooltip {
    display: block;
    opacity: 1;
  }

  .arrow-top {
    background: var(--white);
    border: 1px solid var(--grey-50);
    margin-top: var(--space-28);
    position: absolute;
    z-index: 1;
  }

  .arrow-top::after,
  .arrow-top::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow-top::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--white);
    border-width: 10px;
    margin-left: -10px;
  }

  .arrow-top::before {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: var(--grey-50);
    border-width: 11px;
    margin-left: -11px;
  }
}
