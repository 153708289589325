@use 'assets/styles/utils/helper' as h;

.key-updates {
  --key-updates-title-font-family: var(--founders-cond);
  --key-updates-title-font-size: #{h.rem(20px)};
  --key-updates-title-font-line-height: #{h.rem(20px)};
  --key-updates-title-font-weight: normal;
  --key-updates-title-color: var(--grey-60);
  --key-updates-title-border-top-color: var(--grey-40);
  --key-updates-title-pseudo-bg-color: var(--default-color);
  --key-updates-list-color: var(--grey-60);
  --key-updates-list-font-size: #{h.rem(14px)};
  --key-updates-list-line-height: 1.5;
  --key-updates-breaking-color: var(--breaking-red);
  --key-updates-button-width: #{h.rem(240px)};

  @include h.breakpoint-m {
    --key-updates-button-width: #{h.rem(210px)};
  }

  @include h.breakpoint-l {
    --key-updates-button-width: #{h.rem(240px)};
  }

  body.today & {
    --key-updates-title-font-size: #{h.rem(15px)};
    --key-updates-title-font-family: var(--secondary-font);
  }

  body.think & {
    --key-updates-title-font-size: #{h.rem(15px)};
    --key-updates-title-pseudo-bg-color: var(--white);
  }

  body.mach &,
  body.better &,
  body.globalcitizen & {
    --key-updates-title-pseudo-bg-color: var(--white);
  }

  body.noticias &,
  body.deportes &,
  body.telemundo &,
  body.entretenimiento &,
  body.shows & {
    --key-updates-breaking-color: var(--red-40);
    --key-updates-title-font-size: #{h.rem(20px)};
    --key-updates-title-font-line-height: #{h.rem(20px)};
    --key-updates-title-font-weight: 600;
    --key-updates-title-color: var(--blue-70);
    --key-updates-list-font-size: #{h.rem(14px)};
    --key-updates-list-line-height: #{h.rem(21px)};

    @include h.breakpoint-x {
      --key-updates-list-font-size: #{h.rem(16px)};
      --key-updates-list-line-height: #{h.rem(24px)};
    }
  }
}
