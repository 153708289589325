@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.schedule-live {
  white-space: nowrap;
  height: 78px;
  width: 100%;
  display: flex;
  position: relative;
  margin: 26px 0 20px;
  padding-left: 16px;
  overflow-y: hidden;
  background-color: transparent;

  &__placeholder {
    height: 78px;
    margin: 26px 0 20px;
    width: 100%;
  }

  &__scroll-container {
    height: calc(100% + 18px); /* Hide horizontal scrollbar */
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    background-color: transparent;
  }

  &__episode {
    display: inline-block;
    padding: 10px;
    margin-right: 2px;
    height: 100%;
    background-color: var(--schedule-live__episode-background-color);
    min-width: 170px;
    max-width: 170px;
    position: relative;

    &:last-of-type {
      margin-right: 40px;
    }
  }

  &__time {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.04em;
    font-family: var(--schedule-live__time-font-family);
  }

  &__red-line {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    overflow: visible;
    background-color: rgb(235, 50, 35);
    z-index: 1;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: -5px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid rgb(235, 50, 35);
    }
  }

  &__red-dot {
    border-radius: 100%;
    background-color: rgb(235, 50, 35);
    animation: live-dot-blink 1.5s ease-in-out infinite;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 6px;
    position: relative;
    bottom: 1px;
  }

  &__title {
    white-space: normal;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-clamp: 2;
    font-size: 16px;
    line-height: 18px;
    font-family: var(--schedule-live__title-font-family);
    font-weight: var(--schedule-live__title-font-weight);
  }

  &__btn {
    border: none;
    position: absolute;
    height: 100%;
    background-color: var(--schedule-live__btn-background-color);
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
  }

  &__btn--prev {
    top: 0;
    left: 0;
    padding-right: 24px;
  }

  &__btn--next {
    top: 0;
    right: 0;
    padding-left: 24px;
  }

  &__btn-line {
    position: absolute;
    height: 100%;
    z-index: 1;
    top: 0;
    border-right: 1px solid #ccc;
  }

  &__btn-line--prev {
    right: 12px;
  }

  &__btn-line--next {
    left: 12px;
  }

  &__marker {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;

    &-prev {
      left: 41px;
    }

    &-next {
      right: 41px;
    }
  }
}

@include h.breakpoint-m {
  .schedule-live {
    padding-left: 30px;
  }
}

@include h.breakpoint-l {
  .schedule-live {
    margin-top: 0;
    padding-left: 0;

    &__btn {
      visibility: visible;
      pointer-events: inherit;

      &:active svg {
        opacity: .3;
      }
    }
  }
}
