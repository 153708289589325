@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.one-up {
  background-color: var(--white);
  position: relative;

  @include h.breakpoint-l {
    background-color: rgba(var(--white-rgb), 0);
  }

  &.one-up--has-accent-line {
    border-top: var(--one-up-accent-line-border-top);
  }

  .pkg-bottom-border {
    @include h.breakpoint-s-only {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.one-up__slideshow-pictures {
  background-color: var(--one-up-slideshow-pictures-background);
  display: flex;
  flex-wrap: wrap;
  width: 100vw;

  @include h.breakpoint-m {
    position: relative;
    padding-left: 50%;
    width: auto;
  }

  &::before {
    font-family: var(--founders-cond);
    font-weight: normal;
    font-size: h.rem(22px);
    line-height: h.rem(22px);
    background-color: var(--one-up-slideshow-pictures-background);
    content: attr(data-count);
    color: var(--white);
    flex-basis: 50%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include h.breakpoint-m {
      order: 3;
    }
  }
}

.one-up__slideshow-picture {
  flex-basis: 50%;

  &:first-child {
    order: 1;

    @include h.breakpoint-m {
      position: absolute;
      left: 0;
      width: 50%;
    }
  }

  &:nth-child(2) {
    order: 3;

    @include h.breakpoint-m {
      order: 2;
    }
  }

  &:nth-child(3) {
    order: 4;

    @include h.breakpoint-m {
      flex-basis: 100%;
    }
  }
}

.tease-card--one-up {
  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(40px), l: h.rem(40px)));
    line-height: var(--one-up-tease-card-title-line-height);

    &--think,
    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px), l: h.rem(32px), x: h.rem(32px)));
    }

    .sponsoredcontent {
      font-family: var(--founders-cond);
      font-weight: normal;
    }

    .tease-card__headline--deepLink-episode {
      @include h.breakpoint-l {
        font-size: h.rem(32px);
        line-height: var(--leading-100);
      }
    }
  }

  .tease-card--no-main-image {
    .tease-card__title {
      @include h.responsive-variations(font-size,  (s: h.rem(28px), m: h.rem(48px), x: h.rem(60px)));

      &--today {
        @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(48px), x: h.rem(60px)));
      }
    }
  }
}

.pkg-index-rail.tease-card--one-up {
  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(40px), l: h.rem(32px), x: h.rem(40px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px), x: h.rem(32px)));
    }
  }

  .tease-card--no-main-image .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(48px), l: h.rem(40px), x: h.rem(48px)));

    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(48px), l: h.rem(32px), x: h.rem(48px)));
    }
  }
}

.stickyAdTrack--7up .one-up--7up .tease-card .tease-card__info .tease-card__dek {
  line-height: h.rem(21px) !important;
}
