@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.waffleHeaderCollectionLead {
  color: var(--white);
}

.waffleHeader {
  margin: var(--waffle-header--margin);
}

.dek {
  color: var(--waffle-header--dek--color);
  line-height: var(--leading-150);
}

.description {
  font-family: var(--waffle-header--description--font-family);
  font-size: var(--waffle-header--description--font-size);
  font-weight: var(--waffle-header--description--font-weight);
  margin: var(--waffle-header--description--margin);
  color: var(--waffle-header--description--color);

  .short {
    @include h.breakpoint-m {
      display: none;
    }
  }

  .long {
    display: none;

    @include h.breakpoint-m {
      display: block;
    }
  }
}

.disclaimerModal {
  font-size: h.rem(14px);
  font-style: italic;
  margin: 20px 0 0;
}

.disclaimerText {
  color: var(--waffle-header--disclaimer--color);
  font-family: var(--waffle-header--disclaimer--font-family);
  font-size: var(--waffle-header--disclaimer--font-size);
  font-weight: var(--normal);
  line-height: var(--waffle-header--disclaimer--line-height);
  margin: var(--waffle-header--disclaimer--margin);
  max-width: var(--waffle-header--disclaimer--max-width);
  padding: var(--waffle-header--disclaimer--padding);

  :global(.disclaimer__lead-in) {
    font-family: var(--disclaimer-leadin--font-family);
    font-size: var(--text-14);
    font-weight: var(--semibold);
  }

  a {
    color: var(--waffle-header--disclaimer-link--color);
    outline: none;
    text-decoration: var(--waffle-header--disclaimer-link--text-decoration);
    text-decoration-color:
      var(
        --waffle-header--disclaimer-link--text-decoration-color
      );
    text-underline-position: under;
  }

  a:hover {
    opacity: var(--default-hover-opacity);
  }
}

.separator {
  border-bottom: 2px solid #ccc;
  content: ' ';
  display: var(--waffle-header--separator--display);
  margin-top: var(--space-24);
  width: 230px;
}

.overlayBg {
  display: none;

  &.open {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    min-height: 100%;
    z-index: 10;
    cursor: pointer;

    @include h.breakpoint-l {
      background-color: rgba(var(--black-rgb), 0.05);
    }
  }
}

.filters {
  background-color: var(--waffle-header--filters--background-color);
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  margin: var(--waffle-header--filters--margin);
  -webkit-overflow-scrolling: touch;
  position: fixed;
  max-width: h.rem(340px);
  z-index: 11;
  padding: 25px 0 90px;

  @include h.breakpoint-l {
    background-color: transparent;
    column-gap: var(--waffle-header--column-gap);
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-y: visible;
    -webkit-overflow-scrolling: unset;
    position: relative;
    padding: 25px 0 0;
  }

  & > * {
    flex-shrink: 0;
  }

  &.afterDek {
    margin: var(--waffle-header--filters-after-dek--margin);
  }

  &.open {
    width: 340px;

    @include h.breakpoint-m-max {
      background-color: var(--white);
      bottom: 0;
      display: flex;
      margin: 0;
      right: 0;
      top: 0;
      z-index: 100000;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.mobileFiltersCTA {
  display: block;
  margin: var(--waffle-header--filters--margin);
  text-align: center;

  @include h.breakpoint-l {
    display: none;
  }
}

.results {
  display: none;
  margin: var(--waffle-header--results--margin);
  flex-wrap: wrap;

  .mobileFiltersCTA & {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    justify-content: center;
  }

  @include h.breakpoint-l {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tagList {
  margin-bottom: h.rem(10px);
  width: 100%;
}

.tagListBtn,
.clearAllBtn {
  background: transparent;
  border: none;
  font-family: var(--waffle-header--tag--font-family);
  font-size: var(--waffle-header--tag--font-size);
  font-weight: var(--waffle-header--tag--font-weight);
  line-height: var(--waffle-header--tag--line-height);
  white-space: nowrap;
  padding: 0;
}

.tagListBtn {
  margin-right: 16px;
  padding: 0;
  color: var(--waffle-header--tag--color);

  &:hover {
    opacity: var(--default-hover-opacity);
  }
}

.clearAllBtn {
  color: var(--waffle-header--clear-all--color);
  text-decoration: var(--waffle-header--clear-all--text-decoration);
  text-decoration-color: var(--waffle-header--clear-all--decoration-color);
  text-decoration-thickness: var(--waffle-header--clear-all--decoration-thickness);
  text-underline-offset: var(--waffle-header--clear-all--underline-offset);

  &:hover {
    opacity: var(--default-hover-opacity);
    color: var(--waffle-header--clear-all-hover--color);
    text-decoration-color: var(--waffle-header--clear-all--decoration-color);
  }
}

.resultsCount {
  color: var(--waffle-header--tag--color);
  font-family: var(--waffle-header--tag--font-family);
  font-size: var(--waffle-header--tag--font-size);
  font-weight: var(--waffle-header--tag--font-weight);
  line-height: var(--waffle-header--tag--line-height);
}

.resultsSeparator {
  margin: 0 h.rem(8px);
  color: var(--waffle-header--tag--color);

  @include h.breakpoint-m {
    margin: 0 h.rem(12px);
  }
}

.iconDelete:global(.icon) {
  font-size: h.rem(12px);
  line-height: 1.9;
  margin: 0 h.rem(4px) 0 0;

  @include h.breakpoint-l {
    font-size: h.rem(14px);
    line-height: 1.6;
  }
}

.mobileCTABtn {
  align-items: center;
  background: var(--waffle-header--cta-button--background);
  border-radius: var(--waffle-header--cta-button--border-radius);
  border: 1px solid var(--waffle-header--cta-button--color);
  color: var(--waffle-header--cta-name--color);
  column-gap: 4px;
  display: flex;
  position: relative;
  font-family: var(--waffle-header--cta-button--font-family);
  font-size: var(--waffle-header--cta-button--font-size);
  font-weight: var(--waffle-header--cta-button--font-weight);
  height: h.rem(40px);
  justify-content: center;
  margin: 0 auto;
  padding: 9px 30px;
  text-transform: uppercase;
  width: var(--waffle-header--cta-button--width);
}

.overlayBtnWrapper {
  background-color: var(--waffle-header--overlay-btn-wrapper--background-color);
  display: flex;
  font-family: var(--waffle-header--overlay-btn-wrapper--font-family);
  font-size: h.rem(16px);
  justify-content: space-between;
  padding: h.rem(20px) h.rem(20px);
  gap: 20px;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 12;
  max-width: 340px;
  right: 0;
  border-top: 1px solid var(--grey-40);
  border-bottom-left-radius: 10px;

  @include h.breakpoint-m {
    gap: 48px 0;
  }

  @include h.breakpoint-l {
    display: none;
    border-bottom-left-radius: 0;
  }
}

.overlayBtn {
  border-radius: var(--waffle-header--overlay-btn--border-radius);
  border-width: 0;
  flex: var(--waffle-header--overlay-btn--flex);
  font-weight: var(--waffle-header--overlay-btn--font-weight);
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  width: 45%;
  max-height: 40px;
  line-height: 1;

  &.secondary {
    background-color: var(--waffle-header--overlay-btn--background-color);
    border:
      var(--waffle-header--overlay-btn--border-stroke) solid
      var(--waffle-header--overlay-btn--selected--border-color);
    color: var(--waffle-header--overlay-btn--secondary-color);
  }

  &.primary {
    background-color:
      var(
        --waffle-header--overlay-btn--selected--background-color
      );
    border:
      var(--waffle-header--overlay-btn--border-stroke) solid
      var(--waffle-header--overlay-btn--selected--border-color);
    color: var(--waffle-header--overlay-btn--primary-color);
  }

  &.disabled {
    border:
      var(--waffle-header--overlay-btn--border-stroke) solid
      var(--grey-40);
    color: var(--grey-40);
  }

  &.doneBtn {
    background-color: var(--waffle-header--overlay-done-btn-wrapper--background-color);
    border: 0;
  }
}
