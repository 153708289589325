@use 'assets/styles/utils/helper' as h;

.schedule-live {
  --schedule-live__episode-background-color: var(--grey-60);
  --schedule-live__btn-background-color: var(--grey-70);
  --schedule-live__time-font-family: var(--founders-mono);
  --schedule-live__title-font-family: var(--founders-cond);
  --schedule-live__title-font-weight: normal;

  body.today & {
    --schedule-live__episode-background-color: var(--purple-50);
    --schedule-live__btn-background-color: var(--purple-70);
    --schedule-live__time-font-family: var(--secondary-font);
    --schedule-live__title-font-family: var(--secondary-font);
    --schedule-live__title-font-weight: bold;
  }
}
