@use 'assets/styles/utils/helper' as h;

.stickyAdTrack {
  --sat-ad-col--padding: 0;

  // Design detail when title present
  --sat--content--before--background-color: var(--default-color);
  --sat--content--before--border: 0 solid transparent;
  --sat--content--before--border-width: 0;
  --sat--content--before--content: none;
  --sat--content--before--height: 0;
  --sat--content--before--left: 0;
  --sat--content--before--top: 0;
  --sat--content--before--width: 0;

  // Wrapper around package title and see more link
  --sat--head--background-color: var(--default-color);
  --sat--head--border: 0 solid none;
  --sat--head--border-width: 0;
  --sat--head--display: block;
  --sat--head--margin: 0 0 0 40px;
  --sat--head--max-width: auto;
  --sat--head--padding: 30px 20px 30px 40px;

  // Pseudo element for package title design
  --sat--head--after--content: none;
  --sat--head--after--border: 0 solid var(--default-color);
  --sat--head--after--border-width: 0;
  --sat--head--after--height: 0;
  --sat--head--after--right: 0;
  --sat--head--after--top: 0;
  --sat--head--after--width: 0;

  // Package title
  --sat--title--color: var(--white);
  --sat--title--display: none;
  --sat--title--font-family: var(--default-headline-font-family);
  --sat--title--font-size: var(--text-24);
  --sat--title--font-style: normal;
  --sat--title--font-weight: var(--normal);
  --sat--title--line-height: var(--leading-100);
  --sat--title--margin: 0 10px 0 0;
  --sat--title--text-transform: capitalize;

  // See all link
  --sat--link--border-bottom: 0;
  --sat--link--color: var(--white);
  --sat--link--font-family: var(--founders-cond);
  --sat--link--font-size: var(--text-14);
  --sat--link--line-height: var(--leading-100);
  --sat--link--padding: 0;
  --sat--link--text-transform: uppercase;

  &.hasTitle {
    // Show title
    --sat--title--display: block;

    // Padding above advertisement when title is present
    @include h.breakpoint-l {
      --sat-ad-col--padding: #{h.rem(150px)} 0 0 0;
    }
  }

  @include h.breakpoint-m {
    // Show design detail
    --sat--content--before--content: '';
    --sat--content--before--height: 100%;
    --sat--content--before--left: #{h.rem(-40px)};
    --sat--content--before--width: 63.6vw;

    // Heading is not full width
    --sat--head--display: inline-block;
    --sat--head--margin: 0;
    --sat--head--padding: 30px 20px 40px 0;

    // Other text changes
    --sat--title--font-size: var(--text-32);
    --sat--title--margin: 0 26px 0 0;
    --sat--link--font-size: var(--text-20);

    &.hasTitle {
      --sat--title--display: inline-block;
    }
  }

  @include h.breakpoint-l {
    // Design detail size changes
    --sat--content--before--height: #{h.rem(333px)};
    --sat--content--before--width: #{h.rem(480px)};

    // Other text changes
    --sat--title--font-size: var(--text-48);
  }

  :global(body.news) & {
    --sat--content--before--background-color: var(--blue-70);
    --sat--head--background-color: var(--blue-70);
    --sat--link--border-bottom: 3px solid var(--default-color);
  }

  :global(body.better) &,
  :global(body.think) & {
    --sat--link--border-bottom: 3px solid var(--white);
  }

  :global(body.mach) & {
    --sat--head--background-color: var(--purple-50);
    --sat--link--border-bottom: 3px solid var(--white);
  }

  :global(body.today) & {
    --sat--title--color: var(--default-color);
    --sat--title--font-size: var(--text-48);
    --sat--title--font-style: italic;

    @include h.breakpoint-m {
      --sat--title--font-size: var(--text-60);
    }
  }

  :global(body.msnbc) & {
    --sat--link--color: var(--yellow-40);
    --sat--link--border-bottom: 3px solid var(--white);
  }

  :global(body.noticias) &,
  :global(body.deportes) & {
    --sat--content--before--background-color: var(--blue-60);
    --sat--head--background-color: var(--blue-60);
    --sat--link--border-bottom: 3px solid var(--default-color);
  }

  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --sat--title--color: var(--default-color);
    --sat--title--font-size: var(--text-40);
    --sat--link--font-size: var(--text-20);

    @include h.breakpoint-m {
      --sat--title--font-size: var(--text-80);
    }
  }

  // Line border around package title instead of solid color
  :global(body.today) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --sat--head--border: 0 solid var(--default-color);
    --sat--head--border-width: 1px 0 0 0;
    --sat--head--background-color: transparent;
    --sat--head--display: inline-block;
    --sat--head--margin: 0;
    --sat--head--max-width: calc(100% - 60px);
    --sat--head--padding: 40px 20px;

    // Pseudo element to continue line border on right side of package title
    --sat--head--after--content: '';
    --sat--head--after--display: none;
    --sat--head--after--border-width: 1px 1px 1px 0;
    --sat--head--after--height: 65px;
    --sat--head--after--right: -59px;
    --sat--head--after--top: -1px;
    --sat--head--after--width: 60px;

    // Title spacing
    --sat--title--margin: 0 20px 0 0;

    // See all link differences
    --sat--link--color: var(--default-color);
    --sat--link--padding: 0 0 3px 0;

    &.hasTitle {
      --sat--title--display: inline-block;
    }

    @include h.breakpoint-m {
      // Pseudo element to continue line border on left side of package title
      --sat--content--before--background-color: transparent;
      --sat--content--before--border: 0 solid var(--default-color);
      --sat--content--before--border-width: 1px 0 0 0;
      --sat--content--before--content: '';
      --sat--content--before--height: 100%;
      --sat--content--before--left: #{h.rem(-20px)};
      --sat--content--before--top: 0;
      --sat--content--before--width: #{h.rem(20px)};

      // Lots of padding around package title
      --sat--head--max-width: calc(100% - 80px);
      --sat--head--padding: 40px 20px 40px 0;

      // Right side pseudo element adjustments
      --sat--head--after--height: 72px;
      --sat--head--after--right: -80px;
      --sat--head--after--width: 80px;

      // See all link adjustment
      --sat--link--padding: 0 0 8px 0;
    }

    @include h.breakpoint-l {
      // Left side pseudo element adjustments
      --sat--content--before--border-width: 1px 0 1px 1px;
      --sat--content--before--height: #{h.rem(369px)};
      --sat--content--before--left: #{h.rem(-40px)};
      --sat--content--before--width: #{h.rem(40px)};

      // Heading full width at large breakpoint
      --sat--head--display: block;
      --sat--head--max-width: auto;
      --sat--head--padding: 40px 100px 40px 0;

      // Right side pseudo element adjustments
      --sat--head--after--right: 0;
    }

    @include h.breakpoint-x {
      --sat--content--before--height: #{h.rem(362px)};

      // Heading back to content width at XL
      --sat--head--display: inline-block;
    }
  }
}
