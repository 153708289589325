@use 'assets/styles/utils/helper' as h;

.msnbcDailyCount {
  // container rules
  margin: 0 0 h.rem(8px);
  position: relative;
  width: 100%;

  @include h.breakpoint-m {
    height: 100%;
    margin: 0 h.rem(12px) 0 0;
    width: auto;
  }

  &::before {
    background-image: linear-gradient(to right, #555 20%, transparent 0);
    background-size: h.rem(5px 1px);
    content: ' ';
    display: block;
    height: h.rem(1px);
    left: 0;
    position: absolute;
    top: h.rem(8px);
    width: 100%;
    z-index: 0;

    @include h.breakpoint-m {
      background-image: none;
      content: none;
      display: none;
    }
  }

  .inner {
    font-family: var(--publico-hed);
    font-weight: normal;
    font-style: italic;
    align-items: center;
    background-color: var(--white);
    color: var(--black);
    display: flex;
    font-size: h.rem(16px);
    justify-content: center;
    line-height: 1;
    margin: 0 auto;
    position: relative;
    width: h.rem(40px);
    z-index: 1;

    @include h.breakpoint-m {
      font-size: h.rem(24px);
      justify-content: flex-end;
      margin: 0;

      &::after {
        content: '.';
      }
    }
  }
}
