@use 'assets/styles/utils/helper' as h;

.headerPackageNavigation {
  font-family: var(--default-text-font-family);
}

:global(.product-waffle) .headerPackageNavigation {
  margin: 0;
}

:global(body.today) .headerPackageNavigation {
  font-style: italic;
}

:global(body.noticias) .headerPackageNavigation,
:global(body.deportes) .headerPackageNavigation,
:global(body.telemundo) .headerPackageNavigation,
:global(body.entretenimiento) .headerPackageNavigation,
:global(body.shows) .headerPackageNavigation,
:global(body.news) .headerPackageNavigation {
  font-family: var(--founders-cond);
}

:global(body.today .collection-lead) .headerPackageNavigation {
  font-family: var(--secondary-font);
  font-style: normal;
  overflow-x: auto;
  white-space: nowrap;
}
