@use 'assets/styles/utils/helper' as h;

.list {
  --waffle-list--gap: 48px 0;
  --waffle-list--mobile--gap: 48px 2px;
  --waffle-list--grid-columns: repeat(3, 1fr);

  @include h.breakpoint-m {
    --waffle-list--gap: 40px 3px;
  }

  @include h.breakpoint-l {
    --waffle-list--gap: 40px;
  }

  :global(.product-waffle) &,
  :global(.automated-waffle) & {
    @include h.breakpoint-l {
      --waffle-list--grid-columns: repeat(4, 1fr);
    }
  }

  :global(body.today) & {
    --waffle-list--gap: 48px 15px;
    --waffle-list--mobile--gap: 48px 15px;

    @include h.breakpoint-m {
      --waffle-list--gap: 40px 30px;
    }

    @include h.breakpoint-l {
      --waffle-list--gap: 40px;
    }
  }
}
