@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens.scss';

.collectionTitle {
  background-color: var(--container--background-color);
  margin-right: var(--container--margin-right);
  margin-left: var(--container--margin-left);
  overflow: var(--container--overflow);
  padding: var(--container--padding);
  position: relative;
  width: var(--container--width);

  &.mirror {
    margin-left: var(--container--mirror--margin-left);
    margin-right: var(--container--mirror--margin-right);
  }

  &::after {
    background-color: var(--container--after--background-color);
    content: var(--container--after--content);
    left: var(--container--after--left);
    height: var(--container--after--height);
    position: var(--container--after--position);
    right: var(--container--after--right);
    top: var(--container--after--top);
    width: var(--container--after--width);
    z-index: var(--container--after--z-index);
  }

  &.mirror::after {
    left: var(--container--after--mirror--left);
    right: var(--container--after--mirror--right);
  }
}

.title {
  background: var(--title--background);
  color: var(--title--color);
  display: var(--title--display);
  font-family: var(--default-headline-font-family);
  font-size: var(--title--font-size);
  font-style: var(--title--font-style);
  font-weight: var(--title--font-weight);
  line-height: var(--title--line-height);
  letter-spacing: var(--title--letter-spacing);
  margin: 0;
  position: relative;
  text-align: var(--title--text-align);

  .mirror & {
    text-align: var(--title--mirror--text-align);
  }

  &::after {
    background: var(--title--after--background);
    content: var(--title--after--content);
    display: var(--title--after--display);
    flex: var(--title--after--flex);
    height: var(--title--after--height);
    left: var(--title--after--left);
    position: var(--title--after--position);
    right: var(--title--after--right);
    top: var(--title--after--top);
    width: var(--title--after--width);

    .mirror & {
      left: var(--title--after--mirror--left);
      right: var(--title--after--mirror--right);
    }
  }
}

.dek {
  color: var(--dek--color);
  font-family: var(--dek--font-family);
  font-size: var(--dek--font-size);
  line-height: var(--dek--line-height);
  margin: 0;
  padding: 0;
  text-align: var(--dek--text-align);

  &:not(:empty) {
    margin: var(--dek--margin);
  }

  .mirror & {
    text-align: var(--dek--mirror--text-align);
  }
}

.collectionTitle .button {
  background-image: linear-gradient(35deg, var(--button--background-color) 50%, transparent 50%);
  border: 1px solid var(--button--border-color);
  color: var(--button--text-color);
  margin: var(--button--margin);
  width: h.rem(180px);

  &:hover {
    color: var(--button--hover--background-color);
  }

  .mirror & {
    margin: var(--button--mirror--margin);
  }
}
