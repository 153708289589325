@use 'assets/styles/utils/helper' as h;
// in this file, we're using extra classes/vars instead of overriding existing
// classes/vars, as well as using hard-coded colors, in order to style by stream
// type instead of overall vertical. e.g. if, for whatever reason, an editor
// puts a Today All Day stream on a News front, it should still have Today All
// Day styling, not a News-themed variant of Today All Day styling.
:root {
  --live-video-embed-tagline-font-family: var(--publico-txt);
  --live-video-embed-tagline-font-size: #{h.rem(14px)};
  --live-video-embed-tagline-font-weight: 400;
  --live-video-embed-tagline-line-height: 1.5;
  --live-video-embed-tagline-color: var(--grey-60);
  --live-video-embed-is-rail-tagline-font-family: var(--publico-hed);
  --live-video-embed-is-rail-tagline-font-weight: 300;
  --live-video-embed-is-rail-tagline-line-height: 1.25;
  --live-video-embed-eyebrow-font-family: var(--publico-txt);
  --live-video-embed-eyebrow-font-size: #{h.rem(24px)};
  --live-video-embed-eyebrow-line-height: 1.25;
  --live-video-embed-eyebrow-color: var(--news-red-30);
  --live-video-embed-is-rail-eyebrow-font-size: #{h.rem(18px)};
  --live-video-embed-headline-font-family: var(--founders-cond);
  --live-video-embed-headline-font-size: #{h.rem(28px)};
  --live-video-embed-headline-font-weight: 600;
  --live-video-embed-headline-line-height: 1;
  --live-video-embed-is-rail-headline-font-size: #{h.rem(20px)};
  --live-video-embed-unmute-button-background: var(--news-red-30);
  // see note above for why we're using hard-coded colors here
  // stylelint-disable-next-line ramen/use-design-tokens
  --live-video-embed-unmute-button-background-hover: #fd6f6f;
  // stylelint-disable-next-line ramen/use-design-tokens
  --live-video-embed-unmute-button-background-active: #e91717;
  --live-video-embed-unmute-button-color: var(--white);
  --live-video-embed-link-opacity-hover: var(--opacity-70);
  --live-video-embed-link-opacity-active: var(--opacity-50);

  // Today All Day
  --live-video-embed-today-all-day-eyebrow-font-family: var(--founders-cond);
  --live-video-embed-today-all-day-eyebrow-font-size: #{h.rem(24px)};
  --live-video-embed-today-all-day-eyebrow-font-weight: 600;
  --live-video-embed-today-all-day-eyebrow-line-height: 1;
  --live-video-embed-today-all-day-eyebrow-color: var(--news-red-30);
  --live-video-embed-xl-today-all-day-eyebrow-font-size: #{h.rem(28px)};
  --live-video-embed-is-rail-today-all-day-eyebrow-font-size: #{h.rem(20px)};
  --live-video-embed-xl-is-rail-today-all-day-eyebrow-font-size: #{h.rem(24px)};
  --live-video-embed-today-all-day-headline-font-family: var(--publico-hed);
  --live-video-embed-today-all-day-headline-font-size: #{h.rem(20px)};
  --live-video-embed-today-all-day-headline-font-weight: 300;
  --live-video-embed-today-all-day-headline-line-height: 1.25;
  --live-video-embed-today-all-day-headline-color: #380538;
  --live-video-embed-xl-today-all-day-headline-font-size: #{h.rem(24px)};
  --live-video-embed-is-rail-today-all-day-headline-font-size: #{h.rem(18px)};
  --live-video-embed-xl-is-rail-today-all-day-headline-font-size: #{h.rem(20px)};
}

.live-video-embed {
  --live-video-embed-logo-line-color: var(--grey-40);
}

.live-video-embed--smorgasbord {
  --live-video-embed-logo-line-color: var(--grey-40);
}

.live-video-embed.live-video-embed--today-all-day {
  --live-video-embed-logo-line-color: var(--orange-30);
}
