@use 'assets/styles/utils/helper' as h;

.wrapper {
  padding: h.rem(0 0 12px 0);
  width: 100%;
  max-width: h.rem(1300px);
  min-height: 100%;

  @include h.breakpoint-l {
    padding: h.rem(0 0 40px 0);
  }

  .tab {
    background: none;
    border: none;
    cursor: pointer;
    font-family: var(--secondary-font);
    font-size: h.rem(20px);
    color: var(--grey-70);
    position: relative;
    text-transform: uppercase;
    padding: h.rem(12px 25px);

    @include h.breakpoint-m {
      padding: h.rem(12px 35px);
    }

    &::after {
      content: '';
      height: h.rem(1px);
      width: 100%;
      background-color: var(--grey-50);
      position: absolute;
      left: 0;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.25s;
    }

    &:hover {
      color: var(--primary-color);

      &::after {
        background-color: var(--primary-color);
      }
    }

    &.active {
      font-weight: bold;
      pointer-events: none;
      background: var(--neutral-orange);
      text-decoration: underline;
      text-decoration-color: var(--grey-50);
      text-underline-offset: h.rem(3px);
      text-decoration-thickness: h.rem(1px);
    }

    &.inactive {
      color: var(--grey-40);
      pointer-events: none;
    }
  }
}

.update {
  background: var(--neutral-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: h.rem(281px);
  padding: h.rem(20px 20px);
  flex-direction: column;

  @include h.breakpoint-m {
    justify-content: flex-start;
    flex-direction: row;
    padding: h.rem(20px 88px 20px 62px);
    align-items: center;
  }

  @include h.breakpoint-l {
    padding: h.rem(40px 28px);
    align-items: start;
  }

  .date {
    width: h.rem(230px);
    height: h.rem(84px);
    clip-path: polygon(24.2105px 0, 230px 0, 205.789px 119px, 0 119px, 24.2105px 0);
    background-color: #ffe1d0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    @include h.breakpoint-l {
      width: h.rem(205px);
      height: h.rem(131px);
      clip-path: polygon(26px 0, 247px 0, 221px 131px, 0 131px, 26px 0);
      flex-direction: column;
      padding-left: h.rem(15px);
      padding-top: h.rem(15px);
    }

    .month {
      font-size: h.rem(20px);
      font-weight: 700;
      text-transform: uppercase;
      font-family: var(--secondary-font);
    }

    .day {
      font-size: h.rem(48px);
      font-family: var(--primary-font);
      padding-left: h.rem(12px);

      @include h.breakpoint-l {
        padding-left: 0;
      }
    }
  }

  .lineBelowDate {
    position: absolute;
    border-bottom: h.rem(1px) solid var(--black);
    width: h.rem(10px);
    transform: matrix(1, -5, -1.27085, 0, 106.957, 59);

    @include h.breakpoint-l {
      width: h.rem(109px);
      transform: translate(h.rem(37px), h.rem(69px));
    }

    @include h.breakpoint-x {
      transform: translate(h.rem(47px), h.rem(69px));
    }
  }

  .link {
    width: 100%;
  }

  .dateAndPicture {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .tease {
      aspect-ratio: 2 / 1;
      width: 100%;
      position: relative;
    }

    .tease:hover {
      transition: transform 0.2s ease-in-out;
      transform: rotate(1deg);
    }

    @include h.breakpoint-m {
      width: unset;
      flex: 0 0 50%;
    }

    @include h.breakpoint-l {
      flex: 0 0 60%;
      flex-direction: row;
    }
  }

  .textSummary {
    @include h.breakpoint-m {
      flex: 0 0 50%;
    }

    @include h.breakpoint-l {
      flex: 0 0 40%;
    }
  }

  .text {
    font-size: h.rem(24px);
    font-weight: 700;
    padding-top: h.rem(20px);
    font-family: var(--secondary-font);

    a {
      border-bottom: none;
    }

    @include h.breakpoint-m {
      padding-top: 0;
      padding-left: h.rem(27px);
    }
  }

  .moreInfo {
    font-size: h.rem(18px);
    font-weight: 400;
    padding-top: 0;

    p {
      margin: 0 0 h.rem(12px) 0;
    }

    @include h.breakpoint-m {
      font-size: h.rem(16px);
    }
  }

  .relatedLink {
    margin: 0;

    @include h.breakpoint-m {
      font-size: h.rem(16px);
      margin-left: h.rem(27px);
    }

    a {
      color: var(--grey-70);
      font-weight: 400;
      font-family: var(--secondary-font);
      text-transform: none;
      text-decoration-color: var(--accent-orange);
      text-decoration-thickness: h.rem(2px);

      @include h.breakpoint-m {
        font-size: h.rem(16px);
      }
    }

    a:hover {
      color: var(--default-color);
    }
  }

  .text a:hover {
    color: var(--black);
    text-decoration: underline;
    text-underline-offset: h.rem(4px);
    text-decoration-color: var(--accent-blue);
  }
}
