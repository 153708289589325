@use 'assets/styles/utils/helper' as h;

.selectCover {
  padding: 0 h.rem(20px);

  @include h.breakpoint-m {
    padding: 0;
  }
}

.content {
  display: grid;
  gap: 20px;

  @include h.breakpoint-m {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  @include h.breakpoint-l {
    grid-template-columns: 532px 348px;
    gap: 40px;
  }

  @include h.breakpoint-x {
    grid-template-columns: 676px 444px;
  }
}

.imageTeases {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include h.breakpoint-m {
    align-content: flex-start;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  @include h.breakpoint-l {
    display: grid;
    gap: 30px;
    grid-template-columns: 328px 1fr;
    height: max-content;
  }

  @include h.breakpoint-x {
    grid-template-columns: 400px 1fr;
  }
}

.imageTeasesSecondary {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include h.breakpoint-m {
    flex-flow: row nowrap;
    gap: 30px;
  }

  @include h.breakpoint-l {
    flex-direction: column;
    gap: 16px;
  }
}

.latestWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.latestHeader {
  color: var(--blue-70);
  display: flex;
  font-family: var(--lato);
  font-size: h.rem(20px);
  font-weight: 900;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;

  &::after {
    align-self: center;
    background: var(--default-background-image) repeat-x;
    background-size: h.rem(18px);
    content: ' ';
    flex: 0 1 auto;
    height: h.rem(18px);
    margin-left: h.rem(12px);
    width: 100%;
  }
}

.latestCards {
  border-left: 1px dashed #ccc;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
