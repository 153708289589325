@use 'assets/styles/utils/helper' as h;

.smorgasbord-tease {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-32);

  @include h.breakpoint-m-to-l-only {
    flex-direction: row;
    margin-bottom: 0;
  }

  &--M {
    flex-direction: column;
  }

  &--M2 {
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 0;

    @include h.breakpoint-s-only {
      flex: 1 1 auto;
    }

    @include h.breakpoint-m-to-l-only {
      flex-wrap: wrap;
      flex: 0 0 calc(50% - var(--space-20));
      align-content: flex-start;
      flex-direction: row;
      margin-top: var(--space-24);
      padding-top: var(--space-24);
      border-top: 1px solid var(--grey-40);

      &:first-of-type {
        margin-right: var(--space-40);
      }
    }
  }

  &--L,
  &--XL {
    flex-direction: column;
  }

  &__related-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &--M {
      flex-direction: column;
      row-gap: var(--space-20);
    }

    &--M1 {
      @include h.breakpoint-m {
        flex-direction: row;
      }
    }

    &--M2 {
      flex-direction: column;
      row-gap: var(--space-20);

      @include h.breakpoint-s-only {
        margin-left: var(--space-20);
        margin-right: var(--space-20);
      }

      @include h.breakpoint-m {
        flex: 0 1 100%; // should be on its own row
        flex-wrap: wrap;
      }

      @include h.breakpoint-l {
        flex-direction: row;
      }

      @include h.breakpoint-x {
        align-content: flex-start;
      }
    }

    &--L,
    &--XL {
      row-gap: var(--space-20);

      @include h.breakpoint-m {
        flex-direction: row;
      }
    }
  }

  &__main-content-wrapper {
    margin-right: 0;
    flex: 1;

    &--M2 {
      @include h.breakpoint-m-to-l-only {
        margin-right: var(--space-16);
        margin-bottom: 0;
      }
    }

    .smorgasbord-right-rail-video-logo__logo-container {
      width: auto;
      height: h.rem(16px);
      display: flex;
      align-items: stretch;
      opacity: inherit;

      .live-video-embed__logo-svg {
        margin-right: h.rem(12px);
        height: 100%;
        width: auto;
      }

      .smorgasbord-right-rail-video-logo__logo-line {
        width: 50%;
        margin: 0;
        border: none;
        flex-grow: 1;
        background-image:
          // create a 1px horizontal line
          linear-gradient(
            180deg,
            transparent 0% calc(50% - 1px),
            var(--grey-40) calc(50% - 1px) 50%,
            transparent 50%
          );
      }
    }

    .smorgasbord-right-rail-video-logo__logo-container.msnbc-new-logo {
      height: 26px;

      > svg {
        width: 100%;
        min-width: 151px;
        margin-right: 10px;
        left: -4px;
        position: relative;
      }
    }
  }

  &__meta-content-wrapper,
  .cvsdk-tease-msnbc__title {
    flex: 1;

    @include h.breakpoint-s-only {
      margin-left: var(--space-20);
      margin-right: var(--space-20);

      .cvsdk-tease__flag .cvsdk-tease-msnbc__dot {
        margin-left: 0;
      }
    }
  }
}

.smorgasbord-related-item {
  &__wrapper {
    margin-top: 0;
    padding-top: 0;
    display: flex;
    position: relative;
    flex-wrap: nowrap;

    // 2x2 layout
    &--M1,
    &--L,
    &--XL {
      @include h.breakpoint-m-only {
        flex: 1 0 calc(50% - 30px);

        &:nth-of-type(odd):not(:last-of-type) {
          margin-right: 30px;
        }
      }
    }

    // 2x2 layout
    &--M1,
    &--M2,
    &--L,
    &--XL {
      @include h.breakpoint-l-only {
        flex: 1 0 calc(50% - var(--space-40));

        &:nth-of-type(odd):not(:last-of-type) {
          margin-right: var(--space-40);
        }
      }
      // second half border
      &:nth-of-type(2)::after {
        @include h.breakpoint-l-only {
          content: '';
          width: 50%;
          border-top: 1px solid var(--grey-40);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    // 2x2 layout
    &--L,
    &--XL {
      @include h.breakpoint-x {
        flex: 1 0 calc(50% - var(--space-40));

        &:nth-of-type(odd):not(:last-of-type) {
          margin-right: var(--space-40);
        }
      }

      &:first-of-type {
        margin-top: var(--space-16);
        padding-top: var(--space-16);
      }

      &:nth-of-type(2) {
        @include h.breakpoint-m {
          padding-top: var(--space-16);
          margin-top: var(--space-16);
        }
      }
      // second half border
      &:nth-of-type(2)::after {
        @include h.breakpoint-m {
          content: '';
          width: 50%;
          border-top: 1px solid var(--grey-40);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    // first half border
    &:first-of-type::after {
      content: '';
      width: 50%;
      border-top: 1px solid var(--grey-40);
      position: absolute;
      top: 0;
      left: 0;
    }

    // top spacing
    &--M {
      &:first-of-type {
        margin-top: var(--space-16);
        padding-top: var(--space-16);
      }
    }

    &--M1,
    &--M2 {
      &:first-of-type {
        margin-top: var(--space-16);
        padding-top: var(--space-16);
      }

      &:nth-of-type(2) {
        @include h.breakpoint-l-only {
          padding-top: var(--space-16);
          margin-top: var(--space-16);
        }
      }
    }
  }
}
