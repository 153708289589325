@use 'assets/styles/utils/helper' as h;

.collectionTitle {
  --container--background-color: var(--default-color);
  --container--margin-left: 0;
  --container--margin-right: 0;
  --container--overflow: visible;
  --container--padding: 34px 40px 38px;
  --container--width: auto;
  --container--mirror--margin-left: 0;
  --container--mirror--margin-right: 0;
  --container--after--background-color: var(--default-color);
  --container--after--content: none;
  --container--after--left: 0;
  --container--after--height: 0;
  --container--after--position: absolute;
  --container--after--right: auto;
  --container--after--top: 0;
  --container--after--width: 780px;
  --container--after--z-index: 0;
  --container--after--mirror--left: auto;
  --container--after--mirror--right: 0;
  --title--background: initial;
  --title--color: var(--default-headline-color);
  --title--display: block;
  --title--font-size: 24px;
  --title--font-style: normal;
  --title--font-weight: normal;
  --title--letter-spacing: 0;
  --title--line-height: 1;
  --title--text-align: left;
  --title--text-transform: none;
  --title--mirror--text-align: left;
  --title--after--background: var(--default-color);
  --title--after--content: none;
  --title--after--display: block;
  --title--after--flex: initial;
  --title--after--height: 0;
  --title--after--left: 0;
  --title--after--position: absolute;
  --title--after--right: auto;
  --title--after--top: 50%;
  --title--after--width: 0;
  --title--after--mirror--left: var(--title--after--left);
  --dek--color: var(--white);
  --dek--font-family: var(--publico-text);
  --dek--font-size: 12px;
  --dek--line-height: 20px;
  --dek--margin: 10px 0 0;
  --dek--text-align: left;
  --dek--mirror--text-align: left;
  --button--background-color: var(--default-color);
  --button--border-color: var(--default-color);
  --button--text-color: var(--default-color);
  --button--hover--background-color: var(--white);
  --button--margin: var(--space-40) 0 0 auto;
  --button--mirror--margin: var(--button--margin);

  @include h.breakpoint-m {
    --container--padding: 40px 60px 60px;
    --title--font-size: 60px;
    --title--letter-spacing: 2px;
    --dek--font-size: 14px;
    --dek--margin: 20px 0 0;
  }

  @include h.breakpoint-l {
    --container--margin-left: -40px;
    --container--margin-right: 0;
    --container--padding: 60px 40px 40px;
    --container--mirror--margin-left: -40px;
    --container--mirror--margin-right: 0;
    --container--after--content: '';
    --container--after--height: 60px;
    --title--text-align: right;
    --dek--text-align: right;
    --button--margin: var(--space-40) 0 0 auto;
    --button--mirror--margin: var(--space-40) auto 0 0;
  }

  @include h.breakpoint-x {
    --container--margin-left: -40px;
    --container--margin-right: 0;
    --container--padding: 60px 40px 40px;
    --container--mirror--margin-left: -40px;
    --container--mirror--margin-right: 0;
    --container--after--content: '';
    --container--after--height: 50px;
  }
}

:global(body.news) .collectionTitle {
  --container--background-color: #f6f6f6;
  --container--after--background-color: #f6f6f6;
  --title--font-size: 26px;
  --title--line-height: 30px;
  --dek--color: var(--grey-60);

  @include h.breakpoint-m {
    --title--font-size: 60px;
    --title--line-height: 1;
    --title--letter-spacing: -0.6px;
  }

  @include h.breakpoint-l {
    --container--width: calc(100% + 80px);
  }
}

:global(body.better) .collectionTitle,
:global(body.think) .collectionTitle {
  --title--color: var(--white);
  --button--background-color: var(--white);
  --button--border-color: var(--white);
  --button--text-color: var(--white);
  --button--hover--background-color: var(--default-color);

  @include h.breakpoint-l {
    --container--width: calc(100% + 80px);
  }
}

:global(body.select) .collectionTitle {
  --container--background-color: var(--white);
  --container--margin-left: 40px;
  --container--margin-right: 40px;
  --container--overflow: hidden;
  --container--padding: 0;
  --container--mirror--margin-left: 20px;
  --container--mirror--margin-right: 20px;
  --title--display: flex;
  --title--font-size: 18px;
  --title--font-weight: 900;
  --title--line-height: 120%;
  --title--text-align: left;
  --title--after--background: repeat-x var(--default-background-image);
  --title--after--content: '';
  --title--after--flex: 1 0 auto;
  --title--after--height: 18px;
  --title--after--left: 8px;
  --title--after--position: relative;
  --title--after--width: auto;
  --dek--color: #3e4855;
  --dek--font-family: var(--lato);
  --dek--font-size: 13px;
  --dek--line-height: 18px;
  --dek--margin: 12px 0 0;
  --dek--text-align: left;

  @include h.breakpoint-m {
    --container--margin-left: 60px;
    --container--margin-right: 60px;
    --container--padding: 0;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: 0;
    --title--letter-spacing: 0;
    --dek--margin: 15px 0 0;
  }

  @include h.breakpoint-l {
    --container--margin-left: -40px;
    --container--margin-right: 0;
    --container--padding: 0;
    --container--after--content: none;
  }

  @include h.breakpoint-x {
    --container--margin-left: -40px;
    --container--margin-right: 0;
    --container--padding: 0;
    --container--after--content: none;
  }
}

:global(body.today) .collectionTitle {
  --container--background-color: var(--white);
  --container--padding: 40px;
  --container--mirror--margin-left: 0;
  --container--mirror--margin-right: 0;
  --title--font-style: italic;
  --title--after--content: '';
  --title--after--height: 1px;
  --title--after--left: -40px;
  --title--after--width: 30px;
  --title--after--mirror--left: var(--title--after--left);
  --dek--color: var(--grey-70);

  @include h.breakpoint-m {
    --container--padding: 40px;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: 0;
    --title--after--left: -60px;
    --title--after--width: 50px;
  }

  @include h.breakpoint-l {
    --container--padding: 64px 40px 18px;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: -40px;
    --container--after--content: none;
    --title--after--left: auto;
    --title--after--right: -80px;
    --title--after--width: 70px;
    --title--after--mirror--right: auto;
    --title--after--mirror--left: -80px;
  }

  @include h.breakpoint-x {
    --container--padding: 64px 40px 18px;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: -40px;
    --container--after--content: none;
  }
}

:global(body.msnbc) .collectionTitle {
  --title--color: var(--white);
  --button--background-color: var(--white);
  --button--border-color: var(--white);
  --button--text-color: var(--white);
  --button--hover--background-color: var(--default-color);

  @include h.breakpoint-l {
    --container--width: calc(100% + 80px);
    --title--letter-spacing: -0.6px;
  }
}

:global(body.noticias) .collectionTitle,
:global(body.deportes) .collectionTitle {
  --container--background-color: var(--neutral-10);
  --container--padding: 40px;
  --container--mirror--margin-left: 0;
  --container--mirror--margin-right: 0;
  --container--after--background-color: none;
  --title--color: var(--black);
  --title--font-size: 24px;
  --dek--color: var(--grey-60);

  @include h.breakpoint-m {
    --container--padding: 40px;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: 0;
    --title--font-size: 60px;
    --title--line-height: 1;
    --title--letter-spacing: -0.6px;
  }

  @include h.breakpoint-l {
    --container--padding: 64px 40px 18px;
    --container--width: calc(100% + 80px);
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: -40px;
  }

  @include h.breakpoint-x {
    --container--padding: 64px 40px 18px;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: -40px;
  }
}

:global(body.telemundo) .collectionTitle,
:global(body.entretenimiento) .collectionTitle,
:global(body.shows) .collectionTitle {
  --container--background-color: var(--neutral-30);
  --container--padding: 40px;
  --container--mirror--margin-left: 0;
  --container--mirror--margin-right: 0;
  --title--color: var(--blue-60);
  --title--font-size: 28px;
  --title--after--background: var(--red-50);
  --title--after--content: '';
  --title--after--height: 1px;
  --title--after--left: -40px;
  --title--after--width: 30px;
  --dek--color: var(--grey-60);

  @include h.breakpoint-m {
    --container--padding: 40px;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: 0;
    --title--font-size: 60px;
    --title--line-height: 1;
    --title--after--left: -60px;
    --title--after--width: 50px;
  }

  @include h.breakpoint-l {
    --container--padding: 64px 40px 18px;
    --container--after--content: none;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: -40px;
    --title--after--left: auto;
    --title--after--right: -80px;
    --title--after--width: 70px;
    --title--after--mirror--right: auto;
    --title--after--mirror--left: -80px;
  }

  @include h.breakpoint-x {
    --container--padding: 64px 40px 18px;
    --container--after--content: none;
    --container--mirror--margin-left: 0;
    --container--mirror--margin-right: -40px;
  }
}
