@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.listItem {
  background-color: var(--vli--background-color);
  display: var(--vli--display);
  height: var(--vli--height);
  margin: var(--vli--margin);
  padding: 0;
  position: relative;
  flex: 0 0 var(--vli--width);
  aspect-ratio: var(--vli--aspect-ratio);

  &:hover {
    background-color: var(--vli--hover--background-color);
    cursor: pointer;
  }

  &::after {
    background-image: linear-gradient(to bottom, rgba(var(--black-rgb), 0), rgba(var(--black-rgb), 100));
    bottom: 0;
    content: var(--vli--hover--after-content);
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 10%;
    z-index: 1;

    @include h.breakpoint-l {
      background-color: var(--vli--line--background-color);
      background-image: none;
      height: h.rem(1px);
      left: h.rem(216px);
      opacity: 0.5;
      right: auto;
      top: auto;
      width: h.rem(40px);
    }

    @include h.breakpoint-x {
      left: h.rem(256px);
    }
  }
}

.active {
  background-color: var(--vli--active--background-color);

  &::after {
    @include h.breakpoint-l {
      content: none;
    }
  }

  .image::after {
    content: var(--vli--active--image-after-content);
    background-color: rgba(0, 0, 0, .3);
    font-family: var(--secondary-font);
    font-size: h.rem(12px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    color: var(--white);
  }
}

.playing {
  pointer-events: none;

  @include h.breakpoint-m {
    .image {
      &::before,
      &::after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      &::before {
        background: var(--vli--playing--background);
        opacity: var(--vli--playing--opacity);
        z-index: 1;
      }

      &::after {
        align-items: center;
        color: var(--white);
        content: attr(data-active-text);
        display: flex;
        font-family: var(--vli--playing--font-family);
        font-size: h.rem(12px);
        font-weight: normal;
        justify-content: center;
        line-height: h.rem(12px);
        text-transform: uppercase;
        z-index: 2;
      }
    }
  }

  @include h.breakpoint-l {
    .image::before {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}

.image {
  flex: 0 0 var(--vli--image--flex-basis);
  width: var(--vli--image--width);
  margin-bottom: var(--vli--image--margin-bottom);
  min-width: var(--vli--image--min-width);
  position: relative;
  aspect-ratio: var(--vli--image--aspect-ratio);

  picture {
    display: block;
  }
}

.content {
  align-items: var(--vli--content--align-items);
  bottom: 0;
  color: var(--white);
  display: var(--vli--content--display);
  flex: var(--vli--content--flex);
  flex-flow: var(--vli--content--flex-flow);
  gap: var(--vli--content--gap);
  justify-content: var(--vli--content--justify-content);
  left: 0;
  padding: var(--vli--content--padding);
  position: var(--vli--content--position);
  right: 0;
  width: var(--vli--content--width);
  z-index: 2;
}

:global(body.today) {
  @include h.breakpoint-s-only {
    .listItem:first-child {
      display: block;
      margin: h.rem(0 -20px 20px);

      .image {
        width: 100%;
        margin-bottom: h.rem(15px);

        &::after {
          content: none;
        }
      }

      .content {
        align-items: flex-start;
        padding: h.rem(0 20px);
      }
    }
  }
}

.title {
  color: var(--vli--title--color);
  font-family: var(--vli--title--font-family);
  font-size: var(--vli--title--font-size);
  font-weight: normal;
  line-height: var(--vli--title--line-height);
  margin: 0;
  min-height: var(--vli--title--min-height);
  opacity: 1;
  padding: 0;
  transition: opacity 0.3s;

  .listItem:hover & {
    opacity: var(--vli--title--hover--opacity);
  }
}

.clamp {
  overflow: var(--vli--clamp--overflow);
  max-height: var(--vli--clamp--max-height);

  @supports (-webkit-line-clamp: inherit) and (-webkit-box-orient: vertical) and (display: -webkit-box) {
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-line-clamp: var(--vli--clamp--line-clamp);
    -webkit-box-orient: vertical;
    line-clamp: var(--vli--clamp--line-clamp);
    text-overflow: ellipsis;
    max-height: unset;
  }
}

.duration {
  color: var(--vli--duration--color);
  font-family: var(--vli--duration--font-family);
  font-size: var(--vli--duration--font-size);
  font-weight: normal;
  line-height: var(--vli--duration--line-height);
  margin: var(--vli--duration--margin);
  opacity: var(--vli--duration--opacity);
  padding: 0;
}

.durationInThumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: h.rem(47px);
  height: h.rem(24px);
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  font-family: var(--secondary-font);
  font-size: h.rem(12px);
  line-height: 1.5;
  color: var(--white);
}

.expired {
  align-items: center;
  background-color: rgba(var(--black-rgb), 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

.expiredText {
  color: var(--grey-50);
  font-family: var(--founders-cond);
  font-size: h.rem(14px);
}
