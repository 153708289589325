@use 'assets/styles/utils/helper' as h;

:root {
  --collection-lead--next-package--background-color: var(--white);
  --collection-lead--next-package--border: var(--default-color) 5px solid;
}

:global(body.select) {
  --collection-lead--next-package--background-color: transparent;
  --collection-lead--next-package--border: none;
}

:global(body.today) {
  --collection-lead--next-package--border: none;
  --collection-lead-links-text-decoration: underline;
  --collection-lead-links-text-decoration-thickness: 2px;
  --collection-lead-links-text-decoration-color: var(--accent-orange);
  --collection-lead-links-text-underline-offset: 5px;
}

.collectionLead {
  --collection-lead--header-offset: 60px;
  --collection-lead--next-pkg-pull: 60px;
  --collection-lead--color: var(--white);
  --collection-lead-a-hover--opacity: var(--opacity-70);
  --collection-lead-a-active--opacity: var(--opacity-50);
  // Spacing container
  --spacing--margin: 32px 0;
  // Header text
  --header--color: var(--white);
  --header--font-style: normal;
  --header--font-family: var(--default-headline-font-family);
  --header--font-weight: normal;
  --header--font-size: 32px;
  --header--margin: 0 0 16px 0;
  --header--line-height: var(--leading-100);
  --header--text-transform: uppercase;
  // Description
  --description--font-family: inherit;
  --description--font-size: 14px;
  --description--font-weight: normal;
  --description--margin: 4px 0;
  // Disclaimer
  --disclaimer--bg-color: var(--default-neutral);
  --disclaimer--color: var(--black);
  --disclaimer--font-family: var(--default-text-font-family);
  --disclaimer--font-size: 14px;
  --disclaimer--line-height: 16px;
  --disclaimer--margin: 16px 20px 0;
  --disclaimer--link-color: var(--default-color);
  // Link list wrapper
  --link-list-wrapper--font-size: 16px;
  --link-list-wrapper--color: var(--white);
  --link-list-wrapper--margin: 1.25rem 0 0;
  --link-list-wrapper--padding: 0;
  --link-list-wrapper--text-transform: none;
  // Link List
  --link-list--padding: 0;
  // Link list item
  --link-list-item--padding-right: 30px;
  --link-list-item--border-right: none;
  --link-list-item--margin-right: 0;
  --link-list-item--font-weight: normal;
  --link-list-item--line-height: normal;
  // Updates button
  --updates-button--background: none;
  --updates-button--border: 1px solid var(--white);
  --updates-button--color: var(--white);
  --updates-button--font-family: var(--founders-cond);
  --updates-button--font-size: 16px;
  --updates-button--font-weight: 400;
  --updates-button--hover-opacity: initial;
  --updates-button--margin: 1.25rem 0 0;
  --updates-button--line-height: var(--leading-100);
  --updates-button--padding: 22px 0;
  --updates-button--position: relative;
  --updates-button--width: 183px;

  @include h.breakpoint-m {
    --collection-lead--header-offset: 80px;
    --spacing--margin: 48px 0;
    --header--font-size: 60px;
    --description--font-size: 16px;
    --description--margin: 8px 0;
    --disclaimer--margin: 16px 30px 0;
    --link-list-wrapper--font-size: 20px;
    --link-list-wrapper--margin: 32px 0 0;
    --link-list-item--padding-right: 40px;
    --updates-button--margin: 3rem 0 0;
  }

  @include h.breakpoint-l {
    --header--font-size: 80px;
    --description--margin: 16px 0;
    --disclaimer--margin: 16px auto 0;
    --link-list-wrapper--font-size: 24px;
    --link-list-wrapper--margin: 48px 0 0;
    --updates-button--margin: 2rem 0 0;
  }

  @include h.breakpoint-x {
    --header--font-size: 96px;
  }

  :global(body.news) & {
    --collection-lead--header-offset: 60px;

    @include h.breakpoint-m {
      --collection-lead--header-offset: 60px;
    }
  }

  :global(body.think) & {
    --header--font-family: var(--founders-cond);
  }

  :global(body.select) & {
    --collection-lead--color: #2a2a2a;
    --collection-lead--header-offset: 60px;
    // Spacing container
    --spacing--margin: 32px 0 24px;
    // Header text
    --header--color: #2a2a2a;
    --header--font-family: var(--lato);
    --header--font-size: 24px;
    --header--font-style: normal;
    --header--font-weight: 900;
    --header--line-height: 120%;
    --header--text-transform: none;
    // Description
    --description--font-weight: 700;
    --description--margin: 18px 0 24px;
    // Disclaimer
    --disclaimer--color: var(--neutral-60);
    --disclaimer--bg-color: #f1f1f1;
    --disclaimer--link-color: var(--blue-60);
    // Link list wrapper
    --link-list-wrapper--margin: 16px 0 0;
    --link-list-wrapper--font-size: 12px;
    --link-list-wrapper--color: var(--blue-70);
    --link-list-wrapper--padding: 0;
    --link-list-wrapper--text-transform: uppercase;
    // Link list item
    --link-list-item--padding-right: 12px;
    --link-list-item--border-right: 1px solid var(--blue-70);
    --link-list-item--margin-right: 12px;
    --link-list-item--font-weight: 900;
    --link-list-item--line-height: 20px;
    // Updates button
    --updates-button--border: none;
    --updates-button--color: #005594;
    --updates-button--font-family: var(--lato);
    --updates-button--font-size: 14px;
    --updates-button--font-weight: 700;
    --updates-button--hover-opacity: var(--opacity-70);
    --updates-button--line-height: 1.7;
    --updates-button--margin: 0;
    --updates-button--padding: 0;
    --updates-button--width: auto;

    @include h.breakpoint-m {
      --spacing--margin: 32px 0 24px;
      --header--font-size: 36px;
      --description--font-size: 16px;
      --link-list-wrapper--font-size: 14px;
      --link-list-wrapper--padding: 0 20px;
      --link-list-item--line-height: 24px;
      --collection-lead--header-offset: 80px;
    }

    @include h.breakpoint-l {
      --collection-lead--header-offset: 80px;
      --spacing--margin: 32px 0;
      --header--font-size: 36px;
      --link-list-wrapper--margin: 20px auto 0;
    }

    @include h.breakpoint-x {
      --header--font-size: 36px;
    }
  }

  :global(body.msnbc) & {
    --collection-lead--header-offset: 60px;

    @include h.breakpoint-m {
      --collection-lead--header-offset: 60px;
    }
  }

  :global(body.today) & {
    --collection-lead--color: var(--grey-70);
    --collection-lead-a-hover--opacity: 1;
    --collection-lead-a-active--opacity: 1;
    --collection-lead--header-offset: 60px;
    // Link list wrapper
    --link-list-wrapper--color: var(--grey-70);
    --link-list-wrapper--margin: #{h.rem(0 -20px 16px)};
    // Link List
    --link-list--padding: 0 #{h.rem(30px)};
    // Link list item
    --link-list-item--font-weight: bold;
    // Header text
    --header--color: var(--grey-60);
    --header--font-family: var(--primary-font);
    --header--font-size: #{h.rem(30px)};
    --header--font-style: normal;
    --header--font-weight: var(--light);
    --header--line-height: 1.5;
    --header--text-transform: none;
    --spacing--margin: #{h.rem(10px 0)};
    //--collection-lead--header-offset: 0;
    --header--margin: 0;
    --header-after--left: #{h.rem(-10px)};
    --header-after--scaleX: 1;
    // Logo wrapper
    --logo-wrapper--padding-bottom: #{h.rem(13px)};
    --logo-wrapper-after--left: #{h.rem(-10px)};
    --logo-wrapper-after--scaleX: 0.85;
    --logo-wrapper--line-height: 1.7;
    // Description
    --description--font-family: var(--secondary-font);
    --description--font-size: #{h.rem(16px)};
    --description--margin: #{h.rem(16px 0 25px)};

    @include h.breakpoint-m {
      --spacing--margin: 32px 0 48px;
      --collection-lead--header-offset: 60px;
    }

    @include h.breakpoint-l {
      --link-list--padding: 0;
      --spacing--margin: #{h.rem(28px 0 60px)};
      // Description
      --description--font-size: #{h.rem(24px)};
      // Logo wrapper
      --logo-wrapper--padding-bottom: #{h.rem(25px)};
      --logo-wrapper-after--left: #{h.rem(-25px)};
      --logo-wrapper-after--scaleX: 1.1;
      --logo-wrapper--line-height: 1.2;
      // Header text
      --header--font-size: #{h.rem(80px)};
      --header--line-height: 1.2;
      --header-after--left: #{h.rem(-25px)};
      --header-after--scaleX: 1.1;
    }
  }

  :global(body.today) &.hasBkgImg {
    --collection-lead--header-offset: #{h.rem(130px)};

    @include h.breakpoint-l {
      --collection-lead--header-offset: #{h.rem(150px)};
    }
  }

  :global(body.select .product-waffle) &.hasBkgImg {
    --collection-lead--header-offset: #{h.rem(130px)};

    @include h.breakpoint-l {
      --collection-lead--header-offset: #{h.rem(150px)};
    }
  }

  :global(body.select .product-waffle) &,
  :global(body.today .product-waffle) & {
    --spacing--margin: 12px 0 0;

    @include h.breakpoint-l {
      --spacing--margin: 28px 0 30px;
    }
  }
}
