@use 'assets/styles/utils/helper' as h;

.media-label {
  --media-label-icon-background-color: var(--default-color);
  --media-label-type-icon-inline-color: var(--default-color);
  --media-label-type-icon-width: #{h.rem(30px)};
  --media-label-type-icon-height: #{h.rem(32px)};
  --media-label-slideshow-unibrow-width: #{h.rem(96px)};
  --media-label-video-unibrow-width: #{h.rem(68px)};
  --media-label-type-icon-inline-font-size: #{h.rem(8px)};
}
