@use 'assets/styles/utils/helper' as h;

.msnbcDaily__footer {
  border-bottom: 2px solid var(--blue-40);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0 h.rem(40px) h.rem(60px);
  position: relative;

  @include h.breakpoint-s-only {
    flex-wrap: wrap;
    padding-bottom: h.rem(40px);
  }

  .msnbcDaily__footer-info-text {
    align-items: center;
    color: var(--blue-70);
    display: flex;
    font-family: var(--publico-hed);
    font-size: var(--text-16);
    font-style: italic;
    font-weight: 300;
    line-height: 125%;
    margin: 0 30px 0 0;
    width: 260px;

    @include h.breakpoint-s-only {
      text-align: center;
      margin: 0 h.rem(40px) h.rem(16px) h.rem(40px);
      padding-right: 0;
    }
  }

  .msnbcDaily__action-button {
    height: 64px;
    width: 260px;

    @include h.breakpoint-s-only {
      float: none;
      width: 100%;
    }
  }
}
