@use 'assets/styles/utils/helper' as h;

.filterContainer {
  --filter--open--background-color: var(--white);
  --filter--icon--color: inherit;
  --filter--flex-grow: 1;

  // Category / heading / dropdown text
  --filter--name--border-color: var(--grey-70);
  --filter--name--border-style: solid;
  --filter--name--border-width: 0;
  --filter--name--border:
    0 var(--filter--name--border-style)
    var(--filter--name--border-color);
  --filter--name--color: var(--grey-70);
  --filter--name--font-family: var(--founders-mono);
  --filter--name--font-size: var(--text-12);
  --filter--name--font-weight: var(--normal);
  --filter--name--letter-spacing: var(--tracking-tight);
  --filter--name--text-transform: uppercase;
  --filter--name--border-radius: 100px;
  --filter--name--width: #{h.rem(340px)};
  --filter--name--max-width: none;
  --filter--name--padding: #{h.rem(20px)} #{h.rem(5px)};
  // Multiple-select variation
  --filter--name--multiple--hover--border-color: var(--grey-70);
  --filter--name--multiple--border-style: solid;
  --filter--name--multiple--border-width: 0 0 1px 0;
  // Dropdown open
  --filter--name--open--background-color: var(--default-color);
  --filter--name--open--color: var(--filter--name--color);

  // Option text
  --filter--label--background-color: var(--white);
  --filter--label--color: var(--default-color);
  --filter--label--font-family: var(--default-headline-font-family);
  --filter--label--font-size: var(--text-18);
  --filter--label--font-weight: var(--normal);
  --filter--label--line-height: var(--leading-100);
  --filter--label--text-transform: uppercase;
  --filter--option--selected-border: none;
  // Multiple-select option variation
  --filter--label--multiple--font-size: var(--text-18);
  --filter--label--multiple--font-weight: 400;
  --filter--label--multiple--line-height: var(--leading-100);
  --filter--label--multiple--text-transform: none;
  --filter--label--multiple--border: none;
  // Option hover
  --filter--label--hover--background-color: var(--default-color);
  --filter--label--hover--color: var(--white);
  --filter--label--hover--opacity: 1;
  --filter--label--hover--border: none;
  --filter--label--hover--input: var(--white);
  // Option selected
  --filter--option--selected--background-color: var(--default-color);
  --filter--option--selected--color: var(--white);
  // Accessibility
  --filter-outline: solid 3px #57ad57;
  // desktop button widths
  --filter--button--max-width: #{h.rem(335px)};
  --filter--button--text-transform: none;

  @include h.breakpoint-l {
    --filter--name--border-color: var(--white);
    --filter--name--border-width: 2px;
    --filter--name--color: var(--white);
    --filter--name--font-family: var(--default-headline-font-family);
    --filter--name--font-size: var(--text-20);
    --filter--name--letter-spacing: var(--tracking-normal);
    --filter--name--multiple--border-color: var(--white);
    --filter--name--multiple--border-width: 2px;
    --filter--label--background-color: transparent;
  }

  :global(body.noticias) &,
  :global(body.deportes) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --filter--label--color: var(--blue-60);
    --filter--label--font-family: var(--founders-cond);
    --filter--option--selected--background: var(--red-50);
  }

  :global(body.news) & {
    --filter--label--color: var(--blue-70);
    --filter--option--selected--background: var(--blue-40);
  }

  :global(body.msnbc) & {
    --filter--name--open--background-color: var(--yellow-40);
    --filter--name--open--color: var(--grey-70);
    --filter--label--color: var(--blue-70);
    --filter--label--hover--background-color: var(--yellow-40);
    --filter--option--selected--background-color: var(--blue-40);

    @include h.breakpoint-l {
      --filter--option--selected--background-color: var(--yellow-40);
    }
  }

  :global(body.think) & {
    --filter--name--open--background-color: var(--grey-70);
    --filter--name--open--color: var(--white);
    --filter--name--multiple--border-color: var(--grey-40);
    --filter--label--color: var(--grey-70);
    --filter--label--font-family: var(--publico-text);
    --filter--label--font-size: var(--text-18);
    --filter--label--font-weight: var(--normal);
    --filter--label--line-height: var(--leading-125);
    --filter--label--text-transform: none;
    --filter--label--hover--background-color: var(--grey-70);
    --filter--option--selected--background-color: var(--red-40);

    @include h.breakpoint-l {
      --filter--name--text-transform: none;
      --filter--name--multiple--border-color: var(--white);
      --filter--label--multiple--font-size: var(--text-18);
      --filter--label--multiple--line-height: var(--leading-125);
      --filter--option--selected--background-color: var(--grey-70);
    }
  }

  :global(body.better) & {
    --filter--name--open--background-color: var(--purple-70);
    --filter--name--multiple--border-color: var(--grey-40);
    --filter--label--color: var(--purple-70);
    --filter--label--hover--background-color: var(--purple-70);
    --filter--option--selected--background-color: var(--teal-30);

    @include h.breakpoint-l {
      --filter--name--multiple--border-color: var(--white);
      --filter--option--selected--background-color: var(--purple-70);
    }
  }

  :global(body.select) & {
    --filter--open--background-color: var(--blue-30);
    --filter--label--color: var(--black);
    --filter--label--font-family: var(--lato);
    --filter--label--font-size: #{h.rem(18px)};
    --filter--label--multiple--font-size: #{h.rem(18px)};
    --filter--label--multiple--font-weight: var(--normal);
    --filter--name--color: var(--grey-70);
    --filter--name--font-family: var(--lato);
    --filter--name--font-size: #{h.rem(16px)};
    --filter--name--font-weight: 700;
    --filter--name--letter-spacing: var(--tracking-normal);
    --filter--name--open--background-color: var(--blue-70);
    --filter--option--selected--background-color: var(--blue-70);
    --filter--option--selected--color: var(--white);
    --filter--label--hover--input: rgb(4, 119, 201, 0.5);
    --filter--label--line-height: 27px;
    --filter--text--line-height: 27px;
    --filter--button--hover--background-color: var(--blue-60);
    --filter--button--hover--font-color: var(--white);
    --filter--button--hover--top-border-radius: 20px 20px 0 0;
    --filter--button--hover--bottom-border-radius: 0 0 20px 20px;

    @include h.breakpoint-l {
      --filter--name--border-color: var(--blue-60);
      --filter--name--multiple--border-color: var(--blue-60);
      --filter--name--color: var(--blue-60);
    }
  }

  :global(body.today) & {
    --filter--label--font-size: var(--text-19);
    --filter--label--font-weight: var(--normal);
    --filter--label--line-height: 27px;
    --filter--text--line-height: 27px;
    --filter--label--multiple--font-size: var(--text-19);
    --filter--label--multiple--font-weight: normal;
    --filter--name--font-family: var(--secondary-font);
    --filter--name--border-radius: 0;
    --filter--name--border-color: var(--default-color);
    --filter--label--multiple--line-height: var(--leading-125);
    --filter--name--multiple--border-color: var(--default-color);
    --filter--name--padding: #{h.rem(10px 5px)};
    --filter--name--open--background-color: var(--accent-orange);
    --filter--name--color: var(--black);
    --filter--name--font-size: var(--text-18);
    --filter--name--text-transform: none;
    --filter--name--font-weight: 700;
    --filter--label--color: var(--black);
    --filter--label--text-transform: uppercase;
    --filter--label--multiple--text-transform: none;
    --filter--label--multiple--border: 1px solid var(--black);
    --filter--label--font-family: var(--secondary-font);
    --filter--label--hover--background-color: var(--accent-orange);
    --filter--label--hover--border: 1px solid var(--grey-50);
    --filter--label--hover--input: var(--accent-purple-light);
    --filter--option--selected--color: var(--black);
    --filter--option--selected--background-color: var(--accent-orange);
    --filter--icon--color: var(--default-color);
    --filter--button--hover--background-color: var(--accent-orange);
    --filter--button--hover--font-color: var(--black);
    --filter--button--hover--top-border-radius: 0;
    --filter--button--hover--bottom-border-radius: 0;

    @include h.breakpoint-m {
      --filter--label--line-height: 21px;
    }

    @include h.breakpoint-l {
      --filter--name--width: #{h.rem(165px)};
      --filter--name--font-weight: --normal;
      --filter--label--multiple--border: none;
      --filter--label--multiple--font-size: var(--text-16);
      --filter--label--multiple--line-height: var(--leading-150);
      --filter--name--multiple--border-width: 1px;
      --filter--open--border: solid var(--black);
      --filter--open--border-width: 0 1px 1px 1px;
      --filter--open--multiple--border: 1px solid var(--black);
      --filter--button--max-width: #{h.rem(165px)};
      --filter--button--text-transform: uppercase;
    }

    @include h.breakpoint-x {
      --filter--name--width: #{h.rem(210px)};
      --filter--button--max-width: #{h.rem(210px)};
    }

    &.multiple {
      --filter--name--max-width: #{h.rem(462px)};
      --filter--name--multiple--border-style: 0;
    }
  }

  :global(body.select .product-waffle) & {
    --filter--name--font-size: #{h.rem(16px)};
    --filter--name--font-weight: 700;
  }
}
