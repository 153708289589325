@use 'assets/styles/utils/helper' as h;

.embedInfo {
  --label--color: var(--default-color);
  --label--font-family: var(--publico-txt);
  --label--font-size: var(--text-20);
  --label--font-size-xl: var(--text-28);
  --label--font-weight: var(--light);
  --label--line-height: var(--leading-150);
  --label--breaking--color: #{h.$news-red-30};
  --headline--color: var(--grey-70);
  --headline--font-family: var(--founders-cond);
  --headline--font-style: normal;
  --headline--line-height: var(--leading-100);
}

:global(.think) .embedInfo {
  --label--color: var(--grey-70);
  --label--font-family: var(--founders-cond);
  --label--font-size: var(--text-28);
  --label--font-size-xl: var(--text-40);
  --label--font-weight: var(--normal);
  --label--line-height: var(--leading-100);
  --label--breaking--color: var(--default-color);
  --headline--font-family: var(--publico-hed);
  --headline--font-style: italic;
  --headline--line-height: var(--leading-150);
}

:global(.today) .embedInfo {
  --label--color: var(--purple-70);
  --label--font-family: var(--founders-cond);
  --label--font-size: var(--text-28);
  --label--font-size-xl: var(--text-40);
  --label--font-weight: var(--normal);
  --label--line-height: var(--leading-100);
  --label--breaking--color: var(--default-color);
  --headline--color: var(--purple-70);
  --headline--font-family: var(--publico-hed);
  --headline--font-style: italic;
  --headline--line-height: var(--leading-150);
}

:global(.noticias) .embedInfo {
  --label--color: var(--blue-40);
  --label--breaking--color: var(--red-40);
}
