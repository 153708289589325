@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.promoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25em;
  margin-bottom: 8px !important;
}

.mainText {
  font-weight: 400;
  font-family: var(--waffle--promo-code-font);
  color: var(--grey-70);
  font-size: h.rem(12px);
  line-height: 18px;

  &.isLarge {
    font-size: h.rem(14px);
    line-height: 21px;
  }
}

.promoCodeContainer {
  border: 1.5px dashed var(--waffle--promo-code-icon-color);
  display: flex;
  max-height: 18px;
  padding: 0 1px;

  .promoText {
    font-weight: var(--waffle--promo-code-font-weight);
    font-family: var(--waffle--promo-code-font);
    color: var(--grey-70);
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: h.rem(12px);
    line-height: 15px;
    max-height: inherit;

    &.isLarge {
      font-size: h.rem(14px);
    }
  }

  &:hover {
    background-color: var(--waffle--promo-code-hover-background-color);
    cursor: pointer;
  }
}

.promoCodeIcon {
  margin-bottom: 5px;
}

.promoCodeIconShape {
  fill: var(--waffle--promo-code-icon-color);
}
