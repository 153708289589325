@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.background.show-lead-background__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.show-lead-background__container {
  display: flex;
  width: 100%;
  margin: 0 auto;

  @include h.breakpoint-x {
    width: 1240px;
  }
}

.show-lead-background__image {
  background-position: center right !important;
  transform-origin: unset !important;

  @media screen and (min-width: 500px) {
    background-size: contain !important;
  }

  @include h.breakpoint-m {
    background-size: initial !important;
  }

  @include h.breakpoint-x {
    &::after {
      content: var(--show-lead-background-image-content-after);
      display: block;
      width: 40px;
      height: 100%;
      background-image: var(--show-lead-background-image-fade);
      position: absolute;
      right: calc(50% - (1240px / 2));
      top: 0;
      bottom: 0;
    }
  }
}

.show-lead-background__hide {
  display: none;
}

.show-lead-background__hide--on-large {
  @include h.breakpoint-m {
    display: none;
  }
}

.show-lead-background__hide--on-mobile {
  display: none;

  @include h.breakpoint-m {
    display: block;
  }
}

.show-lead-background__gradient {
  background-image: var(--show-lead-background-gradient);
  background-color: transparent;
  background-blend-mode: screen, normal;

  &.hasImage {
    background-image: var(--show-lead-background-gradient-with-image);
  }
}
