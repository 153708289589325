@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.videoPkg {
  position: relative;
  width: var(--vpkg--package--width);
  margin-right: var(--vpkg--package--margin-right);
  margin-left: var(--vpkg--package--margin-left);
}

.titleContainer {
  display: var(--vpkg--package-title--display);
  align-self: var(--vpkg--package-title--align-self);
  background-color: var(--vpkg--package-title--background-color);
  color: var(--vpkg--package-title--color);
  flex-direction: column;
  justify-content: center;
  margin-left: var(--vpkg--package-title--margin-left);
  padding: var(--vpkg--package-title--padding);
  width: var(--vpkg--package-title--width);
  min-height: var(--vpkg--package-title--min-height);
}

.titleText {
  border-color: var(--vpkg--package-title-text--border-color);
  color: var(--vpkg--package-title-text--color);
  font-family: var(--vpkg--package-title-text--font-family);
  font-size: var(--vpkg--package-title-text--font-size);
  font-style: var(--vpkg--package-title-text--font-style);
  font-weight: var(--vpkg--package-title-text--font-weight);
  letter-spacing: normal;
  line-height: var(--vpkg--package-title-text--line-height);
  margin: 0;
  position: relative;

  &::before {
    border-top: var(--vpkg--package-title-line--border-top);
    content: var(--vpkg--package-title-line--content);
    display: block;
    margin-right: 12px;
    position: absolute;
    right: 100%;
    top: 0.5em;
    width: 130px;
  }

  .isLeadPkgAdj & {
    &::before {
      content: none;
      display: none;
    }
  }
}

.titleDescription {
  font-family: var(--vpkg--package-title-description--font-family);
  font-size: var(--vpkg--package-title-description--font-size);
  line-height: var(--vpkg--package-title-description--line-height);
  margin: var(--vpkg--package-title-description--margin);
}

.grid {
  display: var(--vpkg--grid--display);
  max-width: var(--vpkg--grid--max-width);
  margin: var(--vpkg--grid--margin);

  @include h.breakpoint-l {
    display: flex;
  }
}

.columnVideo {
  display: flex;
  flex-direction: column;
  flex: 0 0 var(--vpkg--columnVideo--flex-basis);
  margin-right: var(--vpkg--columnVideo--margin-right);
  margin-bottom: var(--vpkg--columnVideo--margin-bottom);
}

.columnList {
  display: var(--vpkg--columnList--display);
  flex-direction: var(--vpkg--columnList--flex-direction);
  flex: var(--vpkg--columnList--flex);
  padding: var(--vpkg--columnList--padding);

  &.noSeeAll {
    margin-bottom: var(--vpkg--columnVideo--margin-bottom);
  }
}

.videoOuter {
  display: var(--vpkg--videoOuter--display);
  position: relative;
}

.videoInner {
  display: var(--vpkg--videoInner--display);
  position: relative;
  height: var(--vpkg--videoInner--height);
  overflow: hidden;

  @include h.breakpoint-m {
    background-color: var(--black);
    color: var(--neutral-10);
    width: 100%;
  }
}

.meta {
  display: none;
  padding: 20px 0 0 20px;
  pointer-events: none;

  @include h.breakpoint-m {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    color: var(--white);
    display: block;
    height: h.rem(160px);
    left: 0;
    margin: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 500ms ease-in;
    width: 100%;
    z-index: 1;
  }

  @include h.breakpoint-l {
    padding: 20px 0 0 24px;
  }

  .adPlaying & {
    display: none;
  }

  .infoHidden & {
    opacity: 0;
    transition: opacity 500ms ease-out;
  }
}

.title {
  font-family: var(--vpkg--video-title--font-family);
  font-size: var(--vpkg--video-title--font-size);
  line-height: var(--vpkg--video-title--line-height);
  margin: 0;
  width: calc(100% - 60px);

  a {
    pointer-events: all;
  }

  // title should sit lower when muted to avoid overlap with unmute button
  .muted & {
    margin-top: var(--vpkg--video-title--margin-top);
  }
}

.duration {
  font-family: var(--vpkg--video-duration--font-family);
  font-size: var(--text-12);
  padding-top: h.rem(8px);
}

.share {
  pointer-events: all;

  .muted & {
    margin-top: h.rem(44px);
  }

  // Set share to pointer-events none so adControl pause can be clicked
  .infoHidden & {
    pointer-events: none;
  }
}

.scroll {
  height: var(--vpkg--scroll--height);

  @include h.breakpoint-m-only {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.list {
  display: var(--vpkg--list--display);
}

.buttonWrapper {
  display: flex;
  justify-content: var(--vpkg--see-all-btn--justify-content);
}

.seeAllWrapper {
  background-color: var(--vpkg--see-all-wrapper--background-color);
  padding: h.rem(30px 40px);

  @include h.breakpoint-m {
    display: none;
  }

  @include h.breakpoint-l {
    display: block;
  }
}

.videoPkg .seeAllBtn {
  color: var(--vpkg--see-all-btn--color);
  background-image: var(--vpkg--see-all-btn--background-image);
  border: var(--vpkg--see-all-btn--border);

  &:hover {
    color: var(--vpkg--see-all-btn--hover--color);
    border: var(--vpkg--see-all-btn--border);
  }
}

.buttonTilt {
  width: var(--vpkg--buttonTilt--width);
  align-self: var(--vpkg--buttonTilt--align-self);
  margin-top: var(--vpkg--buttonTilt--margin-top);
  margin-right: var(--vpkg--buttonTilt--margin-right);
}
