:root {
  --two-up-articles-background-color: var(--white);
  --tease-card-two-up-title-line-heights: 100%;
}

.today {
  --two-up-articles-background-color: var(--neutral-10);
  --tease-card-two-up-title-line-heights: 125%;
}

.think {
  --tease-card-two-up-title-line-heights: 125%;
}

.select {
  --tease-card-two-up-title-line-heights: 120%;
}
