@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

:global(.leadPkg-collectionsLead) {
  @include h.breakpoint-s-only {
    :global(.multi-storyline:first-of-type) {
      padding-top: 20px !important;
    }
  }
}

// Apply border to next package
:global(.lead-type--collectionsLead .layout-index-1 .pkg-index-2:first-of-type) {
  background: var(--collection-lead--next-package--background-color);
  position: relative;

  & > :global(.collectionLead-border) {
    border-top: var(--collection-lead--next-package--border);
  }
}

@include h.breakpoint-m {
  // Pull next package up into this one
  .collectionLead,
  :global(.pkg .pkg:last-child).collectionLead {
    margin-bottom: calc(var(--collection-lead--next-pkg-pull) * -1);
  }
}

.hidden {
  visibility: hidden;
}

.toggleSpacing {
  padding-bottom: 80px;
}

@include h.breakpoint-s-only {
  .toggleSpacing {
    padding-bottom: 0;
  }
}

.collectionLead {
  color: var(--collection-lead--color);

  a {
    &:hover {
      opacity: var(--collection-lead-a-hover--opacity);
    }

    &:active {
      opacity: var(--collection-lead-a-active--opacity);
    }
  }
}

.gridContainer {
  text-align: center;
  width: 100%;
}

.gridItem {
  padding: 0 var(--spacing-5);

  @include h.breakpoint-m {
    padding: 0;
  }
}

.spacingOuter {
  padding-top: var(--collection-lead--header-offset);

  @include h.breakpoint-m {
    padding-bottom: var(--collection-lead--next-pkg-pull);
  }
}

// 0 top padding on Collection Leads not in the first layout position
// 0 top padding on Collection Leads in the first layout position but are not the first one in that position
:global(.select) .spacingOuter:not(:global(.layout-index-1) .spacingOuter),
:global(.select) .spacingOuter:not(:global(.layout-index-1:first-child) .spacingOuter) {
  padding-top: var(--collection-lead--header-offset);

  @include h.breakpoint-s-only {
    padding-top: 0;
  }
}

.spacingInner {
  margin: var(--spacing--margin);
}

.header {
  color: var(--header--color);
  font-size: var(--header--font-size);
  font-family: var(--header--font-family);
  font-style: var(--header--font-style);
  font-weight: var(--header--font-weight);
  line-height: var(--header--line-height);
  margin: var(--header--margin);
  text-transform: var(--header--text-transform);

  :global(body.today) & {
    @include h.title-underline(
      var(--grey-20),
      var(--header--font-size),
      var(--header--line-height)
    );

    &::after {
      left: var(--header-after--left);
      transform: scaleX(var(--header-after--scaleX));
    }
    display: inline-block;
  }
}

.logoWrapper {
  display: flex;
  justify-content: center;
  margin: 0;

  :global(body.today) & {
    @include h.title-underline(
      var(--grey-20),
      var(--header--font-size),
      var(--logo-wrapper--line-height)
    );
    display: inline-block;
    padding-bottom: var(--logo-wrapper--padding-bottom);

    &::after {
      left: var(--logo-wrapper-after--left);
      transform: scaleX(var(--logo-wrapper-after--scaleX));
    }
  }

  @include h.breakpoint-m {
    width: auto;
    height: auto;
  }

  @include h.breakpoint-l {
    display: inline-block;
    max-width: h.rem(500px);
  }
}

.logo {
  @include h.object-fit(contain);
  display: block;
  height: auto;
  max-height: 100px;
  max-width: 66%;
  margin: auto;
  width: auto;
  z-index: 1;

  @include h.breakpoint-l {
    max-height: 150px;
    max-width: 100%; // 100% of the 500px `.logoWrapper` container above
  }
}

.description {
  font-family: var(--description--font-family);
  font-size: var(--description--font-size);
  font-weight: var(--description--font-weight);
  line-height: var(--leading-150);
  margin: var(--description--margin);
}

.disclaimer {
  background-color: var(--disclaimer--bg-color);
  color: var(--disclaimer--color);
  font-family: var(--disclaimer--font-family);
  font-size: var(--disclaimer--font-size);
  line-height: var(--disclaimer--line-height);
  margin: var(--disclaimer--margin);
  padding: h.rem(20px);

  // stylelint-disable-next-line no-descending-specificity
  a {
    color: var(--disclaimer--link-color);
    font-weight: var(--bold);
  }
}

.linkListWrapper {
  color: var(--link-list-wrapper--color);
  font-size: var(--link-list-wrapper--font-size);
  margin: var(--link-list-wrapper--margin);
  padding: var(--link-list-wrapper--padding);
  text-transform: var(--link-list-wrapper--text-transform);
}

.linkList {
  overflow-x: auto;
  white-space: nowrap;

  @include h.breakpoint-x {
    justify-content: center;
  }
  padding: var(--link-list--padding);
}

.linkListItem {
  display: inline;
  font-weight: var(--link-list-item--font-weight);
  line-height: var(--link-list-item--line-height);

  &:not(:last-child) {
    border-right: var(--link-list-item--border-right);
    margin-right: var(--link-list-item--margin-right);
    padding-right: var(--link-list-item--padding-right);
  }

  :global(body.today) & {
    a {
      display: inline-block;
      position: relative;
      text-decoration: var(--collection-lead-links-text-decoration);
      text-decoration-thickness: var(--collection-lead-links-text-decoration-thickness);
      text-decoration-color: var(--collection-lead-links-text-decoration-color);
      text-underline-offset: var(--collection-lead-links-text-underline-offset);

      &:hover {
        color: var(--primary-color);
        opacity: 1;
        text-decoration-color: var(--primary-color);
      }
    }
  }
}

// CTA button
.updatesButton {
  background: var(--updates-button--background);
  border: var(--updates-button--border);
  color: var(--updates-button--color);
  font-family: var(--updates-button--font-family);
  font-size: var(--updates-button--font-size);
  font-weight: var(--updates-button--font-weight);
  line-height: var(--updates-button--line-height);
  margin: var(--updates-button--margin);
  padding: var(--updates-button--padding);
  position: var(--updates-button--position);
  text-transform: uppercase;
  width: var(--updates-button--width);

  &:hover {
    color: var(--default-color);
    background: var(--white);
  }
}

.tiltButton {
  margin-top: h.rem(20px);
}

:global(body.select .pkg.lead).collectionLead .separator {
  margin: 16px 0 0;
  padding: 18px 0 0;
  position: relative;

  &::after {
    background-color: #e4f0f9;
    content: '';
    height: 4px;
    left: calc((100vw - 100%) / 2 * -1);
    position: absolute;
    top: 0;
    width: 100vw;
  }
}

:global(body.select .product-waffle .pkg.lead).collectionLead .separator {
  display: none;
}
