@use 'assets/styles/utils/helper' as h;

:root {
  --related-content-tease-headline-link-color: var(--grey-70);
  --related-content-tease-headline-link-line-height: var(--leading-100);
  --related-content-tease-headline-link-font-family: var(--default-headline-font-family);
  --related-content-tease-headline-link-font-weight: 600;
  --related-content-tease-headline-font-size: 20px;
  --related-content-tease-headline-with-media-display: block;
  --related-content-tease-headline-with-media-margin-left: 8px;
  --related-content-tease-headline-with-media-narrow-margin-left: 0;
  --related-content-tease-badge-label-display: inline-block;
  --related-content-tease-badge-label-height: auto;
  --related-content-tease-badge-label-span-margin-bottom: 2px;
  --related-content-tease-badge-label-span-vertical-align: auto;
  --related-content-tease-badge-label-narrow-height: auto;
  --related-content-tease-badge-label-narrow-display: block;
  --related-content-tease-badge-label-narrow-span-margin-bottom: 3px;
  --related-content-tease-box-display: flex;
  --related-content-tease-box-narrow-flex-direction: column;
  --related-content-tease-box-narrow-justify-content: center;
  --related-content-tease-box-narrow-aligh-items: center;
  --related-content-tease-box-narrow-gap: 16px;
}
