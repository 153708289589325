@use 'assets/styles/utils/helper' as h;

.standard-layout {
  --side-related--m--screen--width: calc(100% - 412px);
  --side--related--l--screen--width: calc(100% - 341px);
  --side--related--x--screen--medium--media--width: calc(100% - 440px);
  --side--related--x--screen--large--media--width: calc(100% - 480px);
  --bottom--side--related--l--screen--width: calc(100% - 341px);
  --bottom--related--under--media--m--screen--width: 412px;
  --bottom--related--under--media--l--screen--width: 321px;
  --bottom--related--under--media--x--screen--width: 420px;
  --bottom--related--under--large--media--m--screen--width: 412px;
  --bottom--related--under--large--media--l--screen--width: 321px;
  --bottom--related--under--large--media--x--screen--width: 460px;
  --flex--column--gap: 20px;
  --bottom-related--no--media--l--screen--width: calc(50% - 10px);
  --bottom-related--no--media--x--screen--width: calc(50% - 10px);
}
